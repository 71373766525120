import ApiService from "@/services/api.service";
import { ActionTree } from "vuex";
import _ from 'lodash';
import { successMessage } from "@/utils/messages-handlers";
import { DashboardState, RootState } from "@/types/store/clientzone";

export const actions: ActionTree<DashboardState, RootState> = {
  FETCH_DASHBOARD: ({ getters, dispatch, rootGetters }, payload = {}): void => {
    if (getters["GET_SELECTED_VENUES_IDS"].length) {
      if (_.isEmpty(payload)) {
        let uniqueVenuesIdsString: string = "";
        getters["GET_SELECTED_VENUES_IDS"].forEach((v: number) => { uniqueVenuesIdsString += `venue_ids=${v}&` })
        payload = {
          venue_ids: uniqueVenuesIdsString,
          start_at: getters["GET_START_AT"],
          end_at: getters["GET_END_AT"],
          venue_owner_ids: rootGetters["companyModule/GET_COMPANY_ID"]
        }
      }


      dispatch("FETCH_COUNTS", payload);
      dispatch("FETCH_LISTS", payload);
      dispatch("FETCH_STATS", payload);
      if (payload.filter && payload.autoupdate) {
        successMessage('Dashboard updated', 5000)
      }
    }
    // dispatch("venueModule/FETCH_VENUES", "", ROOT_ACTION);
  },
  FETCH_COUNTS: ({ commit }, payload = {}): Promise<void> => {
    const parameters: string = !_.isEmpty(payload) ? `&${payload.venue_ids}&start_at=${payload.start_at}&end_at=${payload.end_at}&venue_owner_ids=${payload.venue_owner_ids}` : "";
    return new Promise(async (resolve, reject) => {
      const resource: string = `/api/v1/clientzone/dashboard/counts?type=all${parameters}`;

      try {
        const response = await ApiService.get(resource);

        commit("SET_COUNTS", response.data);

        resolve();
      } catch (e) {
        reject()
      }
    })
  },
  FETCH_LISTS: ({ commit }, payload = {}): Promise<void> => {
    const parameters: string = !_.isEmpty(payload) ? `&${payload.venue_ids}&start_at=${payload.start_at}&end_at=${payload.end_at}&venue_owner_ids=${payload.venue_owner_ids}` : "";

    return new Promise(async (resolve, reject) => {
      const resource: string = `/api/v1/clientzone/dashboard/lists?type=all${parameters}`;

      try {
        const response = await ApiService.get(resource);

        commit("SET_LISTS", response.data);

        resolve();
      } catch (e) {
        reject()
      }
    })
  },
  FETCH_STATS: ({ commit }, payload = {}): Promise<void> => {
    const parameters: string = !_.isEmpty(payload) ? `&${payload.venue_ids}&start_at=${payload.start_at}&end_at=${payload.end_at}&venue_owner_ids=${payload.venue_owner_ids}` : "";

    return new Promise(async (resolve, reject) => {
      const resource: string = `/api/v1/clientzone/dashboard/stats?type=all${parameters}`;

      try {
        const response = await ApiService.get(resource);

        commit("SET_STATS", response.data);

        resolve();
      } catch (e) {
        reject()
      }
    })
  },
  FETCH_FILTER: ({ commit, rootGetters }): Promise<void> => {
    const companyId: number = rootGetters["companyModule/GET_COMPANY_ID"];
    return new Promise(async (resolve, reject) => {
      const resource: string = `/api/v1/clientzone/dashboard/filter?venue_owner_id=${companyId}`;

      try {
        const response = await ApiService.get(resource);

        commit("SET_FILTER", response.data);

        resolve();
      } catch (e) {
        reject()
      }
    })
  },
  SET_FILTER: ({ dispatch, rootGetters }, payload = {}): Promise<void> => {
    const companyId = rootGetters["companyModule/GET_COMPANY_ID"];
    return new Promise(async (resolve, reject) => {
      const resource: string = `/api/v1/clientzone/dashboard/filter`;

      try {
        await ApiService.post(resource, {
          venue_ids: payload.venue_ids,
          start_at: payload.start_at,
          end_at: payload.end_at,
          venue_owner_id: parseInt(companyId),
        });

        dispatch("FETCH_FILTER");

        resolve();
      } catch (e) {
        reject()
      }
    })
  },
  SET_SELECTED_VENUES_IDS: ({ commit, rootGetters }, payload): void => {
    const companyId: number = rootGetters["companyModule/GET_COMPANY_ID"];

    localStorage.setItem(`venue_owner_${companyId}_selected_venues`, payload);
    commit("SET_SELECTED_VENUES_IDS", { payload, companyId });
  },
  SET_DASHBOARD_FILTER_TO_DEFAULT: ({ commit }, payload: Array<any>): void => commit("SET_DASHBOARD_FILTER_TO_DEFAULT", payload)
}
