export default {
    "No venue selected.": "Žádná zvolená provozovna",
    "Webstream is suspended, venue is closed.": "Webstream je pozastaven, provozovna je mimo otevírací dobu.",
    "Credentials are invalid or user is not active.": "Přihlašovací údaje jsou neplatné nebo uživatel není aktivní.",
    "User with provided email do not exist.": "„Uživatel se zadaným e-mailem neexistuje.“",
    "There was a problem with verify your request. Please try again.": "Při ověřování žádosti došlo k potížím. Zkuste to prosím znovu.",
    "Current password is invalid.": "Aktuální heslo je neplatné.",
    "Venue questionary start_at and end_at overlap existing campaigns.": "Datum dotazníku se překrývá s jiným existujícím dotazníkem.",
    "New venue created.": "Nová provozovna vytvořená.",
    "Venue deleted.": "Provozovna smazaná.",
    "Venue profile updated.": "Profil provozovny byl aktualizován.",
    "User avatar updated.": "Avatar byl aktualizován",
    "Audio spot added.": "Přidán audio spot.",
    "Audio spot edited.": "Audio spot upraven.",
    "Audio spot removed.": "Audio spot odstraněn.",
    "Advert campaign created.": "Reklamní kampaň vytvořená.",
    "Advert campaign edited.": "Reklamní kampaň upravená.",
    "Advert campaign deleted.": "Reklamní kampaň smazána.",
    "Company profile updated.": "Profil společnosti byl aktualizován.",
    "Request sent. Please check your email address.": "Požadavek odeslán. Zkontrolujte svou e-mailovou adresu.",
    "Request sent.": "Požadavek odeslán.",
    "New payment card added.": "Přidána nová kreditní karta.",
    "Payment card removed.": "Platební karta odstraněna.",
    "New bank account added.": "Přidán nový bankovní účet.",
    "Bank account removed.": "Bankovní účet smazán.",
    "Venue payment method updated.": "Platební metoda operace aktualizována",
    "You have successfully logged in.": "Úspěšně jste se přihlásili.",
    "The invitation was successfully sent to the user.": "Pozvánka uživateli byla úspěšně odeslána.",
    "User profile updated.": "Profil aktualizován.",
    "Your password has been changed successfully. You can log in.": "Vaše heslo bylo úspěšně změněno. Můžete se přihlásit.",
    "Password was successfully changed.": "Heslo bylo úspěšně změněno.",
    "Question added.": "Otázka byla přidána.",
    "Question edited.": "Otázka byla upravena.",
    "Question deleted.": "Otázka byla odstraněna.",
    "Campaign created.": "Průzkum byl vytvořen.",
    "Campaign edited.": "Průzkum by upraven.",
    "Campaign deleted.": "Průzkum byl odstraněn.",
    "Venue subscription edited.": "Balíček služeb pro provozovnu byl aktualizován.",
    "Venue subscription plan updated.": "Balíček služeb pro provozovnu byl aktualizován.",
    "No recipients found for selected venues": "Pro vybraná provozovny nebyli nalezeni žádní příjemci",
    "Are you sure you want to change your subscription plan?": "Opravdu chcete změnit balíček služeb?",
    "An error occurred while processing your card. Try again in a little bit.": "Při zpracování karty došlo k chybě. Zkuste to prosím za chvíli znovu.",
    "Your card's security code is incorrect.": "Bezpečnostní kód vaší karty je nesprávný.",
    "Your card has expired.": "Platnost vaší karty vypršela.",
    "Your card was declined.": "Vaše karta byla odmítnuta.",
    "Your card has insufficient funds.": "Na vaší kartě není dostatek finančních prostředků.",
    "Dashboard updated": "Dashboard aktualizován",
    "The User with this email address already exists": "Uživatel s touto e-mailovou adresou již existuje",
    "Venue subscription renewed.": "Předplatné obnoveno.",
    "Venue subscription canceled.": "Předplatné bylo zrušeno.",
    "Credit card attached to venue.": "Platební karta je vázána na některou z provozoven.",
    "Bank account is attached to venue.": "Bankovní účet je spojen s jednou z provozoven.",
    "Audio spot is too long. Audio spot cannot exceed 30 seconds": "Audio spot je příliš dlouhý. Nesmí přesáhnout 60 sekund.",
    "Unsupported audio format": "Nepodporovaný formát zvuku",
    "Unsupported file format": "Nepodporovaný formát souboru",
    "Your card is not supported.": "Vaše karta není podporována.",
    "Your card number is incomplete.": "Číslo vaší karty je neúplné.",
    "Your card's expiration date is incomplete.": "Datum vypršení platnosti karty je neúplné.",
    "Your card's expiration date is in the past.": "Datum vypršení platnosti vaší karty je minulostí.",
    "Your card's expiration year is in the past.": "Rok vypršení platnosti vaší karty je minulostí.",
    "Your card's expiration year is invalid.": "Rok vypršení platnosti vaší karty je neplatný.",
    "Your card's security code is incomplete.": "Bezpečnostní kód vaší karty je neúplný.",
    "Venue questionary question is default question or is used in active/future active campaigns": "Tuto otázku nelze vymazat. Je přiřazena  k aktivnímu dotazníku.",
    "This additional service is already ordered": "Doplňková služba již byla objednána",
    "Client zone user already exists. Please, provide a new registration.": "Uživatel již v klientské zóně existuje. Začněte se prosím znovu registrovat.",
    "Attachment limit is 10MB.": "Limit příloh je 10 MB.",
    "Attachment can have a maximum of 3 files.": "Příloha může obsahovat max. 3 soubory.",
    "Max audio spot length is": "Maximální délka spotu je",
    "seconds": "sekund",
    "Premium discount code cannot be applied for Basic package": "Prémiový slevový kód nelze uplatnit na základní balíček",
    "Basic discount code cannot be applied for Premium package": "Základní slevový kód nelze uplatnit na prémiový balíček",
    "No such coupon": "Neplatný kód slevového kupónu",
    "Coupon expired": "Neplatný kód slevového kupónu",
    "Discount code is no longer valid.": "Slevový kód již není platný.",
    "Streaming is now played by another clientzone user or a streamer device.": "Streamování právě přehrává jiný uživatel klientské zóny.",
    "All permitted venues are deselected.": "Všechna provozovny, pro která máte oprávnění, již nejsou vybrána.",
    "We are unable to authenticate your payment method. Please choose a different payment method and try again.": "Potvrzení platby se nezdařilo. Zvolte prosím jiný způsob platby.",
    "Your role is not authorised to change the playlist": "Vaše role nemá oprávnění k výběru playlistu",
    "The email you entered has not yet been verified, please check your email.": "E-mail, který jste zadali, ještě nebyl ověřen, zkontrolujte prosím svůj e-mail.",
}
