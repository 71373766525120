export default {
  "Log in": "Log in",
  "Forgot your password?": "Forgot your password?",
  "Don't have an account?": "Don't have an account?",
  "Sign up.": "Sign up.",
  "Let’s find your venue": "Let’s find your venue",
  "Let’s find your business": "Let’s find your business",
  "venue name and/or address": "Venue name and/or address",
  "Skip and enter details manually": "Skip and enter details manually",
  "Enter venue details manually": "Enter venue details manually",
  "Let’s create your first business location": "Let’s create your first business location",
  "If your company has multiple business locations, you can add the remaining business locations within the Songoroo client interface after completing this registration.": "If your company has multiple business locations, you can add the remaining business locations within the Songoroo client interface after completing this registration.",
  "Next step": "Next step",
  "Back": "Back",
  "Let’s create your user account": "Let’s create your user account",
  "Name": "Name",
  "Surname": "Surname",
  "First": "First",
  "days period is for free. You can cancel Songoroo subscription anytime during the trial period and pay nothing": "days period is for free. You can cancel Songoroo subscription anytime during the trial period and pay nothing",
  "Secure Checkout": "Secure Checkout",
  "Pay with stripe": "Pay with stripe",
  "Add Payment Card": "Add Payment Card",
  "Confirm": "Confirm",
  "Congratulations.": "Congratulations.",
  "Card was successfully added.": "Card was successfully added.",
  "By saving your credit/debit card information you agree to the terms & conditions of Stripe. You can read it by clicking on the following link.": "By saving your credit/debit card information you agree to the terms & conditions of Stripe. You can read it by clicking on the following link.",
  "Processing payment": "Processing payment",
  "Processing": "Processing",
  "Pay": "Pay",
  "Company Billing Data": "Company Billing Data",
  "Submit registration": "Submit registration",
  "Business identification number is required.": "Business identification number is required.",
  "VAT is required.": "VAT is required.",
  "Contact person is required": "Contact person is required",
  "Contact person email is required.": "Contact person email is required.",
  "Contact person phone is required.": "Contact person phone is required.",
  "Congratulations, all is set.": "Congratulations, all is set.",
  "Enjoy streaming music at your business.": "Enjoy streaming music at your business.",
  "Continue to your account": "Continue to your account",
  "Reset password": "Reset password",
  "Create new password": "Create new password",
  "Confirm new password": "Confirm new password",
  "address placeholder": "e.g. Garden Restaurant, Main Street, Košice",
  "Company with VAT": "Company with VAT",
  "Company with name": "Company with name",
  "Company with business identification number": "Company with business identification number",
  "is already registered.": "is already registered.",
  "For more details, contact us support": "For more details, contact us support",
  "Continue to login": "Continue to login",
  "is already registered. Please, log in or change email to continue the registration.": "is already registered. Please, log in or change email to continue the registration.",
  "User with email": "User with email",
  "Login and continue": "Login and continue",
  "Please check your email and confirm your registration. Then you can log in.": "Please check your email and confirm your registration. Then you can log in.",
  "Email was successfully verified.": "Email was successfully verified",
  "This account has been already activated. Please login.": "This account has been already activated. Please login.",
  "There was problem to verify your email. Please try again later.": "There was problem to verify your email. Please try again later.",
  "Credit card authorised. Your registration can be submitted.": "Credit card authorised. Your registration can be submitted.",
  "Authorization failed. Enter your card details again.": "Authorization failed. Please, select your subscription plan and enter your card details again.",
  "Allow geolocation": "Allow geolocation",
  "Geolocation is not supported by this browser.": "Geolocation is not supported by this browser.",
  'Geolocation has been disabled for this site. Enable geolocation in your browser settings.': 'Geolocation has been disabled for this site. Enable geolocation in your browser settings.',
  "Find your business": "Find your business",
  "User account": "User account",
  "Email verification": "Email verification",
  "Billing data": "Billing data",
  "Send again": "Send again",
  "If you have not received the verification e-mail, repeat the action with the 'Send again' button.": "If you have not received the verification e-mail, repeat the action with the 'Send again' button.",
  "We have sent a verification link to the email address": "We have sent a verification link to the email address",
  "If you have any questions, please contact us by email at": "If you have any questions, please contact us by email at",
  "Finish registration": "Finish registration",
  "Your email has been successfully verified.": "Your email has been successfully verified",
  "Street and descriptive number": "Street and descriptive number",
  "Please do close this screen and continue in the screen where you started the registration process": "Please do close this screen and continue in the screen where you started the registration process",
  "Check your email inbox and confirm your registration. If you have not received the email, please click on": "Check your email inbox and confirm your registration. If you have not received the email, please click on ",
  "We have sent a verification link to your email address": "We have sent a verification link to your email address",
}
