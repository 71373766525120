import { state } from "@/state/modules/clientzone/emails/state";
import { mutations } from "@/state/modules/clientzone/emails/mutations";
import { getters } from "@/state/modules/clientzone/emails/getters";
import { actions } from "@/state/modules/clientzone/emails/actions";

const emailsModule = {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}

export default emailsModule
