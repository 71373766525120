import { state } from "@/state/modules/clientzone/dashboard/state";
import { mutations } from "@/state/modules/clientzone/dashboard/mutations";
import { getters } from "@/state/modules/clientzone/dashboard/getters";
import { actions } from "@/state/modules/clientzone/dashboard/actions";

const dashboardModule = {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}

export default dashboardModule
