import Vue from "vue";
import { DataFeedData, DataFeedPayData, VenueOwnerData} from "@/types/datafeed";
import {MutationTree} from "vuex";
import { DataFeedState } from "@/types/store/adminzone/datafeed";

export const mutations: MutationTree<DataFeedState> = {
  SET_DATAFEED: (state, payload: Array<DataFeedData>): Array<DataFeedData> => Vue.set(state, "datafeed", payload),
  SET_DATAPAYIN: (state, payload: Array<DataFeedPayData>): Array<DataFeedPayData> => Vue.set(state, "datafeedpayin", payload),
  SET_VENUE_OWNERS: (state, payload: Array<VenueOwnerData>): Array<VenueOwnerData> => Vue.set(state, "venueowners", payload),

}
