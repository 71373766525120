import { CASSOVIA_API_RESOURCE, ROOT_ACTION } from "@/utils/helpers";
import { ActionTree } from "vuex";
import { CompanyData } from "@/types";
import { errorMessage, successMessage } from "@/utils/messages-handlers";
import { RootState, VenueSubscriptionState } from "@/types/store/clientzone";
import AxiosService from "@/services/axios.service";

export const actions: ActionTree<VenueSubscriptionState, RootState> = {
  FETCH_SUBSCRIPTION_PRODUCTS: ({ commit }): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      const resource: string = `${CASSOVIA_API_RESOURCE}/api/stripe/stripe-product-with-prices`;

      try {
        const response = await AxiosService.getAxios().get(resource);

        commit("SET_SUBSCRIPTION_PRODUCTS", response.data)

        resolve();
      } catch (e) {
        reject()
      }
    })
  },
  CREATE_VENUE_SUBSCRIPTION_ON_STRIPE: ({ commit, dispatch, getters }, payload): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      const resource: string = `${CASSOVIA_API_RESOURCE}/api/stripe/create-stripe-subscription`;

      try {
        const response = await AxiosService.getAxios().post(resource, payload)
        commit("SET_STRIPE_SUBSCRIPTION_RESPONSE", response.data)
        resolve();
      } catch (e) {
        commit("venueModule/SET_CREATE_VENUE_LOADER", false, ROOT_ACTION);
        reject()
      }
    })
  },
  RENEW_VENUE_SUBSCRIPTION: ({ commit, dispatch, rootGetters }, payload): Promise<void> => {
    commit("SET_LOADER", true);
    const company = rootGetters["companyModule/GET_COMPANY"];
    let resource = ''
    if (company.corporate) {
      resource = `${CASSOVIA_API_RESOURCE}/api/corporate-subscription-instance/${payload.resourceId}`;
    } else {
      resource = `${CASSOVIA_API_RESOURCE}/api/stripe/stripe-subscription-instances/${payload.resourceId}`;
      if (!payload.isRenew) {
        delete payload['amount']
        delete payload['recurringInterval']
        delete payload['startOfBillingPeriod']
      }
    }
    return new Promise(async (resolve, reject) => {
      try {
        await AxiosService.getAxios().patch(resource, payload.payloadData, {
          headers: {
            'Content-Type': 'application/merge-patch+json'
          }
        });

        commit("SET_LOADER", false);
        successMessage("Venue subscription renewed.", 3000);

        resolve();
      } catch (e) {
        commit("SET_LOADER", false);
        reject()
      }
    });
  },
  CREATE_VENUE_SUBSCRIPTION: ({ commit, dispatch, rootGetters }, payload): Promise<void> => {
    commit("SET_LOADER", true);
    const company: CompanyData = rootGetters["companyModule/GET_COMPANY"];
    let postEndpointUrl: string = '';
    if (company.corporate) {
      postEndpointUrl = `${CASSOVIA_API_RESOURCE}/api/corporate-subscription-instance`
    } else {
      postEndpointUrl = `${CASSOVIA_API_RESOURCE}/api/stripe/edit-subscription-after-card-validation`
      delete payload['amount']
      delete payload['recurringInterval']
      delete payload['startOfBillingPeriod']
    }
    return new Promise(async (resolve, reject) => {
      try {
        await AxiosService.getAxios().post(postEndpointUrl, payload);

        if (!company.corporate) {
          await dispatch("paymentModule/CREATE_PAYMENT_METHOD_ASSIGNMENTS", { cardId: payload.paymentMethodId, venueId: payload.venueId, isRenew: true }, ROOT_ACTION);
          dispatch("venueModule/SET_ERROR_ON_INVALID_CREDIT_CARD", false, ROOT_ACTION);
        }

        if (payload.isRenew) {
          successMessage("Venue subscription renewed.", 3000);
        }
        commit("SET_LOADER", false);
        resolve();
      } catch (e) {
        if (e.response.status == 402) {
          dispatch("venueModule/SET_ERROR_ON_INVALID_CREDIT_CARD", true, ROOT_ACTION);
          dispatch("venueModule/SET_CREATE_VENUE_LOADER", false, ROOT_ACTION);
          errorMessage(e.response.data.detail, 5000);
        }
        if (e.response.status == 404) {
          dispatch("venueModule/SET_CREATE_VENUE_LOADER", false, ROOT_ACTION);
          errorMessage(e.response.data, 5000);
        }
        if (e.response.status == 405) {
          errorMessage(e.response.data.detail, 5000);
          dispatch("venueModule/SET_CREATE_VENUE_LOADER", false, ROOT_ACTION);
        }
        if (e.response.status == 500) {
          errorMessage(e.response.data.detail.split(':')[0], 5000);
          dispatch("venueModule/SET_CREATE_VENUE_LOADER", false, ROOT_ACTION);
        }
        commit("SET_LOADER", false);
        reject()
      }
    })
  },
  EDIT_VENUE_SUBSCRIPTION: ({ commit, dispatch, rootGetters }, { payload, resourceId }): Promise<void> => {
    commit("SET_LOADER", true);
    const selectedCompany: CompanyData = rootGetters["companyModule/GET_COMPANY"];
    let patchUrl = '';
    if (selectedCompany.corporate) {
      patchUrl = `${CASSOVIA_API_RESOURCE}/api/corporate-subscription-instance/${resourceId}`
    } else {
      patchUrl = `${CASSOVIA_API_RESOURCE}/api/stripe/stripe-subscription-instances/${resourceId}`
      delete payload['amount'];
    }
    return new Promise(async (resolve, reject) => {
      const resource: string = patchUrl;

      try {
        await AxiosService.getAxios().patch(resource, payload, {
          headers: {
            'Content-Type': 'application/merge-patch+json'
          }
        });

        if (payload.isStreamerDeviceAddedToSubscription) {
          dispatch("emailsModule/SEND_STREAMER_DISPATCHED_EMAIL", { fromRegistration: false }, ROOT_ACTION);
        }


        successMessage("Venue subscription edited.", 3000);
        setTimeout(function () {
          commit("SET_LOADER", false)
        }, 2000)

        resolve();
      } catch (e) {
        commit("SET_LOADER", false);
        reject()
      }
    })
  },
  CANCEL_VENUE_SUBSCRIPTION: ({ commit }, id: number): Promise<void> => {
    commit("SET_LOADER", true);

    return new Promise(async (resolve, reject) => {
      const resource: string = `${CASSOVIA_API_RESOURCE}/api/subscription-instances/${id}`;

      try {
        await AxiosService.getAxios().delete(resource);

        commit("SET_LOADER", false);
        successMessage("Venue subscription canceled.", 3000);
        // router.push({
        //   path: '/subscription-plan/details/' + router.currentRoute.params.id,
        //   params: { id: router.currentRoute.params.id }
        // })

        resolve();
      } catch (e) {
        commit("SET_LOADER", false);
        reject()
      }
    })
  },
  FETCH_VENUES_SUBSCRIPTION: ({ commit, rootGetters }): Promise<void> => {
    const companyId: number = rootGetters["companyModule/GET_COMPANY_ID"];

    return new Promise(async (resolve, reject) => {
      const resource: string = `${CASSOVIA_API_RESOURCE}/api/stripe/get-last-created-subscriptions/${companyId}`;

      if (companyId) {
        try {
          const response = await AxiosService.getAxios().get(resource);

          commit("SET_VENUES_SUBSCRIPTION", response.data)

          resolve();
        } catch (e) {
          reject()
        }
      }
    })
  },
  FETCH_VENUE_SUBSCRIPTION: ({ commit, dispatch }, venueId: string | number): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      const resource: string = `${CASSOVIA_API_RESOURCE}/api/stripe/get-last-created-subscription/${venueId}`;
      if (venueId) {
        try {
          const response = await AxiosService.getAxios().get(resource);
          commit("SET_VENUE_SUBSCRIPTION", response.data[0])

          resolve();
        } catch (e) {
          reject()
        }
      }
    })
  },
  SET_RECURRING_INTERVAL: ({ commit }, payload: string): void => commit("SET_RECURRING_INTERVAL", payload),
  FETCH_TAX_RATE: ({ commit }, countryCode: string = ""): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      // const resource: string = `${CASSOVIA_API_RESOURCE}/api/stripe/stripe-tax-rates/get-by-country/${countryCode}`;
      const resource: string = `${CASSOVIA_API_RESOURCE}/api/stripe/stripe-tax-rates/get-by-country/sk`;

      try {
        const response = await AxiosService.getAxios().get(resource);

        commit("SET_TAX_RATE", response.data.data[0])

        resolve();
      } catch (e) {
        reject()
      }
    })
  },
  FETCH_DISCOUNT_CODE: ({ commit }, payload): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      const discountCodeId = payload.inputSearch
      const songorooPackageTypeId = payload.selectedProductId
      const resource: string = `${CASSOVIA_API_RESOURCE}/api/validate-discount-code/${discountCodeId}/${songorooPackageTypeId}`;

      try {
        const response = await AxiosService.getAxios().get(resource);

        commit("SET_DISCOUNT_CODE", response.data)
        commit("SET_INVALID_DISCOUNT_CODE", false)

        resolve();
      } catch (e) {
        if (e.response.status == 405) {
          errorMessage(e.response.data, 5000);
          commit("SET_INVALID_DISCOUNT_CODE", true);
          commit("SET_ERROR_DETAIL", e.response.data);
        }
        if (e.response.status == 500) {
          errorMessage(e.response.data.split(':')[0], 5000);
          commit("SET_INVALID_DISCOUNT_CODE", true);
          commit("SET_ERROR_DETAIL", e.response.data.split(':')[0]);
        }
        reject()
      }
    })
  },
  SET_INVALID_DISCOUNT_CODE: ({ commit }, payload: boolean): void => commit("SET_INVALID_DISCOUNT_CODE", payload),
  SET_STRIPE_SUBSCRIPTION_RESPONSE: ({ commit }, payload: object): void => commit("SET_STRIPE_SUBSCRIPTION_RESPONSE", payload)
}
