import { DataFeedData, DataFeedPayData, VenueOwnerData } from "@/types";
import { GetterTree } from "vuex";
import { DataFeedState, RootState } from "@/types/store/adminzone";

export const getters: GetterTree<DataFeedState, RootState> = {
  GET_DATAFEED: (state): Array<DataFeedData> => state.datafeed,
  GET_DATAPAYIN: (state): Array<DataFeedPayData> => state.datafeedpayin,
  GET_VENUEOWNERS: (state): Array<VenueOwnerData> => state.venueowners,
  GET_SELECTED_DATAPAYIN: (state): Array<DataFeedPayData> => state.datafeedpayin.filter(paymentInstrument => paymentInstrument.id == Number(state.datafeed[0].invoiceDataFeedSelectedPaymentMethodId)),
}
