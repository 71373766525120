export default {
  "Manage Payment Method": "Správa spôsobov platieb",
  "Back to Payment method": "Späť na spôsob platby",
  "Add new card": "Pridať novú kartu",
  "Add new bank account": "Pridať nový bankový účet",
  "Bank transfer": "Bankový prevod",
  "Edit": "Upraviť",
  "Remove": "Odstrániť",
  "Card number": "Číslo karty",
  "Valid thru": "Platné do",
  "Add new Credit/Debit card": "Pridať novú Kreditnú/debetnú karta",
  "Name on card": "Meno karty",
  "Security Code": "Bezpečnostný kód",
  "Edit card": "Upraviť kartu",
  "Edit bank account": "Upraviť bankový účet",
  "Add card": "Pridať kartu",
  "Add bank account": "Pridať bankový účet",
  "Full name on the card": "Celé meno na karte",
  "Select payment method": "Vyberte spôsob platby",
  "Assign": "Priradiť",
  "Selected card": "Zvolená karta",
  "Expired": "Vypršaná",
  "Expires on": "Vyprší"
}
