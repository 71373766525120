export default {
  "Select venue": "Select venue",
  "From": "From",
  "Choose a date": "Choose a date",
  "To": "To",
  "Submit": "Submit",
  "Cancel": "Cancel",
  "Search": "Search",
  "Update": "Update",
  "Add New Venue": "Add New Venue",
  "Active": "Active",
  "Inactive": "Inactive",
  "I agree with Songoroo's": "I agree with Songoroo's",
  "Terms of Use": "Terms of Use",
  "Terms of Use footer": "Terms of Use",
  "Privacy Policy": "Privacy Policy",
  "Privacy Policy footer": "Privacy Policy",
  "and": "and",
  "First name": "First name",
  "Last name": "Last name",
  "Password": "Password",
  "Select option": "Select option",
  "excluding": "excluding",
  "VAT": "VAT",
  "Upload Photo": "Upload Photo",
  "Contact phone": "Contact phone",
  "Change your Avatar": "Change your Avatar",
  "Or upload your profile photo": "Or upload your profile photo",
  "Upload your profile photo": "Upload your profile photo",
  "Change your profile photo": "Change your profile photo",
  "My authorisations": "My authorisations",
  "No authorisations": "No authorisations",
  "Change password": "Change password",
  "Old Password": "Old Password",
  "New Password": "New Password",
  "Confirm Password": "Confirm Password",
  "Unlimited": "Unlimited",
  "Selected venues": "Selected venues",
  "Selected questions:": "Selected questions:",
  "Choose File": "Choose File",
  "Action": "Action",
  "Enter email": "Enter email",
  "Oops! Page Not Found.": "Oops! Page Not Found.",
  "Go to start page": "Go to start page",
  "No data": "No data",
  "Other": "Other",
  "Are you sure you want to delete this advert campaign?": "Are you sure you want to delete this advert campaign?",
  "Are you sure you want to delete this audio spot?": "Are you sure you want to delete this audio spot?",
  "Are you sure you want to delete this question?": "Are you sure you want to delete this question?",
  "Are you sure you want to delete this venue survey?": "Are you sure you want to delete this venue survey?",
  "Are you sure you want to delete this payment card?": "Are you sure you want to delete this payment card?",
  "Are you sure you want to delete this bank account?": "Are you sure you want to delete this bank account?",
  "Notifications": "Notifications",
  "No notifications": "No notifications",
  "Load More...": "Load More...",
  "Renew": "Renew",
  "Sorry, only venues that include the web stream are allowed here.": "Sorry, only venues that include the web stream are allowed here.",
  "You have not created any venues at this time. Unable to start webstreaming.": "You have not created any venues at this time. Unable to start webstreaming.",
  "No options.": "No options.",
  "Deleted": "Deleted",
  "paid": "Paid",
  "open": "Open",
  "Yes": "Yes",
  "uncollectible": "Uncollectible",
  "All campaigns": "All campaigns",
  "All surveys": "All surveys",
  "Other user is streaming music of this venue. Do you want to take it over?": "Other user is streaming music of this venue. Do you want to take it over?",
  "Other user took over music stream of venue ": "Other user took over music stream of venue ",
  "Stream is already playing on this device": "Stream is already playing on this device",
  "Your 14-days Trial Expired": "Your 14-days Trial Expired",
  "Your 14-days Trial Expired Description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam elit purus, posuere non est a, venenatis semper ex. Praesent tristique egestas dui quis accumsan. Maecenas ultrices massa ac urna consectetur, sed viverra tortor mattis.",
  "Contact us": "Contact us",
  "Select subscription plan": "Select subscription plan",
  "The trial period has ended on": "The trial period has ended on ",
  "for venue": " for venue ",
  "To continue using Songoroo Smart Music, please select one of our subscriptions plans.": ". To continue using Songoroo Smart Music, please select one of our subscriptions plans.",
  "Show password": "Show password",
  "Hide password": "Hide password",
}
