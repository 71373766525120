import Vue from "vue";
import {MutationTree} from "vuex";
import {PurchaseOrderData} from "@/types";
import {PurchaseOrderState} from "@/types/store/adminzone";

export const mutations: MutationTree<PurchaseOrderState> = {
  SET_PURCHASE_ORDER_NUMBERS: (state, payload: Array<PurchaseOrderData>): Array<PurchaseOrderData> => Vue.set(state, "purchaseOrderNumbers", payload),
  SET_PURCHASE_ORDER_TOTAL_COUNT: (state, payload: number): number => Vue.set(state, "purchaseOrderNumbersTotalCount", payload)
}


