import {state} from "./state";
import {mutations} from "./mutations";
import {getters} from "./getters";

 const azInvoiceSentConfirmationModule = {
   namespaced: true,
   state,
   mutations,
   getters
 }

 export default azInvoiceSentConfirmationModule
