export default {
  "No venue selected.": "Žiadna vybraná prevádzka",
  "Webstream is suspended, venue is closed.": "Webstream je pozastavený, prevádzka je mimo otváracích hodín.",
  "Credentials are invalid or user is not active.": "Prihlasovacie údaje sú neplatné alebo užívateľ nie je aktívny.",
  "User with provided email do not exist.": "„Užívateľ s poskytnutým e-mailom neexistuje.“",
  "There was a problem with verify your request. Please try again.": "Vyskytol sa problém s overením vašej žiadosti. Skúste to znova.",
  "Current password is invalid.": "Aktuálne heslo je neplatné.",
  "Venue questionary start_at and end_at overlap existing campaigns.": "Dátum dotazníka sa prekrýva s iným existujúcim dotazníkom.",
  "New venue created.": "Nová prevádzka vytvorená.",
  "Venue deleted.": "Prevádzka zmazaná.",
  "Venue profile updated.": "Profil prevádzky aktualizovaný.",
  "User avatar updated.": "Avatar bol aktualizovaný",
  "Audio spot added.": "Audio spot pridaný.",
  "Audio spot edited.": "Audio spot upravený.",
  "Audio spot removed.": "Audio spot odstránený.",
  "Advert campaign created.": "Reklamná kampaň vytvorená.",
  "Advert campaign edited.": "Reklamná kampaň upravená.",
  "Advert campaign deleted.": "Reklamná kampaň vymazaná.",
  "Company profile updated.": "Profil spoločnosti bol aktualizovaný.",
  "Request sent. Please check your email address.": "Žiadosť odoslaná. Skontrolujte svoju e-mailovú adresu.",
  "Request sent.": "Žiadosť odoslaná.",
  "New payment card added.": "Nová platobná karta pridaná.",
  "Payment card removed.": "Platobná karta odstránená.",
  "New bank account added.": "Nový bankový účet pridaný.",
  "Bank account removed.": "Bankový účet odstránený.",
  "Venue payment method updated.": "Platobná metóda prevádzky aktualizovaná",
  "You have successfully logged in.": "Úspešne ste sa prihlásili.",
  "The invitation was successfully sent to the user.": "Pozvánka užívateľovi bola úspešne odoslaná.",
  "User profile updated.": "Profil bol aktualizovaný.",
  "Your password has been changed successfully. You can log in.": "Vaše heslo bolo úspešne zmenené. Môžete sa prihlásiť.",
  "Password was successfully changed.": "Heslo bolo úspešne zmenené.",
  "Question added.": "Otázka bola pridaná.",
  "Question edited.": "Otázka bola upravená.",
  "Question deleted.": "Otázka bola vymazaná.",
  "Campaign created.": "Prieskum bol vytvorený.",
  "Campaign edited.": "Prieskum bol upravený.",
  "Campaign deleted.": "Prieskum bol vymazaný.",
  "Venue subscription edited.": "Balík služieb prevádzky bol aktualizovaný.",
  "Venue subscription plan updated.": "Balík služieb prevádzky bol aktualizovaný.",
  "No recipients found for selected venues": "Pre vybrané prevádzky sa nenašli žiadni príjemcovia",
  "Are you sure you want to change your subscription plan?": "Naozaj chcete zmeniť svoj balík služieb?",
  "An error occurred while processing your card. Try again in a little bit.": "Pri spracovaní karty sa vyskytla chyba. O chvíľu to skúste znova.",
  "Your card's security code is incorrect.": "Bezpečnostný kód vašej karty je nesprávny.",
  "Your card has expired.": "Platnosť vašej karty uplynula.",
  "Your card was declined.": "Vaša karta bola odmietnutá.",
  "Your card has insufficient funds.": "Vaša karta nemá dostatok finančných prostriedkov.",
  "Dashboard updated": "Dashboard aktualizovaný",
  "The User with this email address already exists": "Užívateľ s toutou emailovou adresou už existuje",
  "Venue subscription renewed.": "Predplatné bolo obnovené.",
  "Venue subscription canceled.": "Predplatné bolo zrušené.",
  "Credit card attached to venue.": "Platobná karta je naviazaná, na niektorú z prevádzok.",
  "Bank account is attached to venue.": "Bankový účet je naviazaný, na niektorú z prevádzok.",
  "Audio spot is too long. Audio spot cannot exceed 30 seconds": "Audio spot je príliš dlhý. Nesmie presiahnuť 30 sekúnd.",
  "Unsupported audio format": "Nepodporovaný audio formát",
  "Unsupported file format": "Nepodporovaný formát súboru",
  "Your card is not supported.": "Vaša karta nie je podporovaná.",
  "Your card number is incomplete.": "Číslo vašej karty je neúplné.",
  "Your card's expiration date is incomplete.": "Dátum vypršania platnosti vašej karty je neúplný.",
  "Your card's expiration date is in the past.": "Dátum vypršania platnosti vašej karty je v minulosti.",
  "Your card's expiration year is in the past.": "Rok skončenia platnosti vašej karty je v minulosti.",
  "Your card's expiration year is invalid.": "Rok skončenia platnosti vašej karty je neplatný.",
  "Your card's security code is incomplete.": "Bezpečnostný kód vašej karty je neúplný.",
  "Venue questionary question is default question or is used in active/future active campaigns": "Táto otázka nemôže byť vymazána. Je priradená k aktívnemu dotazníku.",
  "This additional service is already ordered": "Daná doplnková služba už je objednaná",
  "Client zone user already exists. Please, provide a new registration.": "Užívateľ v klientskej zóne už existuje. Prosím, začnite registráciu odznova.",
  "Attachment limit is 10MB.": "Limit prílohy je 10 MB.",
  "Attachment can have a maximum of 3 files.": "Príloha môže mať maximálne 3 súbory.",
  "Max audio spot length is": "Maximálna dĺžka spotu je",
  "seconds": "sekúnd",
  "Premium discount code cannot be applied for Basic package": "Na základný balíček nie je možné uplatniť prémiový zľavový kód",
  "Basic discount code cannot be applied for Premium package": "Na prémiový balíček nie je možné uplatniť základný zľavový kód",
  "No such coupon": "Neplatný kód zľavového kupónu",
  "Coupon expired": "Neplatný kód zľavového kupónu",
  "Discount code is no longer valid.": "Zľavový kód už nie je platný.",
  "Streaming is now played by another clientzone user or a streamer device.": "Streamovanie momentálne prehráva iný užívateľ klientskej zóny.",
  "All permitted venues are deselected.": "Všetky prevádzky, na ktoré máte oprávnenie, sú už nezvolené.",
  "We are unable to authenticate your payment method. Please choose a different payment method and try again.": "Potvrdenie platby zlyhalo.  Zvoľte iný spôsob platby.",
  "Your role is not authorised to change the playlist": "Vaša rola na nemá oprávnenie na výber playlistu",
  "The email you entered has not yet been verified, please check your email.": "The email you entered has not yet been verified, please check your email."
}
