



















































import Vue, {PropType} from "vue";
import {mapActions} from "vuex";

export default Vue.extend({
  name: "took-webstream-modal" as string,
    props: {
    Id: Number as PropType<number>,
    name: String as PropType<string>,
    info: Boolean as PropType<boolean>,
  },
  mounted() {
    this.$root.$on('bv::modal::hidden', (bvEvent, modalId) => {
      if("took-webstream-modal" === modalId){
        this.setWebstreamModalToDefault()
      }
    })
    this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
      if("took-webstream-modal" === modalId){
      }
    })
},
  methods: {
    ...mapActions("browserStreamerModule", {
      forcePlaylistSong: "FORCE_PLAYLIST_SONG_",
      setWebstreamModalToDefault: "SET_TOOK_WEBSTREAM_MODAL_TO_DEFAULT",
    }),
    async TookWebStream(): Promise<void> {
      this.forcePlaylistSong(this.Id)
      this.$emit('StreamIsTaken', true);
      this.$bvModal.hide("took-webstream-modal");
    },
    async Confirm(): Promise<void> {
      this.$emit('StreamIsTaken', false);
      this.$bvModal.hide("took-webstream-modal");
    }
  },
  watch:{
    info:{
      handler(value){
        console.log(value)
      }
    }
  }
})
