export default {
  "New Question": "New Question",
  "Question title": "Question title",
  "Question description": "Please write here your question which will be shown in Songoroo apps in venue's questionnaire, accessible in venue detail.",
  "Question Name": "Question Name",
  "Save Question": "Save Question",
  "This Question is active in :": "This question is active in :",
  "This audio spot is active in :": "This audio spot is active in:",
  "New Audio Advertisement Campaign": "New Audio Advertisement Campaign",
  "Name Campaign": "Name Campaign",
  "Select audio spot": "Select audio spot",
  "Select venues": "Select Venues",
  "Select all venues": "Select All Venues",
  "Campaign date duration": "Campaign date duration",
  "Campaign time duration": "Campaign time duration",
  "Spot Frequency": "Spot Frequency",
  "Save Campaign": "Save Campaign",
  "Select all venues where you want to show specific questions you prepared for this campaign.": "Select all venues where you want to show specific questions you prepared for this survey.",
  "Confirm": "Confirm",
  "Edit Question": "Edit Question",
  "Add venues": "Add venues",
  "New Survey": "New Survey",
  "Edit Survey": "Edit Survey",
  "Edit Audio Spot": "Edit Audio Spot",
  "Spot Name": "Spot Name",
  "Text Author": "Text Author",
  "Music Author": "Music Author",
  "No file selected": "No file selected",
  "Max file size": "Max file size",
  "Support format": "Support format",
  "Save spot": "Save spot",
  "Generate Survey": "Generate Survey",
  "description": "Description",
  "You can have up to 5 questions in this survey.": "You can have up to 5 questions in this survey.",
  "Are you sure you want to proceed without adding additional questions?": "Are you sure you want to proceed without adding additional questions?",
  "Proceed anyway": "Proceed anyway",
  "Return to editing": "Return to editing",
  "Question description is required.": "Question description is required.",
  "Question title is required.": "Question title is required.",
}
