export const state = {
  layoutType: 'vertical',
  layoutWidth: 'fluid',
  leftSidebarType: 'dark',
  topBar: 'dark',
  loader: false,
  toggleMenuState: false,
  canShowBrowserStreamer: false,
  isOverlaySpinnerRunning: false,
  clientZoneMenuItems: [
    {
      id: 3,
      label: "Dashboard",
      icon: "bx-radar",
      link: "/dashboard",
    },
    {
      id: 4,
      label: "Company data",
      icon: "bx-briefcase-alt",
      link: "/company",
    },
    {
      id: 5,
      label: "Venues",
      icon: "bx-shopping-bag",
      link: "/venues",
      subItems: [
        {
          id: 4,
          label: "Venues list",
          link: "/venues",
          parentId: 5,
        },
        {
          id: 6,
          label: "App features setup",
          link: "/app-features-setup/",
          parentId: 5,
        },
      ],
    },
    {
      id: 6,
      label: "Subscriptions & Billing",
      icon: "bx-layer",
      link: "/subscriptions-billing",
      subItems: [
        {
          id: 6,
          label: "Subscription Plan",
          link: "/subscription-plan",
          parentId: 6,
        },
        {
          id: 7,
          label: "Billing",
          link: "/billing",
          parentId: 6,
        },
        {
          id: 8,
          label: "Payment Methods",
          link: "/payment",
          parentId: 6,
        },
      ],
    },
    {
      id: 7,
      label: "User management",
      icon: "bx-user-circle",
      link: "/",
      subItems: [
        {
          id: 7,
          label: "User access rights",
          link: "/user-access-rights",
          parentId: 7,
        },
        {
          id: 8,
          label: "Role management",
          link: "/role-management",
          parentId: 7,
        },
      ],
    },
    {
      id: 8,
      label: "Communication with Customers",
      icon: "bx-female",
      link: "/",
      subItems: [
        {
          id: 8,
          label: "Audio Commercials",
          link: "/audio-commercials",
          parentId: 8,
        },
        {
          id: 9,
          label: "Messages to clients",
          icon: "",
          link: "/messages-to-customers",
          parentId: 8,
        },
        {
          id: 10,
          label: "Venue Survey",
          icon: "",
          link: "/venue-survey",
          parentId: 8,
        },
      ],
    },
    {
      id: 10,
      label: "Help & support",
      icon: "bx-support",
      link: "/support",
    }
  ],
  adminZoneMenuItems: [
    {
      id: 1,
      label: "Companies",
      icon: "bx-briefcase-alt",
      link: "/",
      subItems: [
        {
          id: 1,
          label: "List of Companies",
          link: "/list-of-companies",
          parentId: 1
        },
        {
          id: 2,
          label: "Venues",
          link: "/az-venues",
          parentId: 1
        }
      ]
    },
    {
      id: 2,
      label: "Subscription",
      icon: "bx-layer",
      link: "/",
      subItems: [
        {
          id: 1,
          label: "Subscription Plans",
          link: "/subscription-plans",
          parentId: 2,
        },
        {
          id: 2,
          label: "Language Options",
          link: "/subscription-plans/language-options",
          parentId: 2
        },
        {
          id: 3,
          label: "Discount Codes",
          link: "/discount-codes",
          parentId: 2
        },

      ]
    },
    {
      id: 3,
      label: "Billingg",
      icon: "bxs-dollar-circle",
      link: "/",
      subItems: [
        {
          id: 1,
          label: "Invoice Overview",
          link: "/invoices",
          parentId: 3
        },
        {
          id: 2,
          label: "Invoice Data Feed",
          link: "/invoices/datafeed",
          parentId: 3
        }
      ]
    },
    {
      id: 4,
      label: "Communication",
      icon: "bx-female",
      link: "/",
      subItems: [
        {
          id: 1,
          label: "Emails",
          link: "/emails",
          parentId: 4
        },
        {
          id: 2,
          label: "Notifications",
          link: "/notifications",
          parentId: 4
        }
      ]
    },
    {
      id: 5,
      label: "User Management",
      icon: "bx-user-circle",
      link: "",
      subItems: [
        {
          id: 1,
          label: "Client Zone Users",
          link: "/client-zone-users",
          parentId: 5
        },
        {
          id: 2,
          label: "Songoroo Admins",
          link: "/songoroo-admins",
          parentId: 5
        },
        {
          id: 3,
          label: "Job Applicants",
          link: "/job-applicants",
          parentId: 5
        }
      ]
    },
    {
      id: 6,
      label: "Settings",
      icon: "bx-cog",
      link: "",
      subItems: [
        {
          id: 1,
          label: "Activity Log",
          link: "activity-logs",
          parentId: 6
        },
        {
          id: 2,
          label: "Business Type",
          link: "/business-type",
          parentId: 6
        }
      ]
    }
  ]
}
