




import Vue from 'vue';

export default Vue.extend({
  name: "base-layout" as string,

  updated() {
    const navbarHeight: HTMLElement = document.getElementById("page-topbar");
    const sidebarHeight: HTMLElement = document.getElementById("sidebar-menu");

    if (navbarHeight && sidebarHeight) {
      const height: number =  window.screen.width < 992 ? 380 : sidebarHeight.offsetHeight;
      this.$emit("setTopPosition", navbarHeight.offsetHeight + height)
    }
  }
});
