import Vue from 'vue';
import Router from "vue-router";
import App from './App.vue';
import router from './router'
import store from './state/store'
import ApiService from "./services/api.service"
import TokenService from "./services/token.service"
import BootstrapVue from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import Vuelidate from 'vuelidate'
import * as VueGoogleMaps from 'vue2-google-maps'
import VueMask from 'v-mask'
import VueRouter from 'vue-router'
import vco from "v-click-outside"
import Scrollspy from 'vue2-scrollspy';
import VueSweetalert2 from 'vue-sweetalert2';
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import PerfectScrollbar from 'vue2-perfect-scrollbar'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'
import Multiselect from 'vue-multiselect'
import "vue-multiselect/dist/vue-multiselect.min.css";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import globalMixin from "@/mixins/global";
import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import VueGtag from "vue-gtag";

import "@/design/index.scss";

import PrettyRadio from 'pretty-checkbox-vue/radio';
import PrettyCheck from 'pretty-checkbox-vue/check';

import 'boxicons';

// import App from './App.vue'

// import { initFirebaseBackend } from './authUtils'
// import i18n from './i18n'
import i18n from '@/i18n/i18n';

// import { configureFakeBackend } from './helpers/fake-backend';

import tinymce from 'vue-tinymce-editor'
Vue.component('tinymce', tinymce)


/*const firebaseConfig = {
  apiKey: process.env.VUE_APP_APIKEY,
  authDomain: process.env.VUE_APP_AUTHDOMAIN,
  databaseURL: process.env.VUE_APP_VUE_APP_DATABASEURL,
  projectId: process.env.VUE_APP_PROJECTId,
  storageBucket: process.env.VUE_APP_STORAGEBUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGINGSENDERID,
  appId: process.env.VUE_APP_APPId,
  measurementId: process.env.VUE_APP_MEASUREMENTID
};

if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
  initFirebaseBackend(firebaseConfig);
} else {
  configureFakeBackend();
}*/

import { PluginObject } from 'vue';
import IconifyIcon from '@iconify/vue';
import VueTimepicker from 'vue2-timepicker'

Vue.use((IconifyIcon as unknown) as PluginObject<unknown>);

// import vue-tables-2
import { ClientTable } from "vue-tables-2";
import 'boxicons'

Vue.use(ClientTable, {}, false, 'bootstrap4');

Vue.use(VueRouter)
Vue.use(vco)
Vue.use(Scrollspy);
const VueScrollTo = require('vue-scrollto')
Vue.use(VueScrollTo)
Vue.use(PerfectScrollbar)
Vue.config.productionTip = false

Vue.use(BootstrapVue)
Vue.use(Vuelidate)
Vue.use(VueMask)
Vue.use(require('vue-chartist'))
Vue.use(VueSweetalert2);
Vue.component('p-radio', PrettyRadio);
Vue.component('multiselect', Multiselect)
Vue.component('date-picker', DatePicker)
Vue.component('p-check', PrettyCheck);
Vue.mixin(globalMixin)
Vue.use(Toast);
Vue.component('loading', VueLoading);
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAbvyBxmMbFhrzP9Z8moyYr6dCr-pzjhBE',
    libraries: 'places',
  },
  installComponents: true
})
Vue.component('apexchart', VueApexCharts)
Vue.use(require('boxicons'))

Vue.use(VueGtag, {
  config: {
    id: process.env.VUE_APP_GOOGLE_ANALYTICS_ID
  }
})

ApiService.init(process.env.VUE_APP_SONGOROO_API)
// GoogleApiService.init('https://maps.googleapis.com/maps/api/place/findplacefromtext/json?')

if (TokenService.getToken()) {
  ApiService.setHeader();
  ApiService.mount401Interceptor();
}

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
