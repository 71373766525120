import { state } from '@/state/modules/clientzone/register/state';
import { mutations } from '@/state/modules/clientzone/register/mutations';
import { getters } from '@/state/modules/clientzone/register/getters';
import { actions } from '@/state/modules/clientzone/register/actions';

const registerModule = {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}

export default registerModule
