import axios, {AxiosPromise, AxiosRequestConfig} from 'axios'
import TokenService from '@/services/token.service'
import store from '@/state/store'
import router from "@/router"
import UserService from "@/services/user.service"

export interface ApiServiceInterface {
  _401interceptor: null | Function;
  _had401error: boolean;
  _baseUrl: string;
  _headers: object;

  init(baseUrl: string): void;

  setHeader(): void;

  removeHeader(): void;

  get(resource: string, config?: AxiosRequestConfig): AxiosPromise;

  post(resource: string, data: object, config?: AxiosRequestConfig): AxiosPromise;

  put(resource: string, data: object, config?: AxiosRequestConfig): AxiosPromise;

  patch(resource: string, data: object): AxiosPromise;

  delete(resource: string): AxiosPromise;

  customRequest(data: object): AxiosPromise;

  mount401Interceptor(): void;

  unmount401Interceptor(): void;
}

const ApiService: ApiServiceInterface = {
  _401interceptor: null,
  _had401error: false,
  _baseUrl: "",
  _headers: {},

  init(baseURL) {
    this._baseUrl = baseURL;
    axios.defaults.baseURL = baseURL;
  },

  setHeader() {
    axios.defaults.headers.common["Authorization"] = `Bearer ${TokenService.getToken()}`;
    axios.defaults.headers.common["Content-Type"] = `Application/json`;
  },

  removeHeader() {
    axios.defaults.headers.common = {}
  },

  get(resource, config) {
    return axios.get(resource, config)
  },

  post(resource, data, config) {
    return axios.post(resource, data, config)
  },

  put(resource, data, config) {
    return axios.put(resource, data, config)
  },

  patch(resource: string, data: object): AxiosPromise {
    return axios.patch(resource, data)
  },

  delete(resource) {
    return axios.delete(resource)
  },

  /**
   * Perform a custom Axios request.
   *
   * data is an object containing the following properties:
   *  - method
   *  - url
   *  - data ... request payload
   *  - auth (optional)
   *    - username
   *    - password
   **/
  customRequest(data) {
    return axios(data)
  },

  mount401Interceptor() {
    this._401interceptor = axios.interceptors.response.use(
      (response) => {
        this._had401error = false;
        return response;
      },
      async (error) => {
        if (error.request.status == 401) {
          if (this._had401error) {
            TokenService.removeToken();
            TokenService.removeRefreshToken();
            TokenService.removeUserId();
            ApiService.removeHeader();
            store.commit('userModule/LOGOUT_SUCCESS');
            await router.push({name: 'login'});
            this._had401error = false;
            throw error;
          }

          try {
            await UserService.refreshToken()
            this._had401error = true;

            return this.customRequest({
              method: error.config.method,
              url: error.config.url,
              data: error.config.data
            });
          } catch (e) {
            throw error;
          }
        }

        throw error
      }
    )
  },

  unmount401Interceptor() {
    axios.interceptors.response.eject(this._401interceptor);
  }
};

export default ApiService;
