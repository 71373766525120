import {
  QuestionaryAvailableVenueData,
  QuestionaryQuestionData,
  VenueQuestionaryData,
} from "@/types/venue_questionary";
import { VenueQuestionaryState } from "@/types/store/clientzone";

export const state: VenueQuestionaryState = {
  questionaryQuestions: [] as Array<QuestionaryQuestionData>,
  venueQuestionary: [] as Array<VenueQuestionaryData>,
  permissionAvailableQuestionaries: [] as Array<VenueQuestionaryData>,
  questionaryAvailableVenues: [] as Array<QuestionaryAvailableVenueData>,
  filterActiveCampaigns: false,
  startAt: "",
  endAt: "",
  selectedVenuesIds: [] as Array<number>,
  questionLoader: false
}
