import { ActionTree } from "vuex";
import { PurchaseOrderState, RootState } from "@/types/store/adminzone";
import { CASSOVIA_API_RESOURCE } from "@/utils/helpers";
import AxiosService from "@/services/axios.service";

export const actions: ActionTree<PurchaseOrderState, RootState> = {
  FETCH_PURCHASE_ORDER_NUMBERS: ({commit}, {pageNumber: pageNumber, venueOwnerId: venueOwnerId, purchaseOrderNumber: purchaseOrderNumber = ""}): Promise<void> => {
    let purchaseOrderNumberQueryParam = ""
    if(purchaseOrderNumber != "") {
      purchaseOrderNumberQueryParam = `&purchaseOrderNumber=${purchaseOrderNumber}`
    }
    return new Promise(async (resolve, reject) => {
      const resource: string = `${CASSOVIA_API_RESOURCE}/api/purchase-orders?page=${pageNumber}&venueOwnerId=${venueOwnerId}${purchaseOrderNumberQueryParam}`
      try{
        const response = await AxiosService.getAxios().get(resource)
        commit('SET_PURCHASE_ORDER_NUMBERS', response.data)
        commit('SET_PURCHASE_ORDER_TOTAL_COUNT', response.headers['total-count'])
        resolve()
      }catch (e){
        reject()
      }
    })
  },
  ENABLE_PURCHASE_ORDER_NUMBER: ({commit, dispatch}, purchaseOrderId: number): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      const resource: string = `${CASSOVIA_API_RESOURCE}/api/purchase-orders/set-as-active/${purchaseOrderId}`
      try{
        await AxiosService.getAxios().post(resource)
        resolve()
      } catch (e){
        reject()
      }
    })
  },
  DISABLE_ALL_PURCHASE_ORDER_NUMBERS: ({commit, dispatch}, venueOwnerId: number): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      const resource: string = `${CASSOVIA_API_RESOURCE}/api/purchase-orders/deactivate-all/${venueOwnerId}`
      try{
        await AxiosService.getAxios().post(resource)
        resolve()
      }catch (e) {
        reject()
      }
    })
  }
}
