import UserService from "@/services/user.service"
import router from "@/router"
import { CASSOVIA_API_RESOURCE, ROOT_ACTION, SGR_CLIENTZONE_API_KEY } from "@/utils/helpers";
import ApiService from "@/services/api.service";
import TokenService from "@/services/token.service";
import { ActionTree } from "vuex";
import { CompanyData, UserAuthorisationsVenuesData, UserData, VenueData } from "@/types";
import { errorMessage, successMessage } from "@/utils/messages-handlers";
import i18n from "@/i18n/i18n"
import { RootState, UserState } from "@/types/store/clientzone";
import login from "@/router/views/account/login.vue";
import AxiosService from "@/services/axios.service";

export const actions: ActionTree<UserState, RootState> = {
  async LOGIN({ commit, dispatch, rootGetters }, {
    email,
    password,
    firstRegistration = false,
    popupRegisterLogin = false
  }): Promise<void> {
    commit('LOGIN_REQUEST');
    commit("SET_LOADER", true);

    return new Promise(async (resolve, reject) => {
      try {
        const loginsCount = rootGetters["registerModule/GET_LOGINS_COUNT"]
        dispatch('registerModule/SET_LOGINS_COUNT', loginsCount + 1, ROOT_ACTION);
        const response = await UserService.login(email, password);
        commit('LOGIN_SUCCESS', response.access_token);
        dispatch('registerModule/SET_LOGINS_COUNT', 0, ROOT_ACTION);
        commit("SET_LOADER", false);

        if (popupRegisterLogin) {
          successMessage("You have successfully logged in.", 5000);
          sessionStorage.setItem("isLoggedFromRegistration", "true");
          await router.push("/register/step-4");
        }

        // if (!firstRegistration) {
        await dispatch("FETCH_LOGGED_USER", login)
        // }


        /*if (response.user.role == 521)
            await router.push({name: "company-home"});
        else if (response.user.role == 652)
            await router.push({name: "admin-home"});
        else if (response.user.role == 437)
            await router.push({name: "university-home"});
        else if (response.user.role == 362)
            await router.push({name: "employee-home"});*/

        resolve(response);
      } catch (e) {
        commit("SET_LOADER", false);
        reject(e);
      }
    });
  },
  FETCH_LOGGED_USER: ({ commit, dispatch, rootGetters }, login: boolean = false): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      const resource: string = "/api/v1/clientzone/user/myself";
      console.log('20')
      try {
        const response = await ApiService.get(resource);
        console.log('21')
        commit("SET_LOGGED_USER", response.data);
        commit("SET_USER_LOGGED_STATE", true);
        TokenService.saveUserId(response.data.id);
        console.log('22')

        if (login) {
          console.log('23')
          if (response.data.is_superadmin) {
            await router.push({ name: "SubscriptionPlans" }); // TODO: Later change to homepage in AZ
          } else {
            console.log('24')
            await dispatch("companyModule/FETCH_COMPANIES", true, ROOT_ACTION)
          }
        }

        console.log('25')

        if (rootGetters["companyModule/GET_COMPANY_ID"]) {
          await dispatch("CHECK_LOGGED_USER_ROLE_PERMISSION", { permissionId: 1 });
        }

        console.log('26')

        if (rootGetters["companyModule/GET_COMPANY_ID"] && rootGetters["userModule/GET_USER_ID"]) {
          const companyId = rootGetters["companyModule/GET_COMPANY_ID"];
          const userId = rootGetters["userModule/GET_USER_ID"];
          const resource: string = `/api/v1/clientzone/user/${userId}/venue_owner/${companyId}/role_permission`
          const response = await ApiService.get(resource);
          for (const role of response.data) {
            if (response.data.length === 1 && role.role_id === 5) {
              commit('SET_LOGGED_USER_WITHOUT_ANY_PERMISSIONS', true)
            } else {
              commit('SET_LOGGED_USER_WITHOUT_ANY_PERMISSIONS', false)
            }
          }
        }

        resolve();
      } catch (e) {
        reject()
      }
    })
  },
  FETCH_USER_BY_ID: ({ commit }): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      const resource: string = `/api/v1/clientzone/user/${router.currentRoute.query.id}`;
      i18n.locale = localStorage.getItem('language')
      try {
        const response = await ApiService.get(resource, {
          headers: {
            Authorization: "Bearer apikey",
            "SGR-CLIENTZONE-API-KEY": SGR_CLIENTZONE_API_KEY
          }
        });
        if (response.data.active) {
          router.push('/login')
          errorMessage('This account has been already activated. Please login.', 5000)
        }

        commit("SET_SELECTED_USER", response.data);

        resolve();
      } catch (e) {
        reject()
      }
    })
  },
  FETCH_USER: ({ commit }): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      const resource: string = `/api/v1/clientzone/user/${router.currentRoute.params.id}`;

      try {
        const response = await ApiService.get(resource);

        commit("SET_SELECTED_USER", response.data);

        resolve();
      } catch (e) {
        reject()
      }
    })
  },
  FETCH_USERS: ({ commit, dispatch, rootGetters }, name: string = ""): Promise<void> => {
    const companyId: number = rootGetters["companyModule/GET_COMPANY_ID"];

    return new Promise(async (resolve, reject) => {
      const resource: string = `/api/v1/clientzone/user?limit=10000&venue_owner_id=${companyId}&name=${name}`;

      try {
        const response = await ApiService.get(resource);

        commit("SET_USERS", []);
        commit("SET_USER_HAS_ACCESS", true);
        await dispatch("FETCH_USERS_ROLES_SUMMARY");
        await dispatch("FETCH_USERS_AUTHORISATIONS");
        commit("SET_USERS", response.data);
        commit("SET_ADD_USER_LOADER", false);

        resolve();
      } catch (e) {
        if (e.response.data.error.code === 'ClientZoneAccessDeniedError') {
          commit("SET_USER_HAS_ACCESS", false);
          await dispatch('FETCH_USERS_NO_ACCESS')
        }
        reject()
      }
    })
  },
  VALIDATE_IF_USER_EXIST: ({ commit, dispatch }, payload): Promise<void> => {
    commit("SET_ADD_USER_LOADER", true);

    return new Promise(async (resolve, reject) => {
      const resource: string = `/api/v1/clientzone/check/email?email=${payload.email}`;

      try {
        const response = await ApiService.get(resource);


        if (response.data.found) {
          await dispatch("FIND_USER_BY_EMAIL", payload);
        } else await dispatch("ADD_NEW_USER", payload);

        resolve();
      } catch (e) {
        commit("SET_ADD_USER_LOADER", false);
        reject()
      }
    })
  },
  INVITE_USER: ({ commit, dispatch }, payload: object): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      const resource: string = "/api/v1/clientzone/user/invite";

      try {
        const response = await ApiService.post(resource, payload);
        dispatch("emailsModule/SEND_NEW_USER_ADDED_EMAIL", {
          userFirstname: response.data.first_name,
        }, ROOT_ACTION);
        dispatch("emailsModule/SEND_INVITATION_EMAIL", {
          userEmail: response.data.email,
          status: 1,
        }, ROOT_ACTION);
        await dispatch("FETCH_USERS", "");
        commit("SET_ADD_USER_LOADER", false);
        successMessage("The invitation was successfully sent to the user.", 5000);

        resolve();
      } catch (e) {
        commit("SET_ADD_USER_LOADER", false);
        reject()
      }
    })
  },
  ADD_NEW_USER: ({ commit, dispatch, rootGetters }, payload): Promise<void> => {
    const selectedVenue: VenueData = rootGetters["venueModule/GET_SELECTED_VENUE"];
    const userPayload = {
      email: payload.email,
      password: Math.random().toString(36).substr(2, 8),
      first_name: payload.first_name,
      last_name: payload.last_name,
      job_position: payload.job_position,
      venue_owner_id: rootGetters["companyModule/GET_COMPANY_ID"],
      active: false,
      gdpr: true
    }

    return new Promise(async (resolve, reject) => {
      const resource: string = "/api/v1/clientzone/user";

      try {
        const response = await ApiService.post(resource, userPayload);

        await dispatch("emailsModule/SEND_NEW_USER_ADDED_EMAIL", {
          userFirstname: payload.first_name,
          countryId: selectedVenue.country_id,
        }, ROOT_ACTION);
        await dispatch("emailsModule/SEND_INVITATION_EMAIL", {
          userEmail: payload.email,
          status: 2,
          countryId: selectedVenue.country_id,
          userId: response.data.id,
        }, ROOT_ACTION);
        await dispatch("FETCH_USERS", "");
        commit("SET_ADD_USER_LOADER", false);
        successMessage("The invitation was successfully sent to the user.", 5000);
        resolve();
      } catch (e) {
        commit("SET_ADD_USER_LOADER", false);
        reject()
      }
    })
  },
  UPDATE_USER: ({ commit, getters }, payload: UserData): Promise<void> => {
    const userId: UserData = getters["GET_USER_ID"];

    return new Promise(async (resolve, reject) => {
      const resource: string = `/api/v1/clientzone/user/${userId}`;

      try {
        const response = await ApiService.put(resource, payload);

        commit("SET_LOGGED_USER", response.data);

        successMessage("User profile updated.", 3000);

        resolve();
      } catch (e) {
        reject()
      }
    })
  },
  DELETE_USER: ({ dispatch, rootGetters }, userId: number): Promise<void> => {
    const companyId: number = rootGetters["companyModule/GET_COMPANY_ID"];

    return new Promise(async (resolve, reject) => {
      const resource: string = `/api/v1/clientzone/user/${userId}/venue_owner/${companyId}`;

      try {
        await ApiService.delete(resource);

        successMessage("User removed.", 3000);
        await dispatch("FETCH_USERS");

        resolve();
      } catch (e) {
        reject()
      }
    })
  },
  FIND_USER_BY_EMAIL: ({ dispatch }, payload): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      const resource: string = `/api/v1/clientzone/user?name=${payload.email}`;

      try {
        const response = await ApiService.get(resource);

        if (response.data.length) {
          response.data.some((user) => {
            if (user.email === payload.email) {
              dispatch("USER_ACCESS_RIGHTS_EXISTS", { user, payload });
            }
          })
        } else {
          await dispatch("INVITE_USER", payload);
        }

        resolve();
      } catch (e) {
        reject()
      }
    })
  },
  USER_ACCESS_RIGHTS_EXISTS: ({ commit, dispatch, rootGetters }, payload): Promise<void> => {
    const companyId: number = rootGetters["companyModule/GET_COMPANY_ID"];
    let userHasRights = false;

    return new Promise(async (resolve, reject) => {
      const resource: string = `/api/v1/clientzone/user/venue_owner_role?venue_owner_id=${companyId}&czone_user_id=${payload.user.id}`;

      try {
        const response = await ApiService.get(resource);

        if (response.data.length) {
          response.data.some((user) => {
            if (user.czone_user_id === payload.user.id) {
              userHasRights = true
            }
          })

          if (userHasRights) {
            errorMessage(`The User with this email address already exists`, 3000);
            commit("SET_ADD_USER_LOADER", false);
          }
        } else {
          await dispatch("INVITE_USER", payload.payload);
        }

        resolve();
      } catch (e) {
        reject()
      }
    })
  },
  SET_USER_IMAGE: ({ commit }, payload): void => commit("SET_USER_IMAGE", payload),
  SET_SELECTED_USER_IMAGE: ({ commit }, payload): void => commit("SET_SELECTED_USER_IMAGE", payload),
  /*async LOGOUT({commit}): Promise<void> {
    await UserService.logout();
    commit('LOGOUT_SUCCESS');
    await router.push({name: "login"})
  },*/
  async LOGOUT({ commit, dispatch, rootGetters }): Promise<void> {
    const companies: Array<CompanyData> = rootGetters["companyModule/GET_COMPANIES"];
    const userId: number = rootGetters["userModule/GET_USER_ID"];

    await UserService.softLogout();
    commit('LOGOUT_SUCCESS');
    commit("SET_USER_LOGGED_STATE", false);
    document.body.classList.remove("home");
    localStorage.removeItem("company_id");
    localStorage.removeItem("venue_id");

    // Fix: #3569 Zrusenie cacheovania udajov o prihlasenom uzivatelovi
    localStorage.removeItem("webstream_token");
    localStorage.removeItem("isPlaying");
    localStorage.removeItem("user");
    localStorage.removeItem("refresh_token");

    const userNotificationsKey: string = `user_${userId}_notifications`;
    localStorage.removeItem(userNotificationsKey)

    companies.forEach((c) => {
      localStorage.removeItem(`venue_owner_${c.id}_start_at`);
      localStorage.removeItem(`venue_owner_${c.id}_end_at`);
      localStorage.removeItem(`venue_owner_${c.id}_selected_venues`);
    })
    // commit("companyModule/SET_COMPANIES", [], ROOT_ACTION);
    // commit("companyModule/SET_COMPANY", null, ROOT_ACTION);
    commit("companyModule/RESET_STATE", [], ROOT_ACTION);

    dispatch("browserStreamerModule/SET_WEB_STREAMER_TO_DEFAULT", "", ROOT_ACTION);
    dispatch("dashboardModule/SET_DASHBOARD_FILTER_TO_DEFAULT", [], ROOT_ACTION);
    await router.push({ name: "login" });
  },
  async GET_COUNTRIES({ commit }): Promise<object> {
    return new Promise(async (resolve, reject) => {
      const resource: string = `${CASSOVIA_API_RESOURCE}/countries`;

      try {
        const response = await AxiosService.getAxios().get(resource);

        commit('FETCH_COUNTRIES', response.data);

        resolve({});
      } catch (e) {
        reject()
      }
    })
  },
  VALIDATE_USER_RESET_PASSWORD_TOKEN: ({ }, token: string): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      const resource: string = `/api/v1/clientzone/check/reset_password_token?token=${token}&czone_user_id=`;

      try {
        const response = await ApiService.get(resource, {
          headers: {
            Authorization: "Bearer apikey",
            "SGR-CLIENTZONE-API-KEY": SGR_CLIENTZONE_API_KEY
          }
        });

        if (!response.data.found) {
          errorMessage("There was a problem with verify your request. Please try again.", 5000);
          router.push("/forgot-password");
        }

        resolve();
      } catch (e) {
        reject()
      }
    })
  },
  RESET_USER_PASSWORD: ({ commit, dispatch }, { payload, userIsInvited = false }): Promise<void> => {
    commit("SET_LOADER", true);

    return new Promise(async (resolve, reject) => {
      const resource: string = "/api/v1/clientzone/user/password/from_token";

      try {
        await ApiService.put(resource, payload, {
          headers: {
            Authorization: "Bearer apikey",
            "SGR-CLIENTZONE-API-KEY": SGR_CLIENTZONE_API_KEY
          }
        });
        if (!userIsInvited) {
          dispatch("emailsModule/SEND_CONFIRM_RESETTING_PASSWORD_EMAIL", {}, ROOT_ACTION);
          successMessage("Your password has been changed successfully. You can log in.", 5000);
          router.push("/login");
        }

        commit("SET_LOADER", false);

        resolve();
      } catch (e) {
        commit("SET_LOADER", false);
        reject()
      }
    })
  },
  CHANGE_USER_PASSWORD: ({ commit, dispatch, getters }, payload: object): Promise<void> => {
    commit("SET_LOADER", true);
    const user: UserData = getters["GET_LOGGED_USER"];

    const userData = {
      email: user.email,
      clientzoneUsername: user.email,
      locale: i18n.locale
    }

    return new Promise(async (resolve, reject) => {
      const resource: string = "/api/v1/clientzone/user/password";

      try {
        await ApiService.put(resource, payload);

        dispatch("emailsModule/SEND_CONFIRM_RESETTING_PASSWORD_EMAIL", userData, ROOT_ACTION);
        commit("SET_LOADER", false);
        successMessage("Password was successfully changed.", 3000);

        resolve();
      } catch (e) {
        if (e.response.data.error.code === "AuthorizationError") errorMessage("Current password is invalid.", 3000);
        commit("SET_LOADER", false);
        reject()
      }
    })
  },
  FETCH_USER_AUTHORISATIONS: ({ commit, getters, dispatch, rootGetters }, payload = {}): Promise<void> => {
    const userId: number = payload.id ? payload.id : getters["GET_USER_ID"];
    const companyId: number = rootGetters["companyModule/GET_COMPANY_ID"];
    const userHasAccess = rootGetters["userModule/GET_USER_HAS_ACCESS"];

    return new Promise(async (resolve, reject) => {
      let resource: string = `/api/v1/clientzone/user/venue_owner_role?limit=1000&venue_owner_id=${companyId}&czone_user_id=${userId}`;
      if (!userHasAccess) {
        resource = `/api/v1/clientzone/user/venue_owner_role?limit=1000&czone_user_id=${userId}`;
      }

      try {
        const response = await ApiService.get(resource);

        if (payload.list) {
          dispatch("FETCH_VENUE_AUTHORIZED_TO_USER", { venueId: response.data[0].venue_id, userId: userId });
        } else {
          commit("SET_USER_AUTHORISATIONS", response.data);

          /*if (payload.multi) {
            if (rootGetters["venueModule/GET_USER_AUTHORISATIONS_VENUES"].length) {
              await dispatch("SET_USER_ACCESS_RIGHTS", rootGetters["venueModule/GET_USER_AUTHORISATIONS_VENUES"]);
              await dispatch("venueModule/RESET_USER_AUTHORISATIONS_VENUES", [], ROOT_ACTION);
            }
          }*/
        }

        resolve();
      } catch (e) {
        reject()
      }
    })
  },
  FETCH_QUESTIONARY_USERS: ({ commit }, payload): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      const clientzoneUsers = []
      try {
        for (const clientzoneUserId of payload) {
          const resource: string = `/api/v1/clientzone/user/${clientzoneUserId}`

          const response = await ApiService.get(resource)
          clientzoneUsers.push(response.data)
        }
        commit("SET_QUESTIONARY_USERS", clientzoneUsers)

        resolve();
      } catch (e) {
        reject()
      }
    })
  },
  FETCH_AUDIO_SPOTS_USERS: ({ commit }, payload): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      const clientzoneUsers = []
      try {
        for (const clientzoneUserEmail of payload) {
          const resource: string = `/api/v1/clientzone/user?name=${clientzoneUserEmail}&limit=1&include_deleted=False`

          const response = await ApiService.get(resource)
          clientzoneUsers.push(response.data[0])
        }
        commit("SET_AUDIO_SPOTS_USERS", clientzoneUsers)
        resolve()
      } catch (e) {
        reject()
      }
    })
  },
  FETCH_USERS_AUTHORISATIONS: ({ commit, rootGetters }): Promise<void> => {
    const companyId: number = rootGetters["companyModule/GET_COMPANY_ID"];
    const currentUserId: number = rootGetters["userModule/GET_USER_ID"];
    const userHasAccess = rootGetters["userModule/GET_USER_HAS_ACCESS"];
    return new Promise(async (resolve, reject) => {
      let resource: string = `/api/v1/clientzone/user/venue_owner_role?limit=10000&venue_owner_id=${companyId}`;
      if (!userHasAccess) {
        resource = `/api/v1/clientzone/user/venue_owner_role?limit=10000&czone_user_id=${currentUserId}`;
      }

      try {
        const response = await ApiService.get(resource);

        commit("SET_USERS_AUTHORISATIONS", response.data);

        resolve();
      } catch (e) {
        reject()
      }
    })
  },
  FETCH_VENUE_AUTHORIZED_TO_USER: ({ commit }, payload): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      const resource: string = `api/v1/clientzone/venue/${payload.venueId}`;

      try {
        if (payload.venueId) {
          const response = await ApiService.get(resource);

          commit("SET_VENUE_AUTHORIZED_TO_USER", { venueData: response.data, userId: payload.userId });
        } else {
          commit("SET_VENUE_AUTHORIZED_TO_USER", { venueData: payload.venueData, userId: payload.userId });
        }

        resolve();
      } catch (e) {
        reject()
      }
    })
  },
  SET_USER_ACCESS_RIGHTS: async ({ commit }, payload: Array<UserAuthorisationsVenuesData>): Promise<void> => await commit("SET_USER_ACCESS_RIGHTS", payload),
  FETCH_USER_ROLES: ({ commit }): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      const resource: string = "/api/v1/clientzone/codelist/role";

      try {
        const response = await ApiService.get(resource);

        commit("SET_USER_ROLES", response.data);

        resolve();
      } catch (e) {
        reject()
      }
    })
  },
  FETCH_USER_ROLES_SUMMARY: ({ commit }, userId: number): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      const resource: string = `/api/v1/clientzone/user/venue_owner_role/summary?czone_user_id=${userId}`;

      try {
        const response = await ApiService.get(resource);

        if (response.data[0]) commit("SET_USER_ROLES_SUMMARY", response.data[0]);

        resolve();
      } catch (e) {
        reject()
      }
    })
  },
  FETCH_USERS_ROLES_SUMMARY: ({ commit, rootGetters }): Promise<void> => {
    const companyId: number = rootGetters["companyModule/GET_COMPANY_ID"];
    const currentUserId: number = rootGetters["userModule/GET_USER_ID"];
    const userHasAccess = rootGetters["userModule/GET_USER_HAS_ACCESS"];
    return new Promise(async (resolve, reject) => {
      let resource: string = `/api/v1/clientzone/user/venue_owner_role/summary?limit=10000&venue_owner_id=${companyId}`;
      if (!userHasAccess) {
        resource = `/api/v1/clientzone/user/venue_owner_role/summary?limit=10000&czone_user_id=${currentUserId}`;
      }


      try {
        const response = await ApiService.get(resource);

        commit("SET_USERS_ROLES_SUMMARY", response.data);

        resolve();
      } catch (e) {
        reject()
      }
    })
  },
  FETCH_USER_VENUES_PERMISSIONS: ({ commit, dispatch, rootGetters }, group = false): Promise<void> => {
    const companyId: number = rootGetters["companyModule/GET_COMPANY_ID"];
    return new Promise(async (resolve, reject) => {
      const resource: string = `/api/v1/clientzone/user/venue_owner_role?limit=1000&venue_owner_id=${companyId}&czone_user_id=${router.currentRoute.params.id}`;

      try {
        const response = await ApiService.get(resource);

        if (group) dispatch("venueModule/SET_GROUP_ALL_LOADER", false, ROOT_ACTION);

        commit("SET_USER_VENUES_PERMISSIONS", response.data);
        await dispatch("venueModule/FETCH_VENUES", { fetchRatings: false, name: '' }, ROOT_ACTION);

        resolve();
      } catch (e) {
        if (e.response.data.error.code === 'ClientZoneAccessDeniedError') {
          router.push('/dashboard')
        }
        reject()
      }
    })
  },
  CHECK_IF_ADMIN: ({ commit, rootGetters }): Promise<void> => {
    const companyId: number = rootGetters["companyModule/GET_COMPANY_ID"];
    const currentUserId: number = rootGetters["userModule/GET_USER_ID"];
    return new Promise(async (resolve, reject) => {
      const resource: string = `/api/v1/clientzone/user/venue_owner_role?offset=0&venue_owner_id=${companyId}&role_id=1&czone_user_id=${currentUserId}`
      try {
        const response = await ApiService.get(resource);
        commit("SET_USER_IS_ADMIN", false)
        if (response.data.length) {
          const adminVenueOwnerRoles = response.data
          const adminVenueOwnerRole = adminVenueOwnerRoles.find((v) => v.czone_user_id === currentUserId)
          if (adminVenueOwnerRole) {
            commit("SET_USER_IS_ADMIN", true)
          }
        }
        resolve()
      } catch (e) {
        reject()
      }
    })
  },
  FILTER_ACTIVE_USERS: ({ commit }, payload: boolean): void => commit("FILTER_ACTIVE_USERS", payload),
  ADD_USER_PERMISSION: ({ dispatch }, payload): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      const resource: string = "/api/v1/clientzone/user/role_permission";

      dispatch("companyModule/SET_LOADER", payload[0].permission_id, ROOT_ACTION);

      try {
        await ApiService.post(resource, payload);

        await dispatch("companyModule/FETCH_ROLE_PERMISSIONS", {}, ROOT_ACTION);
        await dispatch("companyModule/FETCH_ROLE_PERMISSIONS_ASSOCIATIONS", {}, ROOT_ACTION);

        resolve();
      } catch (e) {
        reject()
      }
    })
  },
  REMOVE_USER_PERMISSION: ({ dispatch }, payload): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      const resource: string = `/api/v1/clientzone/user/role_permission/${payload.id}`;

      dispatch("companyModule/SET_LOADER", payload.permissionId, ROOT_ACTION);

      try {
        await ApiService.delete(resource);

        await dispatch("companyModule/FETCH_ROLE_PERMISSIONS", {}, ROOT_ACTION);
        await dispatch("companyModule/FETCH_ROLE_PERMISSIONS_ASSOCIATIONS", {}, ROOT_ACTION);

        resolve();
      } catch (e) {
        reject()
      }
    })
  },
  CHECK_LOGGED_USER_ROLE_PERMISSION: ({ commit, rootGetters }, { permissionId, venueId = "" }): Promise<void> => {
    const companyId: number = rootGetters["companyModule/GET_COMPANY_ID"];
    const venueParamQuery: string = venueId ? `&venue_id=${venueId}` : "";

    return new Promise(async (resolve, reject) => {
      const resource: string = `/api/v1/clientzone/user/role_permission/check?permission_id=${permissionId}&venue_owner_id=${companyId}${venueParamQuery}`;

      try {
        const response = await ApiService.get(resource);

        if (permissionId === 1) {
          commit("SET_WEB_STREAMING_PERMISSION", response.data.found)
        } else if (permissionId === 2) {
          commit("SET_EDIT_COMPANY_PERMISSION", response.data.found)
        } else if (permissionId === 3) {
          commit("SET_EDIT_VENUE_PERMISSION", response.data.found)
        } else if (permissionId === 4) {
          commit("SET_CREATE_DELETE_VENUE_PERMISSION", response.data.found)
        } else if (permissionId === 5) {
          commit("SET_CHANGE_PLAYLIST_PERMISSION", response.data.found)
        } else if (permissionId === 6) {
          commit("SET_APP_FEATURES_PERMISSION", response.data.found)
        } else if (permissionId === 7) {
          commit("SET_EDIT_SUBSCRIPTION_PERMISSION", response.data.found)
        } else if (permissionId === 8) {
          commit("SET_INVOICES_PERMISSION", response.data.found)
        } else if (permissionId === 9) {
          commit("SET_PAYMENT_METHOD_PERMISSION", response.data.found)
        } else if (permissionId === 10) {
          commit("SET_LIST_OF_USERS_PERMISSION", response.data.found)
        } else if (permissionId === 11) {
          commit("SET_MANAGE_USER_PERMISSION", response.data.found)
        } else if (permissionId === 12) {
          commit("SET_ROLE_MANAGEMENT_PERMISSION", response.data.found)
        } else if (permissionId === 13) {
          commit("SET_VIEW_AUDIO_CAMPAIGNS_PERMISSION", response.data.found)
        } else if (permissionId === 14) {
          commit("SET_MANAGE_AUDIO_SPOTS_PERMISSION", response.data.found)
        } else if (permissionId === 15) {
          commit("SET_MANAGE_AUDIO_CAMPAIGNS_PERMISSION", response.data.found)
        } else if (permissionId === 17) {
          commit("SET_VENUE_SURVEY_PERMISSION", response.data.found)
        } else if (permissionId === 18) {
          commit("SET_SEND_MESSAGES_PERMISSION_PERMISSION", response.data.found)
        } else if (permissionId === 19) {
          commit("SET_HISTORY_OF_MESSAGES_PERMISSION", response.data.found)
        } else {
          commit("SET_LOGGED_USER_ROLE_PERMISSION", response.data.found)
        }

        resolve();
      } catch (e) {
        reject()
      }
    })
  },
  SET_USER_AS_LOGGED: ({ commit }, payload: boolean): void => commit("SET_USER_LOGGED_STATE", payload),
  SET_LOGGED_USER: ({ commit }, payload: UserData): void => commit("SET_LOGGED_USER", payload),
  SET_USER_ID_FOR_REGISTRATION: ({ commit }, payload: number): void => commit("SET_USER_ID_FOR_REGISTRATION", payload),
  FETCH_USERS_NO_ACCESS: ({ commit, dispatch }, name: string = ""): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      const resource: string = `/api/v1/clientzone/user?limit=10000`;

      try {
        const response = await ApiService.get(resource);

        commit("SET_USERS", []);
        await dispatch("FETCH_USERS_ROLES_SUMMARY");
        await dispatch("FETCH_USERS_AUTHORISATIONS");
        commit("SET_USERS", response.data);
        commit("SET_ADD_USER_LOADER", false);
        commit("SET_USER_HAS_ACCESS", false);

        resolve();
      } catch (e) {
        reject()
      }
    })
  },

}

