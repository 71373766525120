import Vue from "vue";
import i18n from "@/i18n/i18n";

const toastOptions: object = {
  position: "bottom-right",
  closeOnClick: false,
  pauseOnFocusLoss: false,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: "button",
  icon: true,
  rtl: false,
  transition: "Vue-Toastification__slideBlurred",
  maxToasts: 20,
  newestOnTop: true
}

export function successMessage(title: string, time: number): void {
  Vue.$toast.success(i18n.t(title), {
    ...toastOptions,
    timeout: time
  })
}

export function errorMessage(title: string, time: number): void {
  Vue.$toast.error(i18n.t(title), {
    ...toastOptions,
    timeout: time
  })
}

