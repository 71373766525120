import Vue from "vue";
import {
  CompanyRegisterData,
  RegisterFilledData,
  UserRegisterData,
  VenueGoogleData,
  VenueRegisterData,
  VenuesFoundData,
  VenuesSlovenskoDigitalData
} from "@/types";
import { MutationTree } from "vuex";
import { RegisterState } from "@/types/store/clientzone/register";

export const mutations: MutationTree<RegisterState> = {
  SET_VENUE_FOUND_DATA: (state, payload: VenuesFoundData): VenuesFoundData => Vue.set(state, "venuesFound", payload),
  SET_VENUE_PLACE_ID: (state, payload: number | null): number | null => Vue.set(state, "venueIndex", payload),
  SET_VENUE_REGISTER_DATA_MANUALLY: (state, payload): void => {
    Vue.set(state.venue, "name", payload);
    Vue.set(state, "venueIndex", -1);
  },
  SET_VENUE_GOOGLE_DATA: (state, payload: VenueGoogleData): VenueGoogleData => Vue.set(state, "venueGoogleData", payload),
  SET_SLOVENSKO_DIGITAL_DATA: (state, payload: VenuesSlovenskoDigitalData): VenuesSlovenskoDigitalData => Vue.set(state, "venuesSlovenskoDigital", payload),
  SET_VENUE: (state, payload: VenueRegisterData): void => {
    Vue.set(state.venue, "name", payload.name);
    Vue.set(state.venue, "address1", payload.address1);
    Vue.set(state.venue, "address2", payload.address2);
    Vue.set(state.venue, "city", payload.city);
    Vue.set(state.venue, "country_id", payload.country_id);
    Vue.set(state.venue, "latitude", payload.latitude);
    Vue.set(state.venue, "longitude", payload.longitude);
    Vue.set(state.venue, "state", payload.state);
    Vue.set(state.venue, "timezone_id", payload.timezone_id);
    Vue.set(state.venue, "venue_type_id", payload.venue_type_id);
  },
  RESET_VENUE_DATA: (state): Object => Vue.set(state, "selectedVenueRegisterData", {}),
  SET_USER: (state, payload: UserRegisterData): UserRegisterData => Vue.set(state, "user", payload),
  SET_COMPANY: (state, payload: CompanyRegisterData): CompanyRegisterData => Vue.set(state, "company", payload),
  SET_VENUE_SUB_PLAN: (state, payload: VenueRegisterData): void => {
    Vue.set(state.venue, "billing_periodicity", payload.billing_periodicity);
    Vue.set(state.venue, "audio_messages", payload.audio_messages);
    Vue.set(state.venue, "webstreamer", payload.webstreamer);
    Vue.set(state.venue, "subscription_fee", payload.subscription_fee);
  },
  SET_COMPANY_BUSINESS_TYPE: (state, payload: number): number => Vue.set(state.company, "business_type_id", payload),
  FILTER_COMPANY_VAT: (state, payload: boolean): boolean => Vue.set(state, "existCompanyWithVat", payload),
  FILTER_COMPANY_NAME: (state, payload: boolean): boolean => Vue.set(state, "existCompanyWithName", payload),
  FILTER_COMPANY_BIN: (state, payload: boolean): boolean => Vue.set(state, "existCompanyWithBin", payload),
  FILTER_USER_EMAIL: (state, payload: boolean): boolean => Vue.set(state, "existUserWithEmail", payload),
  SET_COMPANY_ID: (state, payload: number): number => Vue.set(state, "companyId", payload),
  SET_VENUES_SEARCHING: (state, payload: boolean): boolean => Vue.set(state, "isSearching", payload),
  SET_VENUE_REGISTER_SUBSCRIPTION: (state, payload) => Vue.set(state, "venueRegisterSubscription", payload),
  SET_INIT_REGISTER: (state, payload: boolean): boolean => Vue.set(state, "initRegister", payload),
  SET_REFERRER_LINK: (state, payload: boolean): boolean => Vue.set(state, "referrerLink", payload),
  SET_NEW_REGISTERED_USER_ID: (state, payload: number): number => Vue.set(state, "newRegisteredUserId", payload),
  SET_NEW_REGISTERED_VENUE_ID: (state, payload: number): number => Vue.set(state, "newRegisteredVenueId", payload),
  SET_REGISTER_FAIL: (state, payload: boolean): boolean => Vue.set(state, "registerFail", payload),
  SET_SUBMIT_REGISTRATION_LOADER: (state, payload: boolean): boolean => Vue.set(state, "createRegistrationLoader", payload),
  SET_LOGINS_COUNT: (state, payload: number): number => Vue.set(state, 'loginsCount', payload),
  SET_REGISTER_FILLED_DATA_ID: (state, payload: string): string => Vue.set(state, 'registerFilledDataId', payload),
  SET_REGISTER_FILLED_DATA: (state, payload: RegisterFilledData): void => {
    Vue.set(state, "registerFilledDataId", payload.id);
    Vue.set(state.user, "email", payload.email);
    Vue.set(state.user, "password", payload.password);
    Vue.set(state.user, "first_name", payload.name);
    Vue.set(state.user, "last_name", payload.surname);
    Vue.set(state.user, "gdpr", true);
    Vue.set(state.user, "status", 2);
    Vue.set(state.user, "active", true);
    Vue.set(state.venue, "name", payload.nameOfEstablishment);
    Vue.set(state.venue, "address1", payload.streetAndNumber);
    Vue.set(state.venue, "address2", payload.postalCode);
    Vue.set(state.venue, "city", payload.city);
    Vue.set(state.venue, "country_id", payload.countryId);
    Vue.set(state.venue, "latitude", Number(payload.gpsLatitude));
    Vue.set(state.venue, "longitude", Number(payload.gpsLongtitude));
    Vue.set(state.venue, "state", 0);
    Vue.set(state.venue, "timezone_id", payload.timeZoneId);
    Vue.set(state.venue, "venue_type_id", payload.venueTypeId);
    Vue.set(state.company, "business_type_id", payload.businessTypeId);

    Vue.set(state.company, "name", payload.companyBillingBusinessName);
    Vue.set(state.company, "legal_entity_name", payload.companyBillingLegalEntityName);
    Vue.set(state.company, "billing_street", payload.companyBillingAddress);
    Vue.set(state.company, "billing_zip", payload.companyBillingZipCode);
    Vue.set(state.company, "billing_country_id", payload.companyBillingCountryId);
    Vue.set(state.company, "billing_city", payload.companyBillingCity);
    Vue.set(state.company, "billing_ico", payload.companyBillingBusinessIdentificationNumber);
    Vue.set(state.company, "billing_vat", payload.companyBillingVatId);
    Vue.set(state.company, "contact", payload.companyBillingContactPerson);
    Vue.set(state.company, "phone", payload.companyBillingContactPersonPhone);
    Vue.set(state.company, "billing_email", payload.companyBillingContactPersonEmail);
    Vue.set(state.company, "email", state.user.email);
  },
  SET_SAVED_VENUE_SUBSCRIPTION_DATA: (state, payload: object): object => Vue.set(state, 'savedVenueSubscriptionData', payload),
  SET_VENUE_ID: (state, payload): Object => Vue.set(state, "venueId", payload),
}
