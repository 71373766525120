export default {
    "List of Audio Spots": "Seznam audio spotů",
    "List of audio spots": "Seznam audio spotů",
    "Messages to customers": "Zprávy zákazníkům",
    "Add New Campaign": "Přidání nové kampaně",
    "Add new campaign": "Přidání nové kampaně",
    "Add Audio Spot": "Přidat audio spot",
    "Campaign Name": "Název kampaně",
    "Campaign period": "Období kampaně",
    "Timeslot": "Perioda",
    "Show only active campaigns": "Zobrazit pouze aktivní kampaně",
    "Venue filter": "Filter provozoven",
    "Venues assigned to the campaign": "Provozovny spojené s kampaní",
    "Venues assigned to the survey": "Provozovny přiřazená k tomuto průzkumu",
    "Spot name": "Název spotu",
    "Music": "Hudba",
    "Text": "Text",
    "Uploaded by": "Nahral",
    "Duration": "Trvání",
    "Type of the message": "Typ zprávy",
    "Reach": "Dosah",
    "Choose venues": "Zvolit provozovnu",
    "Message max characters": "Zpráva (max {count} znaků)",
    "Send a message": "Poslat zprávu",
    "Messages history": "Historie zpráv",
    "Message type": "Typ zprávy",
    "Date": "Dátum",
    "Reach (Songoroo app users)": "Dosah (uživatelé aplikace Songoroo)",
    "Announcement": "Oznámení",
    "Advertisement": "Reklama",
    "List of questions": "Seznam otázek",
    "List Of Questions": "Seznam otázek",
    "Add New Survey": "Přidat nový průzkum",
    "Add new survey": "Přidat nový průzkum",
    "Survey": "Průzkum",
    "Add new question": "Přidat novou otázku",
    "Questions": "Otázky",
    "Question": "Otázka",
    "Created at": "Vytvořeno dne",
    "Created by": "Generováno uživatelem",
    "Select type of your message": "Vyberte typ zprávy",
    "Choose campaign questions": "Vyberte otázky průzkumu",
    "Campaign duration": "Doba trvání kampaně",
    "Message": "Zpráva",
    "characters": "znaků",
    "This venue has BASIC subscription package not covering sending messages to costumers of a particular venue": "Pro tuto provozovnu je zakoupen balíček služeb BASIC, který nezahrnuje zasílání zpráv zákazníkům provozovny",
    "Sent on": "Odesláno dne",
    "Back to audio commercials": "Zpět na audio reklamu",
    "Back to venue survey": "Zpět na hodnocení provozovny",
    "This spot cannot be deleted as it is attached to advert campaign.": "Tento audio spot nelze smazat, protože je spojen se audio kampaní.",
    "No audio spots were found": "Nebyly nalezeny žádné reklamy",
    "Your message has been sent to": "Vaše zpráva byla odeslána",
    "Songoroo app users": "uživatelům mobilní aplikace Songoroo",
    "Default survey": "Všeobecní hodnocení provozovny"
}
