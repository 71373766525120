import { GenresData, VenueTypesData } from "@/types";
import { GetterTree } from "vuex";
import { PlaylistState, RootState } from "@/types/store/clientzone";

export const getters: GetterTree<PlaylistState, RootState> = {
  GET_VENUE_TYPES: (state): Array<VenueTypesData> => state.venueTypes,
  GET_GENRES: (state): Array<GenresData> => state.genres,
  GET_SELECTED_VENUE: (state) => state.selectedVenue,
  GET_CUSTOM_PLAYLIST: (state) => state.customPlaylist,
}
