export default {
  "Select venue": "Zvoliť prevádzku",
  "From": "Od",
  "Choose a date": "Vybrať dátum",
  "To": "Do",
  "Submit": "Potvrdiť",
  "Cancel": "Zrušiť",
  "Search": "Hľadať",
  "Update": "Aktualizovať",
  "Add New Venue": "Pridať novú prevádzku",
  "Active": "Aktívna",
  "Inactive": "Neaktívna",
  "I agree with Songoroo's": "Súhlasim so",
  "Terms of Use": "Všeobecnými obchodnými podmienkami",
  "Terms of Use footer": "Podmienky používania",
  "Privacy Policy": "Zásadami ochrany osobných údajov spoločnosti Songoroo",
  "Privacy Policy footer": "Zásady ochrany osobných údajov",
  "and": "a",
  "First name": "Krstné meno",
  "Last name": "Priezvisko",
  "Password": "Heslo",
  "Select option": "Zvoliť možnosť",
  "excluding": "bez",
  "VAT": "DPH",
  "Upload Photo": "Nahrať fotku",
  "Contact phone": "Telefón",
  "Change your Avatar": "Zmeniť avatara",
  "Upload your profile photo": "Nahrať profilovú fotku",
  "Change your profile photo": "Zmeniť profilovú fotku",
  "My authorisations": "Moje oprávnenia",
  "No authorisations": "Žiadne oprávnenia",
  "Change password": "Zmeniť heslo",
  "Old Password": "Staré heslo",
  "New Password": "Nové heslo",
  "Confirm Password": "Potvrdiť heslo",
  "Unlimited": "Neobmedzene",
  "Selected venues": "Zvolené prevádzky",
  "Selected questions:": "Zvolené otázky:",
  "Choose File": "Vybrať súbor",
  "Action": "Akcia",
  "Enter email": "Zadajte e-mail",
  "Oops! Page Not Found.": "Oops! Stránka nenájdená.",
  "Go to start page": "Prejsť na úvodnú stránku",
  "No data": "Žiadne dáta",
  "Other": "Ostatné",
  "Are you sure you want to delete this advert campaign?": "Naozaj chcete odstrániť túto reklamnú kampaň?",
  "Are you sure you want to delete this audio spot?": "Naozaj chcete odstrániť tento audio spot?",
  "Are you sure you want to delete this question?": "Naozaj chcete odstrániť túto otázku?",
  "Are you sure you want to delete this venue survey?": "Naozaj chcete odstrániť toto hodnotenie prevádzky?",
  "Are you sure you want to delete this payment card?": "Naozaj chcete odstrániť túto platobnú kartu?",
  "Are you sure you want to delete this bank account?": "Naozaj chcete odstrániť tento bankový účet?",
  "Notifications": "Oznámenia",
  "No notifications": "Žiadne oznámenia",
  "Load More...": "Načítať viac...",
  "Renew": "Obnoviť",
  "Sorry, only venues that include the web stream are allowed here.": "Ľutujeme, tu sú povolené iba prevádzky, ktoré obsahujú webový stream.",
  "You have not created any venues at this time. Unable to start webstreaming.": "Momentálne nemáte vytvorenú žiadnu prevádzku. Nie je možné spustiť webstreaming.",
  "No options.": "Žiadne možnosti.",
  "Deleted": "Vymazána",
  "paid": "Zaplatené",
  "open": "Otvorená",
  "Yes": "Áno",
  "uncollectible": "Nesplatiteľná",
  "All campaigns": "Všetky kampane",
  "All surveys": "Všetky prieskumy",
  "Other user is streaming music of this venue. Do you want to take it over?": "Iný užívateľ práve prehráva hudbu tejto prevádzky. Chcete to prevziať?",
  "Other user took over music stream of venue ": "Iný užívateľ prevzial streaming hudby prevádzky ",
  "Stream is already playing on this device": "Na tomto zariadení sa už prehráva stream",
  "Your 14-days Trial Expired": "Platnosť vašej 14-dňovej skúšobnej verzie vypršala",
  "Your 14-days Trial Expired Description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam elit purus, posuere non est a, venenatis semper ex. Praesent tristique egestas dui quis accumsan. Maecenas ultrices massa ac urna consectetur, sed viverra tortor mattis.",
  "Contact us": "Kontaktuje nás",
  "Select subscription plan": "Vyberte plán predplatného",
  "The trial period has ended on": "Dňa ",
  "for venue": " skončila skúšobná doba pre prevádzku ",
  "To continue using Songoroo Smart Music, please select one of our subscriptions plans.": ". Pre pokračovanie využívania služby Songoroo Smart Music si vyberte prosím jeden z ponúkaných balíkov.",
  "Show password": "Zobraziť heslo",
  "Hide password": "Skryť heslo",
}
