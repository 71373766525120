import i18n from "@/i18n/i18n";
import AxiosService from "@/services/axios.service";

export const actions = {
  FETCH_BUSINESS_TYPES: ({ commit, dispatch }, payload): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      let resource: string = ''
      if (payload) {
        resource = `/api/v1/clientzone/venue_owner/business_type?name=${payload}&offset=0&limit=1000`;
      } else {
        resource = `/api/v1/clientzone/venue_owner/business_type?offset=0&limit=1000`;
      }
      try {
        const locale = i18n.locale || 'en';
        const config = {
          headers: {
            'sgr-lang': locale,
          }
        }
        const response = await AxiosService.getAxios().get(resource, config)
        commit("SET_BUSINESS_TYPES", response.data);
        resolve();
      } catch (e) {
        reject()
      }
    })
  },

  ADD_BUSINESS_TYPE: ({ commit, dispatch }, payload): Promise<number> => {
    return new Promise(async (resolve, reject) => {
      const resource: string = `/api/v1/clientzone/venue_owner/business_type`;
      try {
        const response = await AxiosService.getAxios().post(resource, { ...payload });
        
        commit("ADD_BUSINESS_TYPE", response.data);
        const { id } = response.data;
        resolve(id);
      } catch (e) {
        reject()
      }
    })
  },

  UPDATE_BUSINESS_TYPE: ({ commit, dispatch }, payload): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      const resource: string = `/api/v1/clientzone/venue_owner/business_type/${payload.id}`;
      try {
        const setDefaultBusinessTypePayload = {id: payload.id, isDefault: payload.default_business_type};

        delete payload.id
        const response = await AxiosService.getAxios().put(resource, { ...payload })

        commit("UPDATE_BUSINESS_TYPE", response.data);
        resolve();
      } catch (e) {
        reject()
      }
    })
  },

  DELETE_BUSINESS_TYPE: ({ commit, dispatch }, payload): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      const resource: string = `/api/v1/clientzone/venue_owner/business_type/${payload.id}`;
      try {
        await AxiosService.getAxios().delete(resource)
        commit("DELETE_BUSINESS_TYPE", payload.id);
        resolve();
      } catch (e) {
        reject()
      }
    })
  },

  FETCH_BUSINESS_TYPE: ({ commit, dispatch }, id): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      const resource: string = `/api/v1/clientzone/venue_owner/business_type/${id}`;
      try {
        const response = await AxiosService.getAxios().get(resource)
        commit("SET_BUSINESS_TYPE", response.data);
        console.log(response.data)
        resolve();
      } catch (e) {
        reject()
      }
    })
  },
  SET_DEFAULT_BUSINESS_TYPE: ({ commit, dispatch }, payload): Promise<void> => {
    console.log(payload);
    return new Promise(async (resolve, reject) => {
      const resource: string = `/api/v1/clientzone/venue_owner/set_default_business_type?default_business_type_id=${payload.id}`;

      try {
        await AxiosService.getAxios().patch(resource)
        commit("SET_DEFAULT_BUSINESS_TYPE", payload);
        resolve();
      } catch (e) {
        reject()
      }

    });
  }
}
