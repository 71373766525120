export const getters = {
  layoutType: (state) => state.layoutType,
  leftSidebarType: (state) => state.leftSidebarType,
  layoutWidth: (state) => state.layoutWidth,
  topBar: (state) => state.topbar,
  loader: (state) => state.loader,
  GET_MENU_ITEMS: (state, rootState, getters, rootGetters) => {
    const isUserSuperAdmin: boolean = rootGetters["userModule/IS_USER_SUPER_ADMIN"];

    if (isUserSuperAdmin) return state.adminZoneMenuItems;
    return state.clientZoneMenuItems;
  },
  GET_TOGGLE_MENU_STATE: (state): boolean => state.toggleMenuState,
  CAN_SHOW_BROWSER_STREAMER: (state): boolean => state.canShowBrowserStreamer,
  GET_GLOBAL_OVERLAY_STATE: (state): boolean => state.isOverlaySpinnerRunning
}
