




import lottie from 'vue-lottie/src/lottie.vue';
import { PropType } from "vue";
import { animationData } from "@/assets/animation/equalizer-animation.json";

export default {
  name: "LottieAnimationEqualizer",
  components: {
    lottie
  },
  data() {
    return {
      anim: null,
      lottieOptions: { animationData: animationData}
    };
  },
  methods: {
    handleAnimation: function (anim) {
      this.anim = anim;
    }
  },
  props: {
    width: {
      type: Number as PropType<number>,
      default: 64.0
    },
    heigh: {
      type: Number as PropType<number>,
      default: 64.0
    },
  }
}
