import { state } from "@/state/modules/clientzone/audio_commercials/state";
import { mutations } from "@/state/modules/clientzone/audio_commercials/mutations";
import { getters } from "@/state/modules/clientzone/audio_commercials/getters";
import { actions } from "@/state/modules/clientzone/audio_commercials/actions";

const audioCommercialsModule = {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}

export default audioCommercialsModule
