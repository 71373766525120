import {DashboardState} from "@/types/store/clientzone/dashboard";
import {CountsData, ListsData, SelectedDateDashboardData, SelectedVenuesIdsDashboardData, StatsData,FilterData} from "@/types";

export const state: DashboardState = {
  counts: {} as CountsData,
  lists: {} as ListsData,
  stats: {} as StatsData,
  filter: {} as FilterData,
  startAt: [] as Array<SelectedDateDashboardData>,
  endAt: [] as Array<SelectedDateDashboardData>,
  selectedVenuesIds: [] as Array<SelectedVenuesIdsDashboardData>
}
