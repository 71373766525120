export default {
  "Active Playlist": "Active Playlist",
  "Congress-Exhibition Centre": "Congress &amp; Exhibition Centre",
  "Select your playlist": "Select your playlist",
  "Playlist Description 1": "All Songoroo playlists are compiled by our experienced music editors. They listen to every song and taking into consideration the genre, tempo, language and other factors, decide which business is the song suitable for. That means that every playlist is tailored for a specific business type. It´s hard, manual and time consuming work, but we don´t compromise quantity over quality. It goes without saying that every playlist is updated on a regular basis.",
  "Playlist Description 2": "All Songoroo playlists are compiled by our experienced music editors. They listen to every song and taking into consideration the genre, tempo, language and other factors, decide which business is the song suitable for. That means that every playlist is tailored for a specific business type. It´s hard, manual and time consuming work, but we don´t compromise quantity over quality. It goes without saying that every playlist is updated on a regular basis.",
  "Playlist Description 3": "Of course we have to also admit that no matter how hard we try, there is still this tiny possibility that you might not quite like any of our pre-defined playlists. No need to worry! You still have the freedom to compile your own playlist and have it exactly the way you want.",
  "Create your own playlist": "Create your own playlist",
  "Venue custom playlist": "Venue custom playlist",
  "Get creative. Follow your taste and compile your own playlist": "Get creative. Follow your taste and compile your own playlist",
  "Select genres from the below list to include them in your custom built playlist. The custom playlist you are about to generate for this venue can be modified anytime.": "Select genres from the below list to include them in your custom built playlist. The custom playlist you are about to generate for this venue can be modified anytime.",
  "Generate Playlist": "Generate Playlist",
  "Genre description": "Genre description",
  "Set for this venue": "Set for this venue",
  "Playlist description": "Playlist description",
  "Included": "Included",
  "Excluded": "Excluded",
  "Low": "Low",
  "Low to medium": "Low to medium",
  "Low to high": "Low to high",
  "Medium": "Medium",
  "High": "High",
  "Playlist changed": "Playlist changed",
  "Back to playlist selection": "Back to playlist selection",
  "More genres error": "Songoroo algorithm requires a certain amount of tracks in order to be able to offer the best possible mix of songs. The music genres you have selected do not contain enough tracks and could cause possible failures in the algorithm calculations. Please select more genres to meet this criteria.",

}
