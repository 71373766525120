
































































































import Vue from "vue";
import Layout from "@/router/layouts/auth.vue";
import { mapActions, mapGetters } from "vuex";
import checkLogin from "../../../mixins/checkLogin";
import TokenService from "@/services/token.service";
import ApiService from "@/services/api.service";
import { required, email } from "vuelidate/lib/validators";
import VueRecaptcha from "vue-recaptcha";
import { errorMessage } from "@/utils/messages-handlers";
import router from "@/router";
import { RegisterFilledData } from "@/types";

export default Vue.extend({
  mixins: [checkLogin],
  components: { Layout, VueRecaptcha },
  data() {
    return {
      email: "",
      password: "",
      rememberMe: true,
      isSubmitted: false,
      recaptchaSiteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
    };
  },
  //@ts-ignore
  validations: {
    email: { required, email },
    password: { required },
  },
  mounted(): void {
    window.addEventListener("message", this.onMessage, false);
    this.setLocale();

    localStorage.removeItem("company_id");
    localStorage.removeItem("venue_id");

    // Fix: #3569 Zrusenie cacheovania udajov o prihlasenom uzivatelovi
    localStorage.removeItem("webstream_token");
    localStorage.removeItem("isPlaying");
    localStorage.removeItem("user");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("cassia_user_id");

    // registration
    localStorage.removeItem("registrationData");
    localStorage.removeItem("place_id");

    // trial dialog
    localStorage.removeItem('trial_dialog_showed');

    // stream auto play
    localStorage.removeItem('init_play');

    this.clearCompanyData;
  },
  beforeDestroy(): void {
    window.removeEventListener("message", this.onMessage);
  },
  created(): void {
    this.checkLogin();
  },

  computed: {
    ...mapGetters("userModule", {
      loader: "GET_LOADER",
    }),
    ...mapGetters("registerModule", {
      loginsCount: "GET_LOGINS_COUNT",
    }),
    ...mapGetters("companyModule", {
      company: "GET_COMPANY"
    }),
    ...mapActions("companyModule", {
      clearCompanyData: "CLEAR_COMPANY",
    }),
    ...mapGetters("emailsModule", {
      isUserEmailVerified: "GET_IS_USER_EMAIL_VERIFIED",
    }),
    isLoginButtonDisabled() {
      return this.loginsCount === 15;
    },
    loginUrl() {
      if (this.$i18n.locale === "sk") {
        return `/register/step-1?lang=sk`;
      } else {
        return `/register/step-1?`;
      }
    },
  },

  methods: {
    ...mapActions("userModule", {
      loginUser: "LOGIN",
    }),
    ...mapActions("registerModule", {
      setLoginsCount: "SET_LOGINS_COUNT",
      checkUnfinishedRegistration: "CHECK_UNFINISHED_REGISTRATION",
    }),
    ...mapActions("emailsModule", {
      checkIfIsUserActive: "IS_USER_ACTIVE",
    }),

    enableLogin() {
      this.isLoginEnabled = true;
      this.setLoginsCount(0);
    },

    onExpired(): void {
      errorMessage("Not valid", 5000);
    },

    onMessage(e: any): void {
      if (e.origin !== window.origin || !e.data.access_token) {
        return;
      }

      TokenService.saveToken(e.data.access_token);
      // TokenService.saveUserId(e.data.user_id);
      // TokenService.saveRefreshToken(e.data.refresh_token);
      ApiService.setHeader();

      this.$router.push("/dashboard");
    },

    setLocale(): void {
      var language = window.localStorage.getItem("language"),
        locale = "",
        defaultLocale = "en";
      if (!language) {
        language = window.navigator.language;
      }

      locale = language.substring(0, 2);
      if (this.$i18n.availableLocales.indexOf(locale) < 0) {
        locale = defaultLocale;
      }
      localStorage.setItem("language", locale);
      this.$i18n.locale = locale;
    },

    async login(): Promise<void> {
      this.setLoginsCount(this.loginsCount + 1);

      this.isSubmitted = true;
      this.$v.$touch();

      if (this.$v.$invalid) return;

      await this.checkIfIsUserActive(this.email);

      let unfinishedRegistrationData = null;

      try {
        unfinishedRegistrationData = await this.checkUnfinishedRegistration(this.email);
      } catch(e) {}

      if (this.isUserEmailVerified === false) {
        errorMessage("The email you entered has not yet been verified, please check your email.", 5000)
      } else if (this.isUserEmailVerified === true && unfinishedRegistrationData) {
        this.tryFinishRegistration(unfinishedRegistrationData);
      } else {
        const user: object = {
          email: this.email,
          password: this.password,
          rememberMe: this.rememberMe,
        };

        await this.loginUser(user);
      }
    },
    async tryFinishRegistration(data: RegisterFilledData): Promise<void> {
      const registerData = {
        "userIsLoggedIn": false,
        "userData": {
            "email": data.email,
            "password": this.password,
            "first_name": data.name,
            "last_name": data.surname,
            "gdpr": true,
            "status": 2,
            "active": this.isUserEmailVerified,
        },
        "companyData": {
            "business_type_id": data.businessTypeId,
            "email": data.email,
        },
        "venueData": {
            "name": data.nameOfEstablishment,
            "address1": data.streetAndNumber,
            "address2": data.postalCode,
            "city": data.city,
            "country_id": data.countryId,
            "latitude": data.gpsLatitude,
            "longitude": data.gpsLongtitude,
            "state": 0,
            "timezone_id": data.timeZoneId,
            "venue_type_id": data.venueTypeId,
        },
        "registrationResourceId": data.id,
        "loggedUser": {}
      };

      localStorage.setItem("registrationData", JSON.stringify(registerData));
      localStorage.setItem("unfinishedRegistration", "true");

      router.push("/register/step-5");
    },
  },
});
