import Vue from "vue";
import { MutationTree } from "vuex";
import { ArtistData, SongData, VenueAppFeaturesData, VenueData } from "@/types";
import { VenueAppFeaturesState } from "@/types/store/clientzone";

export const mutations: MutationTree<VenueAppFeaturesState> = {
  SET_VENUE: (state, payload: VenueData): VenueData => Vue.set(state, "selectedVenue", payload),
  SET_VENUE_FINAL_FEATURES: (state, payload: Array<VenueAppFeaturesData>): Array<VenueAppFeaturesData> => Vue.set(state, "venueFinalFeatures", payload),
  SET_FOUND_SONGS: (state, payload: Array<SongData>): Array<SongData> => Vue.set(state, "foundSongs", payload),
  SET_FOUND_ARTISTS: (state, payload: Array<ArtistData>): Array<ArtistData> => Vue.set(state, "foundArtists", payload),
  SET_CENSORED_SONGS: (state, payload: Array<SongData>): Array<SongData> => Vue.set(state, "censoredSongs", payload),
  SET_CENSORED_ARTISTS: (state, payload: Array<ArtistData>): Array<ArtistData> => Vue.set(state, "censoredArtists", payload),
  SET_SONG_IMAGE: (state, payload): void => {
    const songIndex = state.censoredSongs.findIndex(x => x.artist_id === payload.id);
    Vue.set(state.censoredSongs[songIndex], "image", payload.encodeImage);
  },
  SET_FOUND_SONG_IMAGE: (state, payload): void => {
    const foundSongIndex = state.foundSongs.findIndex(x => x.artist_id === payload.id);
    Vue.set(state.foundSongs[foundSongIndex], "image", payload.encodeImage);
  },
  SET_ARTIST_IMAGE: (state, payload): void => {
    const artistIndex = state.censoredArtists.findIndex(x => x.id === payload.id);
    Vue.set(state.censoredArtists[artistIndex], "image", payload.encodeImage);
  },
  SET_FOUND_ARTIST_IMAGE: (state, payload): void => {
    const foundArtistIndex = state.foundArtists.findIndex(x => x.id === payload.id);
    Vue.set(state.foundArtists[foundArtistIndex], "image", payload.encodeImage);
  },
  RESET_SEARCH_RESULTS: (state, payload): void => {
    Vue.set(state, "foundSongs", payload);
    Vue.set(state, "foundArtists", payload);
  }
}
