export default {
  "Manage Payment Method": "Manage Payment Method",
  "Back to Payment method": "Back To Payment Method",
  "Add new card": "Add New Card",
  "Add new bank account": "Add New Bank Account",
  "Bank transfer": "Bank Transfer",
  "Edit": "Edit",
  "Remove": "Remove",
  "Card number": "Card Number",
  "Valid thru": "Valid Thru",
  "Add new Credit/Debit card": "Add New Credit/Debit Card",
  "Name on card": "Name On Card",
  "Security Code": "Security Code",
  "Edit card": "Edit Card",
  "Edit bank account": "Edit Bank Account",
  "Add card": "Add Card",
  "Add bank account": "Add Bank Account",
  "Full name on the card": "Full Name On The Card",
  "Select payment method": "Select Payment Method",
  "Assign": "Assign",
  "Selected card": "Selected card",
  "Expired": "Expired",
  "Expires on": "Expires on"
}
