import { CompanyData, RolePermissionsAssociationData, RolePermissionsData } from "@/types";
import { CompanyState } from "@/types/store/clientzone";

export const state: CompanyState = {
  companies: [] as Array<CompanyData>,
  company: {} as CompanyData,
  rolePermissions: [] as Array<RolePermissionsData>,
  rolePermissionsAssociation: [] as Array<RolePermissionsAssociationData>
}

