


















import Vue from "vue";
import i18n from "@/i18n/i18n";

export default Vue.extend({
  name: "navbar-main" as string,

  data() {
    return {
      webUrl:process.env.VUE_APP_SONGOROO_WEB,
      navLogoWhite: require('@/assets/images/nav-logo-white.svg'),
      registerFooterBg: require('@/assets/images/register-footer-bg.svg'),
      languages: [
        {
          flag: require("@/assets/images/flags/en.png"),
          language: "en",
          title: "English",
          title2: "Angličtina",
        },
        {
          flag: require("@/assets/images/flags/sk.png"),
          language: "sk",
          title: "Slovenčina",
          title2: "Slovak",
        },
        {
          flag: require("@/assets/images/flags/cs.png"),
          language: "cs",
          title: "Čeština",
          title2: "Czech",
        }
      ],
      lan: i18n.locale,
      text: null,
      flag: null,
      value: null,
      i18: i18n
    }
  },

  mounted() {
    this.value = this.languages.find((x) => x.language === i18n.locale);
    this.text = this.value.title;
    this.flag = this.value.flag;
  },

  methods: {
    openMobileMenu() {
      // TOGGLE MOBILE NAV MENU

      document.getElementsByClassName('mobile-nav-wrapper')[0].classList.add('active')
      const mobileNavOverlay = document.getElementsByClassName('mobile-nav-opened-overlay')[0] as HTMLElement;
      mobileNavOverlay.style.display = 'block'
      document.body.style.overflowY = 'hidden';
    },

    closeMobileMenu() {
      document.getElementsByClassName('mobile-nav-wrapper')[0].classList.remove('active');
      const mobileNavOverlay = document.getElementsByClassName('mobile-nav-opened-overlay')[0] as HTMLElement
      mobileNavOverlay.style.display = 'none';
      document.body.style.overflowY = 'visible';
    },

    setLanguage(locale, country, flag) {
      this.lan = locale;
      this.text = country;
      this.flag = flag;
      i18n.locale = locale;
      localStorage.setItem("language", locale);
    }
  }
});
