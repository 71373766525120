import Vue from "vue";
import {
  UserAuthorisationsData,
  UserAuthorisationsVenuesData,
  UserData,
  UserRolesData,
  UserRolesSummaryData
} from "@/types";
import {MutationTree} from "vuex";
import { UserState } from "@/types/store/clientzone";

export const mutations: MutationTree<UserState> = {
  LOGIN_REQUEST: (state): void => {
    state.authenticating = true;
    state.authenticationError = '';
    state.authenticationErrorCode = 0
  },
  LOGIN_SUCCESS: (state, accessToken: string): void => {
    state.accessToken = accessToken;
    state.authenticating = false;
  },
  LOGIN_ERROR: (state, {errorCode, errorMessage}): void => {
    state.authenticating = false;
    state.authenticationErrorCode = errorCode;
    state.authenticationError = errorMessage;
  },
  LOGOUT_SUCCESS: (state) => {
    state.accessToken = '';
    state.data = {};
    state.authenticating = false;
    state.authenticationErrorCode = 0;
    state.authenticationError = '';
  },
  SET_LOGGED_USER: (state, payload: UserData): UserData => Vue.set(state, "loggedUser", payload),
  SET_LOGGED_USER_WITHOUT_ANY_PERMISSIONS: (state, payload: boolean): boolean => Vue.set(state.loggedUser, 'isWithoutPermissions', payload),
  SET_USER_LOGGED_STATE: (state, payload: boolean): boolean => Vue.set(state, "isUserLogged", payload),
  SET_SELECTED_USER: (state, payload: UserData): UserData => Vue.set(state, "selectedUser", payload),
  SET_USERS: (state, payload: Array<UserData>): Array<UserData> => Vue.set(state, "users", payload),
  SET_QUESTIONARY_USERS: (state, payload: Array<UserData>): Array<UserData> => Vue.set(state, 'questionaryUsers', payload),
  SET_AUDIO_SPOTS_USERS: (state, payload: Array<UserData>): Array<UserData> => Vue.set(state, 'audioSpotsUsers', payload),
  SET_USER_IMAGE: (state, payload): void => {
    if (!!payload.id) {
      const userIndex = state.users.findIndex(x => x.id === payload.id);
      Vue.set(state.users[userIndex], "image", payload.encodeImage);
    } else {
      Vue.set(state, "image", payload)
    }
  },
  SET_SELECTED_USER_IMAGE: (state, payload): void => Vue.set(state.selectedUser, "image", payload),
  SET_USER_AUTHORISATIONS: (state, payload: Array<UserAuthorisationsData>): Array<UserAuthorisationsData> => Vue.set(state, "userAuthorisations", payload),
  SET_USERS_AUTHORISATIONS: (state, payload: Array<UserAuthorisationsData>): Array<UserAuthorisationsData> => Vue.set(state, "usersAuthorisations", payload),
  SET_USER_ROLES: (state, payload: Array<UserRolesData>): Array<UserRolesData> => Vue.set(state, "userRoles", payload),
  // SET_USER_ROLES_SUMMARY: (state, payload: Array<UserRolesSummaryData>): Array<UserRolesSummaryData> => Vue.set(state, "userRolesSummary", payload)
  // SET_USER_ROLES_SUMMARY: (state, payload: UserRolesSummaryData): void => {
  //   const index = state.users.findIndex(u => u.id === payload.id);
  //   Vue.set(state.users[index], "roles", payload.roles)
  // },

  SET_USERS_ROLES_SUMMARY: (state, payload: Array<UserRolesSummaryData>): Array<UserRolesSummaryData> => Vue.set(state, "userRolesSummary", payload),

  SET_USER_VENUES_PERMISSIONS: (state, payload) => Vue.set(state, "userVenuesPermissions", payload),
  SET_USER_ACCESS_RIGHTS: (state, payload: UserAuthorisationsVenuesData) => {
    const index = state.users.findIndex(u => u.id === payload[0].czone_user_id);
    Vue.set(state.users[index], "userAuthorisationsVenues", payload)
  },
  FILTER_ACTIVE_USERS: (state, payload: boolean): boolean => Vue.set(state, "filterByActiveStatus", payload),
  SET_VENUE_AUTHORIZED_TO_USER: (state, payload): void => {
    const userIndex: number = state.users.findIndex(u => u.id === payload.userId);
    Vue.set(state.users[userIndex], "venueAuthorizedToUser", payload.venueData);
  },
  SET_USER_IS_ADMIN: (state, payload: boolean): boolean =>  Vue.set(state.loggedUser, "isAdmin", payload),
  SET_ADD_USER_LOADER: (state, payload: boolean): boolean => Vue.set(state, "addUserLoader", payload),
  SET_USER_HAS_ACCESS: (state, payload: boolean): boolean => Vue.set(state, "userHasAccess", payload),
  SET_LOADER: (state, payload: boolean): boolean => Vue.set(state, "loader", payload),
  SET_LOGGED_USER_ROLE_PERMISSION: (state, payload: boolean): boolean => Vue.set(state, "hasUserRolePermission", payload),
  SET_WEB_STREAMING_PERMISSION: (state, payload: boolean): boolean => Vue.set(state, "hasWebStreamingPermission", payload),
  SET_APP_FEATURES_PERMISSION: (state, payload: boolean): boolean => Vue.set(state, "hasAppFeaturesPermission", payload),
  SET_EDIT_SUBSCRIPTION_PERMISSION: (state, payload: boolean) => Vue.set(state, "hasEditSubscriptionPermission", payload),
  SET_CHANGE_PLAYLIST_PERMISSION: (state, payload: boolean): boolean => Vue.set(state, "hasChangePlaylistPermission", payload),
  SET_LIST_OF_USERS_PERMISSION: (state, payload: boolean): boolean => Vue.set(state, "hasListOfUsersPermission", payload),
  SET_MANAGE_USER_PERMISSION: (state, payload: boolean): boolean => Vue.set(state, "hasManageUserPermission", payload),
  SET_ROLE_MANAGEMENT_PERMISSION: (state, payload: boolean): boolean => Vue.set(state, "hasRoleManagementPermission", payload),
  SET_EDIT_COMPANY_PERMISSION: (state, payload: boolean): boolean => Vue.set(state, "hasEditCompanyPermission", payload),
  SET_CREATE_DELETE_VENUE_PERMISSION: (state, payload: boolean): boolean => Vue.set(state, "hasCreateDeleteVenuePermission", payload),
  SET_EDIT_VENUE_PERMISSION: (state, payload: boolean): boolean => Vue.set(state, "hasEditVenuePermission", payload),
  SET_INVOICES_PERMISSION: (state, payload: boolean): boolean => Vue.set(state, "hasInvoicesPermission", payload),
  SET_PAYMENT_METHOD_PERMISSION: (state, payload: boolean): boolean => Vue.set(state, "hasPaymentMethodPermission", payload),
  SET_VIEW_AUDIO_CAMPAIGNS_PERMISSION: (state, payload: boolean): boolean => Vue.set(state, "hasViewAudioCampaignsPermission", payload),
  SET_MANAGE_AUDIO_CAMPAIGNS_PERMISSION: (state, payload: boolean): boolean => Vue.set(state, "hasManageAudioCampaignsPermission", payload),
  SET_MANAGE_AUDIO_SPOTS_PERMISSION: (state, payload: boolean): boolean => Vue.set(state, "hasManageAudioSpotsPermission", payload),
  SET_VENUE_SURVEY_PERMISSION: (state, payload: boolean): boolean => Vue.set(state, "hasVenueSurveyPermission", payload),
  SET_SEND_MESSAGES_PERMISSION_PERMISSION: (state, payload: boolean): boolean => Vue.set(state, "hasSendMessagesPermission", payload),
  SET_HISTORY_OF_MESSAGES_PERMISSION: (state, payload: boolean): boolean => Vue.set(state, "hasHistoryOfMessagesPermission", payload)
}
