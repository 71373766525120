import axios, { AxiosStatic } from "axios";
import TokenService from "@/services/token.service";

export interface AxiosInterface {
  getAxios() : AxiosStatic;
}

const AxiosService: AxiosInterface = {
  getAxios: () => {
    axios.defaults.headers.common["Content-Type"] = `Application/json`;
    const token: (string | null) = TokenService.getToken();

    if (token === null) {
      return axios;
    }

    axios.defaults.headers.common["Authorization"] = `Bearer ${TokenService.getToken()}`;

    return axios;
  }
};

export default AxiosService;
