import { GetterTree } from "vuex";
import {
  QuestionaryAvailableVenueData,
  QuestionaryQuestionData,
  VenueQuestionaryData,
} from "@/types";
import { RootState, VenueQuestionaryState } from "@/types/store/clientzone";

export const getters: GetterTree<VenueQuestionaryState, RootState> = {
  GET_QUESTIONARY_QUESTIONS: (state): Array<QuestionaryQuestionData> => [...state.questionaryQuestions].sort((a, b) => (a.created_at - b.created_at)),
  GET_QUESTIONARY_AVAILABLE_VENUES: (state): Array<QuestionaryAvailableVenueData> => state.questionaryAvailableVenues,
  // GET_VENUE_QUESTIONARY: (state): Array<VenueQuestionaryData> => state.filterActiveCampaigns ? state.venueQuestionary.filter(q => q.active === state.filterActiveCampaigns) : state.venueQuestionary,
  GET_PERMISSION_AVAILABLE_QUESTIONARIES: (state): Array<VenueQuestionaryData> => [...state.permissionAvailableQuestionaries].filter((venueQuestionary) => venueQuestionary.venues.length || venueQuestionary.main),
  GET_VENUE_QUESTIONARY: (state): Array<VenueQuestionaryData> => state.venueQuestionary,
  GET_QUESTION_LOADER: (state): boolean => state.questionLoader,
  GET_ACTIVE_FILTER: (state): boolean => state.filterActiveCampaigns,
}
