export default {
    "Dashboard": "Dashboard",
    "Company data": "Informace o společnosti",
    "Venues": "Provozovny",
    "Venues list": "Seznam provozoven",
    "Venues detail": "Detail provozovny",
    "App features setup": "Nastavení funkcí aplikace",
    "Subscriptions & Billing": "Balíček služeb a fakturace",
    "Subscription Plan": "Balíček služeb",
    "Payment Methods": "Platební metody",
    "User management": "Správa uživatelů",
    "User access rights": "Přístupová práva uživatelů",
    "Role management": "Správa autorizací",
    "Communication with Customers": "Komunikace se zákazníky",
    "Audio Commercials": "Audio reklama",
    "Messages to clients": "Zprávy zákazníkům",
    "Venue Survey": "Hodnocení provozovny",
    "Venue survey": "Hodnocení provozovny zákazníkmi",
    "Venue Feedback": "Zpětná vazba o provozovne",
    "Reports": "Reporty",
    "Help & support": "Pomoc a podpora",
    "Discover": "Představení",
    "Setup": "Nastavení",
    "Manage": "Spravovat",
    "App features": "Funkce aplikace",
    "Try for free": "Vyzkoušet zdarma",
    "Try it for free": "Vyzkoušet zdarma",
    "Get the app": "Získat aplikaci",
    "Are you a business?": "Ste firma?",
    "Not a business?": "Nejste firma?",
    "Login": "Přihlásit se",
    "My profile": "Můj profil",
    "Logout": "Odhlásit se",
    "Companies": "Společnosti",
    "List of Companies": "Seznam spoločností",
    "Subscription": "Balíček služeb",
    "Subscription Plans": "Plány předplatného",
    "Discount Codes": "Slevové kódy",
    "Billingg": "Fakturace",
    "Invoice Overview": "Přehled faktur",
    "Invoice Data Feed": "Informačný kanál faktur",
    "Communication": "Komunikace",
    "Emails": "Emaily",
    "Notifications": "Oznámení",
    "User Management": "Správa uživatelů",
    "Client Zone Users": "Uživatelé klientské zóny",
    "Songoroo Admins": "Administrátoři Songoroo",
    "Job Applicants": "Uchazeči o zaměstnání",
    "Settings": "Nastavení",
    "Activity Log": "Záznamy o aktivitách",
    "Business Type": "Typ provozovny",
    "Language Options": "Jazykové mutace",
}  
