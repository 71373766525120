import {RouteConfig} from "vue-router";
import PurchaseOrderHistory from "@/router/views/purchaseOrder/PurchaseOrderHistory.vue";

const AZ_TITLE = 'Adminzone - Songoroo';

const adminZoneRoutes: RouteConfig[] = [
  {
    path: "/az-homepage",
    name: "az-homepage",
    meta: {
      public: false,
      title: AZ_TITLE
    },
    component: () => import("@/router/views/dashboard/AZHomepage.vue")
  },
  {
    path: "/activity-logs",
    name: "ActivityLogs",
    meta: {
      public: true,
      title: AZ_TITLE
    },
    component: () => import("@/router/views/activityLogs/ActivityLogs.vue"),
  },
  {
    path: "/business-type",
    name: "BusinessType",
    meta: {
      public: true,
      title: AZ_TITLE
    },
    component: () => import("@/router/views/businessType/BusinessType.vue"),
  },
  {
    path: "/job-applicants",
    name: "JobApplicants",
    meta: {
      public: true,
      title: AZ_TITLE
    },
    component: () => import("@/router/views/jobApplicants/JobApplicants.vue"),
  },
  {
    path: "/job-applicants/:id",
    name: "JobApplicant",
    meta: {
      public: true,
      title: AZ_TITLE
    },
    component: () => import("@/router/views/jobApplicants/JobApplicant.vue"),
  },
  {
    path: "/notifications",
    name: "Notifications",
    meta: {
      public: false,
      title: AZ_TITLE
    },
    component: () => import("@/router/views/notifications/Notifications.vue"),
  },
  {
    path: "/notifications/edit/:id/:lang",
    name: "EditNotification",
    meta: {
      public: false,
      title: AZ_TITLE
    },
    component: () => import("@/router/views/notifications/EditNotification.vue"),
  },
  {
    path: "/discount-codes",
    name: "DiscountCodes",
    meta: {
      public: false,
      title: AZ_TITLE
    },
    component: () => import("@/router/views/discountCodes/DiscountCodes.vue"),
  },
  {
    path: "/emails",
    name: "Emails",
    meta: {
      public: false,
      title: AZ_TITLE
    },
    component: () => import("@/router/views/emails/Emails.vue"),
  },
  {
    path: "/emails/edit/:id/:lang",
    name: "EditEmail",
    meta: {
      public: false,
      title: AZ_TITLE
    },
    component: () => import("@/router/views/emails/EditEmail.vue"),
  },
  {
    path: "/invoices",
    name: "Invoices",
    meta: {
      public: false,
      title: AZ_TITLE
    },
    component: () => import("@/router/views/invoice/Invoices.vue"),
  },
  {
    path: "/invoices/export/pdf",
    name: "PdfInvoice",
    meta: {
      public: false,
      title: AZ_TITLE
    },
    component: () => import("@/router/views/invoice/PdfInvoice.vue"),
  },
  {
    path: "/invoices/create",
    name: "NewInvoice",
    meta: {
      public: false,
      title: AZ_TITLE
    },
    component: () => import("@/router/views/invoice/NewInvoice.vue"),
  },
  {
    path: "/invoices/edit/:id",
    name: "EditInvoice",
    meta: {
      public: false,
      title: AZ_TITLE
    },
    component: () => import("@/router/views/invoice/EditInvoice.vue"),
  },
  {
    path: "/subscription-plans",
    name: "SubscriptionPlans",
    meta: {
      public: false,
      title: AZ_TITLE
    },
    component: () => import("@/router/views/subscription/SubscriptionPlans.vue"),
  },
  {
    path: "/invoices/datafeed",
    name: "InvoiceDataFeed",
    meta: {
      public: false,
      title: AZ_TITLE
    },
    component: () => import("@/router/views/invoice/InvoiceDataFeed.vue"),
  },
  {
    path: "/subscription-plans/edit/:id",
    name: "EditSubscriptionPlan",
    meta: {
      public: false,
      title: AZ_TITLE
    },
    component: () => import("@/router/views/subscription/EditSubscriptionPlan.vue"),
  },
  {
    path: "/subscription-plans/language-options",
    name: "LanguageOptions",
    meta: {
      public: false,
      title: AZ_TITLE
    },
    component: () => import("@/router/views/subscription/LanguageOptions.vue"),
  },
  {
    path: "/purchase-order-history/:id",
    name: "PurchaseOrderHistory",
    meta: {
      public: false,
      title: AZ_TITLE
    },
    component: () => import("@/router/views/purchaseOrder/PurchaseOrderHistory.vue")

  },
  {
    path: "/admin-profile/:id",
    name: "SuperAdminProfile",
    meta: {
      public: false,
      title: AZ_TITLE
    },
    component: () => import("@/router/views/account/SuperAdminProfile.vue")

  },
  {
    path: "/clientzone-user-profile/:id",
    name: "ClientZoneUserProfile",
    meta: {
      public: false,
      title: AZ_TITLE
    },
    component: () => import("@/router/views/account/ClientZoneUserProfile.vue")

  },
  {
    path: "/admin-profile/",
    name: "admin-profile",
    meta: {
      public: false,
      title: AZ_TITLE
    },
    component: () => import("@/router/views/account/AdminMyProfile.vue")

  },
  {
    path: "/client-zone-users/",
    name: "client-zone-users",
    meta: {
      public: false,
      title: AZ_TITLE
    },
    component: () => import("@/router/views/client-zone-users/ClientZoneUsers.vue")

  },
  {
    path: "/songoroo-admins/",
    name: "songoroo-admins",
    meta: {
      public: false,
      title: AZ_TITLE
    },
    component: () => import("@/router/views/songoroo-admins/Songoroo-admins.vue")

  },
  {
    path: "/role-management-admin/",
    name: "role-management-admin",
    meta: {
      public: false,
      title: AZ_TITLE
    },
    component: () => import("@/router/views/role-management/RoleManagementAdmin.vue")

  },
  {
    path: "/add-new-user/",
    name: "add-new-user",
    meta: {
      public: false,
      title: AZ_TITLE
    },
    component: () => import("@/router/views/client-zone-users/AddNewUser.vue")

  },
  {
    path: "/user-management/",
    name: "user-management",
    meta: {
      public: false,
      title: AZ_TITLE
    },
    component: () => import("@/router/views/user/UserManagement.vue")

  },
  {
    path: "/az-company-detail/:id",
    name: "az-company-detail",
    meta: {
      public: false,
      title: AZ_TITLE
    },
    component: () => import("@/router/views/company/AzCompanyDetail.vue")
  },
  {
    path: "/list-of-companies/",
    name: "list-of-companies",
    meta: {
      public: false,
      title: AZ_TITLE
    },
    component: () => import("@/router/views/company/ListOfCompanies.vue")

  },
  {
    path: "/az-venue-detail/:id",
    name: "az-venue-detail",
    meta: {
      public: false,
      title: AZ_TITLE
    },
    component: () => import("@/router/views/azVenues/AzVenueDetail.vue")
  },
  {
    path: "/az-venues/",
    name: "az-venues",
    meta: {
      public: false,
      title: AZ_TITLE
    },
    component: () => import("@/router/views/azVenues/AzVenues.vue")
  },
  {
    path: "/list-of-venues/",
    name: "list-of-venues",
    meta: {
      public: false,
      title: AZ_TITLE
    },
    component: () => import("@/router/views/azVenues/AzListOfVenues.vue")
  },
  {
    path: "/az-create-venue/",
    name: "az-create-venue",
    meta: {
      public: false,
      title: AZ_TITLE
    },
    component: () => import("@/router/views/azVenues/AzNewVenue.vue")
  },
  {
    path: "/az-create-company/",
    name: "az-create-company",
    meta: {
      public: false,
      title: AZ_TITLE
    },
    component: () => import("@/router/views/company/AzAddNewCompany.vue")
  },
]

export default adminZoneRoutes
