import { state } from '@/state/modules/clientzone/payment/state';
import { mutations } from '@/state/modules/clientzone/payment/mutations';
import { getters } from '@/state/modules/clientzone/payment/getters';
import { actions } from '@/state/modules/clientzone/payment/actions';

const paymentModule = {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}

export default paymentModule
