export default {
  "Business Name": "Business Name (trademark)",
  "Business Name subtext": "Company's trademark used to be recognised by it's clients and public",
  "Business type": "Business type",
  "Name of the establishment": "Name of the establishment",
  "Legal entity name": "Legal Entity Name",
  "Legal entity subtext": "Company's official name used for legal purposes - needed for billing purposes",
  "Street and descriptive house number": "Street And Descriptive House Number",
  "Street and descriptive number": "Street And Descriptive Number",
  "Postal code": "Postal Code",
  "City": "City",
  "Country": "Country",
  "VAT number": "VAT Number",
  "VAT ID": "VAT ID",
  "Business Identification Number": "Business Identification Number",
  "Bank name": "Bank Name",
  "IBAN account": "IBAN Account",
  "Swift code": "Swift Code",
  "Zip code": "Zip Code",
  "GPS latitude": "GPS Latitude",
  "GPS longitude": "GPS Longitude",
  "Billing email": "Billing Email",
  "Contact person": "Contact Person",
  "Contact persons phone": "Contact Person's Phone",
  "Contact persons email": "Contact Person's Email",
  "Company Logo": "Company Logo",
  "Upload company logo description": "Company Logo will be shown in Songoroo app in the list of venues, plus in Venue detail. Therefore, we strongly recommend you to upload company logo by which your venue will be easily recognised from other venues in the list.",
  "Upload Company logo": "Upload Company Logo",
  "Change company logo": "Change Company Logo",
  "Save": "Save",
  "Select company": "Select Company",
  "Company registration date": "Company Registration Date",
}
