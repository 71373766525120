export default {
  "Invoice number": "Číslo faktúry",
  "Subscription period": "Fakturačné obdobie",
  "Issue date": "Dátum vystavenia",
  "Payment method": "Spôsob platby",
  "Amount": "Suma",
  "Due Date": "Dátum splatnosti",
  "Status": "Stav",
  "Export": "Export",
  "Paid": "Zaplatené",
  "Past due": "Po splatnosti",
  "Not due": "Neuhradené",
  "Credit invoice": "Dobropis",
  "Credit/Debit card": "Kreditná/debetná karta",
  "Invoice": "Faktúra",
  "Venue Name": "Názov prevádzky",
  "Address": "Adresa",
  "Detail": "Detail",
  "Subscription details": "Detail zvoleného balíka služieb",
  "Change Subscription Plan": "Zmeniť balík služieb",
  "Cancel Subscription Plan": "Zrušiť službu pre túto prevádzku",
  "Expiration date": "Dátum ukončenia",
  "Duration date": "Dátum trvania",
  "Learn more": "Bližšie informácie",
  "Renew Subscription Plan": "Obnoviť balík služieb",
  "Audio commercials and messages": "Audio reklama a oznámenia",
  "Songoroo equips you with a tool enabling the administration of the audio messages in your business at no extra costs. You can easily upload your own messages and commercials and set rules where and when these should be played.": "Songoroo poskytuje nástroj umožňujúci vytváranie audio reklamných kampaní vo Vašich prevádzkach bez žiadnych dodatočných nákladov. Môžete ľahko nahrať svoje vlastné oznamy alebo reklamné správy a nastaviť pravidlá, kde a kedy sa majú tieto oznamy prehrať.",
  "Order": "Objednať"
}
