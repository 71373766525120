import { state } from "@/state/modules/clientzone/messages_to_customers/state";
import { mutations } from "@/state/modules/clientzone/messages_to_customers/mutations";
import { getters } from "@/state/modules/clientzone/messages_to_customers/getters";
import { actions } from "@/state/modules/clientzone/messages_to_customers/actions";

const messagesToCustomersModule = {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}

export default messagesToCustomersModule
