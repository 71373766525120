import { state } from "@/state/modules/clientzone/venue_questionary/state";
import { mutations } from "@/state/modules/clientzone/venue_questionary/mutations";
import { getters } from "@/state/modules/clientzone/venue_questionary/getters";
import { actions } from "@/state/modules/clientzone/venue_questionary/actions";

const venueQuestionaryModule = {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}

export default venueQuestionaryModule
