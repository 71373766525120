export interface NotificationTypeRoute {
  type: number;
  path: string;
  comment: string;
}
export const notificationsTypesRoutes: Array<NotificationTypeRoute> = [
  {
    type: 1,
    path: "/company",
    comment: "MISSING_COMPANY_LOGO"
  },
  {
    type: 2,
    path: "/company",
    comment: "MISSING_COMPANY_DATA"
  },
  {
    type: 3,
    path: "/payment/manage",
    comment: "CREDIT_CARD_EXPIRED"
  },
  {
    type: 4,
    path: "/payment",
    comment: "UNPROCESSED_PAYMENT_WARNING_1"
  },
  {
    type: 5,
    path: "/payment",
    comment: "UNPROCESSED_PAYMENT_WARNING_2"
  },
  {
    type: 6,
    path: "/subscription-plan",
    comment: "DEACTIVATED_VENUE_SUBSCRIPTION_DUE_TO_UNPROCESSED_PAYMENT"
  },
  {
    type: 7,
    path: "/venue-detail",
    comment: "MISSING_CRITICAL_VENUE_DETAILS"
  },
  {
    type: 8,
    path: "/venue-detail",
    comment: "MISSING_VENUE_DETAILS"
  },
  {
    type: 9,
    path: "/audio-commercials",
    comment: "END_OF_AUDIO_CAMPAIGN"
  },
  {
    type: 10,
    path: "/venue-survey",
    comment: "END_OF_CUSTOM_SURVEY"
  },
  {
    type: 11,
    path: "/messages-to-customers",
    comment: "MESSAGES_TO_CUSTOMERS"
  },
  {
    type: 11,
    path: "/venue-survey",
    comment: "CUSTOMIZED_VENUE_SURVEY"
  },
  {
    type: 13,
    path: "/edit-user-permissions",
    comment: "UNASSIGNED_ROLE"
  }
]
