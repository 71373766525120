export default {
  "Choose your subscription plan": "Choose Your Subscription Plan",
  "The terms field is required.": "The terms field is required.",
  "Next step": "Next step",
  "Month": "Month",
  "Year": "Year",
  "Monthly": "Monthly",
  "Yearly": "Yearly",
  "Excluding VAT": "Excluding VAT",
  "Including VAT": "Including VAT",
  "including VAT": "including VAT",
  "including": "including",
  "including XY% VAT": "including {n}% VAT",
  "100+ music playlists to choose from, including customized setup": "100+ music playlists to choose from, including customized setup",
  "Free of charge Songoroo mobile apps for your customers and employees": "Free of charge Songoroo mobile apps for your customers and employees",
  "Multi location management dashboard": "Multi location management dashboard",
  "Standard set of Songoroo app management tools": "Standard set of Songoroo app management tools",
  "Songoroo streamer device for no additional charge": "Songoroo streamer device for no additional charge",
  "Weekly music scheduler customised per location": "Weekly music scheduler customised per location",
  "Full set of Songoroo app management tools": "Full set of Songoroo app management tools",
  "Business Inteligence reporting, i.e. customer behaviour, Songoroo app usage, etc.": "Business Inteligence reporting, i.e. customer behaviour, Songoroo app usage, etc.",
  "Additional services": "Additional Services",
  "Audio messages": "Audio Messages",
  "Payment period": "Payment Period",
  "payment will be automatically processed every year": "payment will be automatically processed every year",
  "payment will be automatically processed every month": "payment will be automatically processed every month",
  "monthly subscription": "monthly subscription",
  "yearly subscription": "yearly subscription",
  "Music Streaming Method": "Music Streaming Method",
  "Streaming device": "Streaming Device",
  "Web Based Streaming": "Web Based Streaming",
  "Webstream": "Webstream",
  "I want to use Songoroo streaming device for no additional charge": "I want to use Songoroo streaming device for no additional charge",
  "I will use only web based streaming solution, using my PC or mobile": "I will use only web based streaming solution, using my PC or mobile",
  "Are you sure you do not want to use Songoroo streaming device?": "Are you sure you do not want to use Songoroo streaming device?",
  "Songoroo streamer is for no additional charge. Comparing to web based solution working with your own device, our streamer provides additional assurance of system stability. In addition, it enables several features beneficial for your busienss such as automatic start and stop, Songoroo app user detection, automatized system updates and maintenance. If you want to sqeeze maximum from what we offer, use Songoroo streaming device.": "Songoroo streamer is for no additional charge. Comparing to web based solution working with your own device, our streamer provides additional assurance of system stability. In addition, it enables several features beneficial for your busienss such as automatic start and stop, Songoroo app user detection, automatized system updates and maintenance. If you want to sqeeze maximum from what we offer, use Songoroo streaming device.",
  "Enter discount code": "Enter discount code",
  "Discount code does not exist": "Discount code does not exist",
  // "First 14 days are for free": "First 14 days are for free",
  "days are for free": "days are for free",
  "then you will be charged": "then you will be charged",
  "Select Credit/Debit Card": "Select Credit/Debit Card",
  "Select Bank Account": "Select Bank Account",
  "Venue name": "Venue's Name",
  "Venue name is required.": "Venue's name is required.",
  "Select playlist": "Select Playlist",
  "Cancel subscription": "Cancel Subscription",
  "Update of Subscription Plan": "Update of Subscription Plan",
  "Renew your subscription plan": "Renew your subscription plan",
  "Cancel your subscription plan": "Cancel Your Subscription Plan",
  "We are sorry you decided to cancel Songoroo subscription for this venue. Songoroo services will be active for this venue until": "We are sorry you decided to cancel Songoroo subscription for this venue. Songoroo services will be active for this venue until",
  "You can renew the subscription anytime even after subscription plan expiration date.": "You can renew the subscription anytime even after subscription plan expiration date.",
  "Are you sure you want to change your subscription plan?": "Are you sure you want to change your subscription plan?",
  "Are you sure you want to renew your subscription plan?": "Are you sure you want to renew your subscription plan?",
  "Delete Venue": "Delete Venue",
  "Are you sure you want to delete venue?": "Are you sure you want to delete venue?",
  "Are you sure you want to delete user": "Are you sure about cancelling account ",
  "It is not possible to cancel this account.": "It is not possible to cancel this account",
  "Back to subscription plan": "Back To Subscription Plan",
  "Current billing period": "Current Billing Period",
  "Your subscription product was changed from Basic to Premium.": "Your subscription product was changed from Basic to Premium.",
  "Your subscription product was changed from Premium to Basic.": "Your subscription product was changed from Premium to Basic.",
  "Audio messages have been activated.": "Audio messages have been activated.",
  "have been activated.": "have been activated.",
  "have been deactivated.": "have been deactivated.",
  "Audio messages have been deactivated.": "Audio messages have been deactivated.",
  "Your payment period was changed from month to year.": "Your payment period was changed from month to year.",
  "Your payment period was changed from year to month.": "Your payment period was changed from year to month.",
  "Your music streaming method was changed from Streaming device to Web Based Streaming.": "Your music streaming method was changed from Streaming device to Web Based Streaming.",
  "Your music streaming method was changed from Web Based Streaming to Streaming device.": "Your music streaming method was changed from Web Based Streaming to Streaming device.",
  "Trial period": "Trial period",
  "Final subscription fee for this venue": "Final subscription fee for this venue",
  "Payment method is required.": "Payment method is required.",
  "Redeem": "Redeem",
  "Not available": "Not available",
  "/ month": "/ month",
  "/ year": "/ year",
  "CHOOSE BASIC PLAN": "CHOOSE BASIC PLAN",
  "CHOOSE PREMIUM PLAN": "CHOOSE PREMIUM PLAN",
}
