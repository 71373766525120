import { CASSOVIA_API_RESOURCE } from "@/utils/helpers";
import AxiosService from "@/services/axios.service";

export const actions = {
    FETCH_JOB_APPLICANTS: ({ commit, dispatch }, payload): Promise<void> => {
      return new Promise(async (resolve, reject) => {
        let resource: string = ''
        if(payload && payload.name) {
          resource = `${CASSOVIA_API_RESOURCE}/api/job-applicants?name=${payload.name}`;
        }
        // if(payload) {
        //   resource = `${CASSOVIA_API_RESOURCE}/api/job-applicants`
        // }
        else {
          resource = `${CASSOVIA_API_RESOURCE}/api/job-applicants`;
        }

        try {
          const response = await AxiosService.getAxios().get(resource)
          commit("SET_JOB_APPLICANTS", response.data);
          resolve();
        } catch (e) {
          reject()
        }
      })
    },

    FETCH_JOB_APPLICANT: ({ commit, dispatch }, id): Promise<void> => {
      return new Promise(async (resolve, reject) => {
        const resource: string = `${CASSOVIA_API_RESOURCE}/api/job-applicants/${id}`;
        try {
          const response = await AxiosService.getAxios().get(resource)
          commit("SET_JOB_APPLICANT", response.data);
          resolve();
        } catch (e) {
          reject()
        }
      })
    },

    UPDATE_JOB_APPLICANT: ({ commit, dispatch }, payload): Promise<void> => {
      return new Promise(async (resolve, reject) => {
        const resource: string = `${CASSOVIA_API_RESOURCE}/api/job-applicants/${payload.id}`;
        try {
          delete payload.id
          await AxiosService.getAxios().patch(resource, {...payload}, {
            headers: {
              'Content-Type': 'application/merge-patch+json'
            }
          })
          // commit("UPDATE_JOB_APPLICANT", response.data);
          resolve();
        } catch (e) {
          reject()
        }
      })
    },

    DOWNLOAD_FILE: ({commit, getters}, path: string): Promise<void> => {
      // commit("SET_LOADER", { invoiceId: invoiceId, value: true });
      const invoiceNumber: Function = getters["GET_INVOICE_NUMBER"];

      return new Promise(async (resolve, reject) => {
        const resource: string = `${CASSOVIA_API_RESOURCE}${path}`;

        try {
          await AxiosService.getAxios().get(resource, {
            responseType: 'blob'
          });

          // commit("SET_LOADER", { invoiceId: invoiceId, value: false });

          // const url = window.URL.createObjectURL(new Blob([response.data]));
          // const link = document.createElement('a');
          // link.href = url;
          // link.setAttribute('download', `invoice-${invoiceNumber(invoiceId)}.pdf`);
          // document.body.appendChild(link);
          // link.click();

          resolve();
        } catch (e) {
          // commit("SET_LOADER", { invoiceId: invoiceId, value: false });
          reject()
        }
      })
    },
}
