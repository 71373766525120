import { CorporateInvoiceVenuesData, InvoiceData } from "@/types";
import {GetterTree} from "vuex";
import { BillingAdminzoneState } from "@/types/store/adminzone/billing";
import { RootState } from "@/types/store/adminzone";

export const getters: GetterTree<BillingAdminzoneState, RootState> = {
  GET_INVOICES: (state): Array<InvoiceData> => state.invoices,
  GET_ALL_INVOICES: (state): Array<InvoiceData> => [...state.all_invoices].filter(invoice => invoice.amount != '0'),
  GET_ALL_INVOICES_COUNT: (state): number => state.allInvoicesCount,
  GET_CORPORATE_INVOICE_VENUES: (state): Array<CorporateInvoiceVenuesData> => state.corporateInvoiceVenues
}
