import ApiService from "@/services/api.service";
import { ActionTree } from "vuex";
import { errorMessage, successMessage } from "@/utils/messages-handlers";
import { RootState, VenueQuestionaryState } from "@/types/store/clientzone";
import { VenueQuestionaryData } from "@/types";

export const actions: ActionTree<VenueQuestionaryState, RootState> = {
  FETCH_QUESTIONARY_QUESTIONS: ({ commit, dispatch, rootGetters }, name: string = ""): Promise<void> => {
    const companyId: number = rootGetters["companyModule/GET_COMPANY_ID"];

    return new Promise(async (resolve, reject) => {
      const resource: string = `/api/v1/clientzone/venue_questionary_question?venue_owner_id=${companyId}&limit=10000&name=${name}`;

      try {
        const response = await ApiService.get(resource);

        commit("SET_QUESTIONARY_QUESTIONS", response.data);
        await dispatch("FETCH_VENUE_QUESTIONARY", "");

        resolve();
      } catch (e) {
        reject()
      }
    })
  },

  ADD_QUESTIONARY_QUESTION: ({ commit, dispatch }, payload: object): Promise<void> => {
    commit("SET_QUESTION_LOADER", true);

    return new Promise(async (resolve, reject) => {
      const resource: string = "/api/v1/clientzone/venue_questionary_question";

      try {
        await ApiService.post(resource, payload);

        await dispatch("FETCH_QUESTIONARY_QUESTIONS", "");
        successMessage("Question added.", 3000);
        commit("SET_QUESTION_LOADER", false);

        resolve();
      } catch (e) {
        commit("SET_QUESTION_LOADER", false);
        reject()
      }
    })
  },
  EDIT_QUESTIONARY_QUESTION: ({ commit, dispatch }, { id, payload }): Promise<void> => {
    commit("SET_QUESTION_LOADER", true);

    return new Promise(async (resolve, reject) => {
      const resource: string = `/api/v1/clientzone/venue_questionary_question/${id}`;

      try {
        await ApiService.put(resource, payload);

        await dispatch("FETCH_QUESTIONARY_QUESTIONS", "");
        successMessage("Question edited.", 3000);
        commit("SET_QUESTION_LOADER", false);

        resolve();
      } catch (e) {
        commit("SET_QUESTION_LOADER", false);
        reject()
      }
    })
  },
  DELETE_QUESTIONARY_QUESTION: ({ dispatch }, id: number): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      const resource: string = `/api/v1/clientzone/venue_questionary_question/${id}`;

      try {
        await ApiService.delete(resource);

        await dispatch("FETCH_QUESTIONARY_QUESTIONS", "");

        successMessage("Question deleted.", 3000);

        resolve();
      } catch (e) {
        errorMessage('Venue questionary question is default question or is used in active/future active campaigns', 5000);
        reject()
      }
    })
  },
  FETCH_VENUE_QUESTIONARY: ({ commit, rootGetters }, { payload = {}, name = "" }): Promise<void> => {
    const companyId: number = rootGetters["companyModule/GET_COMPANY_ID"];
    let parameters: string = "";
    if (!payload.venue_ids) {
      parameters = "";
    } else {
      if (!payload.start_at || !payload.end_at) {
        parameters = `&${payload.venue_ids}`
      } else {
        parameters = `&${payload.venue_ids}&start_at=${payload.start_at}&end_at=${payload.end_at}`;
      }
    }

    return new Promise(async (resolve, reject) => {
      const resource: string = `/api/v1/clientzone/venue_questionary?limit=10000&venue_owner_id=${companyId}&name=${name}${parameters}`;

      try {
        const response = await ApiService.get(resource);

        commit("SET_VENUE_QUESTIONARY", response.data);

        resolve();
      } catch (e) {
        reject()
      }
    })
  },

  FETCH_PERMISSION_AVAILABLE_QUESTIONARIES: ({ commit, rootGetters }, payload): Promise<void> => {
    const companyId: number = rootGetters['companyModule/GET_COMPANY_ID'];
    const venueQuestionaries = []
    return new Promise(async (resolve, reject) => {
      try {
        for (const venueQuestionary of payload) {
          const allowedVenues = []
          if (venueQuestionary.main) {
            venueQuestionaries.push(venueQuestionary)
          } else {
            for (const venue of venueQuestionary.venues) {
              const resource: string = `/api/v1/clientzone/user/role_permission/check?permission_id=17&venue_owner_id=${companyId}&venue_id=${venue.id}`
              const response = await ApiService.get(resource);
              if (response.data.found) {
                allowedVenues.push(venue)
              }
            }
            if (allowedVenues.length === venueQuestionary.venues.length) {
              venueQuestionaries.push({ ...venueQuestionary })
            } else {
              const venueQuestionaryCopy: VenueQuestionaryData = { ...venueQuestionary }
              venueQuestionaryCopy.venues = venueQuestionaryCopy.venues.filter(venue => allowedVenues.includes(venue))

              venueQuestionaries.push(venueQuestionaryCopy)
            }
          }
        }
        commit("SET_PERMISSION_AVAILABLE_QUESTIONARIES", venueQuestionaries)
        resolve();
      } catch (e) {
        reject()
      }
    })
  },

  FETCH_AVAILABLE_VENUE_QUESTIONARY: ({ commit, rootGetters }, payload): Promise<void> => {
    const companyId: number = rootGetters["companyModule/GET_COMPANY_ID"];

    return new Promise(async (resolve, reject) => {
      const resource: string = `/api/v1/clientzone/venue_questionary/available_venue?start_at=${payload.dateFrom}&end_at=${payload.dateTo}&venue_owner_id=${companyId}&offset=0&include_deleted=False`;

      try {
        const response = await ApiService.get(resource);

        commit("SET_AVAILABLE_VENUE_QUESTIONARY", response.data);

        resolve();
      } catch (e) {
        reject()
      }
    })
  },
  ADD_VENUE_QUESTIONARY: ({ dispatch }, payload: object): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      const resource: string = "/api/v1/clientzone/venue_questionary";

      try {
        await ApiService.post(resource, payload);

        await dispatch("FETCH_VENUE_QUESTIONARY", "");
        successMessage("Campaign created.", 3000);

        resolve();
      } catch (e) {
        // console.log(e)
        if (e.response.data.error.code === "VenueQuestionaryDateMismatchError") errorMessage("Venue questionary start_at and end_at overlap existing campaigns.", 5000)
        reject()
      }
    })
  },
  EDIT_VENUE_QUESTIONARY: ({ dispatch }, { payload, id }): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      const resource: string = `/api/v1/clientzone/venue_questionary/${id}`;

      try {
        await ApiService.put(resource, payload);

        await dispatch("FETCH_VENUE_QUESTIONARY", "");
        successMessage("Campaign edited.", 3000);

        resolve();
      } catch (e) {
        if (e.response.data.error.code === "VenueQuestionaryDateMismatchError") errorMessage("Venue questionary start_at and end_at overlap existing campaigns.", 5000)
        reject()
      }
    })
  },
  DELETE_VENUE_QUESTIONARY: ({ dispatch }, id: number): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      const resource: string = `/api/v1/clientzone/venue_questionary/${id}`;

      try {
        await ApiService.delete(resource);

        await dispatch("FETCH_VENUE_QUESTIONARY", "");
        successMessage("Campaign deleted.", 3000);

        resolve();
      } catch (e) {
        reject()
      }
    })
  },
  FILTER_ACTIVE_CAMPAIGNS: ({ commit }, payload: boolean): void => commit("FILTER_ACTIVE_CAMPAIGNS", payload),
  SET_QUESTIONARY_USER_IMAGE: ({ commit }, payload): void => commit("SET_QUESTIONARY_USER_IMAGE", payload),
}
