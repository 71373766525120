export default {
  "Choose your subscription plan": "Vyberte si Songoroo balík",
  "The terms field is required.": "Pole Podmienky používania je povinné.",
  "Next step": "Ďalši krok",
  "Month": "Mesiac",
  "Year": "Rok",
  "Monthly": "Mesačne",
  "Yearly": "Ročne",
  "Excluding VAT": "Bez DPH",
  "Including VAT": "S DPH",
  "including VAT": "s DPH",
  "including": "vrátane",
  "including XY% VAT": "vrátane {n}% DPH",
  "100+ music playlists to choose from, including customized setup": "Na výber viac ako 100 hudobných playlistov, vrátane prispôsobenia nastavení",
  "Free of charge Songoroo mobile apps for your customers and employees": "Mobilné aplikácie Songoroo pre vašich zákazníkov a zamestnancov zadarmo",
  "Multi location management dashboard": "Multi lokalizačná správa klientskeho rozhrania",
  "Standard set of Songoroo app management tools": "Štandardná sada nástrojov na správu aplikácií Songoroo",
  "Songoroo streamer device for no additional charge": "Streamovacie zariadenie Songoro bez ďalších poplatkov",
  "Weekly music scheduler customised per location": "Týždenný hudobný plánovač prispôsobený podľa lokácie",
  "Full set of Songoroo app management tools": "Kompletná sada nástrojov na správu aplikácií Songoroo",
  "Business Inteligence reporting, i.e. customer behaviour, Songoroo app usage, etc.": "Report obchodnej inteligencie, t.j. správanie zákazníka, využitie aplikácie Songoroo atď.",
  "Additional services": "Doplnkové služby",
  "Audio messages": "Audio správy",
  "Payment period": "Platobné obdobie",
  "payment will be automatically processed every year": "platba bude automaticky spracovaná každý rok",
  "payment will be automatically processed every month": "platba bude automaticky spracovaná každý mesiac",
  "Music Streaming Method": "Metóda streamovania hudby",
  "monthly subscription": "mesačné predplatné",
  "yearly subscription": "ročné predplatné",
  "Streaming device": "Streamovacie zariadenie",
  "Web Based Streaming": "Webstream",
  "Webstream": "Webstream",
  "I want to use Songoroo streaming device for no additional charge": "Chcem používať streamovacie zariadenie Songoroo bez ďalších poplatkov",
  "I will use only web based streaming solution, using my PC or mobile": "Budem používať iba webové riešenie na streamovanie pomocou počítača alebo mobilného telefónu",
  "Are you sure you do not want to use Songoroo streaming device?": "Ste si istý, že nechcete využívať streamovacie zariadenie Songoroo?",
  "Songoroo streamer is for no additional charge. Comparing to web based solution working with your own device, our streamer provides additional assurance of system stability. In addition, it enables several features beneficial for your busienss such as automatic start and stop, Songoroo app user detection, automatized system updates and maintenance. If you want to sqeeze maximum from what we offer, use Songoroo streaming device.": "Streamer Songoroo nie je spoplatnený. V porovnaní s webovým riešením pracovať s vlastným zariadením, náš streamer poskytuje záruku stability systému. Okrem toho, umožňuje niekoľko prospešných funkcií  pre vaše podnikanie, ako je automatické spustenie a zastavenie, detekcia užívateľov aplikácie Songoroo, automatické aktualizácie systému a údržba. Ak chcete získať maximum z toho, čo ponúkame, použite streamovacie zariadenie Songoroo.",
  "Enter discount code": "Zadať zľavový kód",
  "Discount code does not exist": "Zľavový kód neexistuje",
  // "First 14 days are for free": "Prvých 14 dní je zadarmo",
  "days are for free": "dní je zadarmo",
  "then you will be charged": "potom Vám bude účtovaný poplatok",
  "Select Credit/Debit Card": "Zvoliť Kreditnú/debetnú kartu",
  "Select Bank Account": "Zvoliť bankový účet",
  "Venue name": "Názov prevádzky",
  "Venue name is required.": "Názov prevádzky je povinné.",
  "Select playlist": "Zvoľ playlist",
  "Cancel subscription": "Zrušiť balík služieb",
  "Update of Subscription Plan": "Aktualizácia balíka služieb",
  "Renew your subscription plan": "Obnoviť balík služieb",
  "Cancel your subscription plan": "Zrušiť balík služieb",
  "We are sorry you decided to cancel Songoroo subscription for this venue. Songoroo services will be active for this venue until": "Je nám ľúto, že ste sa rozhodli zrušiť balík služieb Songoroo pre túto prevádzku. Služby Songoroo budú pre túto prevádzku aktívne až do",
  "You can renew the subscription anytime even after subscription plan expiration date.": "Balík služieb môžete kedykoľvek obnoviť, a to aj po uplynutí doby platnosti balíku služieb.",
  "Are you sure you want to change your subscription plan?": "Naozaj chcete zmeniť plán predplatného?",
  "Are you sure you want to renew your subscription plan?": "Naozaj chcete obnoviť plán predplatného?",
  "Delete Venue": "Zmazať prevádzku",
  "Are you sure you want to delete venue?": "Naozaj chcete odstrániť prevádzku?",
  "Are you sure you want to delete user": "Ste si istý, že chcete zmazať užívateľa ",
  "It is not possible to cancel this account.": "Nie je možné vymazať užívateľa.",
  "Back to subscription plan": "Späť na balík služieb",
  "Current billing period": "Aktuálne fakturačné obdobie",
  "Your subscription product was changed from Basic to Premium.": "Vaše predplatné bolo zmenené z Basic na Premium.",
  "Your subscription product was changed from Premium to Basic.": "Vaše predplatné bolo zmenené z Premium na Basic.",
  "Audio messages have been activated.": "Audio správy boli aktivované.",
  "have been activated.": "boli aktivované.",
  "have been deactivated.": "boli deaktivované.",
  "Audio messages have been deactivated.": "Audio správy boli deaktivované",
  "Your payment period was changed from month to year.": "Vaše platobné obdobie bolo zmenené z mesiaca na rok.",
  "Your payment period was changed from year to month.": "Vaše platobné obdobie bolo zmenené z rok na mesiac.",
  "Your music streaming method was changed from Streaming device to Web Based Streaming.": "Váš spôsob streamovania hudby bol zmenený zo streamovacieho zariadenie na Webstream.",
  "Your music streaming method was changed from Web Based Streaming to Streaming device.": "Váš spôsob streamovania hudby bol zmenený z Webstreamu na streamovacie zariadenie.",
  "Trial period": "Skúšobná doba",
  "Final subscription fee for this venue": "Celková suma predplatného za túto prevádzku",
  "Payment method is required.": "Spôsob platby je povinný.",
  "Redeem": "Uplatniť",
  "Not available": "Nedostupné",
  "/ month": "/ mesiac",
  "/ year": "/ rok",
  "CHOOSE BASIC PLAN": "VYBRAŤ ZÁKLADNÝ BALÍK",
  "CHOOSE PREMIUM PLAN": "VYBRAŤ PRÉMIOVÝ BALÍK",
}
