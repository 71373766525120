export default {
    "Choose your subscription plan": "Vyberte si balíček Songoroo",
    "The terms field is required.": "Pole Podmínky použití je povinné.",
    "Next step": "Další krok",
    "Month": "Měsíc",
    "Year": "Rok",
    "Monthly": "Měsíčně",
    "Yearly": "Ročne",
    "Excluding VAT": "Bez DPH",
    "Including VAT": "S DPH",
    "including VAT": "s DPH",
    "including": "včetně",
    "including XY% VAT": "včetně {n}% DPH",
    "100+ music playlists to choose from, including customized setup": "Vyberte si z více než 100 hudebních playlistů včetně vlastního výběru",
    "Free of charge Songoroo mobile apps for your customers and employees": "Mobilní aplikace Songoroo pro vaše zákazníky a zaměstnance zdarma",
    "Multi location management dashboard": "Správa klientského rozhraní",
    "Standard set of Songoroo app management tools": "Standardní sada nástrojů pro správu aplikace Songoroo",
    "Songoroo streamer device for no additional charge": "Streamovací zařízení Songoro bez dalších nákladů",
    "Weekly music scheduler customised per location": "Týdenní hudební plánovač přizpůsobený podle lokace",
    "Full set of Songoroo app management tools": "Kompletní sada nástrojů pro správu aplikace Songoroo",
    "Business Inteligence reporting, i.e. customer behaviour, Songoroo app usage, etc.": "Business intelligence report, tj. chování zákazníků, používání Songoroo, atd.",
    "Additional services": "Doplňkové služby",
    "Audio messages": "Audio zprávy",
    "Payment period": "Platební období",
    "payment will be automatically processed every year": "platba bude automaticky zpracována každý rok",
    "payment will be automatically processed every month": "platba bude automaticky zpracována každý měsíc",
    "Music Streaming Method": "Metoda streamování hudby",
    "monthly subscription": "měsíční předplatné",
    "yearly subscription": "roční předplatné",
    "Streaming device": "streamovací zařízení",
    "Web Based Streaming": "Webstream",
    "Webstream": "Webstream",
    "I want to use Songoroo streaming device for no additional charge": "Chci používat streamovací zařízení Songoroo bez příplatku",
    "I will use only web based streaming solution, using my PC or mobile": "Ke streamování pomocí počítače nebo mobilního telefonu budu používat pouze webové řešení",
    "Are you sure you do not want to use Songoroo streaming device?": "Opravdu nechcete používat streamovací zařízení Songoroo?",
    "Songoroo streamer is for no additional charge. Comparing to web based solution working with your own device, our streamer provides additional assurance of system stability. In addition, it enables several features beneficial for your busienss such as automatic start and stop, Songoroo app user detection, automatized system updates and maintenance. If you want to sqeeze maximum from what we offer, use Songoroo streaming device.": "Streamer Songoroo není zpoplatněn. Ve srovnání s webovým řešením pro práci s vlastním zařízením poskytuje náš streamer záruku stability systému. Kromě toho umožňuje několik užitečných funkcí pro vaše podnikání, jako je automatické spuštění a zastavení, detekce uživatelů Songoroo, automatické aktualizace systému a údržba. Chcete-li co nejlépe využít to, co nabízíme, použijte streamovací zařízení Songoroo.",
    "Enter discount code": "Zadejte slevový kód",
    "Discount code does not exist": "Slevový kód neexistuje",
    // "First 14 days are for free": "Prvních 14 dní je zdarma",
    "days are for free": "dny jsou zdarma",
    "then you will be charged": "poté vám bude účtován poplatek",
    "Select Credit/Debit Card": "Vyberte si kreditní/debetní kartu",
    "Select Bank Account": "Vyberte bankovní účet",
    "Venue name": "Název provozovny",
    "Venue name is required.": "Název provozovny je povinný",
    "Select playlist": "Výběr playlistu",
    "Cancel subscription": "Zrušit balíček služeb",
    "Update of Subscription Plan": "Aktualizace balíčku služeb",
    "Renew your subscription plan": "Obnovení balíčku služeb",
    "Cancel your subscription plan": "Zrušit balíček služeb",
    "We are sorry you decided to cancel Songoroo subscription for this venue. Songoroo services will be active for this venue until": "Je nám líto, že jste se rozhodli zrušit balíček služeb Songoroo pro tuto operaci.",
    "You can renew the subscription anytime even after subscription plan expiration date.": "Balíček můžete kdykoli obnovit, a to i po uplynutí doby platnosti balíčku služeb.",
    "Are you sure you want to change your subscription plan?": "Opravdu chcete změnit svůj plán předplatného?",
    "Are you sure you want to renew your subscription plan?": "Opravdu chcete obnovit svůj plán předplatného?",
    "Delete Venue": "Smazat provozovnu",
    "Are you sure you want to delete venue?": "Opravdu chcete smazat provozovnu?",
    "Are you sure you want to delete user": "Jste si jisti, že chcete odstranit uživatele ",
    "It is not possible to cancel this account.": "Uživatele není možné smazat.",
    "Back to subscription plan": "Zpět na balíček služeb",
    "Current billing period": "Aktuální fakturační období",
    "Your subscription product was changed from Basic to Premium.": "Vaše předplatné bylo změněno z Basic na Premium.",
    "Your subscription product was changed from Premium to Basic.": "Vaše předplatné bylo změněno z Premium na Basic.",
    "Audio messages have been activated.": "Audio zprávy byly aktivovány.",
    "have been activated.": "byly aktivovány.",
    "have been deactivated.": "byly deaktivovány.",
    "Audio messages have been deactivated.": "Audio zprávy byly deaktivovány",
    "Your payment period was changed from month to year.": "Vaše platební období se změnilo z měsíce na rok.",
    "Your payment period was changed from year to month.": "Platební období se změnilo z roku na měsíc.",
    "Your music streaming method was changed from Streaming device to Web Based Streaming.": "Způsob streamování hudby se změnil ze streamovacího zařízení na  Webstream.",
    "Your music streaming method was changed from Web Based Streaming to Streaming device.": "Způsob streamování hudby se změnil z webového streamu na streamovací zařízení.",
    "Trial period": "Zkušební doba",
    "Final subscription fee for this venue": "Celková částka předplatného pro tento provoz",
    "Payment method is required.": "Způsob platby je povinný",
    "Redeem": "Použít",
    "Not available": "Nedostupný",
    "/ month": "/ měsíc",
    "/ year": "/ rok",
    "CHOOSE BASIC PLAN": "VYBERTE ZÁKLADNÍ BALÍČEK",
    "CHOOSE PREMIUM PLAN": "VYBERTE PRÉMIOVÝ BALÍČEK",
}
