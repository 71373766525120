import ApiService from "@/services/api.service"
import TokenService from "@/services/token.service"
import {errorMessage} from "@/utils/messages-handlers";

class AuthenticationError extends Error {
  public errorCode: number;

  constructor(errorCode, message) {
    super(message);
    this.name = this.constructor.name;
    this.message = message;
    this.errorCode = errorCode;
  }
}

export interface UserServiceInterface {
  login(email: string, password: string): Promise<any | AuthenticationError>;

  refreshToken(): Promise<void>;

  logout(): Promise<void>;

  softLogout(): void;
}

const UserService: UserServiceInterface = {
  login: async (email: string, password: string) => {
    const resource = '/api/v1/clientzone/auth';
    const data: object = {
      username: email,
      password: password
    };

    try {
      const response = await ApiService.post(resource, data);

      // console.log(response.data.access_token)


      TokenService.saveToken(response.data.access_token);
      // TokenService.saveUserId(response.data.user.id);
      // TokenService.saveRefreshToken(response.data.tokens.refresh_token);
      ApiService.setHeader();
      TokenService.saveWebStreamToken(Date.now() + email);

      return response.data;
    } catch (error) {
      if (error.response.data.error.code === "AuthorizationError") errorMessage("Credentials are invalid or user is not active.", 5000);
      // throw new AuthenticationError(error.response.status, error.response.data.detail);
      throw error;
    }
  },
  refreshToken: async () => {
    const refreshToken: string | null = TokenService.getRefreshToken();

    const requestData: object = {
      method: 'get',
      url: '/user',
      data: {
        refresh_token: refreshToken
      }
    };

    try {
      const response = await ApiService.customRequest(requestData);

      TokenService.saveToken(response.data.access_token);
      // TokenService.saveRefreshToken(response.data.refresh);
      ApiService.setHeader();

      return response.data.access_token;
    } catch (e) {
      throw new AuthenticationError(e.response.status, e.response.data.detail);
    }
  },
  // @ts-ignore
  logout: async (email: string, password: string) => {
    const resource = '/logout';
    const data: object = {
      email: email,
      password: password
    };

    try {
      const response = await ApiService.post(resource, data);

      TokenService.removeToken();
      // TokenService.removeRefreshToken();
      TokenService.removeUserId();
      ApiService.removeHeader();
    } catch (e) {
      throw new Error('Failed to logout user')
    }
  },
  softLogout(): void {
    TokenService.removeToken();
    // TokenService.removeRefreshToken();
    TokenService.removeUserId();
    ApiService.removeHeader();
  }
};

export default UserService

export {UserService, AuthenticationError}
