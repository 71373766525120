import {
  CompanyRegisterData,
  RegisterFilledData,
  UserRegisterData,
  VenueGoogleData,
  VenueRegisterData,
  VenuesSlovenskoDigitalData,
  VenuesFoundData
} from "@/types";
import { RegisterState } from "@/types/store/clientzone/register";
import { GetterTree } from "vuex";
import i18n from "@/i18n/i18n";
import { RootState } from "@/types/store/clientzone";

export const getters: GetterTree<RegisterState, RootState> = {
  GET_REGISTER_STATE: (state): RegisterState => state,
  GET_FOUNDED_VENUE_DATA: (state): Array<VenuesFoundData> => state.venuesFound,
  GET_VENUE_ID: (state): number | string => state.venueId ? state.venueId : localStorage.getItem('venue_id'),
  // GET_VENUE_REGISTER_DATA: (state) => state.selectedVenueRegisterData,
  GET_VENUE_PLACE_ID: (state): number | string => {
    let venueIndex = state.venueIndex;
    if (venueIndex == null) {
      venueIndex = localStorage.getItem('place_id')
    } else if (venueIndex == -1) {
      venueIndex = -1
    } else {
      venueIndex = state.venuesFound[venueIndex].place_id;
    }
    return venueIndex;
  },
  GET_VENUE_DATA: (state): VenueRegisterData => state.venue,
  GET_VENUE_GOOGLE_DATA: (state): VenueGoogleData => state.venueGoogleData,
  GET_VENUES_SLOVENSKO_DIGITAL_DATA: (state): Array<VenuesSlovenskoDigitalData> => state.venuesSlovenskoDigital,
  GET_VENUE_COUNTRY_CODE: (state): string => state.venueGoogleData.country_code,
  GET_COMPANY_DATA: (state): CompanyRegisterData => state.company,
  GET_USER_DATA: (state): UserRegisterData => state.user,
  GET_USER_EMAIL: (state): string => state.user.email,
  EXIST_COMPANY_WITH_VAT: (state): boolean => state.existCompanyWithVat,
  EXIST_COMPANY_WITH_NAME: (state): boolean => state.existCompanyWithName,
  EXIST_COMPANY_WITH_BIN: (state): boolean => state.existCompanyWithBin,
  EXIST_USER_WITH_EMAIL: (state): boolean => state.existUserWithEmail,
  GET_COMPANY_ID: (state): number => state.companyId,
  GET_VENUES_SEARCHING: (state): boolean => state.isSearching,
  GET_REFERRER_LINK: (state): string => state.referrerLink,
  GET_VENUE_REGISTER_SUBSCRIPTION: (state) => state.venueRegisterSubscription,
  GET_INIT_REGISTER: (state): boolean => state.initRegister,
  GET_NEW_REGISTERED_USER_ID: (state): number => state.newRegisteredUserId,
  GET_NEW_REGISTERED_VENUE_ID: (state): number => state.newRegisteredVenueId,
  GET_COMPANY_BUSINESS_TYPE: (state): number => state.company.business_type_id,
  GET_REGISTER_FAIL: (state): boolean => state.registerFail,
  GET_SUBMIT_REGISTRATION_LOADER: (state): boolean => state.createRegistrationLoader,
  GET_REGISTER_FILLED_DATA: (state, getters): RegisterFilledData => {
    const user: UserRegisterData = getters["GET_USER_DATA"];
    const venue: VenueRegisterData = getters["GET_VENUE_DATA"];
    const company: CompanyRegisterData = getters["GET_COMPANY_DATA"];
    const companyBusinessTypeId: number = getters["GET_COMPANY_BUSINESS_TYPE"];

    return {
      email: user.email,
      password: user.password,
      repeatedPassword: user.password,
      name: user.first_name,
      surname: user.last_name,
      nameOfEstablishment: venue.name,
      streetAndNumber: venue.address1,
      postalCode: venue.address2,
      city: venue.city,
      countryId: venue.country_id,
      gpsLatitude: venue.latitude.toString(),
      gpsLongtitude: venue.longitude.toString(),
      timeZoneId: venue.timezone_id,
      venueTypeId: venue.venue_type_id,
      businessTypeId: companyBusinessTypeId,
      locale: i18n.locale,

      companyBillingBusinessName: company.name,
      companyBillingLegalEntityName: company.legal_entity_name,
      companyBillingAddress: company.billing_street,
      companyBillingZipCode: company.billing_zip,
      companyBillingCountryId: company.billing_country_id,
      companyBillingCity: company.billing_city,
      companyBillingBusinessIdentificationNumber: company.billing_ico,
      companyBillingVatId: company.billing_vat,
      companyBillingContactPerson: company.contact,
      companyBillingContactPersonPhone: company.phone,
      companyBillingContactPersonEmail: company.billing_email
    }
  },
  GET_REGISTER_FILLED_DATA_ID: (state): string => state.registerFilledDataId,
  TAX_IS_NOT_VALID: (state, getters): boolean => {
    const companyCountryId: number = getters["GET_COMPANY_DATA"].billing_country_id;
    const venueCountryId: number = getters["GET_VENUE_DATA"].country_id;
    const companyVat: string = getters["GET_COMPANY_DATA"].billing_vat;

    if (companyVat && companyCountryId != 1 && venueCountryId != 1) {
      return false;
    } else if (companyCountryId == 1 && venueCountryId == 1) {
      return true;
    } else {
      return true;
    }
  },
  GET_LOGINS_COUNT: (state): number => state.loginsCount,
  GET_SAVED_VENUE_SUBSCRIPTION_DATA: (state): object => state.savedVenueSubscriptionData
}
