export default {
  "Help & Support": "Help & Support",
  "Tech support": "Tech support",
  "Sales support": "Sales support",
  "Submit a request": "Submit a request",
  "How can we help?": "How can we help?",
  "Popular topics": "Popular topics",
  "Connect with Sonos": "Connect with Sonos",
  "How to schedule music": "How to schedule music",
  "Play music legally": "Play music legally",
  "Can't find what you're looking for?": "Can't find what you're looking for?",
  "Send us your question": "Send us your question",
  "Getting started": "Getting Started",
  "Hardware & IT matters": "Hardware & IT matters",
  "Client Account Management": "Client Account Management",
  "Music Playlists": "Music Playlists",
  "Music Licensing": "Music Licensing",
  "Songoroo Apps & App Features": "Songoroo Apps & App Features",
  "Subscriptions & Payments": "Subscriptions & Payments",
  "Client Dashboard and Reports": "Client Dashboard and Reports",
  "Troubleshooting": "Troubleshooting",
  "Please choose from the topics below": "Please choose from the topics below",
  "Subject": "Subject",
  'Active Questionary': "Active",
  "Inactive Questionary": "Inactive",
  "Description word": "Description",
  "Please enter the details of your request. A member of our support staff will respond as soon as possible.": "Please enter the details of your request. A member of our support staff will respond as soon as possible.",
  "Attachments": "Attachments",
  "Add file": "Add file",
  "or drop files here": "or drop files here",
  "Please choose from the topics.": "Please choose from the topics.",
  "Phone number is required.": "Phone number is required.",
  "Only numbers are allowed.": "Only numbers are allowed.",
  "Business name is required.": "Business name is required.",
  "Subject is required.": "Subject is required.",
  "Description is required.": "Description is required.",
  "General": "Other",
  "Hardware & IT": "Hardware & IT matters",
  "Client account": "Client Account Management",
  "Music playlist": "Music Playlists",
  "Music licensing": "Music Licensing",
  "Mobile apps": "Songoroo Apps & App Features",
  "Billing": "Subscriptions & Payments",
  "Reports": "Client Dashboard and Reports",
  "Survey name": "Survey title",
  "Survey Name": "Survey Title",
  "Show only active surveys": "Show only active surveys",
  "Edit Audio Advertisement Campaign": "Edit Audio Advertisement Campaign"
}
