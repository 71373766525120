export default {
    "Songoroo Solutions": "Songoroo komponenty",
    "Portfolio": "Portfolio klientů",
    "How to": "Podpora",
    "Company": "O společnosti",
    "Connect": "Sociální síte",
    "Interactive in-store radio": "Interaktivní in-store rádio",
    "Songoroo Algorithm": "Songoroo algoritmus",
    "Mobile Apps": "Mobilní aplikace",
    "Reporting & App-User-Statistics": "Reporting a Business Intelligence",
    "Pricing": "Cenník",
    "Food & Dining": "Restaurace",
    "Café & Pubs": "Kavárny a bary",
    "Retail": "Maloobchod",
    "Hotels & Offices": "Hotely a kanceláře",
    "Club & Lounge": "Kluby a salóny",
    "Workout & Fitness": "Sportovní zařízení a fitness",
    "Spa, Wellness & Relax": "Lázně, wellness a relax",
    "Public places": "Veřejná prostranství",
    "Events & Special occasions": "Události a zvláštní příležitosti",
    "... many more": "... mnohem více",
    "App Features": "Funkce aplikace",
    "Music Streamer Setup": "Nastavení streamovacího zařízení",
    "Support": "Centrum pomoci Songoroo",
    "About Us": "O nás",
    "Testimonials": "Reference",
    "Partnership": "Partnerství",
    "Contact": "Kontaktujte nás",
    "Careers": "Kariéra",
}
