import i18n from "@/i18n/i18n"

const checkLanguageFromRedirect = {
  methods: {
    checkLanguageFromRedirect() {
      const results = new RegExp('[\?&]' + 'lang' + '=([^&#]*)').exec(document.referrer);

      if (!results) {
        return;
      }

      if(results[1] == 'sk') {
        i18n.locale = 'sk';
        localStorage.setItem('language', 'sk');
      }
    },
  }
};

export default checkLanguageFromRedirect;
