import {
  CompanyRegisterData,
  RegisterFilledData,
  StripeCardData,
  UserRegisterData,
  VenueRegisterData
} from "@/types";
import ApiService from "@/services/api.service";
import { CASSOVIA_API_RESOURCE, ROOT_ACTION, SGR_CLIENTZONE_API_KEY } from "@/utils/helpers";
import router from "@/router";
import { ActionTree } from "vuex";
import { RegisterState } from "@/types/store/clientzone/register";
import UserService from "@/services/user.service";
import { errorMessage } from "@/utils/messages-handlers";
import { RootState } from "@/types/store/clientzone";
import i18n from "@/i18n/i18n";
import AxiosService from "@/services/axios.service";

export const actions: ActionTree<RegisterState, RootState> = {
  SET_VENUE: async ({ commit }, { payload, countryCode }): Promise<void> => {
    commit("SET_VENUE", payload);
    router.push("/register/step-3");
  },
  SET_USER: ({ commit }, payload: UserRegisterData): void => {
    commit("SET_USER", payload);
  },
  SET_COMPANY: ({ commit }, payload: CompanyRegisterData): void => {
    commit("SET_COMPANY", payload);
  },
  SET_VENUE_SUB_PLAN: ({ commit }, payload): void => {
    commit("SET_VENUE_SUB_PLAN", payload);
  },
  SET_COMPANY_BUSINESS_TYPE: ({ commit }, payload: number): void => commit("SET_COMPANY_BUSINESS_TYPE", payload),
  SET_GOOGLE_VENUE_DATA: ({ commit }, payload): Promise<object> => {
    commit("SET_VENUES_SEARCHING", true);

    return new Promise(async (resolve, reject) => {
      const resource: string = `${CASSOVIA_API_RESOURCE}/api/external/search-place`;

      try {
        const response = await AxiosService.getAxios().post(resource, payload);

        commit("SET_VENUES_SEARCHING", false);
        commit('SET_VENUE_FOUND_DATA', response.data.data);

        resolve({});
      } catch (e) {
        reject()
      }
    })
  },
  FETCH_VENUE_DATA: ({ commit, getters }): Promise<void> => {
    const venueId = getters['GET_VENUE_PLACE_ID'];

    if (venueId == -1 || !venueId) return

    localStorage.setItem('place_id', venueId)

    return new Promise(async (resolve, reject) => {
      const resource: string = `${CASSOVIA_API_RESOURCE}/api/register/places/detail/${venueId}`;

      try {
        const response = await AxiosService.getAxios().get(resource);

        commit('SET_VENUE_GOOGLE_DATA', response.data.data);

        resolve();
      } catch (e) {
        reject()
      }
    })
  },
  GET_SLOVENSKO_DIGITAL_DATA: ({ commit }, payload): Promise<void> => {
    commit("SET_VENUES_SEARCHING", true);

    return new Promise(async (resolve, reject) => {
      let resource: string = `${CASSOVIA_API_RESOURCE}/api/external/search-companies`;

      if (payload.name && payload.ico) {
        resource += `?name=${payload.name}&ico=${payload.ico}`;
      } else if (payload.name && !payload.ico) {
        resource += `?name=${payload.name}`;
      } else if (payload.ico && !payload.name) {
        resource += `?ico=${payload.ico}`
      }

      try {
        const response = await AxiosService.getAxios().get(resource);

        commit("SET_VENUES_SEARCHING", false);
        commit('SET_SLOVENSKO_DIGITAL_DATA', response.data.data);

        resolve();
      } catch (e) {
        reject()
      }
    })
  },
  RESET_VENUE_DATA({ commit }): void {
    localStorage.removeItem('place_id_index');
    localStorage.removeItem('place_id');
    localStorage.removeItem('venue_name');

    commit('RESET_VENUE_DATA');
  },
  SET_INIT_REGISTER({ commit }): void {
    commit("SET_INIT_REGISTER", true);
  },
  SET_REFERRER_LINK({ commit }, link): void {
    commit("SET_REFERRER_LINK", link);
  },
  SET_VENUE_PLACE_ID({ commit }, index): void {
    commit("SET_INIT_REGISTER", true);
    commit('SET_VENUE_PLACE_ID', index);
    localStorage.setItem('place_id_index', index);
    router.push("/register/step-2");
  },
  SET_MANUALLY_VENUE_DATA({ commit }, venueName: string): void {
    commit("SET_INIT_REGISTER", true);
    localStorage.setItem('venue_name', venueName);
    commit('SET_VENUE_REGISTER_DATA_MANUALLY', venueName);
    router.push("/register/step-2");
    commit('SET_VENUE_GOOGLE_DATA', '');
  },
  FILTER_COMPANY_VAT: ({ commit }, value: string): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      const resource: string = `/api/v1/clientzone/check/billing_vat?billing_vat=${value}`;

      try {
        const response = await ApiService.get(resource, {
          headers: {
            Authorization: "Bearer apikey",
            "SGR-CLIENTZONE-API-KEY": SGR_CLIENTZONE_API_KEY
          }
        });

        commit("FILTER_COMPANY_VAT", response.data.found)

        resolve();
      } catch (e) {
        reject()
      }
    })
  },
  FILTER_COMPANY_NAME: ({ commit }, value: string): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      const resource: string = `/api/v1/clientzone/check/venue_owner_name?venue_owner_name=${value}`;

      try {
        const response = await ApiService.get(resource, {
          headers: {
            Authorization: "Bearer apikey",
            "SGR-CLIENTZONE-API-KEY": SGR_CLIENTZONE_API_KEY
          }
        });

        commit("FILTER_COMPANY_NAME", response.data.found)

        resolve();
      } catch (e) {
        reject()
      }
    })
  },
  FILTER_COMPANY_BIN: ({ commit }, value: string): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      const resource: string = `/api/v1/clientzone/check/billing_ico?billing_ico=${value}`;

      try {
        const response = await ApiService.get(resource, {
          headers: {
            Authorization: "Bearer apikey",
            "SGR-CLIENTZONE-API-KEY": SGR_CLIENTZONE_API_KEY
          }
        });

        commit("FILTER_COMPANY_BIN", response.data.found)

        resolve();
      } catch (e) {
        reject()
      }
    })
  },
  RESET_COMPANY_FILTERS: ({ commit }): void => {
    commit("FILTER_COMPANY_VAT", false)
    commit("FILTER_COMPANY_NAME", false)
    commit("FILTER_COMPANY_BIN", false)

  },
  FILTER_USER_EMAIL: ({ commit }, email: string): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      const resource: string = `/api/v1/clientzone/check/email?email=${email}`;

      try {
        const response = await ApiService.get(resource, {
          headers: {
            Authorization: "Bearer apikey",
            "SGR-CLIENTZONE-API-KEY": SGR_CLIENTZONE_API_KEY
          }
        });

        commit("FILTER_USER_EMAIL", response.data.found)

        resolve();
      } catch (e) {
        reject()
      }
    })
  },
  CREATE_VENUE: ({ commit, getters, dispatch, rootGetters }): Promise<void> => {
    const venueData: VenueRegisterData = getters["GET_VENUE_DATA"];
    const companyId: number = getters["GET_COMPANY_ID"];
    const venueId: number = getters["GET_NEW_REGISTERED_VENUE_ID"];
    const venueRegisterSubscription = rootGetters["registerModule/GET_VENUE_REGISTER_SUBSCRIPTION"];
    const paymentMethod: number = rootGetters["paymentModule/GET_REGISTER_PAYMENT_METHOD"]

    return new Promise(async (resolve, reject) => {
      const resource: string = `/api/v1/clientzone/venue`;

      try {
        if (!venueId) {
          const response = await ApiService.post(resource, { ...venueData, venue_owner_id: companyId });
          commit("SET_NEW_REGISTERED_VENUE_ID", response.data.id);
          await dispatch("venueModule/INIT_VENUE_OPENING_HOURS", response.data.id, ROOT_ACTION);
          await dispatch("CREATE_VENUE_SUBSCRIPTION", {
            paymentMethodId: paymentMethod, venueOwnerId: companyId, venueId: response.data.id, venueName: venueData.name, countryId: venueData.country_id, ...venueRegisterSubscription
          });
        } else {
          await dispatch("CREATE_VENUE_SUBSCRIPTION", {
            paymentMethodId: paymentMethod, venueOwnerId: companyId, venueId: venueId, venueName: venueData.name, countryId: venueData.country_id, ...venueRegisterSubscription
          });
        }

        resolve();
      } catch (e) {
        reject()
      }
    })
  },
  CREATE_COMPANY: ({ commit, dispatch, getters, rootGetters }): Promise<void> => {
    const companyData: CompanyRegisterData = getters["GET_COMPANY_DATA"];
    const stripeCard: StripeCardData = rootGetters["paymentModule/GET_STRIPE_CARD"];
    const companyId = getters["GET_COMPANY_ID"];

    return new Promise(async (resolve, reject) => {
      const resource: string = "api/v1/clientzone/venue_owner";

      try {
        if (companyId === -1) {
          const response = await ApiService.post(resource, companyData, {
            headers: {
              "SGR-CLIENTZONE-API-KEY": SGR_CLIENTZONE_API_KEY
            }
          });

          commit("SET_COMPANY_ID", response.data.id);

          await dispatch("paymentModule/CREATE_STRIPE_USER",
            { payload: { venueOwnerId: response.data.id, ...stripeCard }, register: true }, ROOT_ACTION);
        }

        resolve();
      } catch (e) {
        reject()
      }
    })
  },
  CREATE_NEW_USER: ({ commit, getters, dispatch }): Promise<void> => {
    const userData: UserRegisterData = getters["GET_USER_DATA"];
    return new Promise(async (resolve, reject) => {
      const resource: string = `${CASSOVIA_API_RESOURCE}/api/create-clientzone-user`;
      await dispatch("FILTER_USER_EMAIL", userData.email);
      const userIsLoggedIn: boolean = getters["EXIST_USER_WITH_EMAIL"];
      if (userIsLoggedIn) {
        resolve();
        return;
      }

      try {
        const payload = {
          "createClientzoneUserPayload": {
            email: userData.email,
            password: userData.password,
            first_name: userData.first_name,
            last_name: userData.last_name,
            gdpr: userData.gdpr,
            active: false,
            status: 1,
          }
        }
        const response = await AxiosService.getAxios().post(resource, payload)

        commit("SET_NEW_REGISTERED_USER_ID", response.data.id);

        dispatch("emailsModule/SEND_REGISTRATION_EMAIL", { email: userData.email, clientZoneUserId: response.data.id, userIsLoggedIn: userIsLoggedIn }, ROOT_ACTION);

        resolve();
      } catch (e) {
        if (e.response.data.error.code === "ClientZoneUserAlreadyExistsError") {
          errorMessage("Client zone user already exists. Please, provide a new registration.", 8000);
        }
        reject()
      }
    })
  },
  CREATE_USER: ({ commit, getters, dispatch }): Promise<void> => {
    const userData: UserRegisterData = getters["GET_USER_DATA"];
    return new Promise(async (resolve, reject) => {
      const resource: string = "/api/v1/clientzone/user";
      await dispatch("FILTER_USER_EMAIL", userData.email);
      const userIsLoggedIn: boolean = getters["EXIST_USER_WITH_EMAIL"];
      if (userIsLoggedIn) {
        resolve();
        return;
      }

      try {
        const response = await ApiService.post(resource, userData, {
          headers: {
            Authorization: "Bearer apikey",
            "SGR-CLIENTZONE-API-KEY": SGR_CLIENTZONE_API_KEY
          }
        })

        commit("SET_NEW_REGISTERED_USER_ID", response.data.id);

        await dispatch("userModule/LOGIN", { email: userData.email, password: userData.password, firstRegistration: true }, ROOT_ACTION)
        resolve();
      } catch (e) {
        if (e.response.data.error.code === "ClientZoneUserAlreadyExistsError") {
          errorMessage("Client zone user already exists. Please, provide a new registration.", 8000);
        }
        reject()
      }
    })
  },
  SET_REGISTER_DATA_AFTER_REDIRECTION: ({ commit, dispatch }) => {
    const retrievedRegisterDataFromLocalStorage: any = JSON.parse(localStorage.getItem('registrationData'));
    commit("SET_COMPANY", retrievedRegisterDataFromLocalStorage.companyData)
    commit("SET_VENUE", retrievedRegisterDataFromLocalStorage.venueData)
    commit("SET_REGISTER_FILLED_DATA_ID", retrievedRegisterDataFromLocalStorage.registrationResourceId)
    commit("SET_SAVED_VENUE_SUBSCRIPTION_DATA", retrievedRegisterDataFromLocalStorage.venueSubscriptionData)
    commit("SET_USER", retrievedRegisterDataFromLocalStorage.userData)
    commit("FILTER_USER_EMAIL", retrievedRegisterDataFromLocalStorage.userIsLoggedIn)
    commit("SET_SAVED_VENUE_SUBSCRIPTION_DATA", retrievedRegisterDataFromLocalStorage.venueSubscriptionData)
    commit("SET_NEW_REGISTERED_USER_ID", retrievedRegisterDataFromLocalStorage.newUserId);
    dispatch("userModule/SET_LOGGED_USER", retrievedRegisterDataFromLocalStorage.loggedUser, ROOT_ACTION)
    dispatch("paymentModule/SET_SETUP_INTENT", retrievedRegisterDataFromLocalStorage.setupIntentObject, ROOT_ACTION)
    dispatch("venueSubscriptionModule/SET_RECURRING_INTERVAL", retrievedRegisterDataFromLocalStorage.selectedRecurringInterval, ROOT_ACTION)
  },
  PROCESS_CLIENTZONE_REGISTRATION: ({ commit, getters, dispatch, rootGetters }, payload): Promise<void> => {
    const userIsLoggedIn: boolean = getters["EXIST_USER_WITH_EMAIL"];
    const userData: UserRegisterData = getters["GET_USER_DATA"];
    const companyData: CompanyRegisterData = getters["GET_COMPANY_DATA"];
    const registrationResourceId: number = getters["GET_REGISTER_FILLED_DATA_ID"];
    let venueData: VenueRegisterData = getters["GET_VENUE_DATA"];
    let newUserId = getters["GET_NEW_REGISTERED_USER_ID"];

    if (!newUserId) {
      newUserId = parseInt(localStorage.getItem("clientZoneUserId"));
    }

    let latitude = venueData.latitude;
    let longitude = venueData.longitude

    if (typeof latitude === 'string') {
      latitude = parseFloat(latitude);
    }

    if (typeof longitude === 'string') {
      longitude = parseFloat(longitude);
    }

    venueData = { ...venueData, latitude, longitude };

    const registrationPayload = {
      createClientzoneUserPayload: userData,
      createVenueOwnerPayload: companyData,
      createVenuePayload: venueData,
      unfinishedRegistrationId: registrationResourceId,
      locale: i18n.locale,
      isExistingUser: true,
      clientzoneUserId: newUserId,
    };
    //if (userIsLoggedIn) {
    if (rootGetters["userModule/GET_USER_ID"]) {
      registrationPayload.clientzoneUserId = rootGetters["userModule/GET_USER_ID"]
    }

    return new Promise(async (resolve, reject) => {
      const resource: string = `${CASSOVIA_API_RESOURCE}/api/finish-clientzone-user-registration`;

      try {
        const response = await AxiosService.getAxios().post(resource, registrationPayload)
        commit("SET_REGISTER_FAIL", true);
        //commit("SET_LOADER", false);
        //dispatch("emailsModule/SEND_REGISTRATION_EMAIL", { email: response.data.clientZoneUserData.email, companyName: response.data.companyData.name, clientZoneUserId: response.data.clientZoneUserData.id, userIsLoggedIn: userIsLoggedIn }, ROOT_ACTION);

        if (!userIsLoggedIn) {
          const { id } = response?.data?.venueData;

          if (id) {
            commit("SET_VENUE_ID", id);
          }

          router.push("/register/step-6");
        } else {
          router.push('/select-company')
        }
        dispatch("layoutModule/SET_GLOBAL_OVERLAY_SPINNER_STATE", false, ROOT_ACTION)
        resolve();
      } catch (e) {
        commit("SET_REGISTER_FAIL", true);
        //commit("SET_LOADER", false);
        reject();
      }
    })
  },
  // PROCESS_CLIENTZONE_REGISTRATION: ({ commit, getters, dispatch, rootGetters }, payload): Promise<void> => {
  //   const userIsLoggedIn: boolean = getters["EXIST_USER_WITH_EMAIL"];
  //   const registerFail: boolean = getters["GET_REGISTER_FAIL"];
  //   const userData: UserRegisterData = getters["GET_USER_DATA"];
  //   const companyData: CompanyRegisterData = getters["GET_COMPANY_DATA"];
  //   const venueData: VenueRegisterData = getters["GET_VENUE_DATA"];
  //   const registrationResourceId: number = getters["GET_REGISTER_FILLED_DATA_ID"];
  //   const setupIntentObject: any = rootGetters["paymentModule/GET_SETUP_INTENT_OBJECT"]
  //   const registrationPayload = {
  //     createClientzoneUserPayload: userData,
  //     createVenueOwnerPayload: companyData,
  //     createVenuePayload: venueData,
  //     createStripeSubscriptionPayload: payload,
  //     unfinishedRegistrationId: registrationResourceId,
  //     locale: i18n.locale,
  //     isExistingUser: userIsLoggedIn,
  //     paymentMethodId: setupIntentObject.payment_method,
  //     clientzoneUserId: -1
  //   };
  //   if (userIsLoggedIn) {
  //     registrationPayload.clientzoneUserId = rootGetters["userModule/GET_USER_ID"]
  //   }

  //   return new Promise(async (resolve, reject) => {
  //     const resource: string = `${CASSOVIA_API_RESOURCE}/api/registrate-clientzone-user`;

  //     try {
  //       const response = await AxiosService.getAxios().post(resource, registrationPayload)
  //       commit("SET_REGISTER_FAIL", true);
  //       commit("SET_LOADER", false);
  //       dispatch("emailsModule/SEND_REGISTRATION_EMAIL", { email: response.data.clientZoneUserData.email, companyName: response.data.companyData.name, clientZoneUserId: response.data.clientZoneUserData.id, userIsLoggedIn: userIsLoggedIn }, ROOT_ACTION);

  //       const editSubscriptionAndInvoicePayload = {
  //         stripeProductWithPriceId: payload.stripeProductWithPriceId,
  //         venueOwnerId: response.data.companyData.id,
  //         paymentType: 1,
  //         paymentMethodId: response.data.paymentMethodId,
  //         venueId: response.data.venueData.id,
  //         subscriptionId: response.data.stripeSubscriptionId,
  //         additionalServices: payload.additionalServices,
  //         includedStreamingDevice: payload.includedStreamingDevice,
  //         appliedDiscountCode: payload.appliedDiscountCode,
  //         initialFreeTrialDays: payload.initialFreeTrialDays
  //       }
  //       setTimeout(() => {
  //         dispatch("EDIT_SUBSCRIPTION_AND_INVOICE", editSubscriptionAndInvoicePayload);
  //       }, 5000)
  //       if (!userIsLoggedIn) {
  //         router.push("/register/step-6");
  //       } else {
  //         router.push('/select-company')
  //       }
  //       dispatch("layoutModule/SET_GLOBAL_OVERLAY_SPINNER_STATE", false, ROOT_ACTION)
  //       resolve();
  //     } catch (e) {
  //       commit("SET_REGISTER_FAIL", true);
  //       commit("SET_LOADER", false);
  //       reject();
  //     }
  //   })
  // },
  EDIT_SUBSCRIPTION_AND_INVOICE: ({ }, payload): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      const resource: string = `${CASSOVIA_API_RESOURCE}/api/stripe/edit-subscription-after-card-validation`;

      try {
        await AxiosService.getAxios().post(resource, payload)
        resolve();
      } catch (e) {
        reject();
      }
    })
  },
  REGISTER_ENTITIES: async ({ getters, dispatch }): Promise<void> => {
    const userIsLoggedIn: boolean = getters["EXIST_USER_WITH_EMAIL"];
    const registerFail: boolean = getters["GET_REGISTER_FAIL"];

    if (!userIsLoggedIn) {
      await dispatch("CREATE_USER")
    }
    await dispatch("CREATE_COMPANY");
    await dispatch("CREATE_VENUE");

    if (!userIsLoggedIn || registerFail) {
      dispatch("emailsModule/SEND_REGISTRATION_EMAIL", {}, ROOT_ACTION);
      await dispatch("SET_USER_TO_INACTIVE");
    }

    const resourceId: number = getters["GET_REGISTER_FILLED_DATA_ID"];
    if (!!resourceId) dispatch("DELETE_REGISTER_FILLED_DATA", resourceId);
    if (!userIsLoggedIn) {
      router.push("/register/step-6");
    } else {
      router.push('/select-company')
    }
  },
  SET_VENUE_REGISTER_SUBSCRIPTION: ({ commit, dispatch }, payload): void => {
    commit("SET_VENUE_REGISTER_SUBSCRIPTION", payload);
    dispatch("REGISTER_ENTITIES");
  },
  SET_USER_TO_INACTIVE: ({ getters }): Promise<void> => {
    const userId: number = getters["GET_NEW_REGISTERED_USER_ID"];

    return new Promise(async (resolve, reject) => {
      const resource: string = `/api/v1/clientzone/user/${userId}`;

      try {
        await ApiService.put(resource, { active: false });

        UserService.softLogout();

        resolve();
      } catch (e) {
        reject()
      }
    })
  },
  SAVE_REGISTER_FILLED_DATA: ({ dispatch, getters }): Promise<void> => {
    const payloadData: RegisterFilledData = getters["GET_REGISTER_FILLED_DATA"];

    return new Promise(async (resolve, reject) => {
      const resource: string = `${CASSOVIA_API_RESOURCE}/api/client-zone-registration-process/client-zone-registration-processes`;

      try {
        const response = await ApiService.post(resource, payloadData);
        await dispatch("FETCH_REGISTER_FILLED_DATA", response.data.id);
        resolve();
      } catch (e) {
        reject()
      }
    })
  },
  FETCH_REGISTER_FILLED_DATA: ({ commit }, id: string): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      const resource: string = `${CASSOVIA_API_RESOURCE}/api/client-zone-registration-process/client-zone-registration-processes/${id}`;

      try {
        const response = await ApiService.get(resource);

        commit("SET_REGISTER_FILLED_DATA", response.data);

        resolve();
      } catch (e) {
        reject()
      }
    })
  },
  DELETE_REGISTER_FILLED_DATA: ({ }, id: string): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      const resource: string = `${CASSOVIA_API_RESOURCE}/api/client-zone-registration-process/client-zone-registration-processes/${id}`;

      try {
        await ApiService.delete(resource);

        resolve();
      } catch (e) {
        reject()
      }
    })
  },
  CREATE_VENUE_SUBSCRIPTION: ({ commit, dispatch, getters }, payload): Promise<void> => {
    const email: string = getters["GET_COMPANY_DATA"].email;
    return new Promise(async (resolve, reject) => {
      const resource: string = `${CASSOVIA_API_RESOURCE}/api/stripe/stripe-subscription-instances`;

      try {
        await AxiosService.getAxios().post(resource, payload);

        await dispatch("CREATE_PAYMENT_METHOD_ASSIGNMENTS", { paymentMethodId: payload.paymentMethodId, venueId: payload.venueId });
        if (payload.includedStreamingDevice == 1) {
          dispatch("emailsModule/SEND_STREAMER_DISPATCHED_EMAIL", { fromRegistration: true, email: email, venueName: payload.venueName, countryId: payload.countryId }, ROOT_ACTION);
        }
        resolve();
      } catch (e) {
        if (e.response.status == 402) {
          dispatch("venueModule/SET_ERROR_ON_INVALID_CREDIT_CARD", true, ROOT_ACTION);
          dispatch("venueModule/SET_CREATE_VENUE_LOADER", false, ROOT_ACTION);
          errorMessage(e.response.data.detail, 5000);
        }
        if (e.response.status == 405) {
          errorMessage(e.response.data.detail, 5000);
          commit("SET_SUBMIT_REGISTRATION_LOADER", false);
          commit("SET_REGISTER_FAIL", true);
        }
        if (e.response.status == 500) {
          errorMessage(e.response.data.detail.split(':')[0], 5000);
          commit("SET_SUBMIT_REGISTRATION_LOADER", false);
          commit("SET_REGISTER_FAIL", true);
        }
        commit("SET_LOADER", false);
        reject()
      }
    })
  },
  CREATE_PAYMENT_METHOD_ASSIGNMENTS: ({ }, payload): Promise<void> => {
    const payloadData: object = {
      venueId: payload.venueId,
      cardId: payload.paymentMethodId
    }

    return new Promise(async (resolve, reject) => {
      const resource: string = `${CASSOVIA_API_RESOURCE}/api/billing/venue-payment-method-assignments`;

      try {
        await AxiosService.getAxios().post(resource, payloadData);

        resolve();
      } catch (e) {
        reject()
      }
    })
  },
  SET_SUBMIT_REGISTRATION_LOADER: ({ commit }, payload): void => {
    commit("SET_SUBMIT_REGISTRATION_LOADER", payload)
  },
  SET_LOGINS_COUNT: ({ commit }, payload): void => {
    commit("SET_LOGINS_COUNT", payload)
  },
  CHECK_UNFINISHED_REGISTRATION: ({ }, payload): Promise<RegisterFilledData> => {
    return new Promise(async (resolve, reject) => {
      const resource: string = `${CASSOVIA_API_RESOURCE}/api/client-zone-registration-process/get-by-email?email=${payload}`;

      try {
        const response = await ApiService.get(resource);

        resolve(response.data);
      } catch (e) {
        reject()
      }
    });
  },
}
