export default {
  "User access rights": "Prístupové práva užívateľa",
  "Add New User": "Pridať nového užívateľa",
  "Show only active user": "Zobraziť iba aktívneho užívateľa",
  "Job position": "Pracovná pozícia",
  "Songoroo Role": "Songoroo rola",
  "Phone number": "Telefónne číslo",
  "Venue authorization": "Oprávnenia k prevádzkam",
  "Admin": "Admin",
  "Venue Manager": "Manažér prevádzky",
  "Marketer": "Marketér",
  "Accountant": "Účtovník",
  "Unassigned": "Bez role",
  "Music web player": "Hudobný webový prehrávač",
  "Venue setup": "Nastavenie prevádzky",
  "Marketing": "Marketing",
  "Pending invitation": "Nevybavená pozvánka",
  "Inactive": "Neaktívna",
  "Never logged in": "Nikdy neprihlásený",
  "Deactivated": "Deaktivované",
  "Add user": "Pridať užívateľa",
  "Send invitation to": "Poslať pozvánku",
  "Edit user permissions": "Upraviť užívateľské oprávnenia",
  "Back to Role management": "Späť na manažment oprávnení",
  "Venue": "Prevádzka",
  "ALL VENUES": "VŠETKY PREVÁDZKY",
  "User's authorisations to venues": "Oprávnenia užívateľa pre prevádzky",
  "Turn on/off music streaming": "Zapnuť/vypnúť streamovanie hudby",
  "Edit company data": "Úprava údajov o spoločnosti",
  "Edit venue details": "Úprava detailov prevádzky",
  "Create/Delete venue": "Vytvoriť/Odstrániť prevádzku",
  "Change music playlist": "Zmeniť hudobný playlist",
  "Set Songoroo app features": "Nastavenie funkcií Songoroo aplikácie",
  "Change subscription plan": "Zmeniť balík služieb",
  "View and export invoices": "Zobraziť a exportovať faktúry",
  "Change payment method": "Zmeniť spôsob platby",
  "View list of users": "Zobraziť zoznam užívateľov",
  "View audio campaigns": "Zobraziť audio kampane",
  "Add/Manage audio spots": "Pridať/Spravovať audio spoty",
  "Create/Manage audio ad campaigns": "Vytvárať/Spravovať audio reklamné kampane",
  "Respond to customer feedback received via Songoroo app": "Odpovedať na spätnú väzbu od zákazníkov prijatú prostredníctvom aplikácie Songoroo",
  "Create tailored questions for customer questionnaire, and edit questionaries": "Vytvorenie a editácia na mieru šitých dotazníkov v mobilnej aplikácií Songoroo",
  "Send messages to customers": "Posielať správy zákazníkom",
  "View history of messages sent to customer": "Zobraziť históriu správ odoslaných zákazníkom",
  "Create, edit and delete users": "Vytvárať, upravovať a mazať užívateľov",
  "Active User": "Aktívny"
}
