














import Vue from 'vue'
import Footer from "@/router/layouts/Footer.vue"
import Navbar from "@/router/layouts/Navbar.vue";

export default Vue.extend({
  components: {
    "navbar-main": Navbar
  },
})
