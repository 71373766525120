import Vue from "vue";
import { SubscriptionProductsData, TaxRateData, VenueSubscriptionData, DiscountCode } from "@/types";
import { MutationTree } from "vuex";
import { VenueSubscriptionState } from "@/types/store/clientzone";

export const mutations: MutationTree<VenueSubscriptionState> = {
  SET_SUBSCRIPTION_PRODUCTS: (state, payload: Array<SubscriptionProductsData>): Array<SubscriptionProductsData> => Vue.set(state, "subscriptionProducts", payload),
  SET_RECURRING_INTERVAL: (state, payload: string): string => Vue.set(state, "selectedRecurringInterval", payload),
  SET_TAX_RATE: (state, payload: TaxRateData): TaxRateData => Vue.set(state, "taxRate", payload),
  SET_VENUES_SUBSCRIPTION: (state, payload: Array<VenueSubscriptionData>): Array<VenueSubscriptionData> => Vue.set(state, "venuesSubscription", payload),
  SET_VENUE_SUBSCRIPTION: (state, payload: VenueSubscriptionData): VenueSubscriptionData => Vue.set(state, "selectedVenueSubscription", payload),
  SET_LOADER: (state, payload: boolean): boolean => Vue.set(state, "loader", payload),
  SET_DISCOUNT_CODE: (state, payload: DiscountCode): DiscountCode => Vue.set(state, "discountCode", payload),
  SET_INVALID_DISCOUNT_CODE: (state, payload: DiscountCode): DiscountCode => Vue.set(state, "invalidDiscountCode", payload),
  SET_ERROR_DETAIL: (state, payload) => Vue.set(state, "errorDetail", payload),
  SET_STRIPE_SUBSCRIPTION_RESPONSE: (state, payload) => Vue.set(state, 'stripeSubscriptionResponse', payload)
}
