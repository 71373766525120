import ApiService from "@/services/api.service";
import { CASSOVIA_API_RESOURCE, SGR_CLIENTZONE_API_KEY, SONGOROO_API_RESOURCE } from "@/utils/helpers";
import { errorMessage, successMessage } from "@/utils/messages-handlers";
import i18n from "@/i18n/i18n"
import router from "@/router";
import TokenService from "@/services/token.service";
import { getEmailLocale } from "@/utils/helpers";

export const actions = {
  SEND_REGISTRATION_EMAIL: ({ rootGetters }, payload): Promise<void> => {
    const emailPayload: object = {
      email: payload.email,
      companyName: payload.companyData,
      clientZoneUserId: payload.clientZoneUserId,
      userIsLoggedIn: payload.userIsLoggedIn,
      locale: i18n.locale
    }

    return new Promise(async (resolve, reject) => {
      const resource: string = `${CASSOVIA_API_RESOURCE}/api/mail/registration`;

      try {
        const response = await ApiService.post(resource, emailPayload);

        resolve();
      } catch (e) {
        reject()
      }
    })
  },
  SEND_RESET_PASSWORD_EMAIL: ({ commit, rootGetters }, payload): Promise<void> => {
    commit("SET_LOADER", true);
    commit("SET_RESET_PASSWORD_USER_EMAIL", payload.email);

    return new Promise(async (resolve, reject) => {
      const resource: string = `${CASSOVIA_API_RESOURCE}/api/mail/reset-password`;

      try {
        const response = await ApiService.post(resource, payload);

        successMessage("Request sent. Please check your email address.", 5000);
        commit("SET_LOADER", false);

        resolve();
      } catch (e) {
        commit("SET_LOADER", false);
        errorMessage("User with provided email do not exist.", 5000);
        reject()
      }
    })
  },
  SEND_CONFIRM_RESETTING_PASSWORD_EMAIL: ({ }, userData = {}): Promise<void> => {
    const payload: object = {
      email: router.currentRoute.query.email,
      locale: i18n.locale
    }

    const payloadData = userData ? userData : payload;

    return new Promise(async (resolve, reject) => {
      const resource: string = `${CASSOVIA_API_RESOURCE}/api/mail/confirm-resetting-password`;

      try {
        const response = await ApiService.post(resource, payloadData);

        resolve();
      } catch (e) {
        reject()
      }
    })
  },
  SEND_NEW_USER_ADDED_EMAIL: ({ rootGetters }, data): Promise<void> => {
    const payload: object = {
      email: rootGetters["companyModule/GET_COMPANY"].email,
      timestamp: Math.floor(Date.now() / 1000),
      newUserName: data.userFirstname,
      locale: i18n.locale
    }

    return new Promise(async (resolve, reject) => {
      const resource: string = `${CASSOVIA_API_RESOURCE}/api/mail/new-user-added`;

      try {
        const response = await ApiService.post(resource, payload);

        resolve();
      } catch (e) {
        reject()
      }
    })
  },
  SEND_INVITATION_EMAIL: ({ rootGetters }, data): Promise<void> => {
    const payload: object = {
      email: data.userEmail,
      companyName: rootGetters["companyModule/GET_COMPANY"].name,
      locale: i18n.locale,
      userStatus: data.status,
      userId: data.userId
    }

    return new Promise(async (resolve, reject) => {
      const resource: string = `${CASSOVIA_API_RESOURCE}/api/mail/invitation`;

      try {
        const response = await ApiService.post(resource, payload);

        resolve();
      } catch (e) {
        reject()
      }
    })
  },
  SEND_VENUE_CREATED_EMAIL: ({ rootGetters }, data): Promise<void> => {
    const payload: object = {
      venueOwnerId: rootGetters["companyModule/GET_COMPANY"].id,
      companyName: data.venueName,
      locale: i18n.locale,
      venueId: data.venueId
    }

    return new Promise(async (resolve, reject) => {
      const resource: string = `${CASSOVIA_API_RESOURCE}/api/mail/venue-created`;

      try {
        const response = await ApiService.post(resource, payload);

        resolve();
      } catch (e) {
        reject()
      }
    })
  },
  SEND_VENUE_DELETED_EMAIL: ({ rootGetters }, data): Promise<void> => {
    const payload: object = {
      companyName: data.venueName,
      venueOwnerId: rootGetters["companyModule/GET_COMPANY"].id,
      venueId: rootGetters["venueModule/GET_SELECTED_VENUE_ID"],
      locale: getEmailLocale(data.countryId),
    }

    return new Promise(async (resolve, reject) => {
      const resource: string = `${CASSOVIA_API_RESOURCE}/api/mail/venue-deleted`;

      try {
        const response = await ApiService.post(resource, payload);

        resolve();
      } catch (e) {
        reject()
      }
    })
  },
  SEND_SUPPORT_CONFIRMATION_EMAIL: ({ rootGetters }, data): Promise<void> => {
    const payload: object = {
      email: data.email,
      supportRequestId: data.supportId,
      locale: i18n.locale
    }

    return new Promise(async (resolve, reject) => {
      const resource: string = `${CASSOVIA_API_RESOURCE}/api/mail/support-confirmation-mail`;

      try {
        const response = await ApiService.post(resource, payload);

        resolve();
      } catch (e) {
        reject()
      }
    })
  },
  SEND_STREAMER_DISPATCHED_EMAIL: ({ rootGetters }, data): Promise<void> => {
    let venueOwnerId = rootGetters["companyModule/GET_COMPANY_ID"];
    if (data.fromRegistration) {
      venueOwnerId = rootGetters["registerModule/GET_COMPANY_ID"]
    }
    const payload: object = {
      venueName: rootGetters["venueModule/GET_SELECTED_VENUE"].name,
      venueId: rootGetters["venueModule/GET_SELECTED_VENUE_ID"],
      venueOwnerId: venueOwnerId,
      timestamp: Math.floor(Date.now() / 1000),
      locale: i18n.locale
    }

    return new Promise(async (resolve, reject) => {
      const resource: string = `${CASSOVIA_API_RESOURCE}/api/mail/streaming-device-dispatched`;

      try {
        const response = await ApiService.post(resource, payload);

        resolve();
      } catch (e) {
        reject()
      }
    })
  },
  VERIFICATION_EMAIL: ({ }, payload): Promise<void> => {
    const loggedIn = !!TokenService.getToken() && TokenService.getToken() !== 'undefined'; // && !isNaN(userId);

    return new Promise(async (resolve, reject) => {
      const resource: string = `${CASSOVIA_API_RESOURCE}/api/verificate-mail`;

      i18n.locale = payload.lang;
      localStorage.setItem('language', payload.lang);

      try {
        const response = await ApiService.post(resource, { encryptedEmail: payload.token });

        if (!loggedIn) {
          router.push("/after-registration").catch((e) => { console.log(e); });
        }
        successMessage("Email was successfully verified.", 5000);

        resolve();
      } catch (e) {
        if (e.response.status == 400) {
          if (!loggedIn) router.push("/login");
          router.push("/dashboard");
          errorMessage("There was problem to verify your email. Please try again later.", 6000);
        } else if (e.response.status == 405) {
          router.push("/login");
          errorMessage('This account has been already activated. Please login.', 5000)
        }
        reject()
      }
    })
  },
  SUBSCRIPTION_OR_PAYMENT_METHOD_CHANGED_EMAIL: ({ rootGetters }, payload: object): Promise<void> => {
    const venue = rootGetters["venueModule/GET_SELECTED_VENUE"]
    const payloadData: object = {
      locale: i18n.locale,
      venueOwnerId: rootGetters["companyModule/GET_COMPANY"].id,
      venueId: rootGetters['venueModule/GET_SELECTED_VENUE_ID'],
      venueName: venue.name,
      ...payload
    }

    return new Promise(async (resolve, reject) => {
      const resource: string = `${CASSOVIA_API_RESOURCE}/api/mail/subscritption-or-payment-method-changed`;

      try {
        const response = await ApiService.post(resource, payloadData);

        resolve();
      } catch (e) {
        reject()
      }
    })
  },
  IS_USER_ACTIVE: ({ commit }, payload: string): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      const resource: string = `${SONGOROO_API_RESOURCE}/api/v1/clientzone/check/is_user_active?email=${payload}`;

      try {
        const response = await ApiService.get(resource, {
          headers: {
            Authorization: "Bearer apikey",
            "SGR-CLIENTZONE-API-KEY": SGR_CLIENTZONE_API_KEY
          }
        });
     
        const { active, czone_user_id } = response.data;

        commit("SET_IS_USER_EMAIL_VERIFIED", active);

        if (czone_user_id) {
          localStorage.setItem("clientZoneUserId", czone_user_id);
        }

        resolve();
      } catch (e) {
        reject()
      }
    })
  },
}
