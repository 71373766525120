import { CASSOVIA_API_RESOURCE, ROOT_ACTION } from "@/utils/helpers";
import { ActionTree } from "vuex";
import { VenueData } from "@/types";
import { errorMessage, successMessage } from "@/utils/messages-handlers";
import { PaymentState, RootState } from "@/types/store/clientzone";
import TokenService from "@/services/token.service";
import AxiosService from "@/services/axios.service";

export const actions: ActionTree<PaymentState, RootState> = {
  FETCH_PAYMENT_CARDS: ({ commit, rootGetters }): Promise<void> => {
    const companyId = rootGetters["companyModule/GET_COMPANY_ID"];

    return new Promise(async (resolve, reject) => {
      const resource: string = `${CASSOVIA_API_RESOURCE}/api/stripe/stripe-cards?venueOwnerId=${companyId}`;

      try {
        const response = await AxiosService.getAxios().get(resource);

        commit("SET_PAYMENT_CARDS", response.data)

        resolve();
      } catch (e) {
        reject()
      }
    })
  },
  FETCH_PAYMENT_REGISTER_CARD: ({ commit }, companyId: number): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      const resource: string = `${CASSOVIA_API_RESOURCE}/api/stripe/stripe-cards?venueOwnerId=${companyId}`;

      try {
        const response = await AxiosService.getAxios().get(resource);

        commit("SET_REGISTER_PAYMENT_METHOD", response.data[0].id);

        resolve();
      } catch (e) {
        reject()
      }
    })
  },
  ADD_PAYMENT_CARD: ({ commit, dispatch }, payload: object): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      const resource: string = `${CASSOVIA_API_RESOURCE}/api/stripe/stripe-cards`;

      try {
        await AxiosService.getAxios().post(resource, payload);

        await dispatch("FETCH_PAYMENT_CARDS");
        commit("SET_PAYMENT_CARD_LOADER", false);
        commit("SET_IS_PAYMENT_CARD_VALID", true);
        successMessage("New payment card added.", 3000);

        resolve();
      } catch (e) {
        errorMessage(e.response.data.detail, 6000);
        commit("SET_PAYMENT_CARD_LOADER", false);
        reject()
      }
    })
  },
  REMOVE_PAYMENT_CARD: ({ dispatch }, id: number): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      const resource: string = `${CASSOVIA_API_RESOURCE}/api/stripe/stripe-cards/${id}`;

      try {
        await AxiosService.getAxios().delete(resource);

        await dispatch("FETCH_PAYMENT_CARDS");
        successMessage("Payment card removed.", 3000);

        resolve();
      } catch (e) {
        errorMessage(e.response.data.detail, 5000);
        reject()
      }
    })
  },
  FETCH_BANK_ACCOUNTS: ({ commit, rootGetters }): Promise<void> => {
    const companyId = rootGetters["companyModule/GET_COMPANY_ID"];
    return new Promise(async (resolve, reject) => {
      const resource: string = `${CASSOVIA_API_RESOURCE}/api/bank-accounts?venueOwnerId=${companyId}`;

      try {
        const response = await AxiosService.getAxios().get(resource);

        commit("SET_BANK_ACCOUNTS", response.data)

        resolve();
      } catch (e) {
        reject()
      }
    })
  },
  ADD_BANK_ACCOUNT: ({ dispatch, commit }, payload: object): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      const resource: string = `${CASSOVIA_API_RESOURCE}/api/bank-accounts`;

      try {
        await AxiosService.getAxios().post(resource, payload);

        await dispatch("FETCH_BANK_ACCOUNTS");
        commit("SET_IBAN_ERROR", '')
        successMessage("New bank account added.", 3000);
        resolve();
      } catch (e) {
        console.log(e.response)
        if (e.response.status === 422) {
          commit("SET_IBAN_ERROR", 'This is not a valid International Bank Account Number (IBAN).')
        }
        reject()
      }
    })
  },
  REMOVE_BANK_ACCOUNT: ({ dispatch }, id: number): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      const resource: string = `${CASSOVIA_API_RESOURCE}/api/bank-accounts/${id}`;

      try {
        await AxiosService.getAxios().delete(resource);

        await dispatch("FETCH_BANK_ACCOUNTS");
        successMessage("Bank account removed.", 3000);

        resolve();
      } catch (e) {
        if (e.response.status === 405) {
          errorMessage('Bank account is attached to venue.', 5000);
        }
        reject()
      }
    })
  },
  SET_PAYMENT_METHOD_ASSIGNMENTS: async ({ commit, dispatch, rootGetters }, filteredVenues: string = ""): Promise<void> => {
    if (!filteredVenues) await dispatch("venueModule/FETCH_VENUES", { fetchRatings: false, name: '' }, ROOT_ACTION);
    const venues: Array<VenueData> = rootGetters["venueModule/GET_VENUES"];
    let venueUniqueString: string = "";

    if (filteredVenues) {
      commit("SET_FILTERING", true);
      venueUniqueString = filteredVenues;
    } else {
      commit("SET_FILTERING", false);
      venues.forEach((v) => {
        venueUniqueString += `venueId%5B%5D=${v.id}&`
      })
    }

    const isCompanyCorporate = rootGetters['companyModule/GET_IS_CORPORATE'];
    const paymentType = isCompanyCorporate ? 2 : 1;

    return new Promise(async (resolve, reject) => {
      const resource: string = `${CASSOVIA_API_RESOURCE}/api/billing/venue-payment-method-assignments?${venueUniqueString.slice(0, -1)}&paymentType=${paymentType}`;

      try {
        const response = await AxiosService.getAxios().get(resource);

        commit("SET_PAYMENT_METHOD_ASSIGNMENTS", response.data);

        resolve();
      } catch (e) {
        reject()
      }
    })
  },
  CREATE_PAYMENT_METHOD_ASSIGNMENTS: ({ dispatch }, payload): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      const resource: string = `${CASSOVIA_API_RESOURCE}/api/billing/venue-payment-method-assignments`;

      try {
        await AxiosService.getAxios().post(resource, payload);
        dispatch("SET_PAYMENT_METHOD_ASSIGNMENTS", "");
        if (!payload.isRenew) successMessage("Venue payment method updated.", 3000);

        resolve();
      } catch (e) {
        reject()
      }
    })
  },
  SET_STRIPE_CARD: ({ commit }, payload): void => { commit("SET_STRIPE_CARD", payload) },
  CREATE_STRIPE_USER: ({ dispatch }, { payload, register = false }): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      const resource: string = `${CASSOVIA_API_RESOURCE}/api/stripe/stripe-users`;

      try {
        const response = await AxiosService.getAxios().post(resource, payload);

        if (register) {
          await dispatch("FETCH_PAYMENT_REGISTER_CARD", response.data.venueOwnerId);
        }

        resolve();
      } catch (e) {
        reject()
      }
    })
  },
  CREATE_SETUP_INTENT: ({ commit }, payload): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      const resource: string = `${CASSOVIA_API_RESOURCE}/api/stripe/create-setup-intent`

      try {
        const response = await AxiosService.getAxios().post(resource, payload);

        commit("SET_SETUP_INTENT", response.data.data)
        commit("SET_STRIPE_CARD_PAYMENT_METHOD_ID", response.data.data.payment_method)
        commit("SET_CARD_ADDED_STATE", true)
        commit("SET_IS_PAYMENT_CARD_VALID", true);
        commit("SET_PAYMENT_CARD_LOADER", false);
        resolve();
      } catch (e) {
        errorMessage(e.response.data.detail, 6000);
        commit("SET_CARD_ADDED_STATE", false)
        commit("SET_IS_PAYMENT_CARD_VALID", false);
        commit("SET_PAYMENT_CARD_LOADER", false);
        reject();
      }
    })
  },
  PREAUTHORIZE_CARD: ({ commit, dispatch }, payload): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      const resource: string = `${CASSOVIA_API_RESOURCE}/api/stripe/preauthorize-card`;

      try {
        const response = await AxiosService.getAxios().post(resource, payload);

        await dispatch("REFUND_PREAUTHORIZATION", response.data.data.id);

        resolve();
      } catch (e) {
        errorMessage(e.response.data.detail, 6000);
        commit("SET_PAYMENT_CARD_LOADER", false);
        reject()
      }
    })
  },
  REFUND_PREAUTHORIZATION: ({ commit }, chargeId: number): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      const resource: string = `${CASSOVIA_API_RESOURCE}/api/stripe/refund-preauthorization`;

      try {
        await AxiosService.getAxios().post(resource, { refundedChargeId: chargeId });

        commit("SET_PAYMENT_CARD_LOADER", false);
        commit("SET_IS_PAYMENT_CARD_VALID", true);

        resolve();
      } catch (e) {
        commit("SET_PAYMENT_CARD_LOADER", false);
        reject()
      }
    })
  },
  SET_PAYMENT_CARD_LOADER: ({ commit }, payload: boolean): void => commit("SET_PAYMENT_CARD_LOADER", payload),
  SET_SETUP_INTENT: ({ commit }, payload: object): void => commit("SET_SETUP_INTENT", payload),
  SET_STRIPE_CARD_PAYMENT_METHOD_ID: ({ commit }, payload: object): void => commit("SET_STRIPE_CARD_PAYMENT_METHOD_ID", payload),
  SET_CARD_ADDED: ({ commit }, payload: boolean): void => commit("SET_CARD_ADDED_STATE", payload),
  CREATE_STRIPE_CUSTOMER: ({ rootGetters }): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      const companyId: number = rootGetters["companyModule/GET_COMPANY_ID"];
      const resource: string = `${CASSOVIA_API_RESOURCE}/api/stripe/create-stripe-customer`;
      const data: object = {
        clientzoneUserId: TokenService.getUserId(),
        venueOwnerId: companyId,
        isExistingUser: true,
      };

      try {
        await AxiosService.getAxios().post(resource, data);

        resolve();
      } catch (e) {
        reject()
      }
    });
  },
  ATTACH_PAYMENT_METHOD_TO_CUSTOMER: ({ }, payload: object): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      const resource: string = `${CASSOVIA_API_RESOURCE}/api/attach-payment-method-to-customer`;

      try {
        await AxiosService.getAxios().post(resource, payload);

        resolve();
      } catch (e) {
        reject()
      }
    });
  }
}
