import { ActionTree } from "vuex";
import { RootState, CompanyAdminzoneState } from "@/types/store/adminzone";
import ApiService from "@/services/api.service";

export const actions: ActionTree<CompanyAdminzoneState, RootState> = {
  FIND_COMPANIES_BY_NAME: ({commit}, name:string): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      const resource: string = `/api/v1/clientzone/venue_owner?name=${name}&offset=0&include_deleted=true`;
      try{
        const response = await ApiService.get(resource)

        commit("SET_COMPANIES", response.data);

        resolve();
      }catch(e){
        reject()
      }
    })
  }
}
