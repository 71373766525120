import Vue from "vue";
import store from "@/state/store";
import {notificationsTypesRoutes} from "@/utils/notifications-types-routes";
import router from "@/router";
import {NOTIFICATION_IMPORTANCE} from "@/types/constants";

const toastOptions: object = {
  position: "top-right",
  closeOnClick: false,
  pauseOnFocusLoss: false,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: false,
  rtl: false,
  transition: "Vue-Toastification__slideBlurred",
  maxToasts: 20,
  newestOnTop: true,
  timeout: 6000,
  icon: false
}

export function notificationMessage(title: string, type: number, importance: number, targetEntityId: number, venueId: number, wasInspected: boolean, clientZoneNotificationInstanceId: number): void {
  let routePath: string = "";

  if (type === 7 || type === 8){
    routePath = notificationsTypesRoutes.find(n => n.type === type).path + `/${venueId}`
  } else if(type === 13){
    routePath = notificationsTypesRoutes.find(n=>n.type === type).path + `/${targetEntityId}`
  } else {
    routePath = notificationsTypesRoutes.find(n => n.type === type).path;
  }

  const importanceClass: string = notificationImportanceClass(importance);

  Vue.$toast(title, {
    ...toastOptions,
    toastClassName: ["cz-notification-custom-toast-class", importanceClass],
    onClick: () => {
      if (!wasInspected) {
        store.dispatch("notificationModule/SET_NOTIFICATION_AS_INSPECTED", clientZoneNotificationInstanceId);
        store.dispatch("notificationModule/FETCH_UNCHECKED_NOTIFICATIONS_LENGTH")
      }

      if (router.currentRoute.path !== routePath) {
        router.push(routePath);
      }
    }
  })
}

function notificationImportanceClass(value: number): string {
  switch (value) {
    case NOTIFICATION_IMPORTANCE.CRITICAL:
      return "critical";
    case NOTIFICATION_IMPORTANCE.WARNING:
      return "warning";
    case NOTIFICATION_IMPORTANCE.SUGGESTION:
      return "suggestion";
    default:
      return "";
  }
}
