




























import Vue from "vue";
//@ts-ignore
import appConfig from "@/app.config";
import checkLanguageFromRedirect from "@/mixins/checkLanguageFromRedirect";
import BaseLayout from "@/router/layouts/BaseLayout.vue";
import {mapActions, mapGetters} from "vuex";
import {UserData} from "@/types";
import TookWebStreamConfirmModal from "@/components/modals/TookWebstreamModal.vue";
import BrowserStreamer from "@/components/BrowserStreamer.vue";
import store from "@/state/store";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default Vue.extend({
  name: "app",

  components: {
    "base-layout": BaseLayout,
    "took-web-stream-confirm-modal":TookWebStreamConfirmModal,
    "browser-streamer": BrowserStreamer,
  },
  data() {
    return {
      webStreamerDynamicTopPosition: 500 as number,
      windowWidth: 0 as number,
      selectedWebStreamVenueId: 0,
      selectedWebStreamVenueName: "",
      info:false,
      isTaken:false,
      isLoading: false,
      fullPage: true
    }
  },

  mixins: [
    checkLanguageFromRedirect
  ],

  //@ts-ignore
  page: {
    // All subcomponent titles will be injected into this template.
    titleTemplate(title) {
      title = typeof title === "function" ? title(this.$store) : title;
      return title ? `${title} | ${appConfig.title}` : appConfig.title;
    }
  },

  created() {
    this.checkLanguageFromRedirect()
  },

  mounted() {
    this.$nextTick(function() {
      window.addEventListener('resize', this.getWindowWidth);
      this.getWindowWidth();
    })
  },

  computed: {
    ...mapGetters("layoutModule", {
      getToggleMenuState: "GET_TOGGLE_MENU_STATE",
      globalOverlaySpinnerState: "GET_GLOBAL_OVERLAY_STATE",
    }),
    ...mapGetters("userModule", {
      isUserSuperAdmin: "IS_USER_SUPER_ADMIN",
      loggedUser: "GET_LOGGED_USER",
      isUserLogged: "IS_USER_LOGGED",
      isBrowserStreamerVisible: "IS_BROWSER_STREAMER_VISIBLE",
      hasWebStreamingPermission: "GET_WEB_STREAMING_PERMISSION",
    }),
    ...mapGetters("companyModule",{
      companyId: "GET_COMPANY_ID"
    }),
    webStreamerDynamicLeftPosition(): string {
      if (this.windowWidth < 992) {
        if (this.getToggleMenuState) return "0px";
        return "-250px";
      }

      return "0px";
    }
  },

  methods: {
    ...mapActions("layoutModule", {
      toggleMenuState: "SET_TOGGLE_MENU_STATE"
    }),
    ...mapActions("userModule", {
      setUserAsLogged: "SET_USER_AS_LOGGED",
      fetchLoggedUser: "FETCH_LOGGED_USER"
    }),
    getWindowWidth(event): void {
      this.windowWidth = document.documentElement.clientWidth;
    },
    tookStream(value){
      if(value){
        this.selectedWebStreamVenueId = value;
        this.info = false
        this.$bvModal.show("took-webstream-modal");
      }
    },
    infoStream(value){
      if(value){
        this.selectedWebStreamVenueName = value;
        this.info = true
        this.$bvModal.show("took-webstream-modal");
      }
    },
    StreamIsTaken(value){
      this.isTaken = value;
    }
  },

  watch: {
    $route(to, from) {
      document.title = to.meta.title;
      if (window.screen.width < 992) {
        this.toggleMenuState(false);
      }
    },
    loggedUser: {
      handler(value: UserData): void {
        if (!!value.id && !this.isUserLogged) {
          this.setUserAsLogged(true);
        }
      }
    },
    companyId: {
     async handler() {
       await this.fetchLoggedUser()
      }
    },
    globalOverlaySpinnerState: {
      handler(value: boolean): void{
        if(value){
          this.isLoading = true
        } else {
          this.isLoading = false
        }
      }
    }
  }
})
