import { state } from "@/state/modules/clientzone/venue_subscription/state";
import { mutations } from "@/state/modules/clientzone/venue_subscription/mutations";
import { getters } from "@/state/modules/clientzone/venue_subscription/getters";
import { actions } from "@/state/modules/clientzone/venue_subscription/actions";

const venueSubscriptionModule = {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}

export default venueSubscriptionModule
