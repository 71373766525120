export default {
  "Dashboard": "Dashboard",
  "Company data": "Company Data",
  "Venues": "Venues",
  "Venues list": "Venues List",
  "Venues detail": "Venues Detail",
  "App features setup": "App Features Setup",
  "Subscriptions & Billing": "Subscriptions & Billing",
  "Subscription Plan": "Subscription Plan",
  "Payment Methods": "Payment Methods",
  "User management": "User Management",
  "User access rights": "User Access Rights",
  "Role management": "Role Management",
  "Communication with Customers": "Communication With Customers",
  "Audio Commercials": "Audio Commercials",
  "Messages to clients": "Messages To Customers",
  "Venue Survey": "Venue Survey",
  "Venue survey": "Venue Survey",
  "Venue Feedback": "Venue Feedback",
  "Reports": "Reports",
  "Help & support": "Help & Support",
  "Discover": "Discover",
  "Setup": "Setup",
  "Manage": "Manage",
  "App features": "App Features",
  "Try for free": "Try for free",
  "Try it for free": "Try it for free",
  "Get the app": "Get the app",
  "Are you a business?": "Are you a business?",
  "Not a business?": "Not a business?",
  "Login": "Login",
  "My profile": "My profile",
  "Logout": "Logout",
  "Companies": "Companies",
  "List of Companies": "List of Companies",
  "Subscription": "Subscription",
  "Subscription Plans": "Subscription Plans",
  "Discount Codes": "Discount Codes",
  "Billingg": "Billing",
  "Invoice Overview": "Invoice overview",
  "Invoice Data Feed": "Invoice Data Feed",
  "Communication": "Communication",
  "Emails": "Emails",
  "Notifications": "Notifications",
  "User Management": "User Management",
  "Client Zone Users": "Client Zone Users",
  "Songoroo Admins": "Songoroo Admins",
  "Job Applicants": "Job Applicants",
  "Settings": "Settings",
  "Activity Log": "Activity Log",
  "Business Type": "Business Type",
  "Language Options": "Language Options",
}
