export default {
  "Log in": "Prihlásiť sa",
  "Forgot your password?": "Zabudli ste heslo?",
  "Don't have an account?": "Nemáte účet?",
  "Sign up.": "Zaregistrujte sa.",
  "Let’s find your venue": "Poďme nájsť vašu prevádzku",
  "Let’s find your business": "Poďme nájsť vašu prevádzku",
  "venue name and/or address": "Názov prevádzky a/alebo jej adresa",
  "Skip and enter details manually": "Preskočiť a zadať údaje manuálne",
  "Enter venue details manually": "Zadať údaje o prevádzke manuálne",
  "Let’s create your first business location": "Poďme vytvoriť vaše prvé miesto podnikania",
  "If your company has multiple business locations, you can add the remaining business locations within the Songoroo client interface after completing this registration.": "Ak má Vaša spoločnosť viacero prevádzok, môžete po dokončení tejto registrácie pridať zvyšné prevádzky už v rámci klienského rozhrania Songoroo.",
  "Next step": "Ďalší krok",
  "Back": "Späť",
  "Let’s create your user account": "Poďme vytvoriť váš užívateľský účet",
  "Name": "Meno",
  "Surname": "Priezvisko",
  "First": "Prvých",
  "days period is for free. You can cancel Songoroo subscription anytime during the trial period and pay nothing": "dní je zadarmo. Balík služieb Songoroo môžete kedykoľvek počas skúšobného obdobia zrušiť a nič neplatiť",
  "Secure Checkout": "Bezpečná platba",
  "Pay with stripe": "Zaplatiť cez stripe",
  "Add Payment Card": "Pridať platobnú kartu",
  "Confirm": "Potvrdiť",
  "Congratulations.": "Gratulujeme.",
  "Card was successfully added.": "Karta bola úspešne pridaná.",
  "By saving your credit/debit card information you agree to the terms & conditions of Stripe. You can read it by clicking on the following link.": "Uložením informácií o vašej kreditnej/debetnej karty vyjadrujete súhlas s obchodnými podmienkami spoločnosti Stripe. Môžete si ich prečítať kliknutím na nasledujúci odkaz.",
  "Processing payment": "Spracovanie platby",
  "Processing": "Spracovávam",
  "Pay": "Zaplatiť",
  "Company Billing Data": "Fakturačné údaje spoločnosti",
  "Submit registration": "Potvrdiť registráciu",
  "Business identification number is required.": "IČO je povinné.",
  "VAT is required.": "DPH je povinné.",
  "Contact person is required": "Kontaktná osoba je povinná",
  "Contact person email is required.": "Email kontaktnej osoby je povinný.",
  "Contact person phone is required.": "Telefón kontaktnej osoby je povinný.",
  "Congratulations, all is set.": "Gratulujeme, všetko je pripravené.",
  "Enjoy streaming music at your business.": "Vychutnajte si Songoroo hudbu vo Vašom podniku.",
  "Continue to your account": "Pokračovať do svojho účtu",
  "Reset password": "Obnoviť heslo",
  "Create new password": "Vytvoriť nové heslo",
  "Confirm new password": "Potvrdiť nové heslo",
  "address placeholder": "napr. Spoločnosť, Hlavná ulica, Košice",
  "Company with VAT": "Spoločnosť s IČ DPH",
  "Company with name": "Spoločnosť s názvom",
  "Company with business identification number": "Spoločnosť s IČO",
  "is already registered.": "je už zaregistrovaná.",
  "For more details, contact us support": "Pre viac informácií, kontaktujte našu podporu",
  "Continue to login": "Pokračovať na prihlásenie",
  "is already registered. Please, log in or change email to continue the registration.": "je už zaregistrovaný. Ak chcete pokračovať v registrácii, prihláste sa alebo zmeňte e-mail.",
  "User with email": "Užívateľ s e-mailom",
  "Login and continue": "Prihlásiť sa",
  "Please check your email and confirm your registration. Then you can log in.": "Skontrolujte svoj e-mail a potvrďte svoju registráciu. Potom sa môžete prihlásiť.",
  "Email was successfully verified.": "Email bol úspešne verifikovaný",
  "This account has been already activated. Please login.": "Váš účet už bol v minulosti aktivovaný. Môžete pokračovať do svojho účtu.",
  "There was problem to verify your email. Please try again later.": "Nastal problém v procese verifikácie.",
  "Allow geolocation": "Povoliť geolokáciu",
  "Credit card authorised. Your registration can be submitted.": "Kreditná karta bola autorizovaná.",
  "Authorization failed. Enter your card details again.": "Autorizácia karty zlyhala. Zvoľte preferovaný balík služieb a použite iný spôsob platby.",
  "Geolocation is not supported by this browser.": "Geolokácia nie je podporovaná týmto prehliadačom.",
  'Geolocation has been disabled for this site. Enable geolocation in your browser settings.': 'Geolokácia, pre túto stránku, bola zakázaná. Povoľte zdieľanie svojej konkrétnej polohy v nastaveniach svojho prehliadača.',
  "Find your business": "Vyhľadať prevádzku",
  "User account": "Užívateľský účet",
  "Email verification": "Potvrdenie e-mailu",
  "Billing data": "Fakturačné údaje",
  "Send again": "Poslať znova",
  "If you have not received the verification e-mail, repeat the action with the 'Send again' button.": "Ak ste nedostali overovací e-mail, zopakujte akciu tlačidlom 'Poslať znova'.",
  "We have sent a verification link to the email address": "Overovací link bol odoslaný na túto emailovú adresu",
  "If you have any questions, please contact us by email at": "Ak máte nejaké otázky, kontaktujte nás e-mailom na adrese",
  "Finish registration": "Dokončiť registráciu",
  "Your email has been successfully verified.": "Váš email bol úspešne overený",
  "Street and descriptive number": "Ulica a popisné číslo",
  "Please do close this screen and continue in the screen where you started the registration process": "Pre dokončenie registrácie zavrite prosím toto okno a pokračujte v okne, v ktorom ste spustili registračný proces",
  "Check your email inbox and confirm your registration. If you have not received the email, please click on": "Skontrolujte svoju e-mailovú schránku a potvrďte svoju registráciu. V prípade, že vám e-mail neprišiel kliknite prosím na ",
  "We have sent a verification link to your email address": "Overovací link bol odoslaný na vašu emailovú adresu",
}
