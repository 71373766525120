import PopupsEN from './popups/en';

export default {
  ...(PopupsEN),
  "List of Audio Spots": "List Of Audio Spots",
  "List of audio spots": "List of audio spots",
  "Messages to customers": "Messages to customers",
  "Add New Campaign": "Add New Campaign",
  "Add new campaign": "Add new campaign",
  "Add Audio Spot": "Add Audio Spot",
  "Campaign Name": "Campaign Name",
  "Campaign period": "Campaign Period",
  "Timeslot": "Timeslot",
  "Show only active campaigns": "Show only active campaigns",
  "Venue filter": "Venue filter",
  "Venues assigned to the campaign": "Venues assigned to the campaign",
  "Venues assigned to the survey": "Venues assigned to the survey",
  "Spot name": "Spot Name",
  "Music": "Music",
  "Text": "Text",
  "Uploaded by": "Uploaded By",
  "Duration": "Duration",
  "Type of the message": "Type of the message",
  "Reach": "Reach",
  "Choose venues": "Choose venues",
  "Message max characters": "Message (max {count} characters)",
  "Send a message": "Send a message",
  "Messages history": "Messages History",
  "Message type": "Message Type",
  "Date": "Date",
  "Reach (Songoroo app users)": "Reach (Songoroo App Users)",
  "Announcement": "Announcement",
  "Advertisement": "Advertisement",
  "List of questions": "List of questions",
  "List Of Questions": "List of questions",
  "Add New Survey": "Add New Survey",
  "Add new survey": "Add new survey",
  "Survey": "Survey",
  "Add new question": "Add New Question",
  "Questions": "Questions",
  "Question": "Question",
  "Created at": "Created at",
  "Created by": "Created by",
  "Select type of your message": "Select type of your message",
  "Choose campaign questions": "Select survey questions",
  "Campaign duration": "Campaign duration",
  "Message": "Message",
  "characters": "characters",
  "This venue has BASIC subscription package not covering sending messages to costumers of a particular venue": "This venue has BASIC subscription package not covering sending messages to costumers of a particular venue",
  "Sent on": "Sent On",
  "Back to audio commercials": "Back To Audio Commercials",
  "Back to venue survey": "Back To Venue Survey",
  "This spot cannot be deleted as it is attached to advert campaign.": "This audio spot cannot be deleted as it is assigned to an audio campaign.",
  "No audio spots were found": "No audio spots were found",
  "Your message has been sent to": "Your message has been sent to",
  "Songoroo app users": "Songoroo app users",
  "Default survey": "Default survey"
}
