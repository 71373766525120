export default {
    //notifications
    "Notifications": "Notifikace",
    "Edit Notifications": "Úprava notifikací",
    "Notification updated successfully": "Notifikace úspěšně aktualizována",
    'Notification Title': "Název notifikace",
    "Level of Importance": "Důležitost notifikace",
    "Repetition": "Opakování",
    "Critical": "Kritická",
    "Suggestion": "Návrh",
    "Warning": "Upozornění",
    "Every": "Každých",
    "days": "dnů",
    "Just once": "Raz",
    "Title": "Název",
    "Description": "Popis",
    //Emails
    "Emails": "Emaily",
    "Edit Emails": "Aktualizace emailů",
    //notifications
    "Supplier": 'Poskytovatel',
    "StreetAndNumber": "Ulice a číslo",
    "Price Ratio Settings": "Fakturační poměr cen",
    "Streaming Device": "Streamovací zařízení",
    "Music Streaming": "Streaming hudby",
    "iban: This is not a vali International Bnk Account Number (IBAN).": "Neplatný IBAN",
    "Datafeed updated successfully": "Fakturační údaje společnosti byly úspěšně aktualizovány.",
    //subscription plan
    "Add Description": "Přidat popis",
    "Subscription plans": "Plány předplatného",
    "New service description": "Přidat popis balíčku (anglicky)",
    "Additional services updated": "Doplňkové služby aktualizovány",
    "Add new additional service": "Přidat novou doplňkovou službu",
    "Description successfully attached to subscription plan.": "Popis úspěšně přidán",
    "Description successfully detached from subscrition plan.": 'Popis úspěšně odebrán',
    "Description deleted successfully": "Popis úspěšně vymazán",
    "Premium price was updated successfully": "Cena za Premium byla úspěšně aktualizována",
    "Basic price was updated successfully": "Cena za Basic byla úspěšně aktualizována",
    //Discount codes
    "Discount codes": "Slevové kupóny",
    "New Discount": "Přidat slevový kupón",
    "Only for first time payment": "Pouze při první platbě.",
    "Valid forever or till end date": "Platný pro všechny platby do konce období nebo do dosažení maximálního počtu použití.",
    "Discount rate": "Sleva v procentech",
    "Maximum usages": "Maximální počet použití",
    "Campaign name": "Název kampaně",
    "Discount code": "Slevový kód",
    "Applies for": "Aplikovatelný pro",
    "Valid for": "Platný pro",
    "Type": "Typ",
    "Apply end date": "Nastavit datum vypršení slevového kupónu",
    "End date": "Dátum expirace",
    "Discount created successfully": "Slevový kupón úspěšně přidán",
    //subscription description:
    "Descriptions updated": "Popisy plánů aktualizováno",
    //Emails
    "Email Title": "Popis emailu",
    //Subscription plan - Language options
    "Subscription plan - Language options": "Plán předplatného - Jazykové úpravy",
    "Translation is complete": "Překlad je dokončen",
    "Additional service": "Doplňková služba",
    "Translation to": "Překlad do",
    "Price": "Cena",
    "Service description": "Popis produktu",
    //Invoice
    "Invoice Overview": "Přehled faktur",
    "Upload file": "Nahrát soubor",
    "New Invoice": "Nová faktura",
    "Invoice type": "Typ faktury",
    "Actions": "Možnosti",
    "Company": "Společnosť",
    "Show only invoices that failed automatic pairing": "Zobrazit faktury nespárované s platbou",
    "Invoice system": "Vystavena automaticky",
    "Invoice manual": "Vystavena manuálně",
    "Credit note": "Dobropis",
    "past due": "Po splatnosti",
    "due": "K zaplacení",
    "was sent to": "byla odeslána na fakturační e-mail",
    "of company": "společnosti",
    "Find company": "Vyhledat společnost",
    "Find venue": "Vyhledat provozovnu",
    "Select payment method": "Vybrat způsob platby",
    "All": "Všechny",
    "Invoice status": "Stav faktury",
    "List of venues": "Seznam provozoven",
    "Period": "Období",
    "Past due date": "Po splatnosti",
    'Upload a file (SEPA XML) to automatically match invoice payments': 'Nahrát soubor (SEPA XML) k automatickému faktur',
    "Attachment can be just 1.": "Možnost nahrát pouze 1 SEPA XML soubor",
    "Unsaved Additional service. Please add service or delete fields": "Neuložená doplňková služba. Prosím, vymažte pole nebo doplňte službu",
    "Company name": "Název společnosti",
    "Company address": "Adresa společnosti",
    "Billing city": "Město společnosti",
    "Billing country": "Země společnosti",
    "Purchaser": "Nákupce",
    "Find Venue Owner": "Najít společnost",
    "Local supply": "Dodání v tuzemsku",
    "Subject to reverse charge": "Osvobozeno od DPH",
    "Invoice No": "Číslo faktury",
    "Location": "Lokalita",
    "VAT rate": "DPH v %",
    "Price with VAT": "Cena s DPH",
    "Billing period": "Fakturační období",
    "Bank name": "Název banky",
    "Variable symbol": "Variabilní symbol",
    "Purchase order": "Číslo objednávky",
    "Payment instruction": "Platební instrukce",
    "Amount to be paid": "Částka k zaplacení",
    "VAT treatment": "Daňový režim",
    "New invoice/Credit/Debit note": "Nová faktura",
    //Multiselect
    "Click to select": "Vybrat",
    "Click to deselect": "Odebrat",
    "Selected": "Vybrané",
    "Date of delivery": "Datum dodání",
    //Purchase order
    "Purchase order history": "Historie čísel objednávky",
    "Valid from": "Platné od",
    "Valid to": "Platné do",
    "Purchase order active": "Aktivní",
    "Purchase order inactive": "Nastavit jako aktivní",
    "Back to company data": "Zpět na údaje společnosti",
    "Purchase order number enabled successfully.": "Číslo objednávky aktivováno.",
    "All purchase order numbers were disabled.": "Všechna čísla objednávek byla deaktivována.",
    "Expired purchase order number cannot be set as active.": "Číslo objednávky, jehož platnost vypršela, nelze aktivovat.",
    // Activity Logs
    "Activity Logs": "Activity Logs",
    "User Name": "User Name",
    "Role": "Role",
    "Activity": "Activity",
    "Date": "Date",
    // Business type
    "Add new business type": "Přidání nového typu provozovny",
    "Action": "Akce",
    "Assign Playlist": "Přiřadit Playlist",
    "Playlist": "Playlist",
    "Are you sure you want to Delete {type}": "Opravdu si přejete smazat {type}?",
    "Delete business type": "Vymazat typ provozovny",
    "Yes, Delete": "Ano, smazat",
    "No, Cancel": "Ne, zrušit",
    "Default": "Výchozí",
    "Set as default": "Nastavit jako výchozí",
    "Yes": "Ano",
    "No": "Ne",
    "Are you sure you want to delete ": "Opravdu si přejete smazat"
}
