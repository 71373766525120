export default {
  //notifications
  "Notifications": "Notifications",
  "Edit Notifications": "Edit notifications",
  "Notification updated successfully": "Notification updated successfully",
  'Notification Title': "Notification Title",
  "Level of Importance": "Level of Importance",
  "Repetition": "Repetition",
  "Critical": "Critical",
  "Suggestion": "Suggestion",
  "Warning": "Warning",
  "Every": "Every",
  "days": "days",
  "Just once": "Just once",
  "Description": "Description",
  //Emails
  "Emails": "Emails",
  "Edit Emails": "Edit emails",
  //invoice data feed
  "Supplier": 'Supplier name',
  "StreetAndNumber": "Street and descriptive house number",
  "Price Ratio Settings": "Price ratio settings",
  "Streaming Device": "Streaming device",
  "Music Streaming": "Music streaming",
  "iban: This is not a vali International Bnk Account Number (IBAN).": "iban: This is not a vali International Bnk Account Number (IBAN).",
  "Datafeed updated successfully": "Datafeed updated successfully",
  //subscription plan
  "Subscription plans": "Subscription plans",
  "Add Description": "Add Description",
  "New service description": "New service description (english)",
  "Additional services updated": "Additional services updated",
  "Add new additional service": "Add new additional service",
  "Description successfully attached to subscription plan.": "Description successfully attached to subscription plan.",
  "Description successfully detached from subscrition plan.": 'Description successfully detached from subscrition plan.',
  "Description deleted successfully": "Description deleted successfully",
  "Premium price was updated successfully": "Premium price was updated successfully",
  "Basic price was updated successfully": "Basic price was updated successfully",
  // "Subscription Plan": "Subscription plan"
  //Discount codes
  "Discount codes": "Discount codes",
  "New Discount": "New Discount",
  "Only for first time payment": "Only for first time payment",
  "Valid forever or till end date": "Valid forever or till end date",
  "Discount rate": "Discount rate",
  "Maximum usages": "Maximum usages",
  "Campaign name": "Campaign name",
  "Discount code": "Discount code",
  "Applies for": "Applies for",
  "Valid for": "Valid for",
  "Type": "Type",
  "Apply end date": "Apply end date",
  "End date": "End date",
  "Discount created successfully": "Discount created successfully",
  //subscription description:
  "Descriptions updated": "Descriptions updated",
  //Emails
  "Email Title": "Email Title",
  //Subscription plan - Language options
  "Subscription plan - Language options": "Subscription plan - Language options",
  "Translation is complete": "Translation is complete",
  "Additional service": "Additional service",
  "Translation to": "Translation to",
  "Price": "Price",
  "Service description": "Service description",
  //Invoice
  "Invoice Overview": "Invoice Overview",
  "Upload file": "Upload file",
  "New Invoice": "New Invoice",
  "Invoice type": "Invoice type",
  "Actions": "Actions",
  "Company": "Company",
  "Show only invoices that failed automatic pairing": "Show only invoices that failed automatic pairing",
  "Invoice system": "Invoice system",
  "Invoice manual": "Invoice manual",
  "Credit note": "Credit note",
  "past due": "Past due",
  "due": "Due",
  "was sent to": "was sent to",
  "of company": "of company",
  "Find company": "Find company",
  "Find venue": "Find venue",
  "Select payment method": "Select payment method",
  "All": "All",
  "Invoice status": "Invoice status",
  "List of venues": "List of venues",
  "Period": "Period",
  "Past due date": "Past due date",
  'Upload a file (SEPA XML) to automatically match invoice payments': 'Upload a file (SEPA XML) to automatically match invoice payments',
  "Attachment can be just 1.": "Attach just one XML file",
  "Unsaved Additional service. Please add service or delete fields": "Unsaved Additional service. Please add service or delete fields",
  "Company name": "Company name",
  "Company address": "Company address",
  "Billing city": "Billing city",
  "Billing country": "Billing country",
  "Purchaser": "Purchaser",
  "Find Venue Owner": "Find Venue Owner",
  "Local supply": "Local supply",
  "Subject to reverse charge": "Subject to reverse charge",
  "Invoice No": "Invoice No",
  "Location": "Location",
  "VAT rate": "VAT rate",
  "Price with VAT": "Price with VAT",
  "Billing period": "Billing period",
  "Bank name": "Bank name",
  "Variable symbol": "Variable symbol",
  "Purchase order": "Purchase order",
  "Payment instruction": "Payment instruction",
  "Amount to be paid": "Amount to be paid",
  "VAT treatment": "VAT treatment",
  "Total with VAT": "Total with VAT",
  "New invoice/Credit/Debit note": "New invoice/Credit/Debit note",
  //Multiselect
  "Click to select": "Click to select",
  "Click to deselect": "Click to remove",
  "Selected": "Selected",
  "Date of delivery": "Date of delivery",
  //Purchase order
  "Purchase order history": "Purchase order history",
  "Valid from": "Valid from",
  "Valid to": "Valid to",
  "Purchase order active": "Aktívne",
  "Purchase order inactive": "Set as active",
  "Back to company data": "Back to company data",
  "Purchase order number enabled successfully.": "Purchase order number enabled successfully.",
  "All purchase order numbers were disabled.": "All purchase order numbers were disabled.",
  "Expired purchase order number cannot be set as active.": "Expired purchase order number cannot be set as active.",
  // Activity Logs
  "Activity Logs": "Activity Logs",
  "User Name": "User Name",
  "Role": "Role",
  "Activity": "Activity",
  "Date": "Date",
  // Business type
  "Add new business type": "Add new business type",
  "Action": "Action",
  "Assign Playlist": "Assign Playlist",
  "Playlist": "Playlist",
  "Are you sure you want to Delete {type}": "Are you sure you want to Delete {type}?",
  "Delete business type": "Delete business type",
  "Yes, Delete": "Yes, delete",
  "No, Cancel": "No, cancel",
  "Default": "Default",
  "Set as default": "Set as default",
  "Yes": "Yes",
  "No": "No",
  "Are you sure you want to delete ": "Are you sure you want to delete "
}
