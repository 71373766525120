import { GetterTree } from "vuex";
import { ProductAdminzoneState, RootState } from "@/types/store/adminzone";
import { ProductData } from "@/types";

export const getters: GetterTree<ProductAdminzoneState, RootState> = {
  GET_ALL_PRODUCTS: (state): Array<ProductData> => JSON.parse(JSON.stringify(state.products.filter(product => product.isActive == true))),
  GET_PRODUCT_BASIC_MONTH: (state): Array<ProductData> => [...state.products].filter(product => product.recurringInterval == 'month' && product.songorooServiceType == 1 && product.songorooPackageType == 1),
  GET_PRODUCT_BASIC_YEAR: (state): Array<ProductData> => [...state.products].filter(product => product.recurringInterval == 'year' && product.songorooServiceType == 1 && product.songorooPackageType == 1),
  GET_PRODUCT_PREMIUM_MONTH: (state): Array<ProductData> => [...state.products].filter(product => product.recurringInterval == 'month' && product.songorooServiceType == 1 && product.songorooPackageType == 2),
  GET_PRODUCT_PREMIUM_YEAR: (state): Array<ProductData> => [...state.products].filter(product => product.recurringInterval == 'year' && product.songorooServiceType == 1 && product.songorooPackageType == 2),
  GET_ADDITIONAL_SERVICES_MONTH: (state): Array<ProductData> => JSON.parse(JSON.stringify(state.products.filter(product => product.recurringInterval == 'month' && product.songorooServiceType == 2))),
  GET_ADDITIONAL_SERVICES_YEAR: (state): Array<ProductData> => JSON.parse(JSON.stringify(state.products.filter(product => product.recurringInterval == 'year' && product.songorooServiceType == 2))),
  GET_ACTIVE_ADDITIONAL_SERVICES_MONTH: (state): Array<ProductData> => JSON.parse(JSON.stringify(state.products.filter(product => product.recurringInterval == 'month' && product.songorooServiceType == 2 && product.isActive == true))),
  GET_ACTIVE_ADDITIONAL_SERVICES_YEAR: (state): Array<ProductData> => JSON.parse(JSON.stringify(state.products.filter(product => product.recurringInterval == 'year' && product.songorooServiceType == 2 && product.isActive == true))),
}
