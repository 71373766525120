import { state } from "@/state/modules/adminzone/discounts/state";
import { mutations } from "@/state/modules/adminzone/discounts/mutations";
import { getters } from "@/state/modules/adminzone/discounts/getters";
import { actions } from "@/state/modules/adminzone/discounts/actions";

const discountsModule = {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}

export default discountsModule
