import ApiService from "@/services/api.service";
import { ActionTree } from "vuex";
import { ROOT_ACTION, SONGOROO_WEB } from "@/utils/helpers";
import { successMessage } from "@/utils/messages-handlers";
import { HelpAndSupportState, RootState } from "@/types/store/clientzone";
import AxiosService from "@/services/axios.service";

export const actions: ActionTree<HelpAndSupportState, RootState> = {

  FETCH_SUPPORT_TICKET_CATEGORY: ({ commit }): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      const resource: string = "api/v1/clientzone/support_ticket/category?limit=100";

      try {
        const response = await ApiService.get(resource);
        commit("SET_SUPPORT_TICKET_CATEGORY", response.data);

        resolve();
      } catch (e) {
        reject()
      }
    })
  },
  CREATE_SUPPORT_TICKET: ({ dispatch, commit }, payload): Promise<void> => {
    commit("SET_LOADER", true);

    return new Promise(async (resolve, reject) => {
      const resource: string = `/api/v1/clientzone/support_ticket`;
      try {
        const response = await ApiService.post(resource, payload);
        await dispatch('emailsModule/SEND_SUPPORT_CONFIRMATION_EMAIL', { email: payload.email, supportId: response.data.ticket_number }, ROOT_ACTION);
        commit("SET_LOADER", false);
        successMessage("Request sent.", 5000);

        resolve();
      } catch (e) {
        commit("SET_LOADER", false);
        reject()
      }
    })
  },
  FETCH_POPULAR_TOPICS: ({ commit }): Promise<void> => {
    return new Promise(async (resolve, reject) => {

      const resource_en: string = `${SONGOROO_WEB}/wp-json/api/v1/popular-topics/`;
      const resource_sk: string = `${SONGOROO_WEB}/wp-json/api/v1/popular-topics/?lang=sk`;

      try {
        const response_en = await AxiosService.getAxios().get(resource_en);
        const response_sk = await AxiosService.getAxios().get(resource_sk);
        commit("SET_POPULAR_TOPICS_EN", response_en.data);
        commit("SET_POPULAR_TOPICS_SK", response_sk.data);
        sessionStorage.setItem('POPULAR_TOPICS_EN', JSON.stringify(response_en.data));
        sessionStorage.setItem('POPULAR_TOPICS_SK', JSON.stringify(response_sk.data));
        sessionStorage.setItem('Support', 'true');

        resolve();
      } catch (e) {
        reject()
      }
    })
  },
  FETCH_POPULAR_SECTIONS: ({ commit }): Promise<void> => {
    return new Promise(async (resolve, reject) => {

      const resource_en: string = `${SONGOROO_WEB}/wp-json/api/v1/sections/`;
      const resource_sk: string = `${SONGOROO_WEB}/wp-json/api/v1/sections/?lang=sk`;

      try {
        const response_en = await AxiosService.getAxios().get(resource_en);
        const response_sk = await AxiosService.getAxios().get(resource_sk);
        commit("SET_POPULAR_SECTIONS_EN", response_en.data);
        commit("SET_POPULAR_SECTIONS_SK", response_sk.data);
        sessionStorage.setItem('POPULAR_SECTIONS_EN', JSON.stringify(response_en.data));
        sessionStorage.setItem('POPULAR_SECTIONS_SK', JSON.stringify(response_sk.data));
        sessionStorage.setItem('Support', 'true');

        resolve();
      } catch (e) {
        reject()
      }
    })
  },
  SEARCH_TOPICS: ({ commit }, payload): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      let resource: string = `${SONGOROO_WEB}/wp-json/api/v1/search/?s=${payload.query}`;
      if (payload.lang == 'sk') {
        resource = `${SONGOROO_WEB}/wp-json/api/v1/search/?s=${payload.query}&lang=${payload.lang}`;
      }

      try {
        const response = await AxiosService.getAxios().get(resource);
        commit("SET_SEARCH_TOPICS", Object.values(response.data));

        resolve();
      } catch (e) {
        reject()
      }
    })
  },

}
