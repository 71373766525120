import { BankAccountData, PaymentCardData, PaymentMethodAssignmentData, StripeCardData } from "@/types";
import { PaymentState } from "@/types/store/clientzone";

export const state: PaymentState = {
  paymentCards: [] as Array<PaymentCardData>,
  bankAccounts: [] as Array<BankAccountData>,
  paymentMethodAssignments: [] as Array<PaymentMethodAssignmentData>,
  stripeObject: null,
  stripeCardObject: {},
  stripeCard: {} as StripeCardData,
  registerPaymentMethod: null,
  isFiltering: false,
  isPaymentCardValid: false,
  loader: false,
  ibanError: '',
  setupIntentObject: {},
  cardPaymentMethodId: null,
  cardAddedState: false
}
