import {InvoiceData} from "@/types";
import Vue from "vue";
import {MutationTree} from "vuex";
import { BillingState } from "@/types/store/clientzone";

export const mutations: MutationTree<BillingState> = {
  SET_INVOICES: (state, payload: Array<InvoiceData>): Array<InvoiceData> => Vue.set(state, "invoices", payload),
  SET_LOADER: (state, payload): void  => {
    const index = state.invoices.findIndex(i => i.id === payload.invoiceId);
    Vue.set(state.invoices[index], "loader", payload.value);
  },
  SET_INVOICES_TOTAL_COUNT: (state, payload: number): number => Vue.set(state, "invoicesTotalCount", payload)
}
