export default {
    "Venues": "Provozovny",
    "Ratings": "{count} hodnocení | {count}  hodnocení | {count} hodnocení",
    "Edit venue": "Upravit provozovnu",
    "Venue detail": "Detail provozovny",
    "Mobile app features": "Funkce mobilní aplikace",
    "Venue information": "Informace o provozovně",
    "Time zone": "Časová zóna",
    "GPS": "GPS: zeměpisná šířka a délka",
    "Venue Rating": "Hodnocení provozovny",
    "Campaign Filter": "Filtr kampaně",
    "Choose Filter": "Zvolit filtr",
    "User ratings": "Hodnocení uživatelů",
    "Your response was sent.": "Vaša odpověď byla odeslána.",
    "Reply": "Odpovědět",
    "Filter": "Filtr",
    "All venues": "Všechny provozovny",
    "Venue name": "Název provozovny",
    "Show all venues": "Zobrazit provozovny",
    "Subscription": "Balíček služeb",
    "None": "Žádný",
    "Subscription expires on:": "Platnost balíku služeb vyprší dne:",
    "Change venue photo": "Změnit fotku provozovnu",
    "Please add a couple of sentences about this venue. This short description will be visible in Songoroo mobile app in Venue detail section.": "Přidejte pár vět o teto provozovně. Tento krátký popis bude vidět v mobilní aplikaci Songoroo v sekci detail provozovny.",
    "GPS location of your venue where visitors can find you.": "GPS poloha Vaší provozovny kde Vás uživatelé umí najít.",
    "Time zone managing the opening hours of your venue.": "Časová zóna podle které se řídí otevírací hodiny vaše provozovny.",
    "Venue Address": "Adresa provozovny",
    "Venue name cannot be empty": "Název provozovny musí být vyplněn.",
    "Back to venues list": "Zpět na seznam provozoven",
    "No active venue": "Žádný aktivní provozovna",
    "Sorry, you have no active venue for which you could setup Songoroo App features.": "Promiňte, nemáte žádnou aktivní provoz pro kterou byste mohli nastavit funkce Songoroo aplikace.",
}
