export default {
  "User access rights": "User Access Rights",
  "Add New User": "Add New User",
  "Show only active user": "Show Only Active User",
  "Job position": "Job Position",
  "Songoroo Role": "Songoroo Role",
  "Phone number": "Phone Number",
  "Venue authorization": "Venue Authorization",
  "Admin": "Admin",
  "Venue Manager": "Venue Manager",
  "Marketer": "Marketer",
  "Accountant": "Accountant",
  "Unassigned": "Unassigned",
  "Music web player": "Music Web Player",
  "Venue setup": "Venue Setup",
  "Marketing": "Marketing",
  "Pending invitation": "Pending Invitation",
  "Inactive": "Inactive",
  "Never logged in": "Never Logged In",
  "Deactivated": "Deactivated",
  "Add user": "Add User",
  "Send invitation to": "Send Invitation To",
  "Edit user permissions": "Edit User Permissions",
  "Back to Role management": "Back To Role Management",
  "Venue": "Venue",
  "ALL VENUES": "ALL VENUES",
  "User's authorisations to venues": "User's Authorisations To Venues",
  "Turn on/off music streaming": "Turn On/Off Music Streaming",
  "Edit company data": "Edit Company Data",
  "Edit venue details": "Edit Venue Details",
  "Create/Delete venue": "Create/Delete Venue",
  "Change music playlist": "Change Music Playlist",
  "Set Songoroo app features": "Set Songoroo App Features",
  "Change subscription plan": "Change Subscription Plan",
  "View and export invoices": "View And Export Invoices",
  "Change payment method": "Change Payment Method",
  "View list of users": "View List Of Users",
  "View audio campaigns": "View Audio Campaigns",
  "Add/Manage audio spots": "Add/Manage Audio Spots",
  "Create/Manage audio ad campaigns": "Create/Manage Audio Ad Campaigns",
  "Respond to customer feedback received via Songoroo app": "Respond To Customer Feedback Received Via Songoroo App",
  "Create tailored questions for customer questionnaire, and edit questionaries": "Create tailored questions for customer questionnaire, and edit questionaries",
  "Send messages to customers": "Send Messages To Customers",
  "View history of messages sent to customer": "View History Of Messages Sent To Customer",
  "Create, edit and delete users": "Create, Edit And Delete Users",
  "Active User": "Active"
}
