import ApiService from "@/services/api.service";
import {ROOT_ACTION} from "@/utils/helpers";
import {ActionTree} from "vuex";
import {GlobalState} from "@/types/store/clientzone/global";
import i18n from "@/i18n/i18n";
import { RootState } from "@/types/store/clientzone";

export const actions: ActionTree<GlobalState, RootState> = {
  FETCH_BUSINESS_TYPES: ({commit}): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      const resource: string = "/api/v1/clientzone/venue_owner/business_type?limit=1000";

      try {
        const response = await ApiService.get(resource, {
          headers: {
            'sgr-lang': i18n.locale
          }
        });

        commit("SET_BUSINESS_TYPES", response.data);

        resolve();
      } catch (e) {
        reject()
      }
    })
  },
  FETCH_TIMEZONES: ({commit}): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      const resource: string = "/api/v1/codelist/timezone?limit=1000";

      try {
        const response = await ApiService.get(resource);

        commit("SET_TIMEZONES", response.data);

        resolve();
      } catch (e) {
        reject()
      }
    })
  },
  FETCH_ENTITY_IMAGE: ({dispatch}, {id, imageType, multi = false, selectedUser = false, song = false, found = false, venueQuestionaryImage = false, audioSpotImage = false}): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      const resource: string = `api/v2/image/${imageType}/${id}`;

      try {
        const response = await ApiService.get(resource, {
          responseType: 'arraybuffer'
        })

        const encodeImage = Buffer.from(response.data, 'binary').toString('base64');

        if (imageType === "clientzone_venue") {
          if (!multi) dispatch("venueModule/SET_VENUE_IMAGE", encodeImage, ROOT_ACTION);
          else dispatch("venueModule/SET_VENUE_IMAGE", { id, encodeImage }, ROOT_ACTION);
        }

        if (imageType === "venue_owner") {
          if (!multi) dispatch("companyModule/SET_COMPANY_IMAGE", encodeImage, ROOT_ACTION);
          else dispatch("companyModule/SET_COMPANY_IMAGE", { id, encodeImage }, ROOT_ACTION);
        }

        if (imageType === "clientzone_user" && venueQuestionaryImage && multi) {
          dispatch("venueQuestionaryModule/SET_QUESTIONARY_USER_IMAGE", { id, encodeImage }, ROOT_ACTION)
        }

        if (imageType === "clientzone_user" && audioSpotImage && multi) {
          dispatch("audioCommercialsModule/SET_AUDIO_SPOT_USER_IMAGE", { id, encodeImage }, ROOT_ACTION)
        }

        if (imageType === "clientzone_user") {
          if (!multi) dispatch("userModule/SET_USER_IMAGE", encodeImage, ROOT_ACTION);
          else dispatch("userModule/SET_USER_IMAGE", { id, encodeImage }, ROOT_ACTION);
          if (selectedUser) dispatch("userModule/SET_SELECTED_USER_IMAGE", encodeImage, ROOT_ACTION);
        }

        if (imageType === "artist") {
          if (multi) {
            if (song) {
              if (found) dispatch("venueAppFeaturesModule/SET_FOUND_SONG_IMAGE", { id, encodeImage }, ROOT_ACTION);
              else dispatch("venueAppFeaturesModule/SET_SONG_IMAGE", { id, encodeImage }, ROOT_ACTION);
            } else {
              if (found) dispatch("venueAppFeaturesModule/SET_FOUND_ARTIST_IMAGE", { id, encodeImage }, ROOT_ACTION);
              else dispatch("venueAppFeaturesModule/SET_ARTIST_IMAGE", { id, encodeImage }, ROOT_ACTION);
            }
          }
        }

        if (imageType === "venue_type") {
          if (!multi) dispatch("playlistModule/SET_PLAYLIST_IMAGE", encodeImage, ROOT_ACTION);
          else dispatch("playlistModule/SET_PLAYLIST_IMAGE", { id, encodeImage }, ROOT_ACTION);
        }

        if (imageType === "genre") {
          if (!multi) dispatch("playlistModule/SET_GENRE_IMAGE", encodeImage, ROOT_ACTION);
          else dispatch("playlistModule/SET_GENRE_IMAGE", { id, encodeImage }, ROOT_ACTION);
        }

        if (imageType === "user") {
          dispatch("venueModule/SET_RATING_USER_IMAGE", { id, encodeImage }, ROOT_ACTION);
        }

        resolve();
      } catch (e) {
        reject()
      }
    })
  },
  FETCH_STREAM: ({dispatch}, {id, streamType, campaignId = null}): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      const resource: string = `api/v2/stream/${streamType}/${id}`;

      try {
        const response = await ApiService.get(resource, {
          responseType: 'arraybuffer'
        })

        const encodeFile = btoa([].reduce.call(new Uint8Array(response.data),function(p, c){return p + String.fromCharCode(c)}, ''));

        if (streamType === "spot") {
          if (campaignId) dispatch("audioCommercialsModule/SET_AUDIO_SPOT_FILE", { id, encodeFile, campaignId }, ROOT_ACTION);
          else dispatch("audioCommercialsModule/SET_AUDIO_SPOT_FILE", { id, encodeFile }, ROOT_ACTION);
        }

        resolve();
      } catch (e) {
        reject()
      }
    })
  },
  GET_SPOT_FILE: ({}, {id, streamType, campaignId = null}): Promise<Object> => {
    return new Promise(async (resolve, reject) => {
      const resource: string = `api/v2/stream/${streamType}/${id}`;

      try {
        const response = await ApiService.get(resource, {
          responseType: 'arraybuffer'
        })

        const encodeFile = btoa([].reduce.call(new Uint8Array(response.data),function(p, c){return p + String.fromCharCode(c)}, ''));

        if (streamType === "spot") {
          if (campaignId) {
            resolve({ id, encodeFile, campaignId });
          } else {
            resolve({ id, encodeFile });
          }
        }
      } catch (e) {
        reject()
      }
    })
  },
}
