





























import Vue, {PropType} from "vue";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import { VenueData, VenueSubscriptionData } from "@/types";

export default Vue.extend({
  name: "webstreamer-custom-select" as string,

  components: {
    vSelect,
  },
  props: {
    onPlay: {
      type: Function as PropType<Function>,
      required: true,
    },
    isDisabled: {
      type: Boolean as PropType<Boolean>,
    }
  },
  data() {
    return {
      attributes: {
        ref: "openIndicator",
        role: "presentation",
        class: "vs__open-indicator",
      },
    };
  },
  async created() {
    await this.setVenues({ fetchRatings: false, name: "" });
    await this.setVenuesSubscription();
  },
  updated() {
    let selectRef = this.$refs.selectRef;
    let filteredVenues: Array<VenueData> = this.filteredVenuesWithActiveSubscription;

    if (this.selectedWebstreamVenue != null) {
      let actualVenue: VenueData | null = filteredVenues.find(v => v.id === this.selectedWebstreamVenue.id);

      if (actualVenue != null && actualVenue.name != this.selectedWebstreamVenue.label) {
        this.setVenue({id: actualVenue.id, label: actualVenue.name});
      }
    }

    if (selectRef && this.isCorporate === false) {
      if (localStorage.getItem('init_play') === null && filteredVenues.length === 1 && this.isDisabled === false) {
        setTimeout(() => {
          this.setVenue({id: filteredVenues[0].id, label: filteredVenues[0].name});
          this.onPlay();

          localStorage.setItem('init_play', 'true');
        }, 2000);
      }
    }
  },
  computed: {
    ...mapGetters("venueModule", {
      venues: "GET_VENUES",
      selectedWebstreamVenue: "GET_SELECTED_WEBSTREAM_VENUE",
    }),
    ...mapGetters("venueSubscriptionModule", {
      venuesSubscription: "GET_VENUES_SUBSCRIPTION",
    }),
    ...mapGetters("companyModule", {
      companyId: "GET_COMPANY_ID",
      isCorporate: "GET_IS_CORPORATE",
    }),
    ...mapGetters("browserStreamerModule", {
      infoWebStream: "GET_INFO_WEBSTREAM_MODAL",
    }),
    filteredVenuesWithActiveSubscription(): Array<VenueData> {
      const activeVenues = [];
      if (!this.venuesSubscription.length) return;
      const nowTimestamp = moment().unix();
      this.venues.forEach((v: VenueData) => {
        const venueSubscription: VenueSubscriptionData =
          this.venuesSubscription.find(s => s.venueId === v.id);
        if (venueSubscription) {
          if (
            Number(venueSubscription.subscriptionEndsAt) > nowTimestamp &&
            venueSubscription.subscriptionStatus != "canceled" &&
            venueSubscription.subscriptionStatus != "incomplete" &&
            venueSubscription.subscriptionStatus != "incomplete_expired"
          )
            activeVenues.push(v);
        }
      });
      return activeVenues;
    },
  },

  methods: {
    ...mapActions("venueModule", {
      setVenues: "FETCH_VENUES",
      setSelectedWebstreamVenue: "SET_SELECTED_WEBSTREAM_VENUE",
    }),
    ...mapActions("venueSubscriptionModule", {
      setVenuesSubscription: "FETCH_VENUES_SUBSCRIPTION",
    }),
    setVenue(venue): void {
      this.setSelectedWebstreamVenue(venue);
      this.$emit("change", venue.id);
      this.$emit("name", venue.label);
    },
  },

  watch: {
    companyId: {
      async handler(newValue: number, oldValue: number): Promise<void> {
        if (newValue != oldValue) {
          await this.setVenues({ fetchRatings: false, name: "" });
          await this.setVenuesSubscription();
          this.setSelectedWebstreamVenue(null);
          this.$emit("change", null);
        }
      },
    },
    infoWebStream: {
      immediate: true,
      deep: true,
      handler(value) {
        if (value) {
          this.setSelectedWebstreamVenue(null);
        }
      },
    },
  },
});
