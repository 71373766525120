import { GetterTree } from "vuex";
import { AdvertCampaignData } from "@/types";
import { AudioSpotData } from "@/types/audio_commercials";
import { AudioCommercialsState, RootState } from "@/types/store/clientzone";

export const getters: GetterTree<AudioCommercialsState, RootState> = {
  GET_AUDIO_SPOTS: (state): Array<AudioSpotData> => state.audioSpots,
  GET_ADVERT_CAMPAIGNS: (state): Array<AdvertCampaignData> => state.advertCampaigns,
  GET_PERMISSION_AVAILABLE_ADVERT_CAMPAIGNS: (state): Array<AdvertCampaignData> => [...state.permissionAvailableAdvertCampaigns].filter((advertCampaigns) => advertCampaigns.venues.length),
  GET_ALL_ADVERT_CAMPAIGNS: (state): Array<AdvertCampaignData> => state.advertCampaigns,
  GET_ADVERT_SPOT_AVAILABLE_VENUES: (state): Array<AdvertCampaignData> => state.advertSpotAvailableVenues,
  GET_AUDIO_SPOT_LOADER: (state): boolean => state.audioSpotLoader,
  GET_FILTER: (state): boolean => state.filterActiveCampaigns
}
