export default {
  "Songoroo app features": "Songoroo App Features",
  "Description": "Our mobile app and many of its functionalities have been developed with the intention to offer interactivity to its users and allow them influencing music playlists in multiple ways. And when they do, we believe they are helping you to create and develop your brand into something unique. It´s up to you if you want to grant your customers full freedom to contribute to the creation of music vibe for your business or if and how much you want to limit these options.",
  "Disable explicit content": "Disable Explicit Content",
  "Disable explicit content description": "Artists have different ways how they express themselves. But sometimes you might find it simply too much for your business and we understand that. Therefore, we give you the option to ban songs using language which you deem inappropriate for your business.",
  "Voting": "Voting",
  "Voting description": "This function is quite self-explanatory. As you can guess, taping the “up” arrow will send your song to higher positions on the playlist. The more users like the same song, the higher it gets. “Down” arrow works the same way just the opposite direction.",
  "Add song": "Add Song",
  "Add song description": "All pre-defined and manually selected playlists consist of songs which are according to both, you as the owner and Songoroo, the best fit for the business you are running. Your customers, however, might be of a different opinion. If they are in the mood to listen to a particular song, they can search our database and add songs they like. Our Premium subscribers have even the option to set limits for this functionality, i.e. how many songs could be added by customers. Another option how to control what is added to the playlist is using the Limit Playlist Adjustment function.",
  "Boost": "Boost",
  "Boost description": "Boost allows your customers to shoot selected song to the top position in the playlist. This feature is frequently used in combination with Add Song functionality. With that your customers have the option to choose their music from our database, add it to the playlist and Boost it up so the time until they hear their song playing is minimal. By default, every user is allowed to have only one active Boost. Premium subscribers are able to customise this limitation.",
  "Song for you": "Song For You",
  "Song for you description": "We developed this communication functionality to allow your customers to dedicate songs with attached messages to each other. This interactive option keeps people busy and as a result they spend more time (and money) in your establishment. By default, every user is allowed to have only one active Boost. Premium subscribers are able to customise this limitation.",
  "Playlist modification via Songoroo app": "Playlist Modification Via Songoroo App",
  "Playlist modification via Songoroo app description": "Limit the possibility to add songs to the running playlist of this venue to songs falling into the scope of selected playlist. In other words, your customers will not be able to add any song (via Songoroo app) into the playlist that does not fit the given criteria, i.e. playlist type assigned.",
  "Censor song": "Censor Song",
  "Censor artist": "Censor Artist",
  "Search results": "Search Results",
  "Add back to playlist": "Add Back To Playlist",
  "Set as banned": "Set As Banned",
  "Censored songs": "Censored Songs",
  "Censored artists": "Censored Artists",
  "No censored songs": "No Censored Songs",
  "No censored artists": "No Censored Artists",
  "Back to venue detail": "Back To Venue Detail"
}
