import { ActionTree } from "vuex";
import { RootState, SubscriptionDescriptionAdminzoneState } from "@/types/store/adminzone";
import { CASSOVIA_API_RESOURCE, ROOT_ACTION } from "@/utils/helpers";
import { successMessage } from "@/utils/messages-handlers";
import AxiosService from "@/services/axios.service";

export const actions: ActionTree<SubscriptionDescriptionAdminzoneState, RootState> = {
  FETCH_SUBSCRIPTION_DESCRIPTIONS:({commit, dispatch}): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      const resource: string = `${CASSOVIA_API_RESOURCE}/api/subscription-descriptions?page=1`;
      try{
        const response = await AxiosService.getAxios().get(resource)
        commit("SET_SUBSCRIPTION_DESCRIPTIONS", response.data)
        resolve()
      }catch (e){
        reject()
      }
    })
  },

  CREATE_SUBSCRIPTION_DESCRIPTION:({commit, dispatch}, payload): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      const resource: string = `${CASSOVIA_API_RESOURCE}/api/subscription-descriptions`;
      try {
        await AxiosService.getAxios().post(resource, payload)
        successMessage("Subscription description created successfully", 3000);
        resolve();
      } catch (e){
        reject()
      }
    })
  },
  ATTACH_SUBSCRIPTION_DESCRIPITON:({commit, dispatch}, payload): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      const resource: string = `${CASSOVIA_API_RESOURCE}/api/attach-to-stripe-product-with-price/${payload.descriptionId}/${payload.songorooPackageType}`
      try{
        await AxiosService.getAxios().post(resource, payload)
        successMessage("Description successfully attached to subscription plan.", 3000);
        await dispatch("azProductsModule/FETCH_PRODUCTS", null ,ROOT_ACTION)
        resolve();
      }catch (e){
        reject()
      }
    })
  },
  DETACH_SUBSCRIPTION_DESCRIPTION:({commit, dispatch}, payload): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      const resource: string = `${CASSOVIA_API_RESOURCE}/api/detach-from-stripe-product-with-price/${payload.descriptionId}/${payload.songorooPackageType}`
      try{
        await AxiosService.getAxios().post(resource, payload)
        successMessage("Description successfully detached from subscrition plan.", 3000);
        await dispatch("azProductsModule/FETCH_PRODUCTS", null ,ROOT_ACTION)
        resolve();
      }catch (e){
        reject()
      }
    })
  },
  SOFT_DELETE_SUBSCRIPTION_DESCRIPTION:({commit, dispatch}, {resourceId: resourceId, payload: payload}): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      const resource: string = `${CASSOVIA_API_RESOURCE}/api/subscription-descriptions/${resourceId}`
      try{
        await AxiosService.getAxios().patch(resource, payload, {
          headers: {
            'Content-Type': 'application/merge-patch+json'
          }
        });
        successMessage("Description deleted successfully", 3000);
        resolve()

      }catch(e){
        reject()
      }
    })
  },
  UPDATE_SUBSCRIPTION_DESCRIPTION({commit, dispatch}, payload){
    return new Promise(async (resolve, reject) => {
      try{
        for (const subscriptionDescripiton of payload) {
          const updateSubscripitonDescriptionPayload = {
            'descriptionEn': subscriptionDescripiton.descriptionEn
          }
          const resource: string = `${CASSOVIA_API_RESOURCE}/api/subscription-descriptions/${subscriptionDescripiton.id}`
          await AxiosService.getAxios().patch(resource, updateSubscripitonDescriptionPayload, {
            headers: {
              'Content-Type': 'application/merge-patch+json'
            }
          })
        }
        successMessage("Descriptions updated", 3000);
        resolve({});
      } catch (e) {
        reject()
      }
    })
  },
  UPDATE_SUBSCRIPTION_DESCRIPTION_TRANSLATION({commit, dispatch}, {descriptionId: descriptionId, payload: payload}){
    return new Promise(async (resolve, reject) => {
      const resource: string = `${CASSOVIA_API_RESOURCE}/api/subscription-descriptions/${descriptionId}`
      try {
        await AxiosService.getAxios().patch(resource, payload, {
          headers: {
            'Content-Type': 'application/merge-patch+json'
          }
        })
        resolve({})
      } catch (e) {
        reject()
      }
    })
  }


}
