export default {
    "Opening hours": "Otevírací hodiny",
    "Opening time": "Otevírací doba",
    "Closing time": "Uzavírací doba",
    "Closed": "Uzavřeno",
    "Monday": "Pondělí",
    "Tuesday": "Úterý",
    "Wednesday": "Středa",
    "Thursday": "Čtvrtek",
    "Friday": "Pátek",
    "Saturday": "Sobota",
    "Sunday": "Neděle",
}
