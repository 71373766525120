export default {
  "Votes on Songs": "Počet hlasov v skladbách",
  "Active users": "Aktívni používatelia",
  "Boosts used": "Použitý boost",
  "Songs added": "Pridané skladby",
  "Songs sent to others": "Skladby odoslané ostatným",
  "Songs played": "Odohrané skladby",
  "Voted songs": "Hlasované skladby",
  "Artists": "Interpreti",
  "add song": "Pridané skladby",
  "Song boost": "Skladby s boostom",
  "Most Frequent Genres": "Najbežnejšie hrané žánre",
  "Song title": "Názov skladby",
  "Artist name": "Meno interpreta",
  "Count of votes": "Počet hlasov",
}
