export default {
    "Invoice number": "Čislo faktury",
    "Subscription period": "Zúčtovací období",
    "Issue date": "Datum vydání",
    "Payment method": "Platební metóda",
    "Amount": "Suma",
    "Due Date": "Datum splatnosti",
    "Status": "Stav",
    "Export": "Export",
    "Paid": "Zaplacené",
    "Past due": "V opoždění",
    "Not due": "Nezaplacené",
    "Credit invoice": "Dobropis",
    "Credit/Debit card": "Kreditní/debetní karta",
    "Invoice": "Faktura",
    "Venue Name": "Název provozovny",
    "Address": "Adresa",
    "Detail": "Detail",
    "Subscription details": "Detail vybraného balíčku služeb",
    "Change Subscription Plan": "Změna balíčku služeb",
    "Cancel Subscription Plan": "Zrušit službu pro tuto provozovnu",
    "Expiration date": "Datum ukončení",
    "Duration date": "Datum trvání",
    "Learn more": "Bližší informace",
    "Renew Subscription Plan": "Obnovení balíčku služeb",
    "Audio commercials and messages": "Audio reklama a oznámení",
    "Songoroo equips you with a tool enabling the administration of the audio messages in your business at no extra costs. You can easily upload your own messages and commercials and set rules where and when these should be played.": "Songoroo poskytuje nástroj, který vám umožní vytvářet audio reklamní kampaně ve vašich provozovnách bez dalších nákladů. Můžete snadno nahrát vlastní hlášky nebo reklamní sdělení a nastavit pravidla, kde a kdy se mají tato oznámení přehrávat.",
    "Order": "Objednat",
}  
