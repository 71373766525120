export default {
  "Opening hours": "Otváracie hodiny",
  "Opening time": "Otvárací čas",
  "Closing time": "Zatvárací čas",
  "Closed": "Zatvorené",
  "Monday": "Pondelok",
  "Tuesday": "Utorok",
  "Wednesday": "Streda",
  "Thursday": "Štvrtok",
  "Friday": "Piatok",
  "Saturday": "Sobota",
  "Sunday": "Nedeľa",
}
