import { state } from "@/state/modules/clientzone/playlist/state";
import { mutations } from "@/state/modules/clientzone/playlist/mutations";
import { getters } from "@/state/modules/clientzone/playlist/getters";
import { actions } from "@/state/modules/clientzone/playlist/actions";

const playlistModule = {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}

export default playlistModule
