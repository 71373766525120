import Vue from "vue";
import { MutationTree } from "vuex";
import { CZUserNotificationData, NotificationData } from "@/types";
import { NotificationsState } from "@/types/store/clientzone";

export const mutations: MutationTree<NotificationsState> = {
  SET_CZ_USER_NOTIFICATIONS: (state, payload: Array<CZUserNotificationData>): Array<CZUserNotificationData> => Vue.set(state, "czUserNotifications", payload),
  SET_NOTIFICATIONS: (state, payload: NotificationData): number => state.notifications.push(payload),
  RESET_NOTIFICATIONS_STATE: (state, payload: Array<any>): Array<any> => Vue.set(state, "notifications", payload),
  SET_NOTIFICATION_AS_INSPECTED: (state, payload: number): void => {
    const notificationIndex: number = state.notifications.findIndex(n => n.clientZoneNotificationInstanceId === payload);

    Vue.set(state.notifications[notificationIndex], "wasInspected", true);
  },
  SET_UNCHECKED_NOTIFICATIONS_LENGTH: (state, payload: number): number => Vue.set(state, 'uncheckedNotificationsLength', payload)
}
