





















































import Vue, { PropType } from "vue";
import WebstreamerCustomSelect from "@/components/WebstreamerCustomSelect.vue";
import LottieAnimationEqualizer from "@/components/animation/LottieAnimationEqualizer.vue";
import { mapActions, mapGetters } from "vuex";
import { errorMessage } from "@/utils/messages-handlers";
import NoSleep from "nosleep.js";
import { VenueSubscriptionData } from "@/types";

export default Vue.extend({
  name: "browser-streamer" as string,

  components: {
    "webstreamer-custom-select": WebstreamerCustomSelect,
    "lottie-animation-equalizer": LottieAnimationEqualizer
  },
  props: {
    isStreamerVisible: Boolean as PropType<boolean>,
    hasPermission: Boolean as PropType<boolean>,
    isTaken: Boolean as PropType<boolean>,
  },

  data() {
    return {
      audio: null as HTMLAudioElement,
      selectedVenueId: null,
      selectedVenueName: "",
      lastVenueId: null,
      isSongPlaying: false,
      newVenueSelected: false,
      loader: false,
      force: false,
      noSleep: new NoSleep(),
      isFetchingSong: false,
      initLoading: false,
      isDisabled: false,
    };
  },

  async mounted(): Promise<void> {
    this.audio = this.$refs.webstreamerPlayer;
    window.addEventListener(
      "beforeunload",
      this.checkIfWebStreamAlreadyPlaying
    );
  },

  async created() {
    await this.setVenuesSubscription();
  },

  updated() {
    let playing = localStorage.getItem("isPlaying");

    if (playing == 'false' && this.isSongPlaying === true) {
      this.isSongPlaying = false;
    }
  },

  computed: {
    ...mapGetters("browserStreamerModule", {
      playlistSong: "GET_PLAYLIST_SONG",
      stopLoader: "GET_STOP_PLAYER",
      tookWebStream: "GET_TOOK_WEBSTREAM_MODAL",
      infoWebStream: "GET_INFO_WEBSTREAM_MODAL",
    }),
    ...mapGetters("companyModule", {
      companyId: "GET_COMPANY_ID",
    }),
    ...mapGetters("companyModule", {
      isCorporate: "GET_IS_CORPORATE",
    }),
    ...mapGetters("venueSubscriptionModule", {
      venuesSubscription: "GET_VENUES_SUBSCRIPTION",
    }),
    ...mapGetters("venueModule", {
      selectedWebstreamVenue: "GET_SELECTED_WEBSTREAM_VENUE",
    }),
    playlistSongSrc(): string {
      if (!this.playlistSong.url) return "";
      return this.playlistSong.url + "#t=" + this.getOpeningTime;
    },
    buttonImgSrc(): string {
      return this.isSongPlaying
        ? require("@/assets/images/pause-button-blue.svg")
        : require("@/assets/images/play-button-blue.svg");
    },
    getOpeningTime(): number {
      var time = this.playlistSong.opening;
      if (!time) {
        return 0;
      } else {
        var timeArr = time.split(":");
        return (
          Number(timeArr[0]) * 360 +
          Number(timeArr[1]) * 60 +
          Number(timeArr[2])
        );
      }
    },
    getEndingTime(): number {
      var time = this.playlistSong.ending;
      if (!time) {
        return 0;
      } else {
        var timeArr = time.split(":");
        return (
          Number(timeArr[0]) * 360 +
          Number(timeArr[1]) * 60 +
          Number(timeArr[2])
        );
      }
    },
    isEndedTrial() {
      let isEndedTrial: boolean = false;

      this.venuesSubscription.map((venueSubscription: VenueSubscriptionData) => {
        let currentTimestamp = Math.floor(Date.now() / 1000) as number;
        let subscriptionEndTimestamp = parseInt(venueSubscription.subscriptionEndsAt) as number;

        if (venueSubscription.isTrial === true && currentTimestamp > subscriptionEndTimestamp) {
          isEndedTrial = true;
        }
      });

      return isEndedTrial;
    }
  },

  methods: {
    ...mapActions("browserStreamerModule", {
      setPlaylistSong: "FETCH_PLAYLIST_SONG_",
      forcePlaylistSong: "FORCE_PLAYLIST_SONG_",
      stopPlaylistSong: "STOP_PLAYLIST_SONG",
    }),
    ...mapActions("venueSubscriptionModule", {
      setVenuesSubscription: "FETCH_VENUES_SUBSCRIPTION",
    }),
    async onEnded(): Promise<void> {
      await this.setPlaylistSong({ id: this.selectedVenueId, autoplay: true });
    },
    onLoadStart(): void {
      if (this.selectedVenueId && this.initLoading === false) {
        this.initLoading = true;
        this.loader = true;
      }
    },
    checkIfWebStreamAlreadyPlaying() {
      if (localStorage.getItem("isPlaying") == "true" && this.isSongPlaying) {
        localStorage.setItem("isPlaying", "false");
      }
    },
    onCanplay(): void {
      this.loader = false;
      let playing = localStorage.getItem("isPlaying");
      if (playing == 'true') {
        this.playSong(this.audio);
      }
    },
    onAbort(): void {
      this.loader = false;
    },
    async setVenue(value: number): Promise<void> {
      this.selectedVenueId = value;
    },
    async setVenueName(value: number): Promise<void> {
      this.selectedVenueName = value;
    },
    async playPlaylistSong(): Promise<void> {
      let playing = localStorage.getItem("isPlaying");
      if (this.loader || this.isDisabled || this.isCorporate) {
        return;
      }
      if (playing == "true" && !this.isSongPlaying) {
        return errorMessage("Stream is already playing on this device", 5000);
      }
      const player: HTMLAudioElement = this.$refs.webstreamerPlayer;

      if (!this.selectedVenueId) {
        errorMessage("No venue selected.", 5000);
        return;
      }

      if (player.paused) {
        if (!this.playlistSongSrc || this.force) {
          await this.setPlaylistSong({
            id: this.selectedVenueId,
            autoplay: false,
          });
        } else {
          if (this.newVenueSelected) {
            await this.setPlaylistSong({
              id: this.selectedVenueId,
              autoplay: false,
            });
            this.newVenueSelected = false;
          } else {
            //player.play();
            this.playSong(player);
          }
          //player.play();
          this.playSong(player);
        }

        this.isSongPlaying = true;
      } else {
        player.pause();
        this.isSongPlaying = false;
      }
    },
    playSong(player: HTMLAudioElement): void {
      player.play();
      this.loader = false;
    },
    async onTimeUpdate(): Promise<void> {
      var endTime = this.getEndingTime || this.audio.duration;

      if (this.audio.currentTime > endTime - 2 && this.isFetchingSong === false) {
        this.isFetchingSong = true;

        await this.setPlaylistSong({
          id: this.selectedVenueId,
          autoplay: true,
        });
        this.isFetchingSong = false;
      }
    },
  },

  watch: {
    selectedVenueId: {
      handler(newValue: number, oldValue: number): void {
        if (oldValue) {
          this.lastVenueId = oldValue;
          if (oldValue !== newValue) {
            this.newVenueSelected = true;
            const player: HTMLAudioElement = this.$refs.webstreamerPlayer;
            player.pause();
            this.isSongPlaying = false;
          }
        }
      },
    },
    isSongPlaying: {
      handler(value) {
        if (value) {
          this.lastVenueId = this.selectedVenueId;
          localStorage.setItem("isPlaying", "true");
          this.noSleep.enable();
        }
        if (!value) {
          this.stopPlaylistSong(this.lastVenueId);
          localStorage.setItem("isPlaying", "false");
          this.noSleep.disable();
        }
      },
    },
    stopLoader: {
      handler(value) {
        if (value) {
          this.isSongPlaying = false;
        }
      },
    },
    isTaken: {
      handler(value) {
        if (value) {
          this.isSongPlaying = true;
        } else if (!value) {
          this.isSongPlaying = false;
        }
      },
    },
    tookWebStream: {
      immediate: true,
      deep: true,
      handler(value) {
        if (value) {
          this.$emit("tookStream", this.selectedVenueId);
        } else {
          this.$emit("tookStream", false);
        }
      },
    },
    infoWebStream: {
      immediate: true,
      deep: true,
      handler(value) {
        if (value) {
          this.$emit("infoStream", this.selectedVenueName);
          this.force = true;
          this.selectedVenueId = null;
        } else {
          this.$emit("infoStream", false);
        }
      },
    },
    companyId: {
      immediate: true,
      handler() {
        if (this.isStreamerVisible && this.hasPermission && this.lastVenueId) {
          this.stopPlaylistSong(this.lastVenueId);
        }
      },
    },
    venuesSubscription: {
      immediate: true,
      handler(_) {
        this.isDisabled = this.isEndedTrial;
      },
    },
  },
  beforeDestroy() {
    this.stopPlaylistSong(this.lastVenueId);
  },
});
