import { SubscriptionProductsData, TaxRateData, VenueSubscriptionData, DiscountCode } from "@/types";
import { GetterTree } from "vuex";
import { RootState, VenueSubscriptionState } from "@/types/store/clientzone";

export const getters: GetterTree<VenueSubscriptionState, RootState> = {
  GET_SELECTED_RECURRING_INTERVAL: (state): string => state.selectedRecurringInterval,
  GET_ALL_SUBSCRIPTION_PRODUCTS: (state): Array<SubscriptionProductsData> => state.subscriptionProducts,
  GET_FILTERED_SUBSCRIPTION_PRODUCTS: (state, getters): Array<SubscriptionProductsData> => {
    const selectedInterval: string = getters["GET_SELECTED_RECURRING_INTERVAL"];

    return state.subscriptionProducts.filter(s => s.recurringInterval === selectedInterval);
  },
  GET_PRODUCT_BASIC_MONTH: (state): Array<SubscriptionProductsData> => [...state.subscriptionProducts].filter(product => product.recurringInterval == 'month' && product.songorooServiceType == 1 && product.songorooPackageType == 1),
  GET_PRODUCT_PREMIUM_MONTH: (state): Array<SubscriptionProductsData> => [...state.subscriptionProducts].filter(product => product.recurringInterval == 'month' && product.songorooServiceType == 1 && product.songorooPackageType == 2),
  GET_TAX_RATE: (state): TaxRateData => state.taxRate,
  GET_VENUES_SUBSCRIPTION: (state): Array<VenueSubscriptionData> => state.venuesSubscription,
  GET_SELECTED_VENUE_SUBSCRIPTION: (state): VenueSubscriptionData => state.selectedVenueSubscription,
  GET_RECURRING_INTERVAL: (state) => state.selectedRecurringInterval,
  GET_LOADER: (state): boolean => state.loader,
  GET_DISCOUNT_CODE: (state): DiscountCode => state.discountCode,
  GET_INVALID_DISCOUNT_CODE: (state): boolean => state.invalidDiscountCode,
  GET_ERROR_DETAIL: (state): string => state.errorDetail,
  GET_STRIPE_SUBSCRIPTION_RESPONSE: (state): object => state.stripeSubscriptionResponse
}
