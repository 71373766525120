import { ArtistData, SongData, VenueAppFeaturesData, VenueData } from "@/types";
import { VenueAppFeaturesState } from "@/types/store/clientzone";

export const state: VenueAppFeaturesState = {
  selectedVenue: {} as VenueData,
  // venueFeatures: [] as Array<VenueAppFeaturesData>,
  // venueTypeFeatures: [] as Array<VenueAppFeaturesData>,
  // venueOwnerFeatures: [] as Array<VenueAppFeaturesData>,
  venueFinalFeatures: [] as Array<VenueAppFeaturesData>,
  foundSongs: [] as Array<SongData>,
  foundArtists: [] as Array<ArtistData>,
  censoredSongs: [] as Array<SongData>,
  censoredArtists: [] as Array<ArtistData>
}
