import GeneralSK from './general/sk';
import GeneralEN from './general/en';
import GeneralCS from './general/cs';
import DashboardSK from './dashboard/sk';
import DashboardEN from './dashboard/en';
import DashboardCS from './dashboard/cs';
import NavigationSK from './navigation/sk';
import NavigationEN from './navigation/en';
import NavigationCS from './navigation/cs';
import CompanyDataSK from './company-data/sk';
import CompanyDataEN from './company-data/en';
import CompanyDataCS from './company-data/cs';
import VenuesSK from './venues/sk';
import VenuesEN from './venues/en';
import VenuesCS from './venues/cs';
import OpeningHoursSK from './opening-hours/sk';
import OpeningHoursEN from './opening-hours/en';
import OpeningHoursCS from './opening-hours/cs';
import PlaylistSK from './playlist/sk';
import PlaylistEN from './playlist/en';
import PlaylistCS from './playlist/cs';
import AppFeaturesSK from './app-features/sk';
import AppFeaturesEN from './app-features/en';
import AppFeaturesCS from './app-features/cs';
import SubscriptionAndBillingSK from './subscription-billing/sk';
import SubscriptionAndBillingEN from './subscription-billing/en';
import SubscriptionAndBillingCS from './subscription-billing/cs';
import PaymentMethodsSK from './payment-methods/sk';
import PaymentMethodsEN from './payment-methods/en';
import PaymentMethodsCS from './payment-methods/cs';
import UserManagementSK from './user-managment/sk';
import UserManagementEN from './user-managment/en';
import UserManagementCS from './user-managment/cs';
import CommunicationWithCustomersSK from './communication-with-customers/sk';
import CommunicationWithCustomersEN from './communication-with-customers/en';
import CommunicationWithCustomersCS from './communication-with-customers/cs';
import HelpAndSupportSK from './help-and-support/sk';
import HelpAndSupportEN from './help-and-support/en';
import HelpAndSupportCS from './help-and-support/cs';
import SubscriptionPlanSK from './subscription-plan/sk';
import SubscriptionPlanEN from './subscription-plan/en';
import SubscriptionPlanCS from './subscription-plan/cs';
import ValidationsSK from './validations/sk';
import ValidationsEN from './validations/en';
import ValidationsCS from './validations/cs';
import TooltipsSK from './tooltips/sk';
import TooltipsEN from './tooltips/en';
import TooltipsCS from './tooltips/cs';
import RegistrationSK from './registration/sk';
import RegistrationEN from './registration/en';
import RegistrationCS from './registration/cs';
import FooterSK from './footer/sk';
import FooterEN from './footer/en';
import FooterCS from './footer/cs';
import SuccessErrorMessageSK from './success-error-message/sk';
import SuccessErrorMessageEN from './success-error-message/en';
import SuccessErrorMessageCS from './success-error-message/cs';
import AdminzoneTranslationsSK from './adminzone/sk';
import AdminzoneTranslationsEN from './adminzone/en';
import AdminzoneTranslationsCS from './adminzone/cs';


export default {
  "sk": {
    ...(GeneralSK),
    ...(DashboardSK),
    ...(NavigationSK),
    ...(CompanyDataSK),
    ...(VenuesSK),
    ...(OpeningHoursSK),
    ...(PlaylistSK),
    ...(AppFeaturesSK),
    ...(SubscriptionAndBillingSK),
    ...(PaymentMethodsSK),
    ...(UserManagementSK),
    ...(CommunicationWithCustomersSK),
    ...(HelpAndSupportSK),
    ...(SubscriptionPlanSK),
    ...(ValidationsSK),
    ...(TooltipsSK),
    ...(RegistrationSK),
    ...(FooterSK),
    ...(SuccessErrorMessageSK),
    ...(AdminzoneTranslationsSK),

  },
  "en": {
    ...(GeneralEN),
    ...(DashboardEN),
    ...(NavigationEN),
    ...(CompanyDataEN),
    ...(VenuesEN),
    ...(OpeningHoursEN),
    ...(PlaylistEN),
    ...(AppFeaturesEN),
    ...(SubscriptionAndBillingEN),
    ...(PaymentMethodsEN),
    ...(UserManagementEN),
    ...(CommunicationWithCustomersEN),
    ...(HelpAndSupportEN),
    ...(SubscriptionPlanEN),
    ...(ValidationsEN),
    ...(TooltipsEN),
    ...(RegistrationEN),
    ...(FooterEN),
    ...(SuccessErrorMessageEN),
    ...(AdminzoneTranslationsEN),
  },
  "cs": {
    ...(GeneralCS),
    ...(DashboardCS),
    ...(NavigationCS),
    ...(CompanyDataCS),
    ...(VenuesCS),
    ...(OpeningHoursCS),
    ...(PlaylistCS),
    ...(AppFeaturesCS),
    ...(SubscriptionAndBillingCS),
    ...(PaymentMethodsCS),
    ...(UserManagementCS),
    ...(CommunicationWithCustomersCS),
    ...(HelpAndSupportCS),
    ...(SubscriptionPlanCS),
    ...(ValidationsCS),
    ...(TooltipsCS),
    ...(RegistrationCS),
    ...(FooterCS),
    ...(SuccessErrorMessageCS),
    ...(AdminzoneTranslationsCS),
  },
}
