export default {
  "Email is required.": "Email je povinný.",
  "Please enter a valid email address.": "Prosím zadajte platnú emailovú adresu.",
  "Please enter a valid VAT ID.": "Prosím zadajte platné IČ DPH.",
  "Please enter a valid swift code.": "Prosím zadajte platný swift kód.",
  "Please enter a valid IBAN.": "Prosím zadajte platný IBAN.",
  "First name is required.": "Krstné meno je povinné.",
  "Last name is required.": "Priezvisko je povinné.",
  "Job position is required.": "Pracovná pozícia je povinná.",
  "Password is required.": "Heslo je povinné.",
  "Password must have at least": "Heslo musí mať minimálne",
  "Passwords must be identical.": "Heslá sa musia zhodovať.",
  "Repeat password": "Zopakujte heslo",
  "letters.":  "znakov.",
  "letter.":  "znaky.",
  "Access denied": "Prístup zamietnutý",
  "Operation failed": "Operácia zlyhala",
  "We are sorry but we were unable to find your venue based on selected search criteria. Please try using alternative search criteria or register manually.": "Je nám ľúto, ale na základe vybraných vyhľadávacích kritérií sa nám nepodarilo nájsť vašu prevádzku. Skúste použiť alternatívne kritériá vyhľadávania alebo sa zaregistrujte manuálne.",
  "Thank you.": "Ďakujeme vám.",
  "Bank name is required.": "Meno banky je povinné.",
  "IBAN is required.": "IBAN je povinný.",
  "This is not a valid International Bank Account Number (IBAN).": "Prosím zadajte platný IBAN.",
  "Swift code is required.": "Swift kód je povinný.",
  "Name is required.": "Obchodné meno je povinný udaj.",
  "Legal entity name is required.": "Názov spoločnosti je povinný údaj.",
  "Business type is required.": "Typ podnikania je povinný.",
  "Address is required.": "Adresa je povinný údaj.",
  "Postal code is required.": "PSČ je povinné.",
  "Please enter valid postal code": "Zadajte platné PSČ.",
  "GPS latitude is required.": "GPS zemepisná šírka je povinná.",
  "GPS latitude must be a number.": "GPS zemepisná šírka musí byť číslo.",
  "GPS longitude is required.": "GPS zemepisná dĺžka je povinná.",
  "GPS longitude must be a number.": "GPS zemepisná šdĺžka musí byť číslo.",
  "City is required.": "Mesto je povinné.",
  "Country is required.": "Krajina je povinná.",
  "This email is already registered.": "Tento e-mail je už registrovaný.",
  "Entity name is required.": "Meno právnickej osoby je povinné",
  "Zip code is required.": "Psč je povinné.",
  "Please enter valid zip code": "Zadajte platné Psč",
  "Playlist is required.": "Playlist je povinný.",
  "Venue name must be less than 30 characters.": "Názov prevádzky musí mať menej ako 30 znakov",
  "Name must be less than 30 characters.": "Meno musí mať menej ako 30 znakov",
  "Venue's address is required.": "Adresa prevádzky je povinná.",
  "Contact person is required.": "Kontaktná osoba je povinná.",
  "Contact phone is required.": "Telefón kontaktnej osoby je povinný.",
  "All fields are required.": "Všetky polia sú povinné.",
  "Please select venues.": "Prosím zvoľte prevádzky pre danú kampaň.",
  "No venues available for this campaing.": "Žiadne prevádzky nie su dostupné pre danú kampaň.",
  "No venues available for this survey.": "Žiadne prevádzky nie su dostupné pre daný prieskum.",
  "Please select audio spot.": "Vyberte audio spot",
  "Please select at least one question.": "Vyberte aspoň jednu otázku.",
  "First name must have at least": "Krstné meno musí mať aspoň",
  "First name must have a maximum of": "Krstné meno musí mať najviac",
  "Last name must have at least": "Priezvisko musí mať aspoň",
  "Last name must have a maximum of": "Priezvisko musí mať najviac",
  "Value must be between -90 and 90": "Hodnota musí byť medzi -90 a 90",
  "Value must be between -180 and 180": "Hodnota musí byť medzi -180 a 180",
  "Remove duplicate audio spots.": "Odstráňte duplicitné zvukové spoty.",
  "Sorry, survey can have up to 5 questions.": "Prieskum môže mať najviac 5 otázok.",
  "Please take some of selected questions out.": "Odoberte niektoré z vybraných otázok.",
  "Discount rate is required.": "Zľava v percentách je povinné.",
  "Discount rate must be between 1 and 100.": "Zľava musí byť v medzi 1 a 100.",
  "Value cannot be less than 1.": "Hodnota musí byť vyššia ako 0.",
  "Campaign name is required.": "Názov kampane je povinný.",
  "Select at least one.": "Vyberte aspoň jednu možnosť.",
  "Date not specified": "Vyberte dátum",
  "Venue is required.": "Názov prevádzky je povinný.",
  "Product name is required.": "Názov produktu je povinný.",
  "Product price is required.": "Cena produktu je povinná.",
  "Company data are required.": "Prosím vyberte spoločnosť. ",
  "Billing period is required.": "Fakturačné obdobie je povinný údaj.",
  "Date of delivery is required.": "Dátum dodania je povinný údaj.",
  "Invoice number is required.": "Číslo faktúry je povinný údaj.",
  "Variable symbol is required.": "Variabilný symbol je povinný údaj.",
  "Campaign needs to start before its end.": "Čas kampane nemôže byť väčší ako jej udaný čas ukončenia.",
  "Advert spot campaign start and end time overlap existing campaigns": "Zvolený čas kampane sa prekrýva s existujúcou kampanou",
  "Discount code must be valid: apply valid discount code or delete your discount code input.": "Pre pokračovanie, vymažte neplatný alebo zadajte platný zľavový kód."
}
