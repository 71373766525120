export default {
  "Active Playlist": "Aktívny Playlist",
  "Congress-Exhibition Centre": "Kongresové a výstavné centrum",
  "Select your playlist": "Vyberte si svoj playlist",
  "Playlist Description 1": "Všetky Songoroo playlisty zostavujú naši skúsení hudobní redaktori. Vypočujú si každú skladbu a pri zohľadnení žánru, tempa, jazyka a ďalších faktorov sa rozhodnú, pre ktoré podnikanie je skladba vhodná. To znamená, že každý playlist je šitý na mieru pre konkrétny typ podnikania. Je to náročná, manuálna a časovo náročná práca, ale nerobíme kompromisy nad kvalitou. Je samozrejmé, že každý playlist je pravidelne aktualizovaný.",
  "Playlist Description 2": "Aj keď sú naše zoznamy skladieb starostlivo zostavené, nie sú vytesané do kameňa. Vaši zákazníci a zamestnanci majú pri konečnej zmesi veľkú úlohu. V okamihu, keď vstúpia do vašich priestorov s aktívnou aplikáciou Songoroo v telefóne, náš playlist sa prispôsobí ich hudobným preferenciám a podľa možnosti umiestni ich obľúbené skladby do playlistu.",
  "Playlist Description 3": "Samozrejme musíme tiež pripustiť, že nech sa snažíme akokoľvek, stále existuje táto malá možnosť, že by sa vám nemusel celkom páčiť žiadny z našich preddefinovaných playlistov. Netreba sa báť! Stále máte slobodu zostaviť si vlastný playlist a mať ho presne tak, ako chcete.",
  "Create your own playlist": "Vytvoriť vlastný playlist",
  "Venue custom playlist": "Custom playlist prevádzky",
  "Get creative. Follow your taste and compile your own playlist": "Buďte kreatívni. Riaďte sa podľa svojho vkusu a zostavte si vlastný playlist",
  "Select genres from the below list to include them in your custom built playlist. The custom playlist you are about to generate for this venue can be modified anytime.": "Vyberte žánre z nižšie uvedeného zoznamu a zahrňte ich do svojho vytvoreného playlistu. Vlastný playlist, ktorý sa chystáte pre túto prevádzku vytvoriť, môžete kedykoľvek upraviť.",
  "Generate Playlist": "Generovať playlist",
  "Genre description": "Popis žánru",
  "Set for this venue": "Nastaviť pre túto prevádzku",
  "Playlist description": "Popis playlistu",
  "Energy": "Energia",
  "Included": "Zahrnutý",
  "Excluded": "Vylúčený",
  "Low": "Nízka",
  "Low to medium": "Nízka až mierna",
  "Low to high": "Nízka až vysoká",
  "Medium": "Mierna",
  "High": "Vysoká",
  "Playlist changed": "Playlist bol zmenený",
  "Back to playlist selection": "Späť na výber playlistu",
  "More genres error": "Preto aby mohol Songoroo algoritmus ponúknuť najlepšiu možnú kombináciu skladieb vyžaduje určité množstvo skladieb v playliste. Vami vybrané hudobné žánre neobsahujú dostatok sladieb čo môže viesť k zlyhaniu pri výpočte algoritmu generujúceho hudobný playlist pre Vašu prevádzku. Preto si Vás dovoľujeme požiadať aby ste vybrali dodatočné žánre. Ďakujeme.",

}
