import { state } from "@/state/modules/clientzone/help_and_support/state";
import { mutations } from "@/state/modules/clientzone/help_and_support/mutations";
import { getters } from "@/state/modules/clientzone/help_and_support/getters";
import { actions } from "@/state/modules/clientzone/help_and_support/actions";

const helpAndSupportModule = {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}

export default helpAndSupportModule
