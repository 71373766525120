import Vue from "vue";
import {
  NewVenueSubPlanData,
  UserAuthorisationsVenuesData,
  VenueOpeningHoursData,
  VenueRegisterData,
  SelectedWebstreamSelectVenueData,
} from "@/types";
import {MutationTree} from "vuex";
import { VenueState } from "@/types/store/clientzone";

export const mutations: MutationTree<VenueState> = {
  SET_VENUES: (state, payload) => Vue.set(state, "venues", payload),
  SET_VENUE: (state, payload) => Vue.set(state, "selectedVenue", payload),
  RESET_USER_AUTHORISATIONS_VENUES: (state, payload) => Vue.set(state, "userAuthorisationsVenues", payload),
  SET_USER_AUTHORISATIONS_VENUES: (state, payload: UserAuthorisationsVenuesData) => state.userAuthorisationsVenues.push(payload),
  SET_USER_ROLES_TO_AUTHORISATIONS_VENUES: (state, payload) => {
    const arrayLength = state.userAuthorisationsVenues.length;

    Vue.set(state.userAuthorisationsVenues[arrayLength - 1], "role_id", payload.czone_user_role_id);
    Vue.set(state.userAuthorisationsVenues[arrayLength - 1], "czone_user_id", payload.czone_user_id);
  },
  SET_NEW_VENUE_ID: (state, payload) => Vue.set(state, "newVenueId", payload),
  SET_NEW_VENUE_SUB_PLAN_DATA: (state, payload: NewVenueSubPlanData): NewVenueSubPlanData => Vue.set(state, "newVenueSubPlan", payload),
  SET_VENUE_OPENING_HOURS: (state, payload: Array<VenueOpeningHoursData>): Array<VenueOpeningHoursData> => Vue.set(state, "venueOpeningHours", payload),
  SET_COUNTRY_CODES: (state, payload) => Vue.set(state, "countryCodes", payload),
  SET_VENUE_IMAGE: (state, payload): void => {
    if (!!payload.id) {
      const venueIndex = state.venues.findIndex(x => x.id === payload.id);
      Vue.set(state.venues[venueIndex], "image", payload.encodeImage);
    } else {
      Vue.set(state.selectedVenue, "image", payload);
    }
  },
  SET_LOADER: (state, payload): void => {
    const index = state.venues.findIndex(v => v.id === payload);
    Vue.set(state.venues[index], "loader", true);
  },
  UNSET_LOADER: (state, payload): void => {
    const index = state.venues.findIndex(v => v.id === payload);
    Vue.set(state.venues[index], "loader", false);
  },
  SET_GROUP_ALL_LOADER: (state, payload: boolean): boolean => Vue.set(state, "groupLoader", payload),
  RESET_VENUE_IMAGE: (state, payload: string): string => Vue.set(state.selectedVenue, "image", payload),
  SET_VENUES_DETAIL_RATINGS: (state, payload) => Vue.set(state, "venueRatings", payload),
  SET_VENUES_RATINGS: (state, payload): void => {
      const venueIndex = state.venues.findIndex(x => x.id === payload.id);
      Vue.set(state.venues[venueIndex], "average_rating", payload.data.average_rating);
      Vue.set(state.venues[venueIndex], "total_ratings", payload.data.total_ratings);
  },
  SET_VENUES_DETAIL_ANSWERS: (state, payload) => Vue.set(state, "venueAnswers", payload),
  SET_VENUE_REGISTER_DATA: (state, payload: VenueRegisterData): void => {
    Vue.set(state, "registerVenue", payload);
    Vue.set(state, "initVenueRegister", true);
  },
  SET_RATING_USER_IMAGE: (state, payload): void => {
      const ratingIndex = state.venueRatings.ratings.findIndex(x => x.user_id === payload.id);
      Vue.set(state.venueRatings.ratings[ratingIndex], "image", payload.encodeImage);
  },
  SET_VENUE_LOADER: (state, payload: boolean): boolean => Vue.set(state, "loader", payload),
  SET_CREATE_VENUE_LOADER: (state, payload: boolean): boolean => Vue.set(state, "createVenueLoader", payload),
  SET_ERROR_ON_INVALID_CREDIT_CARD: (state, payload: boolean): boolean => Vue.set(state, "errorOnInvalidCreditCard", payload),
  SET_NEW_CREATED_VENUE_ID: (state, payload: number): number => Vue.set(state, "newCreateVenueId", payload),
  SET_AVAILABLE_VENUES_FOR_ROLE_MANAGEMENT: (state, payload) => Vue.set(state, 'availableVenuesForRoleManagement', payload),
  SET_SELECTED_WEBSTREAM_VENUE: (state, payload: SelectedWebstreamSelectVenueData | null) => Vue.set(state, "selectedWebstreamVenue", payload),
}
