export default {
  "Songoroo app features": "Funkcie aplikácie Songoroo",
  "Description": "Naša mobilná aplikácia a mnoho jej funkcií boli vyvinuté s úmyslom ponúknuť užívateľom interaktivitu a umožniť im ovplyvňovať hudobné zoznamy skladieb viacerými spôsobmi. A keď tak urobia, veríme, že vám pomáhajú vytvárať a rozvíjať vašu značku v niečo jedinečné. Je len na vás, či chcete zákazníkom poskytnúť úplnú slobodu prispievať k vytváraniu hudobnej atmosféry pre vaše podnikanie alebo či a ako veľmi chcete tieto možnosti obmedziť.",
  "Disable explicit content": "Zakázať explicitný obsah",
  "Disable explicit content description": "Interpreti majú rôzne spôsoby vyjadrovania. Niekedy vám to však môže pripadať príliš veľa pre vaše podnikanie a my to chápeme. Preto vám dávame možnosť zakázať skladby, ktoré považujete za nevhodný pre vaše podnikanie.",
  "Voting": "Hlasovanie",
  "Voting description": "Táto funkcia je pomerne jasná. Ako môžete aj sami uhádnuť, klepnutím na šípku nahor sa vaša skladba presunie na vyššie miesto v zozname skladieb. Čím viac sa užívateľom páči rovnaká skladba, tým sa nachádza vyššie v zozname. Šípka „dole“ funguje obdobne, avšak s opačným efektom.",
  "Add song": "Pridať skladbu",
  "Add song description": "Všetky preddefinované a ručne vybrané zoznamy skladieb pozostávajú zo skladieb, ktoré sú podľa vás ako vlastníka aj pre spoločnosťi Songoroo, najvhodnejšie pre podnikanie, ktoré prevádzkujete. Vaši zákazníci však môžu mať odlišný názor. Ak majú náladu počúvať konkrétnu skladbu, môžu vyhľadávať v našej databáze a pridať piesne, ktoré sa im páčia. Naši predplatitelia Premium majú dokonca možnosť nastaviť limity pre túto funkcionalitu, t.j. koľko skladieb by mohlo byť pridaných zákaznkmi. Ďalšou možnosťou, ako ovládať, čo sa pridá do zoznamu skladieb, je použitie funkcie Limit Playlist Adjustment.",
  "Boost": "Boost",
  "Boost description": "Boost umožňuje vašim zákazníkom vystreliť vybranú skladbu na najvyššiu pozíciu v zozname skladieb. Táto funkcia sa často používa v kombinácii s funkciou Pridať skladbu. Vďaka tomu majú vaši zákazníci možnosť vybrať si hudbu z našej databázy, pridať ju do zoznamu skladieb a boostuť ju, takže čas, kým nepočujú prehrávanie svojej skladby, je minimálny. V predvolenom nastavení má každý užívateľ povolený iba jeden aktívny Boost. Predplatitelia prémium si môžu toto obmedzenie prispôsobiť.",
  "Song for you": "Skladba pre teba",
  "Song for you description": "Vyvinuli sme túto komunikačnú funkcionalitu, aby sme zákazníkom umožnili venovať skladby s priloženými správami. Táto interaktívna možnosť udržuje ľudí zaneprázdnených a vďaka tomu trávia vo vašom podniku viac času. V predvolenom nastavení má každý užívateľ povolený iba jeden aktívny Boost. Predplatitelia prémium si môžu toto obmedzenie prispôsobiť.",
  "Playlist modification via Songoroo app": "Úprava playlistu cez Songoroo mobilnú aplikáciu",
  "Playlist modification via Songoroo app description": "Aktivovaním tejto funkcie obmedzíte možnosť pridať skladby do playlistu tejto prevádzky iba na tie, ktoré vyhovujú kritériam zvoleného playlistu. Ináč povedané, Vaši zákazníci nebudú môcť pridať do playlistu (použitím Songoroo aplikácie) skladbu, ktorá nie je zahrnutá v playliste, ktorý ste zvolili pre danú prevádzku.",
  "Censor song": "Zakázať skladbu",
  "Censor artist": "Zakázať interpreta",
  "Search results": "Výsledky vyhľadávania",
  "Add back to playlist": "Pridať späť",
  "Set as banned": "Zakázať",
  "Censored songs": "Zakázané skladby",
  "Censored artists": "Zakázaní interpreti",
  "No censored songs": "Žiadne zakázané skladby",
  "No censored artists": "Žiadni zakázaní interpreti",
  "Back to venue detail": "Späť na detail prevádzky"
}
