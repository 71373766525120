export enum USER_ROLES {
  ADMIN = 1,
  OPERATOR = 2,
  MARKETER = 3,
  ACCOUNTANT = 4
}

export enum SUMMARY_TYPES {
  SONG_VOTES,
  USERS_ACTIVATED,
  SONG_BOOSTS,
  SONG_ADDED,
  SONG_SENT,
  SONG_PLAYED
}

export enum SUMMERY_TYPE_COLOR {
  SONG_VOTES= '#00CAD0' ,
  USERS_ACTIVATED= '#83BCFF',
  SONG_BOOSTS= '#F55F8A',
  SONG_ADDED= '#8F8AF0',
  SONG_SENT= '#EBA721',
  SONG_PLAYED= '#59C5C7'
}

export enum TABLE_ACTIONS_TYPE {
  UPDATE = 'pencil',
  DELETE = 'trash',
  EXPAND = 'down-arrow',
  COLLAPSE = 'up-arrow',
  EXPORT = 'cloud-download',
}

export enum NOTIFICATION_IMPORTANCE {
  CRITICAL = 1,
  WARNING = 2,
  SUGGESTION = 3
}

/*
         const IMPORTANCE_CRITICAL = 1;
         const IMPORTANCE_WARNING = 2;
         const IMPORTANCE_SUGGESTION = 3;
       */


export const AUDIOCOMMERCALSTESTDATA = [
         {
           CampName: "Audio Ads Campaings",
           campStatus: true,
           Startperiod: 123,
           EndPeriod: 1111,
           Status: null,
           timeSlot: "11:00 - 13:00",
           venues: undefined,
           subRows: [
             {
               id: 100,
               name: "Spont Name 3",
             },
             { id: 101, name: "Spont Name 2" },
             { id: 102, name: "Spont Name 3" },
           ],
           id: 1100,
         },
         {
           CampName: "Audio Ads Campaings",
           campStatus: true,
           Startperiod: 147,
           EndPeriod: 1111,
           Status: null,
           timeSlot: "11:00 - 13:00",
           venues: ['test1', 'test2'],
           subRows: [
             { id: 100, name: "Spont Name 3" },
             { id: 101, name: "Spont Name 2" },
             { id: 102, name: "Spont Name 3" },
           ],
           id: 1101,
         },
         {
           CampName: "Audio Ads Campaings",
           campStatus: true,
           Startperiod: 159,
           EndPeriod: 1111,
           Status: true,
           timeSlot: "11:00 - 13:00",
           venues: undefined,
           subRows: [
             { id: 100, name: "Spont Name 3" },
             { id: 101, name: "Spont Name 2" },
             { id: 102, name: "Spont Name 3" },
           ],
           id: 1102,
         },
         {
           CampName: "Audio Ads Campaings",
           campStatus: true,
           Startperiod: 156,
           EndPeriod: 1111,
           Status: null,
           timeSlot: "11:00 - 13:00",
           venues: undefined,
           subRows: [
             { id: 100, name: "Spont Name 3" },
             { id: 101, name: "Spont Name 2" },
             { id: 102, name: "Spont Name 3" },
           ],
           id: 1103,
         },
         {
           CampName: "Audio Ads Campaings",
           campStatus: true,
           Startperiod: 153,
           EndPeriod: 1111,
           Status: null,
           timeSlot: "11:00 - 13:00",
           venues: undefined,
           subRows: [
             { id: 100, name: "Spont Name 3" },
             { id: 101, name: "Spont Name 2" },
             { id: 102, name: "Spont Name 3" },
           ],
           id: 1104,
         },
         {
           CampName: "Audio Ads Campaings",
           campStatus: true,
           Startperiod: 152,
           EndPeriod: 1111,
           Status: null,
           timeSlot: "11:00 - 13:00",
           venues: undefined,
           subRows: [
             { id: 100, name: "Spont Name 3" },
             { id: 101, name: "Spont Name 2" },
             { id: 102, name: "Spont Name 3" },
           ],
           id: 1105,
         },
         {
           CampName: "Audio Ads Campaings",
           campStatus: true,
           Startperiod: 151,
           EndPeriod: 1111,
           Status: null,
           timeSlot: "11:00 - 13:00",
           venues: undefined,
           subRows: [
             { id: 100, name: "Spont Name 3" },
             { id: 101, name: "Spont Name 2" },
             { id: 102, name: "Spont Name 3" },
           ],
           id: 1106,
         },
       ];

export const DASHBOARDTESTDATA = [
    { 'id': 68,
      'isrc': 'DEA621501057',
      'title': 'Sugar (ft. Francesco Yates)',
      'like_status': null,
      'artist_id': 29,
      'artist_name': 'Robin Schulz',
      'artist_image_last_updated': 1574876930,
      'artist_like_status': null,
      'playable': true
    },
    {
      'id': 107,
      'isrc': 'GBUM71507204',
      'title': 'Love Me Like You Do',
      'like_status': null,
      'artist_id': 92,
      'artist_name': 'Ellie Goulding',
      'artist_image_last_updated': 1575984553,
      'artist_like_status': null,
      'playable': true
    },
    {
      'id': 423,
      'isrc': 'FR69T0080260',
      'title': 'Chillin',
      'like_status': null,
      'artist_id': 286,
      'artist_name': 'Modjo',
      'artist_image_last_updated': 1575809973,
      'artist_like_status': null,
      'playable': true
    },
    {
      'id': 449,
      'isrc': 'USIR29400440',
      'title': 'Ode To My Family',
      'like_status': null,
      'artist_id': 293,
      'artist_name': 'The Cranberries',
      'artist_image_last_updated': 1575810881,
      'artist_like_status': null,
      'playable': true
    },
    {
      'id': 550,
      'isrc': 'CZA110306181',
      'title': 'Pěkná, Pěkná, Pěkná',
      'like_status': null,
      'artist_id': 352,
      'artist_name': 'Janek Ledecký',
      'artist_image_last_updated': 1575866902,
      'artist_like_status': null,
      'playable': true
    },
    {
      'id': 615,
      'isrc': 'USVI29100003',
      'title': 'It Aint Over Til Its Over',
      'like_status': null,
      'artist_id': 158,
      'artist_name': 'Lenny Kravitz',
      'artist_image_last_updated': 1574876930,
      'artist_like_status': null,
      'playable': true
    },
    {
      'id': 647,
      'isrc': 'GBAHS1700195',
      'title': 'Ciao Adios',
      'like_status': null,
      'artist_id': 11,
      'artist_name': 'Anne-Marie',
      'artist_image_last_updated': 1575407138,
      'artist_like_status': null,
      'playable': true
    },
    {
      'id': 799,
      'isrc': 'SKA019903005',
      'title': 'Čo ak',
      'like_status': null,
      'artist_id': 513,
      'artist_name': 'I.M.T. Smile',
      'artist_image_last_updated': 1576121609,
      'artist_like_status': null,
      'playable': true
    },
    {
      'id': 1120,
      'isrc': 'NO0000000000',
      'title': 'Slovenský klín',
      'like_status': null,
      'artist_id': 616,
      'artist_name': 'Chinaski',
      'artist_image_last_updated': 1575402825,
      'artist_like_status': null,
      'playable': true
    },
    {
      'id': 1155,
      'isrc': 'GBBPW9900021',
      'title': 'Les Nuits',
      'like_status': null,
      'artist_id': 629,
      'artist_name': 'Nightmares On Wax',
      'artist_image_last_updated': 1576157786,
      'artist_like_status': null,
      'playable': true
    }
  ];
