import { CorporateInvoiceVenuesData, InvoiceData } from "@/types";
import Vue from "vue";
import {MutationTree} from "vuex";
import { BillingAdminzoneState } from "@/types/store/adminzone/billing";

export const mutations: MutationTree<BillingAdminzoneState> = {
  SET_INVOICES: (state, payload: Array<InvoiceData>): Array<InvoiceData> => Vue.set(state, "invoices", payload),
  SET_ALL_INVOICES: (state, payload: Array<InvoiceData>): Array<InvoiceData> => Vue.set(state, "all_invoices", payload),
  SET_ALL_INVOICES_COUNT: (state, payload: number): number => Vue.set(state, 'allInvoicesCount', payload),
  SET_CORPORATE_INVOICE_VENUES: (state, payload: Array<CorporateInvoiceVenuesData>): Array<CorporateInvoiceVenuesData> => Vue.set(state, 'corporateInvoiceVenues', payload),
  SET_LOADER: (state, payload): void  => {
    const index = state.all_invoices.findIndex(i => i.id === payload.invoiceId);
    Vue.set(state.all_invoices[index], "loader", payload.value);
  },
  SET_LOADER_2: (state, payload): void  => {
    const index = state.all_invoices.findIndex(i => i.id === payload.invoiceId);
    Vue.set(state.all_invoices[index], "loader2", payload.value);
  }
}
