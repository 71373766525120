import ApiService from "@/services/api.service";
import { ActionTree } from "vuex";
import { errorMessage } from "@/utils/messages-handlers";
import { BrowserStreamerState, RootState } from "@/types/store/clientzone";

export const actions: ActionTree<BrowserStreamerState, RootState> = {
  FETCH_PLAYLIST_SONG_: ({ commit }, payload): Promise<void> => {
    const webStreamToken = localStorage.getItem('webstream_token')
    return new Promise(async (resolve, reject) => {
      const resource: string = `/api/v1/clientzone/webstreamer/venue/${payload.id}/get_song?session_token=${webStreamToken}`;

      try {
        const response = await ApiService.get(resource, {
          headers: {
            "range": "bytes=0-"
          }
        });

        commit("SET_PLAYLIST_SONG", response.data);

        resolve();
      } catch (e) {
        if (e.response.data.error.code === "ClientZoneSongStreamingAlreadyPlayingError") {
          // errorMessage("Streaming is now played by another clientzone user or a streamer device.", 5000);
          if (!payload.autoplay) {
            commit("SET_TOOK_WEBSTREAM_MODAL", true);
            commit("SET_INFO_WEBSTREAM_MODAL", false);
          }
          if (payload.autoplay) {
            commit("SET_TOOK_WEBSTREAM_MODAL", false);
            commit("SET_INFO_WEBSTREAM_MODAL", true);
          }
          commit("SET_STOP_PLAYER", true);
        }
        if (e.response.data.error.code === "ClientZoneGetSongError") {
          if (JSON.parse(e.response.data.error.details.error).error.code === "VenueNotOpenError") {
            errorMessage("Webstream is suspended, venue is closed.", 5000);
            commit("SET_STOP_PLAYER", true);
          }
        }
        reject()
      }
    })
  },
  FORCE_PLAYLIST_SONG_: ({ commit }, venueId: number): Promise<void> => {
    const webStreamToken = localStorage.getItem('webstream_token')
    return new Promise(async (resolve, reject) => {
      const resource: string = `/api/v1/clientzone/webstreamer/venue/${venueId}/get_song?session_token=${webStreamToken}&force_play=1`;

      try {
        const response = await ApiService.get(resource, {
          headers: {
            "range": "bytes=0-"
          }
        });

        commit("SET_PLAYLIST_SONG", response.data);

        resolve();
      } catch (e) {
        if (JSON.parse(e.response.data.error.details.error).error.code === "VenueNotOpenError") {
          errorMessage("Webstream is suspended, venue is closed.", 5000);
          commit("SET_STOP_PLAYER", true);
        }
        reject()
      }
    })
  },
  STOP_PLAYLIST_SONG: ({ }, venueId: number): Promise<void> => {
    const webStreamToken = localStorage.getItem('webstream_token')
    return new Promise(async (resolve, reject) => {
      if (webStreamToken) {
        const resource: string = `/api/v1/clientzone/webstreamer/venue/${venueId}/stop_song?session_token=${webStreamToken}`;

        try {
          await ApiService.get(resource);

          resolve();
        } catch (e) {
          reject()
        }
      }
    })
  },
  SET_WEB_STREAMER_TO_DEFAULT: ({ commit }): void => commit("SET_PLAYLIST_SONG", ""),
  SET_TOOK_WEBSTREAM_MODAL_TO_DEFAULT: ({ commit }): void => {
    commit("SET_TOOK_WEBSTREAM_MODAL_TO_DEFAULT")
  },
  FORCE_REFRESH_VENUE: ({ }, venueId: number): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      const resource: string = `/api/v1/venue/refresh?force_venue_id=${venueId}`;

      try {
        await ApiService.get(resource);

        resolve();
      } catch (e) {
        reject()
      }
    })
  }
}
