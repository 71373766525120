export default {
    "Select venue": "Zvolit provozovnu",
    "From": "Od",
    "Choose a date": "Vybrat datum",
    "To": "Do",
    "Submit": "Potvrdit",
    "Cancel": "Zrušit",
    "Search": "Hledat",
    "Update": "Aktualizovat",
    "Add New Venue": "Přidat novú provozovnu",
    "Active": "Aktívní",
    "Inactive": "Neaktívní",
    "I agree with Songoroo's": "Souhlasím se",
    "Terms of Use": "Všeobecnými obchodními podmínkami",
    "Terms of Use footer": "Podmínky použití",
    "Privacy Policy": "Zásadami ochrany osobních údajů Songoroo",
    "Privacy Policy footer": "Zásady ochrany osobních údajů",
    "and": "a",
    "First name": "Křestní jméno",
    "Last name": "Příjmení",
    "Password": "Heslo",
    "Select option": "Vybrat možnost",
    "excluding": "bez",
    "VAT": "DPH",
    "Upload Photo": "Nahrát fotku",
    "Contact phone": "Telefon",
    "Change your Avatar": "Změnit avatara",
    "Upload your profile photo": "Nahrát profilovú",
    "Change your profile photo": "Změnit profilovú fotku",
    "My authorisations": "Moje oprávnění",
    "No authorisations": "Žádná oprávnění",
    "Change password": "Změnit heslo",
    "Old Password": "Staré heslo",
    "New Password": "Nové heslo",
    "Confirm Password": "Potvrdit heslo",
    "Unlimited": "Neomezený",
    "Selected venues": "Zvolené provozovny",
    "Selected questions:": "Zvolené otázky:",
    "Choose File": "Vybrat soubor",
    "Action": "Akce",
    "Enter email": "Zadejte e-mail",
    "Oops! Page Not Found.": "Jejda! Stránka nenalezena.",
    "Go to start page": "Přejít na úvodní stránku",
    "No data": "Žádná data",
    "Other": "Ostatní",
    "Are you sure you want to delete this advert campaign?": "Opravdu chcete smazat tuto reklamní kampaň??",
    "Are you sure you want to delete this audio spot?": "Opravdu chcete smazat tento audio spot?",
    "Are you sure you want to delete this question?": "Opravdu chcete smazat tuto otázku?",
    "Are you sure you want to delete this venue survey?": "Opravdu chcete smazat toto hodnocení provozovny?",
    "Are you sure you want to delete this payment card?": "Opravdu chcete smazat tuto platební kartu?",
    "Are you sure you want to delete this bank account?": "Opravdu chcete smazat tento bankovní účet?",
    "Notifications": "Oznamy",
    "No notifications": "Žádná oznamy",
    "Load More...": "Načíst další...",
    "Renew": "Obnovit",
    "Sorry, only venues that include the web stream are allowed here.": "Je nám líto, ale zde jsou zde povolena pouze provozovny, která obsahují webový stream.",
    "You have not created any venues at this time. Unable to start webstreaming.": "V současné době nemáte vytvořený žádný provoz. Nelze spustit webové streamování.",
    "No options.": "Žádné možnosti",
    "Deleted": "Smazaná",
    "paid": "Zaplacené",
    "open": "Otevřená",
    "Yes": "Ano",
    "uncollectible": "Nesplatitelná",
    "All campaigns": "Všechny kampaně",
    "All surveys": "Všechny průzkumy",
    "Other user is streaming music of this venue. Do you want to take it over?": "Jiný uživatel právě přehrává hudbu této provozovny. Chcete to převzít?",
    "Other user took over music stream of venue ": "Jiný uživatel převzal streamování hudby v provozovňe",
    "Stream is already playing on this device": "Na tomto zařízení se již přehrává stream hudby",
    "Your 14-days Trial Expired": "Vypršela platnost vaší 14-denní zkušební verze",
    "Your 14-days Trial Expired Description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam elit purus, posuere non est a, venenatis semper ex. Praesent tristique egestas dui quis accumsan. Maecenas ultrices massa ac urna consectetur, sed viverra tortor mattis.",
    "Contact us": "Kontaktujte nás",
    "Select subscription plan": "Vyberte si plán předplatného",
    "The trial period has ended on": "Dne ",
    "for venue": " skončila zkušební doba provozu ",
    "To continue using Songoroo Smart Music, please select one of our subscriptions plans.": ". Chcete-li pokračovat v používání Songoroo Smart Music, vyberte si jeden z nabízených balíčků.",
    "Show password": "Zobrazit heslo",
    "Hide password": "Skrýt heslo",
}
