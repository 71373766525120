import AxiosService from "@/services/axios.service";
import { CASSOVIA_API_RESOURCE } from "@/utils/helpers";
import { errorMessage, successMessage } from "@/utils/messages-handlers";

export const actions = {
  FETCH_EMAILS: ({ commit }, payload): Promise<void> => {
    return new Promise(async (resolve, reject) => {

      const resource: string = `${CASSOVIA_API_RESOURCE}/api/emails?page=${payload.page}`;
      try {
        const response = await AxiosService.getAxios().get(resource)
        commit("SET_EMAILS", response.data);
        resolve();
      } catch (e) {
        reject()
      }
    })
  },
  UPDATE_EMAIL: (context, payload): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      const resource: string = `${CASSOVIA_API_RESOURCE}/api/emails/${payload.id}`;

      try {
        await AxiosService.getAxios().patch(resource, payload.data, {
          headers: {
            'Content-Type': 'application/merge-patch+json'
          }
        });

        // commit("SET_COMPANY", response.data);
        successMessage("Email updated successfully", 3000);

        resolve();
      } catch (e) {
        errorMessage(e.message, 3000)
        reject()
      }
    })
  },
}
