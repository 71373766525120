export default {
  "Email is required.": "Email is required.",
  "Please enter a valid email address.": "Please enter a valid email address.",
  "Please enter a valid VAT ID.": "Please enter a valid VAT ID.",
  "Please enter a valid swift code.": "Please enter a valid swift code.",
  "Please enter a valid IBAN.": "Please enter a valid IBAN.",
  "First name is required.": "First name is required.",
  "Last name is required.": "Last name is required.",
  "Job position is required.": "Job position is required.",
  "Password is required.": "Password is required.",
  "Password must have at least": "Password must have at least",
  "Passwords must be identical.": "Passwords must be identical.",
  "Repeat password": "Repeat password",
  "letters.": "letters.",
  "letter.": "letters.",
  "Access denied": "Access denied",
  "Operation failed": "Operation failed",
  "We are sorry but we were unable to find your venue based on selected search criteria. Please try using alternative search criteria or register manually.": "We are sorry but we were unable to find your venue based on selected search criteria. Please try using alternative search criteria or register manually.",
  "Thank you.": "Thank you.",
  "Bank name is required.": "Bank name is required.",
  "IBAN is required.": "IBAN is required.",
  "This is not a valid International Bank Account Number (IBAN).": "This is not a valid International Bank Account Number (IBAN).",
  "Swift code is required.": "Swift code is required.",
  "Name is required.": "Name is required.",
  "Legal entity name is required.": "Legal entity name is required.",
  "Business type is required.": "Business type is required.",
  "Address is required.": "Address is required.",
  "Postal code is required.": "Postal code is required.",
  "Please enter valid postal code": "Please enter valid postal code",
  "GPS latitude is required.": "GPS latitude is required.",
  "GPS latitude must be a number.": "GPS latitude must be a number.",
  "GPS longitude is required.": "GPS longitude is required.",
  "GPS longitude must be a number.": "GPS longitude must be a number.",
  "City is required.": "City is required.",
  "Country is required.": "Country is required.",
  "This email is already registered.": "This email is already registered.",
  "Entity name is required.": "Entity name is required.",
  "Zip code is required.": "Zip code is required.",
  "Please enter valid zip code": "Please enter valid zip code",
  "Playlist is required.": "Playlist is required.",
  "Venue name must be less than 30 characters.": "Venue name must be less than 30 characters.",
  "Name must be less than 30 characters.": "Name must be less than 30 characters.",
  "Venue's address is required.": "Venue's address is required.",
  "Contact person is required.": "Contact person is required.",
  "Contact phone is required.": "Contact phone is required.",
  "All fields are required.": "All fields are required.",
  "Please select venues.": "Please select venues for a respective campaign.",
  "No venues available for this campaing.": "No venues available for this campaing.",
  "No venues available for this survey.": "No venues available for this survey.",
  "Please select audio spot.": "Please select audio spot.",
  "Please select at least one question.": "Please select at least one question.",
  "First name must have at least": "First name must have at least",
  "First name must have a maximum of": "First name must have a maximum of",
  "Last name must have at least": "Last name must have at least",
  "Last name must have a maximum of": "Last name must have a maximum of",
  "Value must be between -90 and 90": "Value must be between -90 and 90",
  "Value must be between -180 and 180": "Value must be between -180 and 180",
  "Remove duplicate audio spots.": "Remove duplicate audio spots.",
  "Sorry, survey can have up to 5 questions.": "Sorry, survey can have up to 5 questions.",
  "Please take some of selected questions out.": "Please take some of selected questions out.",
  "Discount rate is required.": "Discount rate is required.",
  "Discount rate must be between 1 and 100.": "Discount rate must be between 1 and 100.",
  "Value cannot be less than 1.": "Value cannot be less than 1.",
  "Campaign name is required.": "Campaign name is required.",
  "Select at least one.": "Select at least one package.",
  "Date not specified": "Date not specified",
  "Venue is required.": "Venue is required.",
  "Product name is required.": "Product name is required.",
  "Product price is required.": "Product price is required.",
  "Company data are required.": "Company data are required.",
  "Billing period is required.": "Billing period is required.",
  "Date of delivery is required.": "Date of delivery is required.",
  "Invoice number is required.": "Invoice number is required.",
  "Variable symbol is required.": "Variable symbol is required.",
  "Campaign needs to start before its end.": "Campaign needs to start before its end.",
  "Advert spot campaign start and end time overlap existing campaigns": "Advert spot campaign start and end time overlap existing campaigns",
  "Discount code must be valid: apply valid discount code or delete your discount code input.": "Discount code must be valid: apply valid discount code or delete your discount code input."
}
