import Vue from "vue";
import { MutationTree } from "vuex";
import { HelpAndSupportState } from "@/types/store/clientzone";

export const mutations: MutationTree<HelpAndSupportState> = {
  SET_SUPPORT_TICKET_CATEGORY: (state, payload) => Vue.set(state, "SupportTicketCategory", payload),
  SET_POPULAR_TOPICS_EN: (state, payload) => Vue.set(state, "PopularTopicsEn", payload),
  SET_POPULAR_TOPICS_SK: (state, payload) => Vue.set(state, "PopularTopicsSk", payload),
  SET_POPULAR_SECTIONS_EN: (state, payload) => Vue.set(state, "PopularSectionsEn", payload),
  SET_POPULAR_SECTIONS_SK: (state, payload) => Vue.set(state, "PopularSectionsSk", payload),
  SET_SEARCH_TOPICS: (state, payload) => Vue.set(state, "SearchTopics", payload),
  SET_LOADER: (state, payload: boolean): boolean => Vue.set(state, "loader", payload)
}
