import Vue from 'vue'
import Vuex from 'vuex'

import layoutModule from '@/state/modules/clientzone/layout';
import registerModule from "@/state/modules/clientzone/register";
import userModule from '@/state/modules/clientzone/user';
import companyModule from "@/state/modules/clientzone/company";
import venueModule from "@/state/modules/clientzone/venue";
import venueAppFeaturesModule from "@/state/modules/clientzone/venue_app_features";
import globalModule from "@/state/modules/clientzone/global";
import dashboardModule from "@/state/modules/clientzone/dashboard";
import paymentModule from "@/state/modules/clientzone/payment";
import playlistModule from "@/state/modules/clientzone/playlist";
import venueQuestionaryModule from "@/state/modules/clientzone/venue_questionary";
import audioCommercialsModule from "@/state/modules/clientzone/audio_commercials";
import venueSubscriptionModule from "@/state/modules/clientzone/venue_subscription";
import messagesToCustomersModule from "@/state/modules/clientzone/messages_to_customers";
import browserStreamerModule from "@/state/modules/clientzone/browser_streamer";
import emailsModule from "@/state/modules/clientzone/emails";
import billingModule from "@/state/modules/clientzone/billing";
import helpAndSupportModule from "@/state/modules/clientzone/help_and_support";
import notificationModule from "@/state/modules/clientzone/notifications";
import azNotificationModule from "@/state/modules/adminzone/az_notifications";
import azBillingModule from "@/state/modules/adminzone/az_billing";
import azDataFeedModule from "@/state/modules/adminzone/az_data_feed";
import discountsModule from "@/state/modules/adminzone/discounts";
import azEmailsModule from "@/state/modules/adminzone/az_emails";
import azTrialPeriodModule from "@/state/modules/adminzone/az_trial_period";
import azProductsModule from "@/state/modules/adminzone/az_products";
import azSubscriptionDescriptionsModule from "@/state/modules/adminzone/az_subscription_descriptions";
import azTaxPricePeriodTranslationModule from "@/state/modules/adminzone/az_tax_price_period_translation";
import trialPeriodModule from "@/state/modules/clientzone/trial_period";
import subscriptionDescriptionsModule from "@/state/modules/clientzone/subscription_descriptions";
import taxPricePeriodTranslationModule from "@/state/modules/clientzone/tax_price_period_translation";
import azInvoiceSentConfirmationModule from "@/state/modules/adminzone/az_invoice_sent_information";
import azVenuesModule from "@/state/modules/adminzone/az_venue";
import azCompaniesModule from "@/state/modules/adminzone/az_companies";
import azPurchaseOrderModule from "@/state/modules/adminzone/az_purchase_order";
import azActivityLogsModule from "@/state/modules/adminzone/az_activity_logs";
import azJobApplicantsModule from "@/state/modules/adminzone/az_job_applicants";
import azBusinessTypeModule from "@/state/modules/adminzone/az_business_type";

Vue.use(Vuex)

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',

  modules: {
    layoutModule,
    registerModule,
    userModule,
    companyModule,
    venueModule,
    venueAppFeaturesModule,
    globalModule,
    dashboardModule,
    paymentModule,
    playlistModule,
    venueQuestionaryModule,
    audioCommercialsModule,
    venueSubscriptionModule,
    messagesToCustomersModule,
    browserStreamerModule,
    emailsModule,
    billingModule,
    helpAndSupportModule,
    notificationModule,
    azNotificationModule,
    azBillingModule,
    azDataFeedModule,
    discountsModule,
    azEmailsModule,
    azTrialPeriodModule,
    azProductsModule,
    azSubscriptionDescriptionsModule,
    azTaxPricePeriodTranslationModule,
    trialPeriodModule,
    subscriptionDescriptionsModule,
    taxPricePeriodTranslationModule,
    azInvoiceSentConfirmationModule,
    azVenuesModule,
    azCompaniesModule,
    azPurchaseOrderModule,
    azActivityLogsModule,
    azJobApplicantsModule,
    azBusinessTypeModule

  }
  // Enable strict mode in development to get a warning
  // when mutating state outside of a mutation.
  // https://vuex.vuejs.org/guide/strict.html
})
