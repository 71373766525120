export default {
  "Opening hours": "Opening Hours",
  "Opening time": "Opening Time",
  "Closing time": "Closing Time",
  "Closed": "Closed",
  "Monday": "Monday",
  "Tuesday": "Tuesday",
  "Wednesday": "Wednesday",
  "Thursday": "Thursday",
  "Friday": "Friday",
  "Saturday": "Saturday",
  "Sunday": "Sunday",
}
