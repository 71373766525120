import TokenService from '@/services/token.service'
import {
  UserAuthorisationsData,
  UserData,
  UserRolesData,
  UserRolesSummaryData,
  UserVenuesPermissionsData
} from "@/types";
import { UserState } from "@/types/store/clientzone";

export const state: UserState = {
  authenticating: false,
  accessToken: TokenService.getToken(),
  authenticationErrorCode: 0,
  authenticationError: '',
  data: {},
  venueId: null,
  coupon: {},
  loggedUser: {} as UserData,
  selectedUser: {} as UserData,
  users: [] as Array<UserData>,
  image: "",
  userAuthorisations: [] as Array<UserAuthorisationsData>,
  usersAuthorisations: [] as Array<UserAuthorisationsData>,
  userRoles: [] as Array<UserRolesData>,
  questionaryUsers: [] as Array<UserData>,
  audioSpotsUsers: [] as Array<UserData>,
  userRolesSummary: [] as Array<UserRolesSummaryData>,
  userVenuesPermissions: [] as Array<UserVenuesPermissionsData>,
  filterByActiveStatus: false,
  addUserLoader: false,
  userHasAccess: false,
  loader: false,
  hasUserRolePermission: false,
  isUserLogged: false,
  hasWebStreamingPermission: false,
  hasAppFeaturesPermission: false,
  hasEditSubscriptionPermission: false,
  hasChangePlaylistPermission: false,
  hasListOfUsersPermission: false,
  hasManageUserPermission: false,
  hasRoleManagementPermission: false,
  hasEditCompanyPermission: false,
  hasCreateDeleteVenuePermission: false,
  hasEditVenuePermission: false,
  hasInvoicesPermission: false,
  hasPaymentMethodPermission: false,
  hasViewAudioCampaignsPermission: false,
  hasManageAudioCampaignsPermission: false,
  hasManageAudioSpotsPermission: false,
  hasVenueSurveyPermission: false,
  hasSendMessagesPermission: false,
  hasHistoryOfMessagesPermission: false
}
