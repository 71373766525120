import Vue from "vue";
import { CompanyData, RolePermissionsAssociationData, RolePermissionsData } from "@/types";
import { MutationTree } from "vuex";
import { CompanyState } from "@/types/store/clientzone";

const defaultState: CompanyState = {
  companies: [] as Array<CompanyData>,
  company: {} as CompanyData,
  rolePermissions: [] as Array<RolePermissionsData>,
  rolePermissionsAssociation: [] as Array<RolePermissionsAssociationData>
}


export const mutations: MutationTree<CompanyState> = {
  SET_COMPANIES: (state, payload: Array<CompanyData>): Array<CompanyData> => Vue.set(state, "companies", payload),
  SET_COMPANY: (state, payload: CompanyData): CompanyData => Vue.set(state, "company", payload),
  // SET_COMPANY_IMAGE: (state, payload): void => Vue.set(state, "image", payload),
  SET_COMPANY_IMAGE: (state, payload): void => {
    if (!!payload.id) {
      const companyIndex = state.companies.findIndex(x => x.id === payload.id);
      Vue.set(state.companies[companyIndex], "image", payload.encodeImage);
    } else {
      Vue.set(state.company, "image", payload)
    }
  },
  SET_ROLE_PERMISSIONS: (state, payload: Array<RolePermissionsData>): Array<RolePermissionsData> => Vue.set(state, "rolePermissions", payload),
  SET_ROLE_PERMISSIONS_ASSOCIATIONS: (state, payload: Array<RolePermissionsAssociationData>): Array<RolePermissionsAssociationData> => Vue.set(state, "rolePermissionsAssociation", payload),
  SET_LOADER: (state, payload): void => {
    const index = state.rolePermissions.findIndex(r => r.id === payload);
    Vue.set(state.rolePermissions[index], "loader", true);
  },
  RESET_STATE: (state): void => {
    state.companies.splice(0, state.companies.length);

    const newCompany = <CompanyData>{
      id: null,
      name: '',
      contact: '',
      role: '',
      phone: '',
      email: '',
      sla: null,
      legal_entity_name: null,
      billing_name: null,
      billing_street: null,
      billing_city: null,
      billing_zip: null,
      billing_country_id: null,
      billing_ico: null,
      billing_vat: null,
      billing_bank_name: null,
      billing_iban: null,
      billing_swift: null,
      billing_email: '',
      image_last_updated: null,
      note: '',
      created_at: null,
      updated_at: null,
      deleted_at: null,
      image: '',
      corporate: null,
      billing_interval: null,
      czone_user_id: null,
      status: null,
      created_by: '',
    };

    Object.assign(state.company, newCompany)
  }
}
