import { state } from '@/state/modules/clientzone/global/state';
import { mutations } from '@/state/modules/clientzone/global/mutations';
import { getters } from '@/state/modules/clientzone/global/getters';
import { actions } from '@/state/modules/clientzone/global/actions';

const globalModule = {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}

export default globalModule
