import { state } from "@/state/modules/clientzone/venue/state";
import { mutations } from "@/state/modules/clientzone/venue/mutations";
import { getters } from "@/state/modules/clientzone/venue/getters";
import { actions } from "@/state/modules/clientzone/venue/actions";

const venueModule = {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}

export default venueModule
