export default {
    "Business Name": "Obchodní jméno (ochranná známka)",
    "Business Name subtext": "Název společnosti (ochranná známka), pod kterou společnost vystupuje vůči svým zákazníkům",
    "Business type": "Typ provozovny",
    "Name of the establishment": "Název provozovny",
    "Legal entity name": "Název provozovny",
    "Legal entity subtext": "Přesný název společnosti dle obchodního rejstříku - vyžadováno pro účely fakturace",
    "Street and descriptive house number": "Ulice a popisné číslo domu",
    "Street and descriptive number": "Ulice a popisné číslo",
    "Postal code": "Poštovní směrovací číslo",
    "City": "Město",
    "Country": "Stát",
    "VAT number": "IČ DPH",
    "VAT ID": "IČ DPH",
    "Business Identification Number": "IČO",
    "Bank name": "Název banky",
    "IBAN account": "IBAN účet",
    "Swift code": "Swift kód",
    "Zip code": "PSČ",
    "GPS latitude": "GPS zemepisná šířka",
    "GPS longitude": "GPS zemepisná délka",
    "Billing email": "Fakturačny e-mail",
    "Contact person": "Kontaktní osoba",
    "Contact persons phone": "Telefón kontaktní osoby",
    "Contact persons email": "Email kontaktní osoby",
    "Company Logo": "Logo společnosti",
    "Upload company logo description": "Logo společnosti se zobrazí v aplikaci Songoroo v seznamu provozoven a v detailu provozovny. Proto důrazně doporučujeme nahrát logo společnosti, díky kterému bude vaše provozovna snadno rozpoznatelná od ostatních míst na seznamu.",
    "Upload Company logo": "Nahrát logo společnosti",
    "Change company logo": "Změnit logo společnosti",
    "Save": "Uložit",
    "Select company": "Vybrat společnost",
    "Company registration date": "Datum registrace společnosti",
}
