export default {
  "No venue selected.": "No venue selected.",
  "Webstream is suspended, venue is closed.": "Webstream is suspended, venue is closed.",
  "Credentials are invalid or user is not active.": "Credentials are invalid or user is not active.",
  "User with provided email do not exist.": "User with provided email do not exist.",
  "There was a problem with verify your request. Please try again.": "There was a problem with verify your request. Please try again.",
  "Current password is invalid.": "Current password is invalid.",
  "Venue questionary start_at and end_at overlap existing campaigns.": "Venue questionary start at and end at overlap existing campaigns.",
  "New venue created.": "New venue created.",
  "Venue deleted.": "Venue deleted.",
  "Venue profile updated.": "Venue profile updated.",
  "User avatar updated.": "User avatar updated.",
  "Audio spot added.": "Audio spot added.",
  "Audio spot edited.": "Audio spot edited.",
  "Audio spot removed.": "Audio spot removed.",
  "Advert campaign created.": "Advert campaign created.",
  "Advert campaign edited.": "Advert campaign edited.",
  "Advert campaign deleted.": "Advert campaign deleted.",
  "Company profile updated.": "Company profile updated.",
  "Request sent. Please check your email address.": "Request sent. Please check your email address.",
  "Request sent.": "Request sent.",
  "New payment card added.": "New payment card added.",
  "Payment card removed.": "Payment card removed.",
  "New bank account added.": "New bank account added.",
  "Bank account removed.": "Bank account removed.",
  "Venue payment method updated.": "Venue payment method updated.",
  "You have successfully logged in.": "You have successfully logged in.",
  "The invitation was successfully sent to the user.": "The invitation was successfully sent to the user.",
  "User profile updated.": "User profile updated.",
  "Your password has been changed successfully. You can log in.": "Your password has been changed successfully. You can log in.",
  "Password was successfully changed.": "Password was successfully changed.",
  "Question added.": "Question added.",
  "Question edited.": "Question edited.",
  "Question deleted.": "Question deleted.",
  "Campaign created.": "Campaign added.",
  "Campaign edited.": "Campaign edited.",
  "Campaign deleted.": "Survey deleted.",
  "Venue subscription edited.": "Venue subscription edited.",
  "Venue subscription plan updated.": "Venue subscription plan updated.",
  "No recipients found for selected venues": "No recipients found for selected venues",
  "Are you sure you want to change your subscription plan?": "Are you sure you want to change your subscription plan?",
  "An error occurred while processing your card. Try again in a little bit.": "An error occurred while processing your card. Try again in a little bit.",
  "Your card's security code is incorrect.": "Your card's security code is incorrect.",
  "Your card has expired.": "Your card has expired.",
  "Your card was declined.": "Your card was declined.",
  "Your card has insufficient funds.": "Your card has insufficient funds.",
  "Dashboard updated": "Dashboard updated",
  "The User with this email address already exists": "The User with this email address already exists",
  "Venue subscription renewed.": "Venue subscription renewed.",
  "Venue subscription canceled.": "Venue subscription canceled.",
  "Credit card attached to venue.": "Credit card attached to venue.",
  "Bank account is attached to venue.": "Bank account is attached to venue.",
  "Audio spot is too long. Audio spot cannot exceed 30 seconds": "Audio spot is too long. Audio spot cannot exceed 30 seconds",
  "Unsupported audio format": "Unsupported audio format",
  "Unsupported file format": "Unsupported file format",
  "Your card is not supported.": "Your card is not supported.",
  "Your card number is incomplete.": "Your card number is incomplete.",
  "Your card's expiration date is incomplete.": "Your card's expiration date is incomplete.",
  "Your card's expiration date is in the past.": "Your card's expiration date is in the past.",
  "Your card's expiration year is in the past.": "Your card's expiration year is in the past.",
  "Your card's expiration year is invalid.": "Your card's expiration year is invalid.",
  "Your card's security code is incomplete.": "Your card's security code is incomplete.",
  "Venue questionary question is default question or is used in active/future active campaigns": "Venue questionary question is default question or is used in active/future active campaigns",
  "This additional service is already ordered": "This additional service is already ordered",
  "Client zone user already exists. Please, provide a new registration.": "Client zone user already exists. Please, provide a new registration.",
  "Attachment limit is 10MB.": "Attachment limit is 10MB.",
  "Attachment can have a maximum of 3 files.": "Attachment can have a maximum of 3 files.",
  "Max audio spot length is": "Max audio spot length is",
  "seconds": "seconds",
  "Premium discount code cannot be applied for Basic package": "Premium discount code cannot be applied for Basic package",
  "Basic discount code cannot be applied for Premium package": "Basic discount code cannot be applied for Premium package",
  "No such coupon": "Invalid discount coupon code",
  "Coupon expired": "Invalid discount coupon code",
  "Discount code is no longer valid.": "Discount code is no longer valid.",
  "Streaming is now played by another clientzone user or a streamer device.": "Streaming is now played by another clientzone user or a streamer device.",
  "All permitted venues are deselected.": "All permitted venues are deselected.",
  "We are unable to authenticate your payment method. Please choose a different payment method and try again.": "We are unable to authenticate your payment method. Please choose a different payment method and try again.",
  "Your role is not authorised to change the playlist": "Your role is not authorised to change the playlist",
  "The email you entered has not yet been verified, please check your email.": "E-mail, ktorý ste zadali, ešte nebol overený, skontrolujte prosím svoju emailovú schránku."
}
