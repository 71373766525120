import { CASSOVIA_API_RESOURCE } from "@/utils/helpers";
import { ActionTree } from "vuex";
import i18n from "@/i18n/i18n";
import { BillingState, RootState } from "@/types/store/clientzone";
import { UserData, VenueData } from "@/types";
import ApiService from "@/services/api.service";
import AxiosService from "@/services/axios.service";

export const actions: ActionTree<BillingState, RootState> = {
  FETCH_INVOICES: ({ commit, rootGetters }, { currentPage: currentPage, invoiceNumber: invoiceNumber = "" }): Promise<void> => {
    const companyId: number = rootGetters["companyModule/GET_COMPANY_ID"];
    const companyVenues: Array<VenueData> = rootGetters["venueModule/GET_VENUES"];
    const loggedUser: UserData = rootGetters['userModule/GET_LOGGED_USER'];

    let venueIdsParams = '';
    const foundPermissions = [];
    const currentPageParam: string = currentPage ? `&page=${currentPage}` : "";
    const invoiceNumberParam: string = invoiceNumber ? `&invoiceNumber=${invoiceNumber}` : "";

    return new Promise(async (resolve, reject) => {
      if (!loggedUser.isAdmin) {
        //TODO uncomment when allowed by SO BE
        // const resource: string = `/api/v1/clientzone/user/role_permission/venue_owner/${companyId}`
        // const venueOwnerSetRolePermissions = await ApiService.get(resource);
        // for(const venueOwnerSetPermission of venueOwnerSetRolePermissions.data){
        //   if(venueOwnerSetPermission.permission_id === 8) foundPermissions.push(venueOwnerSetPermission)
        // }
        // if(foundPermissions.length){
        //   for(const foundPermission of foundPermissions){
        //     const resource: string = `/api/v1/clientzone/user/venue_owner_role?offset=0&venue_owner_id=${companyId}&role_id=${foundPermission.role_id}&czone_user_id=${loggedUser.id}`
        //     const venuePermissions = await ApiService.get(resource);
        //     for(const venuePermission of venuePermissions.data){
        //       foundPermissions.push(venuePermission.venue_id)
        //     }
        //   }
        // }
        // if(foundPermissions.length){
        //   for (const allowedVenueId in foundPermissions){
        //     venueIdsParams += `&venueId%5B%5D=${allowedVenueId}`
        //   }
        // }
        for (const companyVenue of companyVenues) {
          const resource: string = `/api/v1/clientzone/user/role_permission/check?permission_id=8&venue_owner_id=${companyId}&venue_id=${companyVenue.id}`;
          const response = await ApiService.get(resource);
          if (response.data.found) {
            venueIdsParams += `&venueId%5B%5D=${companyVenue.id}`
          }
        }
      }
      const resource: string = `${CASSOVIA_API_RESOURCE}/api/invoices?venueOwnerId=${companyId}${currentPageParam}${invoiceNumberParam}${venueIdsParams}`;
      try {
        const response = await AxiosService.getAxios().get(resource);
        commit("SET_INVOICES", response.data);
        commit("SET_INVOICES_TOTAL_COUNT", response.headers['total-count'])

        resolve();
      } catch (e) {
        reject()
      }
    })
  },
  DOWNLOAD_INVOICE: ({ commit, getters }, invoiceId: number): Promise<void> => {
    commit("SET_LOADER", { invoiceId: invoiceId, value: true });
    const invoiceNumber: Function = getters["GET_INVOICE_NUMBER"];

    return new Promise(async (resolve, reject) => {
      const resource: string = `${CASSOVIA_API_RESOURCE}/api/download-invoice/${invoiceId}/${i18n.locale}`;

      try {
        const response = await AxiosService.getAxios().get(resource, {
          responseType: 'blob'
        });

        commit("SET_LOADER", { invoiceId: invoiceId, value: false });

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `invoice-${invoiceNumber(invoiceId)}.pdf`);
        document.body.appendChild(link);
        link.click();

        resolve();
      } catch (e) {
        commit("SET_LOADER", { invoiceId: invoiceId, value: false });
        reject()
      }
    })
  },
}
