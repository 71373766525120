import { state } from "@/state/modules/clientzone/browser_streamer/state";
import { mutations } from "@/state/modules/clientzone/browser_streamer/mutations";
import { getters } from "@/state/modules/clientzone/browser_streamer/getters";
import { actions } from "@/state/modules/clientzone/browser_streamer/actions";

const browserStreamerModule = {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}

export default browserStreamerModule
