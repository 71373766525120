import { GetterTree } from "vuex";
import {
  ArtistData,
  SongData,
  VenueAppFeaturesData,
  VenueData
} from "@/types";
import { RootState, VenueAppFeaturesState } from "@/types/store/clientzone";

export const getters: GetterTree<VenueAppFeaturesState, RootState> = {
  GET_SELECTED_VENUE: (state): VenueData => state.selectedVenue,
  GET_SELECTED_VENUE_ID: (state): number => state.selectedVenue.id,
  GET_VENUE_FINAL_FEATURES: (state): Array<VenueAppFeaturesData> => state.venueFinalFeatures,
  GET_FOUND_SONGS: (state): Array<SongData> => state.foundSongs,
  GET_FOUND_ARTISTS: (state): Array<ArtistData> => state.foundArtists,
  GET_CENSORED_SONGS: (state): Array<SongData> => state.censoredSongs,
  GET_CENSORED_ARTISTS: (state): Array<ArtistData> => state.censoredArtists,
}
