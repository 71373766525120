import Vue from "vue";

export const mutations = {
  CHANGE_LAYOUT(state, layoutType) {
    state.layoutType = layoutType;
  },
  CHANGE_LAYOUT_WIDTH(state, layoutWidth) {
    state.layoutWidth = layoutWidth;
  },
  CHANGE_LEFT_SIDEBAR_TYPE(state, leftSidebarType) {
    state.leftSidebarType = leftSidebarType;
  },
  CHANGE_TOPBAR(state, topbar) {
    state.topbar = topbar;
  },
  LOADER(state, loader) {
    state.loader = loader
  },
  // SET_MENU_VENUE_IDS: (state, payload: number): void => {
  //   const venuesMenuIndex = state.clientZoneMenuItems.findIndex(i => i.label === "Venues");
  //   const subItem1 = state.clientZoneMenuItems[venuesMenuIndex].subItems.findIndex(i => i.label === "Venues detail");
  //   const subItem2 = state.clientZoneMenuItems[venuesMenuIndex].subItems.findIndex(i => i.label === "App features setup");
  //
  //   Vue.set(state.clientZoneMenuItems[venuesMenuIndex].subItems[subItem1], "link", `/venue-detail/${payload}`);
  //   Vue.set(state.clientZoneMenuItems[venuesMenuIndex].subItems[subItem2], "link", `/app-features-setup/${payload}`);
  // },
  SET_TOGGLE_MENU_STATE: (state, payload: boolean): boolean => Vue.set(state, "toggleMenuState", payload),
  SET_BROWSER_STREAMER_STATE: (state, payload: boolean): boolean => Vue.set(state, "canShowBrowserStreamer", payload),
  SET_GLOBAL_OVERLAY_SPINNER_STATE: (state, payload: boolean): boolean => Vue.set(state, "isOverlaySpinnerRunning", payload)
}
