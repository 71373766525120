import { ActionTree } from "vuex";
import { successMessage, errorMessage } from "@/utils/messages-handlers";
import { DataFeedState } from "@/types/store/adminzone/datafeed";
import { RootState } from "@/types/store/adminzone";
import { CASSOVIA_API_RESOURCE } from "@/utils/helpers";
import AxiosService from "@/services/axios.service";

export const actions: ActionTree<DataFeedState, RootState> = {
  FETCH_DATAFEED: ({commit, dispatch},payload): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      const resource: string = `${CASSOVIA_API_RESOURCE}/api/invoice-data-feeds?page=${payload.page}`;
      const second_resource: string = `${CASSOVIA_API_RESOURCE}/api/invoice-data-feed-payment-instruments?page=${payload.page}`;

      try {
        const response = await AxiosService.getAxios().get(resource)
        const second_response = await AxiosService.getAxios().get(second_resource)

        commit("SET_DATAFEED", response.data);
        commit("SET_DATAPAYIN", second_response.data);

        resolve();
      } catch (e) {
        reject()
      }
    })
  },

  CREATE_BANK: (context, payload): Promise<void> => {

    return new Promise(async (resolve, reject) => {
      const resource: string = `${CASSOVIA_API_RESOURCE}/api/invoice-data-feed-payment-instruments`;
      try {
        await AxiosService.getAxios().post(resource, payload.data)
        successMessage("Bank created successfully", 3000);

        resolve();
      } catch (e) {
        if(e.response.status == 422 ){
          errorMessage(e.response.data.detail, 3000);
        }
        reject()
      }
    })
  },
  UPDATE_DATAFEED: (context, payload): Promise<void> => {

    return new Promise(async (resolve, reject) => {
      const resource: string = `${CASSOVIA_API_RESOURCE}/api/invoice-data-feeds/${payload.id}`;
      const temp=[]
      try {
        payload.banks.forEach(async item=>{
          const {id,...ex}=item
          // console.log(id)
          const sec_resource: string = `${CASSOVIA_API_RESOURCE}/api/invoice-data-feed-payment-instruments/${id}`;
          temp.push(AxiosService.getAxios().patch(sec_resource, ex, {
            headers: {
              'Content-Type': 'application/merge-patch+json'
            }
          }))
        })
        await Promise.all(temp)
        await AxiosService.getAxios().patch(resource, payload.data, {
          headers: {
            'Content-Type': 'application/merge-patch+json'
          }
        })
        // console.log(response)

        // commit("SET_COMPANY", response.data);
        successMessage("Datafeed updated successfully", 3000);

        resolve();
      } catch (e) {
        errorMessage(e.message,3000)
        reject()
      }
    })
  },
}
