import PopupsSK from './popups/sk';

export default {
  ...(PopupsSK),
  "List of Audio Spots": "Zoznam audio spotov",
  "List of audio spots": "Zoznam audio spotov",
  "Messages to customers": "Správy zákazníkom",
  "Add New Campaign": "Pridať novú kampaň",
  "Add new campaign": "Pridať novú kampaň",
  "Add Audio Spot": "Pridať audio spot",
  "Campaign Name": "Názov kampane",
  "Campaign period": "Obdobie kampane",
  "Timeslot": "Časový úsek",
  "Show only active campaigns": "Zobraziť iba aktívne kampane",
  "Venue filter": "Filter prevádzok",
  "Venues assigned to the campaign": "Prevádzky priradené ku kampani",
  "Venues assigned to the survey": "Prevádzky priradené tomuto prieskumu",
  "Spot name": "Názov spotu",
  "Music": "Hudba",
  "Text": "Text",
  "Uploaded by": "Nahral",
  "Duration": "Trvanie",
  "Type of the message": "Typ správy",
  "Reach": "Dosah",
  "Choose venues": "Zvoliť prevádzky",
  "Message max characters": "Správa (max {count} znakov)",
  "Send a message": "Poslať správu",
  "Messages history": "História správ",
  "Message type": "Typ správy",
  "Date": "Dátum",
  "Reach (Songoroo app users)": "Dosah (používatelia Songoroo aplikácie)",
  "Announcement": "Oznámenie",
  "Advertisement": "Reklama",
  "List of questions": "Zoznam otázok",
  "List Of Questions": "Zoznam otázok",
  "Add New Survey": "Pridať nový prieskum",
  "Add new survey": "Pridať nový prieskum",
  "Survey": "Prieskum",
  "Add new question": "Pridať novú otázku",
  "Questions": "Otázky",
  "Question": "Otázka",
  "Created at": "Vytvorené dňa",
  "Created by": "Vytvorená užívateľom",
  "Select type of your message": "Zvoľte typ správy",
  "Choose campaign questions": "Zvoľte otázky prieskumu",
  "Campaign duration": "Trvanie kampane",
  "Message": "Správa",
  "characters": "znakov",
  "This venue has BASIC subscription package not covering sending messages to costumers of a particular venue": "Pre túto prevádzku je zakúpený balík služieb BASIC, ktorý nezahŕňa zasielanie správ zákazníkom danej prevádzky",
  "Sent on": "Zaslané dňa",
  "Back to audio commercials": "Späť na audio reklamu",
  "Back to venue survey": "Späť na hodnotenie prevádzky",
  "This spot cannot be deleted as it is attached to advert campaign.": "Tento audio spot nemôže byť vymazaný, keďže je priradený k audio kampani. ",
  "No audio spots were found": "Neboli nájdené žiadne reklamy",
  "Your message has been sent to": "Vaša správa bola zaslaná",
  "Songoroo app users": "užívateľom Songoroo mobilnej aplikácie",
  "Default survey": "Všeobecné hodnotenie prevádzky"

}
