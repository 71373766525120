import { GetterTree } from "vuex";
import {
  CountsData,
  ListsData,
  StatsData, TopAddSongsData, TopBoostsData,
  TopVotedArtistsData,
  TopVotedSongsData,
  FilterData
} from "@/types";
import { fillWithMockData } from "@/utils/helpers";
import { DashboardState, RootState } from "@/types/store/clientzone";

export const getters: GetterTree<DashboardState, RootState> = {
  GET_COUNTS: (state): CountsData => state.counts,
  GET_LISTS: (state): ListsData => state.lists,
  GET_STATS: (state): StatsData => state.stats,
  GET_FILTER: (state): FilterData => state.filter,
  GET_FILTER_1: (state, getters, rootState, rootGetters): string => {
    const companyId: number = rootGetters["companyModule/GET_COMPANY_ID"];

    if (state.filter.created_at) {
      const timestamp = Number(state.filter.start_at) * 1000;
      const date = new Date(timestamp);
      return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    } else {
      const date = new Date();
      const month = date.getMonth() + 1;
      const formattedMonth = (month < 10 ? "0" : "") + month;
      const formattedDay = (date.getDate() < 10 ? "0" : "") + date.getDate();

      return `${date.getFullYear()}-${formattedMonth}-${formattedDay}`;
    }
  },
  GET_START_AT: (state): string => {
    if (state.filter.created_at) {
      const timestamp = Number(state.filter.start_at) * 1000;
      const date = new Date(timestamp);
      const month = date.getMonth() + 1;
      const formattedMonth = (month < 10 ? "0" : "") + month;
      const formattedDay = (date.getDate() < 10 ? "0" : "") + date.getDate();
      return `${date.getFullYear()}-${formattedMonth}-${formattedDay}`;
    } else {
      const period = 30;
      const date = new Date();
      const startDate: Date = new Date(date.setDate(new Date().getDate() - period));

      const month = startDate.getMonth() + 1;
      const formattedMonth = (month < 10 ? "0" : "") + month;
      const formattedDay = (startDate.getDate() < 10 ? "0" : "") + startDate.getDate();

      return `${startDate.getFullYear()}-${formattedMonth}-${formattedDay}`;
    }
  },
  GET_END_AT: (state): string => {
    if (state.filter.created_at) {
      const timestamp = Number(state.filter.end_at) * 1000;
      const date = new Date(timestamp);
      const month = date.getMonth() + 1;
      const formattedMonth = (month < 10 ? "0" : "") + month;
      const formattedDay = (date.getDate() < 10 ? "0" : "") + date.getDate();
      return `${date.getFullYear()}-${formattedMonth}-${formattedDay}`;
    } else {
      const date = new Date();
      const month = date.getMonth() + 1;
      const formattedMonth = (month < 10 ? "0" : "") + month;
      const formattedDay = (date.getDate() < 10 ? "0" : "") + date.getDate();

      return `${date.getFullYear()}-${formattedMonth}-${formattedDay}`;
    }
  },
  GET_SELECTED_VENUES_IDS: (state, getters, rootState, rootGetters): Array<number> => {
    const venues: any = rootGetters["venueModule/GET_VENUES"];
    return venues.map(v => v.id);

    // #3938 tento if sposobuje posielanie nespravnych venue_ids v requestoch
    // if (state.filter.created_at) {
    //   return state.filter.venue_ids;
    // } else {
    //   return venues.map(v => v.id);
    // }
  },
  GET_TOP_VOTED_SONGS: (state, getters): Array<TopVotedSongsData> => {
    const listsState: ListsData = getters["GET_LISTS"];

    return fillWithMockData(listsState.top_voted_songs, 0, 1, {
      name: "No data",
      artist_name: "No data",
      votes: "No data",
      mock_value: true
    })
  },
  GET_TOP_VOTED_ARTISTS: (state, getters): Array<TopVotedArtistsData> => {
    const listsState: ListsData = getters["GET_LISTS"];

    return fillWithMockData(listsState.top_voted_artists, 0, 1, {
      name: "No data",
      votes: "No data",
      mock_value: true
    })
  },
  GET_TOP_ADD_SONGS: (state, getters): Array<TopAddSongsData> => {
    const listsState: ListsData = getters["GET_LISTS"];

    return fillWithMockData(listsState.top_add_songs, 0, 1, {
      name: "No data",
      artist_name: "No data",
      mock_value: true
    })
  },
  GET_TOP_ADD_BOOSTS: (state, getters): Array<TopBoostsData> => {
    const listsState: ListsData = getters["GET_LISTS"];

    return fillWithMockData(listsState.top_boosts, 0, 1, {
      song_name: "No data",
      artist_name: "No data",
      mock_value: true
    })
  }
}
