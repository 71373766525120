import { CASSOVIA_API_RESOURCE } from "@/utils/helpers";
import { ActionTree } from "vuex";
import { RootState, TaxPricePeriodTranslationState } from "@/types/store/clientzone";
import AxiosService from "@/services/axios.service";

export const actions: ActionTree<TaxPricePeriodTranslationState, RootState> = {
  FETCH_TAX_PRICE_PERIOD_TRANSLATION: ({ commit }): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      const resource: string = `${CASSOVIA_API_RESOURCE}/api/price-period-tax-translations`;
      try {
        const response = await AxiosService.getAxios().get(resource)
        commit("SET_TAX_PRICE_PERIOD_TRANSLATION", response.data)
        resolve()
      } catch (e) {
        reject()
      }
    })
  }
}
