import ApiService from "@/services/api.service";
import { ActionTree } from "vuex";
import { ArtistData, SongData } from "@/types";
import { RootState, VenueAppFeaturesState } from "@/types/store/clientzone";

export const actions: ActionTree<VenueAppFeaturesState, RootState> = {
  FETCH_VENUE: ({ commit, dispatch }, venueId: number): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      const resource: string = `api/v1/clientzone/venue/${venueId}`;

      try {
        const response = await ApiService.get(resource);

        commit("SET_VENUE", response.data);
        await dispatch("FETCH_VENUE_FEATURES", response.data.id);

        resolve();
      } catch (e) {
        reject()
      }
    })
  },
  FETCH_VENUE_FEATURES: ({ commit }, venueId: number): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      const resource: string = `/api/v1/clientzone/venue/${venueId}/features`;

      try {
        const response = await ApiService.get(resource);

        commit('SET_VENUE_FINAL_FEATURES', response.data.final_features);

        resolve();
      } catch (e) {
        reject()
      }
    })
  },
  UPDATE_VENUE_FEATURE: ({ getters, dispatch }, payload: object): Promise<void> => {
    const venueId: number = getters["GET_SELECTED_VENUE_ID"];

    return new Promise(async (resolve, reject) => {
      const resource: string = `/api/v1/clientzone/venue/${venueId}/feature`;

      try {
        const response = await ApiService.put(resource, payload);

        await dispatch("FETCH_VENUE_FEATURES", venueId);

        resolve();
      } catch (e) {
        reject()
      }
    })
  },
  SEARCH_SONGS: ({ commit }, name = ""): Promise<void> => {
    const formatName = name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
    return new Promise(async (resolve, reject) => {
      const resource: string = `/api/v1/clientzone/song/search?limit=50&artists_limit=0&name=${formatName}`;

      try {
        const response = await ApiService.get(resource);

        commit("SET_FOUND_SONGS", response.data.songs);

        resolve();
      } catch (e) {
        reject()
      }
    })
  },
  SEARCH_ARTISTS: ({ commit }, name = ""): Promise<void> => {
    const formatName = name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
    return new Promise(async (resolve, reject) => {
      const resource: string = `/api/v1/clientzone/song/search?limit=0&artists_limit=9&name=${formatName}`;

      try {
        const response = await ApiService.get(resource);

        commit("SET_FOUND_ARTISTS", response.data.artists);

        resolve();
      } catch (e) {
        reject()
      }
    })
  },
  FETCH_CENSORED_SONGS: ({ commit }, venueId: number): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      const resource: string = `/api/v1/clientzone/venue/${venueId}/censor_song?limit=1000`;

      try {
        const response = await ApiService.get(resource);

        commit("SET_CENSORED_SONGS", response.data);

        resolve();
      } catch (e) {
        reject()
      }
    })
  },
  FETCH_CENSORED_ARTISTS: ({ commit }, venueId: number): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      const resource: string = `/api/v1/clientzone/venue/${venueId}/censor_artist?limit=1000`;

      try {
        const response = await ApiService.get(resource);

        commit("SET_CENSORED_ARTISTS", response.data);

        resolve();
      } catch (e) {
        reject()
      }
    })
  },
  UPDATE_CENSORED: ({ getters, dispatch }, { id, isCensored = true, type }): Promise<void> => {
    const venueId: number = getters["GET_SELECTED_VENUE_ID"];
    const censoredSongs: Array<SongData> = getters["GET_CENSORED_SONGS"];
    const censoredArtists: Array<ArtistData> = getters["GET_CENSORED_ARTISTS"];
    let idsArray: Array<number>;

    if (type === "censor_song") {
      if (isCensored) {
        const songs: Array<SongData> = censoredSongs.filter(s => s.id !== id);
        idsArray = songs.map(s => s.id);
      } else {
        const songs: Array<number> = censoredSongs.map(s => s.id);
        songs.push(id)
        idsArray = songs;
      }
    } else {
      if (isCensored) {
        const artists: Array<ArtistData> = censoredArtists.filter(s => s.id !== id);
        idsArray = artists.map(s => s.id);
      } else {
        const artists: Array<number> = censoredArtists.map(s => s.id);
        artists.push(id)
        idsArray = artists;
      }
    }

    const payload: object = {
      type: type,
      option: idsArray
    }

    return new Promise(async (resolve, reject) => {
      const resource: string = `/api/v1/clientzone/venue/${venueId}/feature`;

      try {
        await ApiService.put(resource, payload);

        if (type === "censor_song") await dispatch("FETCH_CENSORED_SONGS", venueId);
        else await dispatch("FETCH_CENSORED_ARTISTS", venueId);
        if (!idsArray.length) {
          await dispatch('REMOVE_RULE_FROM_VENUE', { venueId: venueId, type: type })
        }
        resolve();
      } catch (e) {
        reject()
      }
    })
  },
  REMOVE_RULE_FROM_VENUE: ({ commit }, { venueId: venueId, type: type }): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      const resource: string = `/api/v1/clientzone/venue/${venueId}/feature?type=${type}`
      try {
        const response = await ApiService.delete(resource);
        resolve();
      } catch (e) {
        reject()
      }
    })
  },
  SET_SONG_IMAGE: ({ commit }, payload): void => commit("SET_SONG_IMAGE", payload),
  SET_FOUND_SONG_IMAGE: ({ commit }, payload): void => commit("SET_FOUND_SONG_IMAGE", payload),
  SET_ARTIST_IMAGE: ({ commit }, payload): void => commit("SET_ARTIST_IMAGE", payload),
  SET_FOUND_ARTIST_IMAGE: ({ commit }, payload): void => commit("SET_FOUND_ARTIST_IMAGE", payload),
  RESET_SEARCH_RESULTS: ({ commit }): void => commit("RESET_SEARCH_RESULTS", [])
}
