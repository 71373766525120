export default {
  "Invoice number": "Invoice Number",
  "Subscription period": "Subscription Period",
  "Issue date": "Issue Date",
  "Payment method": "Payment Method",
  "Amount": "Amount",
  "Due Date": "Due Date",
  "Status": "Status",
  "Export": "Export",
  "Paid": "Paid",
  "Past due": "Past Due",
  "Not due": "Not Due",
  "Credit invoice": "Credit Invoice",
  "Credit/Debit card": "Credit/Debit Card",
  "Invoice": "Invoice",
  "Venue Name": "Venue Name",
  "Address": "Address",
  "Detail": "Detail",
  "Subscription details": "Subscription Details",
  "Change Subscription Plan": "Change Subscription Plan",
  "Cancel Subscription Plan": "Cancel Subscription Plan",
  "Expiration date": "Expiration Date",
  "Duration date": "Duration date",
  "Learn more": "Learn More",
  "Renew Subscription Plan": "Renew Subscription Plan",
  "Audio commercials and messages": "Audio Commercials And Messages",
  "Songoroo equips you with a tool enabling the administration of the audio messages in your business at no extra costs. You can easily upload your own messages and commercials and set rules where and when these should be played.": "Songoroo equips you with a tool enabling the administration of the audio messages in your business at no extra costs. You can easily upload your own messages and commercials and set rules where and when these should be played.",
  "Order": "Order"
}
