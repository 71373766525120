export default {
  "Songoroo Solutions": "Songoroo Solutions",
  "Portfolio": "Portfolio",
  "How to": "How to",
  "Company": "Company",
  "Connect": "Connect",
  "Interactive in-store radio": "Interactive in-store radio",
  "Songoroo Algorithm": "Songoroo Algorithm",
  "Mobile Apps": "Mobile Apps",
  "Reporting & App-User-Statistics": "Reporting & App User Statistics",
  "Pricing": "Pricing",
  "Food & Dining": "Food & Dining",
  "Café & Pubs": "Café & Pubs",
  "Retail": "Retail",
  "Hotels & Offices": "Hotels & Offices",
  "Club & Lounge": "Club & Lounge",
  "Workout & Fitness": "Workout & Fitness",
  "Spa, Wellness & Relax": "Spa, Wellness & Relax",
  "Public places": "Public places",
  "Events & Special occasions": "Events & Special occasions",
  "... many more": "... many more",
  "App Features": "App Features",
  "Music Streamer Setup": "Music Streamer Setup",
  "Support": "Support",
  "About Us": "About us",
  "Testimonials": "Testimonials",
  "Partnership": "Partnership",
  "Contact": "Contact",
  "Careers": "Careers",
}
