import { state } from "@/state/modules/clientzone/billing/state";
import { mutations } from "@/state/modules/clientzone/billing/mutations";
import { getters } from "@/state/modules/clientzone/billing/getters";
import { actions } from "@/state/modules/clientzone/billing/actions";

const billingModule = {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}

export default billingModule
