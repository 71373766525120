export default {
  "Business Name": "Obchodné meno (ochranná známka)",
  "Business Name subtext": "Názov spoločnosti (Obchodná značka) pod ktorou vystupuje spoločnosť smerom k svojim zákazníkom",
  "Business type": "Typ prevádzky",
  "Name of the establishment": "Názov prevádzky",
  "Legal entity name": "Názov spoločnosti",
  "Legal entity subtext": "Presný názov spoločnosti podľa Obchodného registra - potrebné pre fakturačné účely",
  "Street and descriptive house number": "Ulica a popisné číslo domu",
  "Street and descriptive number": "Ulica a popisné číslo",
  "Postal code": "Poštové smerovacie číslo",
  "City": "Mesto",
  "Country": "Krajina",
  "VAT number": "IČ DPH",
  "VAT ID": "IČ DPH",
  "Business Identification Number": "IČO",
  "Bank name": "Názov banky",
  "IBAN account": "IBAN účet",
  "Swift code": "Swift kód",
  "Zip code": "PSČ",
  "GPS latitude": "GPS zemepisná šírka",
  "GPS longitude": "GPS zemepisná dĺžka",
  "Billing email": "Fakturačný e-mail",
  "Contact person": "Kontaktná osoba",
  "Contact persons phone": "Telefón kontaktnej osoby",
  "Contact persons email": "Email kontaktnej osoby",
  "Company Logo": "Logo Spoločnosti",
  "Upload company logo description": "Logo spoločnosti sa zobrazí v aplikácii Songoroo v zozname prevádzok plus v detaile prevádzky. Preto vám dôrazne odporúčame nahrať logo spoločnosti, podľa ktorého bude vaša prevádzka ľahko rozpoznateľná od ostatných prevádzok v zozname.",
  "Upload Company logo": "Nahrať logo spoločnosti",
  "Change company logo": "Zmeniť logo spoločnosti",
  "Save": "Uložiť",
  "Select company": "Vybrať spoločnosť",
  "Company registration date": "Dátum registrácie spoločnosti",
}
