export default {
  "Venues": "Venues",
  "Ratings": "{count} Ratings | {count} Rating | {count} Ratings",
  "Edit venue": "Edit Venue",
  "Venue detail": "Venue Detail",
  "Mobile app features": "Mobile App Features",
  "Venue information": "Venue Information",
  "Time zone": "Time Zone",
  "GPS": "GPS: Latitude And Longitude",
  "Venue Rating": "Venue Rating",
  "Campaign Filter": "Campaign Filter",
  "Choose Filter": "Choose Filter",
  "User ratings": "User Ratings",
  "Your response was sent.": "Your response was sent.",
  "Reply": "Reply",
  "Filter": "Filter",
  "All venues": "All venues",
  "Venue name": "Venue Name",
  "Show all venues": "Show All Venues",
  "Subscription": "Subscription",
  "None": "None",
  "Subscription expires on:": "Subscription Expires On:",
  "Change venue photo": "Change Venue Photo",
  "Please add a couple of sentences about this venue. This short description will be visible in Songoroo mobile app in Venue detail section.": "Please add a couple of sentences about this venue. This short description will be visible in Songoroo mobile app in Venue detail section.",
  "GPS location of your venue where visitors can find you.": "GPS location of your venue where visitors can find you.",
  "Time zone managing the opening hours of your venue.": "Time zone managing the opening hours of your venue.",
  "Venue Address": "Venue Address",
  "Venue name cannot be empty": "Venue name cannot be empty",
  "Back to venues list": "Back To Venues List",
  "No active venue": "No active venue",
  "Sorry, you have no active venue for which you could setup Songoroo App features.": "Sorry, you have no active venue for which you could setup Songoroo App features.",
}
