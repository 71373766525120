import {
  CountryCodes,
  NewVenueSubPlanData,
  UserAuthorisationsVenuesData,
  VenueData,
  VenueOpeningHours, VenueRatingAnswers, VenueRatingData, VenueRegisterData,
} from "@/types";
import { VenueState } from "@/types/store/clientzone";

export const state: VenueState = {
  venues: [] as Array<VenueData>,
  availableVenuesForRoleManagement: [] as Array<VenueData>,
  selectedVenue: {} as VenueData,
  userAuthorisationsVenues: [] as Array<UserAuthorisationsVenuesData>,
  venueOpeningHours: [] as Array<VenueOpeningHours>,
  newVenueSubPlan: {} as NewVenueSubPlanData,
  newVenueId: -1,
  countryCodes: [] as Array<CountryCodes>,
  groupLoader: false,
  venueRatings: {} as VenueRatingData,
  venueAnswers: [] as Array<VenueRatingAnswers>,
  registerVenue: {} as VenueRegisterData,
  loader: false,
  initVenueRegister: false,
  errorOnInvalidCreditCard: false,
  createVenueLoader: false,
  newCreateVenueId: null,
  selectedWebstreamVenue: null,
}
