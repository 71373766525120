import { state } from "@/state/modules/clientzone/company/state";
import { mutations } from "@/state/modules/clientzone/company/mutations";
import { getters } from "@/state/modules/clientzone/company/getters";
import { actions } from "@/state/modules/clientzone/company/actions";

const companyModule = {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}

export default companyModule
