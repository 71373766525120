export default {
    "Help & Support": "Pomoc a podpora",
    "Tech support": "Technická podpora",
    "Sales support": "Podpora predeje",
    "Submit a request": "Odeslat žádost",
    "How can we help?": "Jak můžeme pomoci?",
    "Popular topics": "Oblíbená témata",
    "Connect with Sonos": "Spojte se se Sonos",
    "How to schedule music": "Jak naplánovat hudbu",
    "Play music legally": "Přehrávejte hudbu legálně",
    "Can't find what you're looking for?": "Nemůžete najít to, co hledáte?",
    "Send us your question": "Pošlete nám svůj dotaz",
    "Getting started": "Začíname",
    "Hardware & IT matters": "Hardvér a IT",
    "Client Account Management": "Správa klientského účtu",
    "Music Playlists": "Hudobní playlisty",
    "Music Licensing": "Licencovaní hudby",
    "Songoroo Apps & App Features": "Aplikace a funkce aplikace Songoroo",
    "Subscriptions & Payments": "Balíček služeb a platby",
    "Client Dashboard and Reports": "Klientský dashboard a reporty",
    "Troubleshooting": "Řešení problémů",
    "Please choose from the topics below": "Vyberte si z následujících témat níže",
    "Subject": "Predmet",
    'Active Questionary': "Aktivní",
    "Inactive Questionary": "Neaktivní",
    "Description word": "Obsah dotazu",
    "Please enter the details of your request. A member of our support staff will respond as soon as possible.": "Zadejte prosím podrobnosti svého dotazu. Naši členové podpory vám odpoví co nejdříve.",
    "Attachments": "Prílohy",
    "Add file": "Přidat soubor",
    "or drop files here": "nebo přesunout sem",
    "Please choose from the topics.": "Vyberte si z témat.",
    "Phone number is required.": "Je vyžadováno telefonní číslo.",
    "Only numbers are allowed.": "Povolena jsou pouze čísla.",
    "Business name is required.": "Obchodní jméno je povinné.",
    "Subject is required.": "Předmět je povinný.",
    "Description is required.": "Obsah dotazu je povinný",
    "General": "Iné",
    "Hardware & IT": "Hardware & IT",
    "Client account": "Správa klientského účtu",
    "Music playlist": "Seznamy hudby",
    "Music licensing": "Licencovaní hudby",
    "Mobile apps": "Aplikace a funkce aplikace Songoroo",
    "Billing": "Předplatné a platby",
    "Reports": "Dashboard a reporty",
    "Survey name": "Název průzkumu",
    "Survey Name": "Název průzkumu",
    "Show only active surveys": "Zobrazit pouze aktivní průzkumy",
    "Edit Audio Advertisement Campaign": "Úprava audio reklamní kampaně",
}  
