import { state } from "@/state/modules/adminzone/az_emails/state";
import { mutations } from "@/state/modules/adminzone/az_emails/mutations";
import { getters } from "@/state/modules/adminzone/az_emails/getters";
import { actions } from "@/state/modules/adminzone/az_emails/actions";

const azEmailsModule = {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}

export default azEmailsModule
