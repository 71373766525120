import Vue from "vue";
import {MutationTree} from "vuex";
import {CountsData, ListsData, StatsData,FilterData} from "@/types";
import { DashboardState } from "@/types/store/clientzone";

export const mutations: MutationTree<DashboardState> = {
  SET_COUNTS: (state, payload: CountsData): CountsData => Vue.set(state, "counts", payload),
  SET_LISTS: (state, payload: ListsData): ListsData => Vue.set(state, "lists", payload),
  SET_STATS: (state, payload: StatsData): StatsData => Vue.set(state, "stats", payload),
  SET_FILTER: (state, payload: FilterData): FilterData => Vue.set(state, "filter", payload),
  SET_SELECTED_VENUES_IDS: (state, payload): void => {
    if (!!state.selectedVenuesIds.find(v => v.companyId === payload.companyId)) {
      const index: number = state.selectedVenuesIds.findIndex(v => v.companyId === payload.companyId)
      Vue.set(state.selectedVenuesIds[index], "venuesIds", payload.payload)
    } else {
      state.selectedVenuesIds.push({ companyId: payload.companyId, venuesIds: payload.payload})
    }
  },
  SET_DASHBOARD_FILTER_TO_DEFAULT: (state, payload: Array<any>): void => {
    Vue.set(state, "startAt", payload);
    Vue.set(state, "endAt", payload);
    Vue.set(state, "selectedVenuesIds", payload);
  }
}
