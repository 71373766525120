import { ActionTree } from "vuex";
import { TrialPeriodState } from "@/types/store/clientzone/trial_period";
import { RootState } from "@/types/store/clientzone";
import { CASSOVIA_API_RESOURCE } from "@/utils/helpers";
import AxiosService from "@/services/axios.service";

export const actions: ActionTree<TrialPeriodState, RootState> = {
  FETCH_TRIAL_PERIOD: ({commit, dispatch}): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      const resource: string = `${CASSOVIA_API_RESOURCE}/api/free-trial-periods?page=1`;
      try{
        const response = await AxiosService.getAxios().get(resource)
        commit("SET_TRIAL_PERIOD", response.data)
        resolve();
      } catch (e){
        reject()
      }
    })
  }
}
