import { state } from "@/state/modules/clientzone/venue_app_features/state";
import { mutations } from "@/state/modules/clientzone/venue_app_features/mutations";
import { getters } from "@/state/modules/clientzone/venue_app_features/getters";
import { actions } from "@/state/modules/clientzone/venue_app_features/actions";

const venueAppFeaturesModule = {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}

export default venueAppFeaturesModule
