import {
  UserAuthorisationsVenuesData,
  VenueData,
  VenueOpeningHoursData,
  VenueRegisterData,
  SelectedWebstreamSelectVenueData,
} from "@/types";
import {GetterTree} from "vuex";
import { RootState, VenueState } from "@/types/store/clientzone";

export const getters: GetterTree<VenueState, RootState> = {
  GET_VENUE_STATE: (state): VenueState => state,
  GET_SELECTED_VENUE: (state) => state.selectedVenue,
  GET_SELECTED_VENUE_ID: (state) => {
    if (state.venues.length) {
      return state.venues[0].id ? state.venues[0].id : localStorage.getItem("venue_id");
    } else {
      return 1;
    }
  },
  GET_VENUES: (state): Array<VenueData> => state.venues,
  GET_USER_AUTHORISATIONS_VENUES: (state): Array<UserAuthorisationsVenuesData> => state.userAuthorisationsVenues,
  GET_VENUE_OPENING_HOURS: (state): Array<VenueOpeningHoursData> => state.venueOpeningHours,
  GET_NEW_VENUE_SUB_PLAN: (state) => state.newVenueSubPlan,
  GET_NEW_VENUE_ID: (state) => state.newVenueId,
  IS_VENUE_IMAGE: (state) => !!state.selectedVenue.image_last_updated,
  GET_COUNTRY_CODES: (state) => state.countryCodes,
  GET_GROUP_ALL_LOADER: (state): boolean => state.groupLoader,
  GET_VENUES_DETAIL_RATINGS: (state) => state.venueRatings,
  GET_VENUES_DETAIL_ANSWERS: (state) => state.venueAnswers,
  GET_VENUE_REGISTER_DATA: (state): VenueRegisterData => state.registerVenue,
  GET_VENUE_LOADER: (state): boolean => state.loader,
  GET_INIT_VENUE_REGISTER: (state): boolean => state.initVenueRegister,
  TAX_IS_NOT_VALID: (state, getters, rootState, rootGetters): boolean => {
    const companyCountryId: number = rootGetters["companyModule/GET_COMPANY"].billing_country_id;
    const venueCountryId: number = getters["GET_SELECTED_VENUE"].country_id;
    const companyVat: string = rootGetters["companyModule/GET_COMPANY"].billing_vat;

    if(companyVat && companyCountryId != 1 && venueCountryId != 1){
      return false;
    }else if (companyCountryId == 1 && venueCountryId == 1){
      return true;
    } else {
      return true;
    }
  },
  GET_CREATE_VENUE_LOADER: (state): boolean => state.createVenueLoader,
  GET_ERROR_ON_INVALID_CREDIT_CARD: (state): boolean => state.errorOnInvalidCreditCard,
  GET_NEW_CREATED_VENUE_ID: (state): number => state.newCreateVenueId,
  GET_AVAILABLE_VENUES_FOR_ROLE_MANAGEMENT: (state): Array<VenueData> => state.availableVenuesForRoleManagement,
  GET_SELECTED_WEBSTREAM_VENUE: (state): SelectedWebstreamSelectVenueData | null => state.selectedWebstreamVenue,
}
