import { CompanyRegisterData, UserRegisterData, VenueGoogleData, VenueRegisterData, VenuesFoundData, VenuesSlovenskoDigitalData } from "@/types";
import { RegisterState } from "@/types/store/clientzone/register";

export const state: RegisterState = {
  venue: {} as VenueRegisterData,
  user: {} as UserRegisterData,
  company: {} as CompanyRegisterData,
  venueGoogleData: {} as VenueGoogleData,
  venuesSlovenskoDigital: [] as Array<VenuesSlovenskoDigitalData>,
  userId: -1,
  venueId: -1,
  companyId: -1,
  venuesFound: [] as Array<VenuesFoundData>,
  venueIndex: null,
  existCompanyWithVat: false,
  existCompanyWithName: false,
  existCompanyWithBin: false,
  existUserWithEmail: false,
  isSearching: false,
  newRegisteredUserId: null,
  newRegisteredVenueId: null,
  venueRegisterSubscription: {},
  initRegister: false,
  referrerLink: "",
  registerFilledDataId: "",
  registerFail: false,
  createRegistrationLoader: false,
  loginsCount: 0,
  savedVenueSubscriptionData: {}
}
