import {GetterTree} from "vuex";
import {CZUserNotificationData, NotificationData} from "@/types";
import { NotificationsState, RootState } from "@/types/store/clientzone";

export const getters: GetterTree<NotificationsState, RootState> = {
  GET_CZ_USER_NOTIFICATIONS: (state): Array<CZUserNotificationData> => state.czUserNotifications,
  GET_NOTIFICATIONS: (state): Array<NotificationData> => {
    if (state.czUserNotifications.length && state.czUserNotifications.length === state.notifications.length) {
      return state.notifications.slice().sort((n1, n2) => (n2.createdAt - n1.createdAt));
    }

    return [];
  },
  GET_UNCHECKED_NOTIFICATIONS_LENGTH: (state): number => state.uncheckedNotificationsLength
}
