import { ActionTree } from "vuex";
import { RootState, VenueAdminzoneState } from "@/types/store/adminzone";
import ApiService from "@/services/api.service";

export const actions: ActionTree<VenueAdminzoneState, RootState> = {
  FIND_VENUES_BY_NAME: ({commit}, name: string): Promise<void> => {
    return new Promise(async (resolve, reject)=> {
      const resource: string = `api/v1/clientzone/venue?name=${name}&offset=0&include_deleted=true`;

      try{
        const response = await ApiService.get(resource)
        commit("SET_VENUES", response.data)
        resolve();
      }catch (e){
        reject()
      }
    })
  },

  FETCH_ALL_VENUES: ({commit}): Promise<void> => {
    return new Promise(async (resolve, reject)=> {
      const resource: string = `api/v1/clientzone/venue_type?type=all`;

      try{
        const response = await ApiService.get(resource)
        commit("SET_ALL_VENUES", response.data)
        resolve();
      }catch (e){
        reject()
      }
    })
  }
}
