import Vue from "vue";
import {MutationTree} from "vuex";
import {AdvertCampaignData} from "@/types";
import {AudioSpotData} from "@/types/audio_commercials";
import { AudioCommercialsState } from "@/types/store/clientzone";

export const mutations: MutationTree<AudioCommercialsState> = {
  SET_AUDIO_SPOTS: (state, payload: Array<AudioSpotData>): Array<AudioSpotData> => Vue.set(state, "audioSpots", payload),
  SET_ADVERT_CAMPAIGNS: (state, payload: Array<AdvertCampaignData>): Array<AdvertCampaignData> => Vue.set(state, "advertCampaigns", payload),
  SET_PERMISSION_AVAILABLE_ADVERT_CAMPAIGNS: (state, payload: Array<AdvertCampaignData>): Array<AdvertCampaignData> => Vue.set(state, "permissionAvailableAdvertCampaigns", payload),
  SET_ADVERT_SPOT_AVAILABLE_VENUES: (state, payload: Array<AdvertCampaignData>): Array<AdvertCampaignData> => Vue.set(state, "advertSpotAvailableVenues", payload ),
  FILTER_ACTIVE_CAMPAIGNS: (state, payload: boolean): boolean => Vue.set(state, "filterActiveCampaigns", payload),
  SET_AUDIO_SPOT_FILE: (state, payload): void => {
    if (payload.campaignId) {
      const advertCampaignIndex = state.advertCampaigns.findIndex(c => c.id === payload.campaignId);
      const audioSpotIndex = state.advertCampaigns[advertCampaignIndex].spots.findIndex(s => s.id === payload.id);

      Vue.set(state.advertCampaigns[advertCampaignIndex].spots[audioSpotIndex], "canPlay", true);
      Vue.set(state.advertCampaigns[advertCampaignIndex].spots[audioSpotIndex], "file", payload.encodeFile);
    } else {
      const audioSpotIndex = state.audioSpots.findIndex(s => s.id === payload.id);
      Vue.set(state.audioSpots[audioSpotIndex], "canPlay", true);
      Vue.set(state.audioSpots[audioSpotIndex], "file", payload.encodeFile);
    }
  },
  SET_AUDIO_SPOT_LOADER: (state, payload: boolean): boolean => Vue.set(state, "audioSpotLoader", payload),
  SET_CAN_PLAY_STATUS: (state, payload): void => {
    if (payload.campaignId) {
      const advertCampaignIndex = state.advertCampaigns.findIndex(c => c.id === payload.campaignId);
      const audioSpotIndex = state.advertCampaigns[advertCampaignIndex].spots.findIndex(s => s.id === payload.id);

      Vue.set(state.advertCampaigns[advertCampaignIndex].spots[audioSpotIndex], "canPlay", payload.state);
    } else {
      const audioSpotIndex = state.audioSpots.findIndex(s => s.id === payload.id);
      Vue.set(state.audioSpots[audioSpotIndex], "canPlay", payload.state);
    }
  },
  SET_AUDIO_SPOT_USER_IMAGE: (state, payload): void => {
    if(!!payload.id){
      const audioSpotIndexes = state.audioSpots.map((x) => x.czone_user_id == payload.id);
      for(const audioSpotIndex in audioSpotIndexes){
        Vue.set(state.audioSpots[audioSpotIndex], "image", payload.encodeImage);
      }
    }
  },
}
