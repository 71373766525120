export default {
  "Votes on Songs": "Votes On Songs",
  "Active users": "Active Users",
  "Boosts used": "Boosts Used",
  "Songs added": "Songs Added",
  "Songs sent to others": "Songs Sent To Others",
  "Songs played": "Songs Played",
  "Voted songs": "Voted Songs",
  "Artists": "Artists",
  "add song": "Add Song",
  "Song boost": "Song Boost",
  "Most Frequent Genres": "Most Frequent Genres",
  "Song title": "Song Title",
  "Artist name": "Artist Name",
  "Count of votes": "Count Of Votes",
}
