export default {
  "New Question": "Nová otázka",
  "Question title": "Titul otázky",
  "Question description": "Sem napíšte svoju otázku, ktorá sa zobrazí v aplikáciách Songoroo, v dotazníku prevádzky, ktorý je dostupný v detaile prevádzky.",
  "Question Name": "Meno otázky",
  "Save Question": "Uložiť otázku",
  "This Question is active in :": "Táto otázka je aktívna v :",
  "This audio spot is active in :": "Tento audio spot je aktívny pre :",
  "New Audio Advertisement Campaign": "Nová audio reklamná kampaň",
  "Name Campaign": "Názov kampane",
  "Select audio spot": "Zvoliť audio spot",
  "Select venues": "Zvoľte prevádzky",
  "Select all venues": "Zvoliť všetky prevádzky",
  "Campaign date duration": "Trvanie kampane",
  "Campaign time duration": "Trvanie kampane",
  "Spot Frequency": "Frekvencia spotu",
  "Save Campaign": "Uložiť kampaň",
  "Select all venues where you want to show specific questions you prepared for this campaign.": "Vyberte všetky prevádzky, kde chcete zobraziť špecifické otázky, ktoré ste pripravili pre tento prieskum.",
  "Confirm": "Potvrdiť",
  "Edit Question": "Upraviť otázku",
  "Add venues": "Pridať prevádzky",
  "New Survey": "Nový prieskum",
  "Edit Survey": "Upraviť prieskum",
  "New Audio Spot": "Nový audio spot",
  "Edit Audio Spot": "Upraviť audio spot",
  "Spot Name": "Názov spotu",
  "Text Author": "Autor textu",
  "Music Author": "Autor hudby",
  "No file selected": "Nie je vybraný žiadny súbor",
  "Max file size": "Maximálna veľkosť súboru",
  "Support format": "Podporované formáty",
  "Save spot": "Uložiť spot",
  "Generate Survey": "Generovať prieskum",
  "description": "Popis",
  "You can have up to 5 questions in this survey.": "V tomto prieskume môžete mať až 5 otázok.",
  "Are you sure you want to proceed without adding additional questions?": "Naozaj chcete pokračovať bez pridania ďalších otázok?",
  "Proceed anyway": "Pokračovať",
  "Return to editing": "Späť k editácii",
  "Question description is required.": "Popis je povinný",
  "Question title is required.": "Titul je povinný",
}
