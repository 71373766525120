import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import TokenService from "@/services/token.service"
import store from '@/state/store'
import clientZoneRoutes from "@/router/routes/clientZone";
import adminZoneRoutes from "@/router/routes/adminZone";
import registerRoutes from "@/router/routes/register";
import globalRoutes from "@/router/routes/global";
import UserService from "@/services/user.service";

Vue.use(VueRouter)

const CLIENT_ZONE = 'client_zone';
const ADMIN_ZONE = 'admin_zone';
const REGISTER = 'register'
const ALL = 'all';
const CZ_TITLE = 'Clientzone - Songoroo';
const AZ_TITLE = 'Adminzone - Songoroo';

const routes: RouteConfig[] = [
  {
    path: "",
    component: () => import('@/router/layouts/BaseLayout.vue'),
    meta: {
      type: CLIENT_ZONE,
    },
    children: clientZoneRoutes
  },
  {
    path: "",
    component: () => import('@/router/layouts/BaseLayout.vue'),
    meta: {
      type: ADMIN_ZONE,
    },
    children: adminZoneRoutes
  },
  {
    path: "",
    component: () => import('@/router/layouts/BaseLayout.vue'),
    meta: {
      type: REGISTER,
    },
    children: registerRoutes
  },
  {
    path: "",
    component: () => import('@/router/layouts/BaseLayout.vue'),
    meta: {
      type: ALL
    },
    children: globalRoutes
  },
  {
    path: "*",
    name: 'not-found',
    component: () => import('@/router/views/errors/Error404.vue'),
    meta: {
      public: false
    }
  }
]

const router = new VueRouter({
  // Use the HTML5 history API (i.e. normal-looking routes)
  // instead of routes with hashes (e.g. example.com/#/about).
  // This may require some server configuration in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  mode: 'history',
  base: '/',
  routes,
  // Simulate native-like scroll behavior when navigating to a new
  // route and using back/forward buttons.
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

router.beforeEach(async (to, from, next) => {
  const isPublic: boolean = to.matched.some(record => record.meta.public);
  // const isUserFilled = !!store.state.userModule.data;
  // const userId = TokenService.getUserId();
  const loggedIn = !!TokenService.getToken() && TokenService.getToken() !== 'undefined'; // && !isNaN(userId);
  if (loggedIn) {
    if (isPublic) {
      if (!from.meta.accessToClientzone) {

        return next()
      }
    }
    await store.dispatch("userModule/FETCH_LOGGED_USER");
  }

  const isSuperAdmin: string = store.getters["userModule/GET_ZONE_TYPE"];
  const hasRightPermissions: boolean = to.matched.some(record => {

    if (record.meta.type === ALL) {
      return !loggedIn;
    }

    if (record.meta.type === REGISTER) {
      return !loggedIn;
    }

    if (record.meta.type === CLIENT_ZONE) {
      store.dispatch("notificationModule/FETCH_UNCHECKED_NOTIFICATIONS_LENGTH")
    }

    return record.meta.type === isSuperAdmin;
  });

  if (to.path === '/') {
    if (isSuperAdmin === CLIENT_ZONE) return next('/dashboard');
    if (isSuperAdmin === ADMIN_ZONE) return next('/subscription-plans'); // TODO: Later change to homepage in AZ
  }

  if (!hasRightPermissions) {
    if (isSuperAdmin === CLIENT_ZONE) return next('/dashboard');
    if (isSuperAdmin === ADMIN_ZONE) return next('/subscription-plans'); // TODO: Later change to homepage in AZ
  }

  if (!isPublic && !loggedIn && to.path !== '/register' && typeof to.query["verification-token"] == 'undefined') {
    return next('/login')
  }

  if (to.query["verification-token"] && to.query["lang"]) {
    const payload: object = {
      //@ts-ignore
      token: to.query["verification-token"].replaceAll(' ', '+'),
      lang: to.query["lang"],
    };

    await store.dispatch("emailsModule/VERIFICATION_EMAIL", payload);
  }

  if (to.path === '/register') {
    return next('/register/step-1')
  }

  next();
});

export default router
