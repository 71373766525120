import { AdvertCampaignData, AudioSpotData } from "@/types/audio_commercials";
import { AudioCommercialsState } from "@/types/store/clientzone";

export const state: AudioCommercialsState = {
  audioSpots: [] as Array<AudioSpotData>,
  advertCampaigns: [] as Array<AdvertCampaignData>,
  advertSpotAvailableVenues: [] as Array<AdvertCampaignData>,
  permissionAvailableAdvertCampaigns: [] as Array<AdvertCampaignData>,
  filterActiveCampaigns: false,
  audioSpotLoader: false
}
