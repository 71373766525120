import store from "@/state/store";
import { RouteConfig } from "vue-router";
import { errorMessage } from "@/utils/messages-handlers";

const CZ_TITLE = 'Clientzone - Songoroo';

const clientZoneRoutes: RouteConfig[] = [
  {
    path: "/dashboard",
    name: "dashboard",
    meta: {
      public: false,
      title: CZ_TITLE,
    },
    component: () => import("@/router/views/dashboard/Dashboard.vue"),
  },
  {
    path: "/select-company",
    name: "select-company",
    meta: {
      public: false,
      title: CZ_TITLE,
    },
    component: () => import("@/router/views/dashboards/SelectCompany.vue"),
  },
  {
    path: "/user-account",
    name: "user-account",
    meta: {
      public: false,
      title: CZ_TITLE,
    },
    component: () => import("@/router/views/user/UserAccount.vue"),
  },
  {
    path: "/user-settings",
    name: "User settings",
    meta: {
      public: false,
      title: CZ_TITLE,
    },
    component: () => import("@/router/views/user/UserSettings.vue"),
  },
  {
    path: "/company",
    name: "Company",
    meta: {
      public: false,
      title: CZ_TITLE,
    },
    component: () => import("@/router/views/company/CompanyDetail.vue"),
  },
  {
    path: "/venues",
    name: "Venues",
    meta: {
      public: false,
      title: CZ_TITLE,
    },
    component: () => import("@/router/views/venues/Venues.vue")
  },
  {
    path: "/venue-detail/:id",
    name: "venue-detail",
    meta: {
      public: false,
      title: CZ_TITLE,
    },
    component: () => import("@/router/views/venues/VenueDetail.vue"),
    async beforeEnter(_, __, next): Promise<void> {
      if (store.getters["userModule/GET_IS_LOGGED_USER_WITHOUT_ANY_PERMISSIONS"]) {
        return next("/dashboard");
      }
      next();
    }
  },
  {
    path: "/app-features-setup",
    name: "App features setup",
    meta: {
      public: false,
      title: CZ_TITLE,
    },
    component: () => import("@/router/views/venues/VenueAppFeaturesDefault.vue"),
    // async beforeEnter(to, from, next): Promise<void> {
    //   await store.dispatch("userModule/CHECK_LOGGED_USER_ROLE_PERMISSION", { permissionId: 6, venueId: to.params.id });
    //   if (!store.getters["userModule/GET_APP_FEATURES_PERMISSION"]) {
    //     return next("/dashboard");
    //   }

    //   next();
    // }
  },
  {
    path: "/app-features-setup/:id",
    name: "App features setup",
    meta: {
      public: false,
      title: CZ_TITLE,
    },
    component: () => import("@/router/views/venues/VenueAppFeatures.vue"),
    async beforeEnter(to, __, next): Promise<void> {
      await store.dispatch("userModule/CHECK_LOGGED_USER_ROLE_PERMISSION", { permissionId: 6, venueId: to.params.id });
      if (!store.getters["userModule/GET_APP_FEATURES_PERMISSION"]) {
        return next("/dashboard");
      }

      next();
    }
  },
  {
    path: "/add-new-venue-step-1",
    name: "Add new venue",
    meta: {
      public: false,
      title: CZ_TITLE,
    },
    component: () => import("@/router/views/venues/AddNewVenueForm.vue"),
    async beforeEnter(_, __, next): Promise<void> {
      await store.dispatch("userModule/CHECK_LOGGED_USER_ROLE_PERMISSION", { permissionId: 4 });
      await store.dispatch("companyModule/SET_COMPANY", store.getters["companyModule/GET_COMPANY_ID"]);
      if (store.getters["companyModule/GET_IS_CORPORATE"]) {
        return next("/dashboard");
      } else if (!store.getters["userModule/GET_CREATE_DELETE_VENUE_PERMISSION"]) {
        return next("/dashboard");
      }

      next();
    }
  },
  {
    path: "/add-new-venue-step-2",
    name: "Add new venue",
    meta: {
      public: false,
      title: CZ_TITLE,
    },
    component: () => import("@/router/views/venues/AddNewVenue.vue"),
    beforeEnter: (_, __, next) => {
      if (!store.getters['venueModule/GET_INIT_VENUE_REGISTER']) {
        next("/add-new-venue-step-1")
      }

      next()
    }
  },
  {
    path: "/user-access-rights",
    name: "User access rights",
    meta: {
      public: false,
      title: CZ_TITLE,
    },
    component: () => import("@/router/views/user/UserAccessRights.vue"),
    async beforeEnter(_, __, next): Promise<void> {
      await store.dispatch("userModule/CHECK_LOGGED_USER_ROLE_PERMISSION", { permissionId: 10 });
      if (!store.getters["userModule/GET_LIST_OF_USERS_PERMISSION"]) {
        return next("/dashboard");
      }

      next();
    }
  },
  {
    path: "/billing",
    name: "Billing",
    meta: {
      public: false,
      title: CZ_TITLE,
    },
    component: () => import("@/router/views/billingAndSubscritions/Billing.vue"),
    async beforeEnter(_, __, next): Promise<void> {
      await store.dispatch("userModule/CHECK_LOGGED_USER_ROLE_PERMISSION", { permissionId: 8 });
      if (!store.getters["userModule/GET_INVOICES_PERMISSION"]) {
        return next("/dashboard");
      }

      next();
    }
  },
  {
    path: "/faqs",
    name: "F&Qs",
    meta: {
      public: false,
    },
    component: () => import("@/router/views/dashboards/Faqs.vue"),
  },
  {
    path: "/features-management",
    name: "Features management",
    meta: {
      public: false,
      title: CZ_TITLE,
    },
    component: () => import("@/router/views/dashboards/FeaturesManagement.vue"),
  },
  {
    path: "/venue-general",
    name: "General venue data",
    meta: {
      public: false,
      title: CZ_TITLE,
    },
    component: () => import("@/router/views/venues/Venue.vue"),
  },
  {
    path: "/audio-commercials",
    name: "audio-commercials",
    meta: {
      public: false,
      title: CZ_TITLE,
    },
    component: () => import("@/router/views/communications/audio-commercials/AudioCommercials.vue"),
    async beforeEnter(_, __, next): Promise<void> {
      await store.dispatch("userModule/CHECK_LOGGED_USER_ROLE_PERMISSION", { permissionId: 13 });
      if (!store.getters["userModule/GET_VIEW_AUDIO_CAMPAIGNS_PERMISSION"]) {
        return next("/dashboard");
      }

      next();
    }
  },
  {
    path: "/audio-commercials/list-of-spots",
    name: "list-of-spots",
    meta: {
      public: false,
      title: CZ_TITLE,
    },
    component: () => import("@/router/views/communications/audio-commercials/ListOfSpots.vue"),
    async beforeEnter(_, __, next): Promise<void> {
      await store.dispatch("userModule/CHECK_LOGGED_USER_ROLE_PERMISSION", { permissionId: 14 });
      if (!store.getters["userModule/GET_MANAGE_AUDIO_SPOTS_PERMISSION"]) {
        return next("/dashboard");
      }

      next();
    }
  },
  {
    path: "/logout",
    name: "logout",
    meta: {
      public: false,
      title: CZ_TITLE,
    },
  },
  {
    path: "/user-profile",
    name: "user-profile",
    component: () => import("@/router/views/account/UserProfile.vue"),
    meta: {
      public: false,
      title: CZ_TITLE,
    }
  },
  {
    path: "/select-playlist",
    name: "select-playlist",
    component: () => import("@/router/views/playlist/SelectPlaylist.vue"),
    meta: {
      public: false,
      title: CZ_TITLE,
    },
    async beforeEnter(_, __, next): Promise<void> {
      await store.dispatch("userModule/CHECK_LOGGED_USER_ROLE_PERMISSION", { permissionId: 5, venueId: localStorage.getItem("venue_id") });
      if (!store.getters["userModule/GET_CHANGE_PLAYLIST_PERMISSION"]) {
        errorMessage('Your role is not authorised to change the playlist', 5000)
        return next(false);
      }

      next();
    }
  },
  {
    path: "/select-genres",
    name: "select-genres",
    component: () => import("@/router/views/playlist/SelectGenres.vue"),
    meta: {
      public: false,
      title: CZ_TITLE,
    },
    async beforeEnter(_, __, next): Promise<void> {
      await store.dispatch("userModule/CHECK_LOGGED_USER_ROLE_PERMISSION", { permissionId: 5 });
      if (!store.getters["userModule/GET_CHANGE_PLAYLIST_PERMISSION"]) {
        return next("/dashboard");
      }

      next();
    }
  },
  {
    path: "/support",
    name: "support",
    component: () => import("@/router/views/support/Support.vue"),
    meta: {
      public: false,
      title: CZ_TITLE,
    }
  },
  {
    path: "/support/submit-request",
    name: "submit-request",
    component: () => import("@/router/views/support/SubmitRequest.vue"),
    meta: {
      public: false,
      title: CZ_TITLE,
    }
  },
  {
    path: "/role-management",
    name: "role-management",
    component: () => import("@/router/views/role-management/RoleManagement.vue"),
    meta: {
      public: false,
      title: CZ_TITLE,
    },
    async beforeEnter(_, __, next): Promise<void> {
      await store.dispatch("userModule/CHECK_LOGGED_USER_ROLE_PERMISSION", { permissionId: 12 });
      if (!store.getters["userModule/GET_ROLE_MANAGEMENT_PERMISSION"]) {
        return next("/dashboard");
      }

      next();
    }
  },
  {
    path: "/edit-user-permissions/:id",
    name: "edit-user-permissions",
    meta: {
      public: false,
      title: CZ_TITLE,
    },
    component: () => import("@/router/views/user/EditUserPermissions.vue"),
    async beforeEnter(_, __, next): Promise<void> {
      await store.dispatch("userModule/CHECK_LOGGED_USER_ROLE_PERMISSION", { permissionId: 12 });
      if (!store.getters["userModule/GET_ROLE_MANAGEMENT_PERMISSION"]) {
        return next("/dashboard");
      }

      next();
    }
  },
  {
    path: "/subscription-plan",
    name: "subscription-plan",
    meta: {
      public: false,
      title: CZ_TITLE,
    },
    component: () => import("@/router/views/subscription/SubscriptionPlan.vue"),
    async beforeEnter(_, __, next): Promise<void> {
      await store.dispatch("userModule/CHECK_LOGGED_USER_ROLE_PERMISSION", { permissionId: 9 });
      await store.dispatch("userModule/CHECK_LOGGED_USER_ROLE_PERMISSION", { permissionId: 7 });
      if (!store.getters["userModule/GET_PAYMENT_METHOD_PERMISSION"] || !store.getters["userModule/GET_EDIT_SUBSCRIPTION_PERMISSION"]) {
        return next("/dashboard")
      }

      next();
    }
  },
  {
    path: "/subscription-plan/details/:id",
    name: "subscription-details",
    meta: {
      public: false, title: CZ_TITLE,
    },
    component: () => import("@/router/views/subscription/SubscriptionDetails.vue"),
    async beforeEnter(to, __, next): Promise<void> {
      await store.dispatch("userModule/CHECK_LOGGED_USER_ROLE_PERMISSION", { permissionId: 7, venueId: to.params.id });
      if (!store.getters["userModule/GET_EDIT_SUBSCRIPTION_PERMISSION"]) {
        return next("/dashboard");
      }

      next();
    }
  },
  {
    path: "/subscription-plan/edit/:id",
    name: "subscription-edit",
    meta: {
      public: false,
      title: CZ_TITLE,
    },
    component: () => import("@/router/views/subscription/SubscriptionPlanEdit.vue"),
    async beforeEnter(_, __, next): Promise<void> {
      await store.dispatch("companyModule/SET_COMPANY", store.getters["companyModule/GET_COMPANY_ID"]);
      if (store.getters["companyModule/GET_IS_CORPORATE"]) {
        return next("/dashboard");
      }

      next();
    }
  },
  {
    path: "/subscription-plan/renew/:id",
    name: "subscription-edit",
    meta: {
      public: false,
      title: CZ_TITLE,
    },
    component: () => import("@/router/views/subscription/SubscriptionPlanRenew.vue"),
    async beforeEnter(_, __, next): Promise<void> {
      await store.dispatch("companyModule/SET_COMPANY", store.getters["companyModule/GET_COMPANY_ID"]);
      if (store.getters["companyModule/GET_IS_CORPORATE"]) {
        return next("/dashboard");
      }

      next();
    }
  },
  {
    path: "/payment",
    name: "payment-method",
    meta: {
      public: false,
      title: CZ_TITLE,
    },
    component: () => import("@/router/views/payments/VenuePaymentMethod.vue"),
    async beforeEnter(_, __, next): Promise<void> {
      await store.dispatch("userModule/CHECK_LOGGED_USER_ROLE_PERMISSION", { permissionId: 9 });
      if (!store.getters["userModule/GET_PAYMENT_METHOD_PERMISSION"]) {
        return next("/dashboard");
      }

      next();
    }
  },
  {
    path: "/payment/manage",
    name: "manage-payment-method",
    meta: {
      public: false,
      title: CZ_TITLE,
    },
    component: () => import("@/router/views/payments/VenueManagePaymentMethod.vue"),
    async beforeEnter(_, __, next): Promise<void> {
      await store.dispatch("userModule/CHECK_LOGGED_USER_ROLE_PERMISSION", { permissionId: 9 });
      if (!store.getters["userModule/GET_PAYMENT_METHOD_PERMISSION"]) {
        return next("/dashboard");
      }

      next();
    }
  },
  {
    path: "/venue-survey",
    name: "Venue Survey",
    meta: {
      public: false,
      title: CZ_TITLE,
    },
    component: () => import("@/router/views/communications/venue-survey/VenueSurvey.vue"),
    async beforeEnter(_, __, next): Promise<void> {
      await store.dispatch("userModule/CHECK_LOGGED_USER_ROLE_PERMISSION", { permissionId: 17 });
      if (!store.getters["userModule/GET_VENUE_SURVEY_PERMISSION"]) {
        return next("/dashboard");
      }

      next();
    }
  },
  {
    path: "/venue-survey/list-of-questions",
    name: "List Of questions",
    meta: {
      public: false,
      title: CZ_TITLE,
    },
    component: () => import("@/router/views/communications/venue-survey/ListOfQuestions.vue"),
    async beforeEnter(_, __, next): Promise<void> {
      await store.dispatch("userModule/CHECK_LOGGED_USER_ROLE_PERMISSION", { permissionId: 17 });
      if (!store.getters["userModule/GET_VENUE_SURVEY_PERMISSION"]) {
        return next("/dashboard");
      }

      next();
    }
  },
  {
    path: "/messages-to-customers",
    name: "Messages to customers",
    meta: {
      public: false,
      title: CZ_TITLE,
    },
    component: () => import("@/router/views/communications/messages-to-customers/MessagesToCustomers.vue"),
    async beforeEnter(_, __, next): Promise<void> {
      await store.dispatch("userModule/CHECK_LOGGED_USER_ROLE_PERMISSION", { permissionId: 18 });
      if (!store.getters["userModule/GET_SEND_MESSAGES_PERMISSION"]) {
        return next("/dashboard");
      }

      next();
    }
  }
]

export default clientZoneRoutes
