export default {
    "Active Playlist": "Aktivní Playlist",
    "Congress-Exhibition Centre": "Kongresové a výstavní centrum",
    "Select your playlist": "Vyberte si svůj playlist",
    "Playlist Description 1": "Všechny playlisty Songoroo sestavují naši zkušení hudební dramaturgové. Poslouchají každou skladbu a s přihlédnutím k žánru, tempu, jazyku a dalším faktorům se rozhodnou, pro jaký podnik je píseň vhodná. To znamená, že každý playlist je šitý na míru konkrétnímu typu podnikání. Je to náročná, manuální a časově náročná práce, ale neděláme kompromisy v kvalitě. Je samozřejmé, že každý playlist je pravidelně aktualizován.",
    "Playlist Description 2": "I když jsou naše seznamy skladeb pečlivě vybírány, nejsou vytesány do kamene. Vaši zákazníci a zaměstnanci hrají v konečném mixu velkou roli. V okamžiku, kdy vstoupí do vašich prostor s aplikací Songoroo aktivní ve svém telefonu, playlist se přizpůsobí jejich hudebním preferencím a umístí jejich oblíbené skladby (pokud je to možné) do playlistu.",
    "Playlist Description 3": "Samozrejme musíme tiež pripustiť, že nech sa snažíme akokoľvek, stále existuje táto malá možnosť, že by sa vám nemusel celkom páčiť žiadny z našich preddefinovaných playlistov. Netreba sa báť! Stále máte slobodu zostaviť si vlastný playlist a mať ho presne tak, ako chcete.",
    "Create your own playlist": "Vytvořte si vlastní playlist",
    "Venue custom playlist": "Vlastní playlist provozovny",
    "Get creative. Follow your taste and compile your own playlist": "Buďte kreativní. Následujte svůj vkus a sestavte si vlastní playlist",
    "Select genres from the below list to include them in your custom built playlist. The custom playlist you are about to generate for this venue can be modified anytime.": "Z níže uvedeného seznamu vyberte žánry, které chcete zahrnout do vytvořeného playlistu. Vlastní playlist, který se chystáte pro toto místo vytvořit, můžete kdykoli upravit.",
    "Generate Playlist": "Generovať playlist",
    "Genre description": "Popis žánru",
    "Set for this venue": "Nastavit pro tuto provozovnu",
    "Playlist description": "Popis playlistu",
    "Energy": "Energie",
    "Included": "Zahrnutý",
    "Excluded": "Vyloučený",
    "Low": "Nízka",
    "Low to medium": "Nízka až mírna",
    "Low to high": "Nízka až vysoká",
    "Medium": "Mírna",
    "High": "Vysoká",
    "Playlist changed": "Playlist se změnil",
    "Back to playlist selection": "Zpět na výběr playlistu",
    "More genres error": "Proto, aby Songoroo nabídlo nejlepší možnou kombinaci skladeb, vyžaduje určité množství skladeb v playlistu. Vámi vybrané hudební žánry neobsahují dostatek skladeb, což může vést k selhání výpočtu algoritmu generujícího hudební playlisty pro vaši provozovnu. Proto vás prosíme, abyste si vybrali další žánry. Děkujeme.",
}
