export default {
    "Manage Payment Method": "Správa platebních metod",
    "Back to Payment method": "Zpět na způsob platby",
    "Add new card": "Přidání nové karty",
    "Add new bank account": "Přidání nového bankovního účtu",
    "Bank transfer": "Bankovní převod",
    "Edit": "Upravit",
    "Remove": "Odstranit",
    "Card number": "Číslo karty",
    "Valid thru": "Platní do",
    "Add new Credit/Debit card": "Přidat novou kreditní/debetní kartu",
    "Name on card": "Meno na kartě",
    "Security Code": "Bezpečnostní kód",
    "Edit card": "Upravit kartu",
    "Edit bank account": "Upravit bankovní účet",
    "Add card": "Přidat kartu",
    "Add bank account": "Přidat bankovní účet",
    "Full name on the card": "Celé jméno na kartě",
    "Select payment method": "Vyberte způsob platby",
    "Assign": "Přiradit",
    "Selected card": "Vybraná karta",
    "Expired": "Prošlá",
    "Expires on": "Vyprší",
}
