import { CompanyData, RolePermissionsAssociationData, RolePermissionsData } from "@/types";
import { GetterTree } from "vuex";
import { CompanyState, RootState } from "@/types/store/clientzone";

export const getters: GetterTree<CompanyState, RootState> = {
  GET_COMPANIES: (state): Array<CompanyData> => state.companies,
  GET_COMPANY: (state): CompanyData => state.company,
  HAS_COMPANY: (state): boolean => state.company.id != -1,
  GET_COMPANY_ID: (state): number | string => state.company.id ? state.company.id : localStorage.getItem("company_id"),
  IS_COMPANY_IMAGE: (state): boolean => !!state.company.image_last_updated,
  GET_COMPANY_IMAGE: (state): string => state.company.image,
  GET_LIST_OF_USER_ROLE_PERMISSIONS_ASSOCIATIONS: (state): Array<RolePermissionsAssociationData> => state.rolePermissionsAssociation,
  GET_ROLE_MANAGEMENT_STATE: (state) => {
    const rolePermissions: Array<RolePermissionsData> = state.rolePermissions;
    const rolePermissionsAssociation: Array<RolePermissionsAssociationData> = state.rolePermissionsAssociation;
    const roleManagementArray: Array<any> = [] as Array<any>;

    rolePermissions.forEach((r) => {

      const role = rolePermissionsAssociation.filter(p => p.permission_id === r.id);

      if (!!role.length) {
        const rolesArray: Array<object> = [] as Array<object>;

        role.forEach((v) => {
          rolesArray.push({ id: v.czone_venue_owner_role_permission_id, role_id: v.role_id })
        })

        roleManagementArray.push({ ...r, roles: rolesArray })
      } else {
        roleManagementArray.push({ ...r })
      }
    })

    return roleManagementArray;
  },
  GET_IS_CORPORATE: (state): boolean => state.company.corporate,
  TAX_IS_NOT_VALID: (state, getters, rootState, rootGetters): boolean => {
    const companyCountryId: number = getters["GET_COMPANY"].billing_country_id;
    const venueCountryId: number = rootGetters["venueModule/GET_VENUE_REGISTER_DATA"].country_id;
    const companyVat: string = getters["GET_COMPANY"].billing_vat;

    if (companyVat && companyCountryId != 1 && venueCountryId != 1) {
      return false;
    } else if (companyCountryId == 1 && venueCountryId == 1) {
      return true;
    } else {
      return true;
    } // 1 = sk
  }
}
