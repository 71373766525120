import ApiService from "@/services/api.service";
import { ActionTree } from "vuex";
import { MessagesState, RootState } from "@/types/store/clientzone";

export const actions: ActionTree<MessagesState, RootState> = {
  FETCH_VENUE: ({ commit }, payload = {}): Promise<void> => {
    const venueId: number = !!payload.venue_id ? payload.venue_id : localStorage.getItem("venue_id");

    return new Promise(async (resolve, reject) => {
      const resource: string = `api/v1/clientzone/venue/${venueId}`;

      try {
        const response = await ApiService.get(resource)

        if (payload.venue_id) {
          commit("SET_VENUE", response.data)
        }
        resolve();
      } catch (e) {
        reject()
      }
    })
  },
  FETCH_MESSAGES: ({ commit }, payload = {}): Promise<void> => {
    const venueOwnerId: number = payload.venue_owner_id;
    const startAt: string = payload.startAt;
    const endAt: string = payload.endAt;
    return new Promise(async (resolve, reject) => {
      let resource: string = '';
      if (startAt && endAt) {
        resource = `api/v1/clientzone/message?venue_owner_id=${venueOwnerId}&start_at=${startAt}&end_at=${endAt}&limit=1000`;
      } else {
        resource = `api/v1/clientzone/message?venue_owner_id=${venueOwnerId}&limit=1000`;
      }

      try {
        const response = await ApiService.get(resource)

        commit("SET_MESSAGES", response.data)

        resolve();
      } catch (e) {
        reject()
      }
    })
  },
  ADD_MESSAGE_TO_CUSTOMERS: ({ dispatch, commit }, payload): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      const resource: string = `/api/v1/clientzone/message/${payload.messageType}`;

      try {
        const response = await ApiService.post(resource, payload.data);

        commit("SET_MESSAGES", response.data);
        await dispatch("FETCH_MESSAGES", response.data);

        resolve();
      } catch (e) {
        commit("SET_ERROR_MESSAGES", e.response.data);
        reject()
      }
    })
  },
}
