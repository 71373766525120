import { state } from '@/state/modules/clientzone/user/state';
import { mutations } from '@/state/modules/clientzone/user/mutations';
import { getters } from '@/state/modules/clientzone/user/getters';
import { actions } from '@/state/modules/clientzone/user/actions';

const userModule = {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}

export default userModule
