import { PlaylistSongData } from "@/types";
import { GetterTree } from "vuex";
import { BrowserStreamerState, RootState } from "@/types/store/clientzone";

export const getters: GetterTree<BrowserStreamerState, RootState> = {
  GET_PLAYLIST_SONG: (state): PlaylistSongData => state.playlistSong,
  GET_STOP_PLAYER: (state): boolean => state.StopPlayer,
  GET_TOOK_WEBSTREAM_MODAL: (state): boolean => state.TookWebStream,
  GET_INFO_WEBSTREAM_MODAL: (state): boolean => state.InfoWebStream,
}
