import Vue from "vue";
import { MutationTree } from "vuex";
import {
  QuestionaryAvailableVenueData,
  QuestionaryQuestionData,
  VenueQuestionaryData,
} from "@/types";
import { VenueQuestionaryState } from "@/types/store/clientzone";

export const mutations: MutationTree<VenueQuestionaryState> = {
  SET_QUESTIONARY_QUESTIONS: (state, payload: Array<QuestionaryQuestionData>): Array<QuestionaryQuestionData> => Vue.set(state, "questionaryQuestions", payload),
  SET_VENUE_QUESTIONARY: (state, payload: Array<VenueQuestionaryData>): Array<VenueQuestionaryData> => Vue.set(state, "venueQuestionary", payload),
  SET_PERMISSION_AVAILABLE_QUESTIONARIES: (state, payload: Array<VenueQuestionaryData>): Array<VenueQuestionaryData> => Vue.set(state, 'permissionAvailableQuestionaries', payload),
  SET_AVAILABLE_VENUE_QUESTIONARY: (state, payload: Array<QuestionaryAvailableVenueData>): Array<QuestionaryAvailableVenueData> => Vue.set(state, "questionaryAvailableVenues", payload),
  FILTER_ACTIVE_CAMPAIGNS: (state, payload: boolean): boolean => Vue.set(state, "filterActiveCampaigns", payload),
  SET_QUESTION_LOADER: (state, payload: boolean): boolean => Vue.set(state, "questionLoader", payload),
  SET_QUESTIONARY_USER_IMAGE: (state, payload): void => {
    if (!!payload.id) {
      const questionaryIndexes = state.questionaryQuestions.map((x) => x.czone_user_id == payload.id);
      for (const questionaryIndex in questionaryIndexes) {
        Vue.set(state.questionaryQuestions[questionaryIndex], "image", payload.encodeImage);
      }
    }
  },
}
