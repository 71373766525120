import { ActionTree } from "vuex";
import { RootState, SubscriptionDescriptionState } from "@/types/store/clientzone";
import { CASSOVIA_API_RESOURCE } from "@/utils/helpers";
import AxiosService from "@/services/axios.service";

export const actions: ActionTree<SubscriptionDescriptionState, RootState> = {
  FETCH_SUBSCRIPTION_DESCRIPTIONS: ({ commit }): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      const resource: string = `${CASSOVIA_API_RESOURCE}/api/subscription-descriptions?page=1`;
      try {
        const response = await AxiosService.getAxios().get(resource)
        commit("SET_SUBSCRIPTION_DESCRIPTIONS", response.data)
        resolve()
      } catch (e) {
        reject()
      }
    })
  }
}
