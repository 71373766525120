import Vue from "vue";
import { PlaylistSongData } from "@/types";
import { MutationTree } from "vuex";
import { BrowserStreamerState } from "@/types/store/clientzone";

export const mutations: MutationTree<BrowserStreamerState> = {
  // SET_PLAYLIST_SONG: (state, payload: PlaylistSongData | string): PlaylistSongData | string => Vue.set(state, "playlistSong", payload),
  SET_PLAYLIST_SONG: (state, payload: PlaylistSongData): void => {
    Vue.set(state.playlistSong, "song_id", payload.song_id || null);
    Vue.set(state.playlistSong, "spot_id", payload.spot_id || null);
    Vue.set(state.playlistSong, "url", payload.url);
    Vue.set(state.playlistSong, "opening", payload.opening || null);
    Vue.set(state.playlistSong, "ending", payload.ending || null);
  },
  SET_STOP_PLAYER: (state, payload: boolean): boolean => Vue.set(state, "StopPlayer", payload),
  SET_TOOK_WEBSTREAM_MODAL: (state, payload: boolean): boolean => Vue.set(state, "TookWebStream", payload),
  SET_INFO_WEBSTREAM_MODAL: (state, payload: boolean): boolean => Vue.set(state, "InfoWebStream", payload),
  SET_TOOK_WEBSTREAM_MODAL_TO_DEFAULT: (state) => {
    state.TookWebStream = null
    state.InfoWebStream = null
  }

}
