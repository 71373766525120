const TOKEN_KEY = 'access_token';
const REFRESH_TOKEN_KEY = 'refresh_token';
const USER_ID = 'cassia_user_id';
const WEBSTREAM_TOKEN = 'webstream_token';

/**
 * Manage the how Access Tokens are being stored and retrieved from storage.
 *
 * Current implementation stores to localStorage. Local Storage should always be
 * accessed through this instance.
 **/

export interface TokenServiceInterface {
  getToken(): string | null;

  saveToken(accessToken: string): void;

  removeToken(): void;

  saveUserId(id: number): void;

  getRefreshToken(): string | null;

  getUserId(): number;

  saveRefreshToken(refreshToken: string): void;

  removeRefreshToken(): void;

  removeUserId(): void;

  isUserLoggedIn(): boolean;

  saveWebStreamToken(webStreamToken:string): void;
}

const TokenService: TokenServiceInterface = {

  getToken() {
    return sessionStorage.getItem(TOKEN_KEY)
  },

  saveToken(accessToken) {
    sessionStorage.setItem(TOKEN_KEY, accessToken)
  },

  removeToken() {
    sessionStorage.removeItem(TOKEN_KEY)
  },

  saveUserId(id: number | string): void {
    localStorage.setItem(USER_ID, id as string)
  },

  getRefreshToken() {
    return localStorage.getItem(REFRESH_TOKEN_KEY)
  },

  getUserId(): number {
    return parseInt(localStorage.getItem(USER_ID));
  },

  saveRefreshToken(refreshToken) {
    localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken)
  },

  removeRefreshToken() {
    localStorage.removeItem(REFRESH_TOKEN_KEY)
  },

  removeUserId(): void {
    localStorage.removeItem(USER_ID);
  },

  isUserLoggedIn(): boolean {
    const userId: number = this.getUserId();
    return !!this.getToken() && !isNaN(userId);
  },
  async saveWebStreamToken(webStreamToken) {
    // @ts-ignore
    const buffer = new TextEncoder( 'utf-8' ).encode( webStreamToken );
    const digest = await crypto.subtle.digest('SHA-1', buffer);

    const result = Array.from(new Uint8Array(digest)).map( x => x.toString(16).padStart(2,'0') ).join('');

    localStorage.setItem(WEBSTREAM_TOKEN, result)
  },
};

export default TokenService
