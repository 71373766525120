export default {
  "Dashboard": "Dashboard",
  "Company data": "Informácie o spoločnosti",
  "Venues": "Prevádzky",
  "Venues list": "Zoznam prevádzok",
  "Venues detail": "Detail prevádzky",
  "App features setup": "Nastavenie funkcií aplikácie",
  "Subscriptions & Billing": "Balík služieb & fakturácia",
  "Subscription Plan": "Balík služieb",
  "Payment Methods": "Spôsob platby",
  "User management": "Správa užívateľov",
  "User access rights": "Prístupové práva užívateľov",
  "Role management": "Manažment oprávnení",
  "Communication with Customers": "Komunikácia so zákazníkmi",
  "Audio Commercials": "Audio reklama",
  "Messages to clients": "Správy zákazníkom",
  "Venue Survey": "Hodnotenie prevádzky",
  "Venue survey": "Hodnotenie prevádzky zákazníkmi",
  "Venue Feedback": "Spätná väzba prevádzky",
  "Reports": "Nahlásenia",
  "Help & support": "Pomoc a podpora",
  "Discover": "Predstavenie",
  "Setup": "Nastavenia",
  "Manage": "Spravovať",
  "App features": "Funkcie aplikácie",
  "Try for free": "Vyskúšajte zadarmo",
  "Try it for free": "Vyskúšajte zadarmo",
  "Get the app": "Získať aplikáciu",
  "Are you a business?": "Ste firma?",
  "Not a business?": "Nie ste firma?",
  "Login": "Prihlásiť sa",
  "My profile": "Môj profil",
  "Logout": "Odhlásiť sa",
  "Companies": "Spoločnosti",
  "List of Companies": "Zoznam spoločností",
  "Subscription": "Balík služieb",
  "Subscription Plans": "Plány predplatného",
  "Discount Codes": "Zľavové kódy",
  "Billingg": "Fakturácia",
  "Invoice Overview": "Prehľad faktúr",
  "Invoice Data Feed": "Informačný kanál faktúr",
  "Communication": "Komunikácia",
  "Emails": "Emaily",
  "Notifications": "Oznámenia",
  "User Management": "Správa uživateľov",
  "Client Zone Users": "Užívatelia klientskej zóny",
  "Songoroo Admins": "Songoroo správcovia",
  "Job Applicants": "Uchádzači o zamestnanie",
  "Settings": "Nastavenia",
  "Activity Log": "Denník aktivít",
  "Business Type": "Typ prevádzky",
  "Language Options": "Jazykové mutácie",
}
