export default {
  //notifications
  "Notifications": "Notifikácie",
  "Edit Notifications": "Úprava notifikácií",
  "Notification updated successfully": "Notifikácia úspešne aktualizovaná",
  'Notification Title': "Názov notifikácie",
  "Level of Importance": "Dôležitosť notifikácie",
  "Repetition": "Opakovanie",
  "Critical": "Kritická",
  "Suggestion": "Návrh",
  "Warning": "Upozornenie",
  "Every": "Každých",
  "days": "dní",
  "Just once": "Raz",
  "Title": "Názov",
  "Description": "Popis",
  //Emails
  "Emails": "Emaily",
  "Edit Emails": "Aktualizácia emailov",
  //notifications
  "Supplier": 'Poskytovateľ',
  "StreetAndNumber": "Ulica a číslo",
  "Price Ratio Settings": "Fakturačný pomer cien",
  "Streaming Device": "Streamovacie zariadenie",
  "Music Streaming": "Streaming hudby",
  "iban: This is not a vali International Bnk Account Number (IBAN).": "Neplatný IBAN",
  "Datafeed updated successfully": "Fakturačné údaje spoločnosti, boli úspešne aktualizované.",
  //subscription plan
  "Add Description": "Pridať popis",
  "Subscription plans": "Plány predplatného",
  "New service description": "Pridať popis balíka (anglicky)",
  "Additional services updated": "Doplnkové služby aktualizované",
  "Add new additional service": "Pridať novú doplnkovú službu",
  "Description successfully attached to subscription plan.": "Popis úspešne pridaný",
  "Description successfully detached from subscrition plan.": 'Popis úspešne odobraný',
  "Description deleted successfully": "Popis úspešne vymazaný",
  "Premium price was updated successfully": "Cena za Premium bola úspešne aktualizovaná",
  "Basic price was updated successfully": "Cena za Basic bola úspešne aktualizovaná",
  //Discount codes
  "Discount codes": "Zľavové kupóny",
  "New Discount": " Pridať zľavový kupón",
  "Only for first time payment": "Iba pri prvej platbe.",
  "Valid forever or till end date": "Platný pre všetky platby do konca obdobia alebo do dosiahnutia maximálneho počtu použití.",
  "Discount rate": "Zľava v percentách",
  "Maximum usages": "Maximálny počet použití",
  "Campaign name": "Názov kampane",
  "Discount code": "Zľavový kód",
  "Applies for": "Aplikovateľný pre",
  "Valid for": "Validný pre",
  "Type": "Typ",
  "Apply end date": "Nastaviť dátum vypršania zľavového kupónu",
  "End date": "Dátum vypršania",
  "Discount created successfully": "Zľavový kupón úspešne pridaný",
  //subscription description:
  "Descriptions updated": "Popisy plánov aktualizované",
  //Emails
  "Email Title": "Popis emailu",
  //Subscription plan - Language options
  "Subscription plan - Language options": "Plán predplatného - Jazykové  úpravy",
  "Translation is complete": "Preklad je dokončený",
  "Additional service": "Doplnková služba",
  "Translation to": "Preklad do",
  "Price": "Price",
  "Service description": "Popis produktu",
  //Invoice
  "Invoice Overview": "Prehľad faktúr",
  "Upload file": "Nahrať súbor",
  "New Invoice": "Nová faktúra",
  "Invoice type": "Typ faktúry",
  "Actions": "Možnosti",
  "Company": "Spoločnosť",
  "Show only invoices that failed automatic pairing": "Zobraziť faktúry nespárované s platbou",
  "Invoice system": "Vystavená automaticky",
  "Invoice manual": "Vystavená manuálne",
  "Credit note": "Dobropis",
  "past due": "Po splatnosti",
  "due": "K zaplateniu",
  "was sent to": "bola odoslaná na fakturačný e-mail",
  "of company": "spoločnosti",
  "Find company": "Vyhľadať spoločnosť",
  "Find venue": "Vyhľadať prevádzku",
  "Select payment method": "Vybrať spôsob platby",
  "All": "Všetky",
  "Invoice status": "Stav faktúry",
  "List of venues": "Zoznam prevádzok",
  "Period": "Obdobie",
  "Past due date": "Po splatnosti",
  'Upload a file (SEPA XML) to automatically match invoice payments': 'Nahrať súbor (SEPA XML) k automatickému faktúr',
  "Attachment can be just 1.": "Možnosť nahrať iba 1 SEPA XML súbor",
  "Unsaved Additional service. Please add service or delete fields": "Neuložená doplnková služba. Prosím, vymažte polia alebo doplňte službu",
  "Company name": "Názov spoločnosti",
  "Company address": "Adresa spoločnosti",
  "Billing city": "Mesto spoločnosti",
  "Billing country": "Krajina spoločnosti",
  "Purchaser": "Nákupca",
  "Find Venue Owner": "Nájsť spoločnosť",
  "Local supply": "Dodanie v tuzemsku",
  "Subject to reverse charge": "Oslobodené od DPH",
  "Invoice No": "Číslo faktúry",
  "Location": "Lokalita",
  "VAT rate": "DPH v %",
  "Price with VAT": "Cena s DPH",
  "Billing period": "Fakturačné obdobie",
  "Bank name": "Názov banky",
  "Variable symbol": "Variabilný symbol",
  "Purchase order": "Číslo objednávky",
  "Payment instruction": "Platobné inštrukcie",
  "Amount to be paid": "Suma k zaplateniu",
  "VAT treatment": "Daňový režim",
  "New invoice/Credit/Debit note": "Nová faktúra",
  //Multiselect
  "Click to select": "Vybrať",
  "Click to deselect": "Odobrať",
  "Selected": "Vybrané",
  "Date of delivery": "Dátum dodania",
  //Purchase order
  "Purchase order history": "História čísel objednávky",
  "Valid from": "Platné od",
  "Valid to": "Platné do",
  "Purchase order active": "Aktívne",
  "Purchase order inactive": "Nastaviť ako aktívne",
  "Back to company data": "Späť na údaje spoločnosti",
  "Purchase order number enabled successfully.": "Číslo objednávky bolo aktivované.",
  "All purchase order numbers were disabled.": "Všetky čísla objednávok boli deaktivované.",
  "Expired purchase order number cannot be set as active.": "Expirované číslo objednávky nemôže byť aktivované.",
  // Activity Logs
  "Activity Logs": "Activity Logs",
  "User Name": "User Name",
  "Role": "Role",
  "Activity": "Activity",
  "Date": "Date",
  // Business type
  "Add new business type": "Pridať nový typ prevádzky",
  "Action": "Akcia",
  "Assign Playlist": "Priradiť Playlist",
  "Playlist": "Playlist",
  "Are you sure you want to Delete {type}": "Naozaj si prajete vymazať {type}?",
  "Delete business type": "Vymazať typ prevádzky",
  "Yes, Delete": "Áno, vymazať",
  "No, Cancel": "Nie, zrušiť",
  "Default": "Predvolený",
  "Set as default": "Nastaviť ako predvolené",
  "Yes": "Áno",
  "No": "Nie",
  "Are you sure you want to delete ": "Naozaj si prajete vymazať "
}
