export default {
  "Venues": "Prevádzky",
  "Ratings": "{count} hodnotení | {count} hodnotenie | {count} hodnotení",
  "Edit venue": "Upraviť prevádzku",
  "Venue detail": "Detail prevádzky",
  "Mobile app features": "Funkcie mobilnej aplikácie",
  "Venue information": "Informácie o prevádzke",
  "Time zone": "Časová zóna",
  "GPS": "GPS: zemepisná šírka a dĺžka",
  "Venue Rating": "Hodnotenie prevádzky",
  "Campaign Filter": "Filter kampane",
  "Choose Filter": "Zvoliť filter",
  "User ratings": "Hodnotenia užívateľov",
  "Your response was sent.": "Vaša odpoveď bola odoslaná.",
  "Reply": "Odpovedať",
  "Filter": "Filter",
  "All venues": "Všetky prevádzky",
  "Venue name": "Názov prevádzky",
  "Show all venues": "Zobraziť prevádzky",
  "Subscription": "Balík služieb",
  "None": "Žiadny",
  "Subscription expires on:": "Platnosť balíku služieb vyprší dňa:",
  "Change venue photo": "Zmeniť fotku prevádzky",
  "Please add a couple of sentences about this venue. This short description will be visible in Songoroo mobile app in Venue detail section.": "Pridajte pár viet o tejto prevádzke. Tento krátky popis bude viditeľný v mobilnej aplikácii Songoroo v sekcii detail prevádzky.",
  "GPS location of your venue where visitors can find you.": "GPS poloha Vašej prevádzky kde Vás používatelia vedia nájsť.",
  "Time zone managing the opening hours of your venue.": "Časová zóna podľa ktorej sa riadia otváracie hodiny Vašej prevádzky.",
  "Venue Address": "Adresa prevádzky",
  "Venue name cannot be empty": "Názov prevádzky musí byť vyplnený.",
  "Back to venues list": "Späť na zoznam prevádzok",
  "No active venue": "Žiadna aktívna prevádzka",
  "Sorry, you have no active venue for which you could setup Songoroo App features.": "Prepáčte, nemáte žiadnu aktívnu prevádzku pre ktorú by ste mohli nastaviť funkcie Songoroo aplikácie."
}
