import ApiService from "@/services/api.service";
import { ActionTree } from "vuex";
import { PlaylistState, RootState } from "@/types/store/clientzone";

export const actions: ActionTree<PlaylistState, RootState> = {
  FETCH_VENUE_TYPES: ({ commit, getters, dispatch }, newVenue = false): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      const venueId = localStorage.getItem("venue_id");
      let resource: string = "/api/v1/clientzone/venue_type";
      if (venueId) {
        resource = `/api/v1/clientzone/venue_type?venue_id=${venueId}`;
      }

      await dispatch("FETCH_CUSTOM_PLAYLIST");
      const customPlaylist = getters["GET_CUSTOM_PLAYLIST"].venue_type_id;
      const customPlaylistCreated = getters["GET_CUSTOM_PLAYLIST"].created;

      try {
        const response = await ApiService.get(resource);
        const data = response.data;

        if (customPlaylistCreated && !newVenue) {
          for (const x in data) data[x].venue_type_id == customPlaylist ? data.push(data.splice(x, 1)[0]) : 0;
        } else {
          for (const y in data) data[y].name == "Custom" ? data.push(data.pop(data.splice(y, 1)[0])) : 0;
        }

        commit("SET_VENUE_TYPES", response.data);

        resolve();
      } catch (e) {
        reject()
      }
    })
  },
  FETCH_GENRES: ({ commit }): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      const resource: string = "/api/v1/clientzone/genres?limit=1000";

      try {
        const response = await ApiService.get(resource);

        commit("SET_GENRES", response.data);

        resolve();
      } catch (e) {
        reject()
      }
    })
  },
  SET_PLAYLIST_IMAGE: ({ commit }, payload): void => commit("SET_PLAYLIST_IMAGE", payload),
  SET_GENRE_IMAGE: ({ commit }, payload): void => commit("SET_GENRE_IMAGE", payload),
  FETCH_VENUE: ({ state, commit, dispatch }, payload = {}): Promise<void> => {
    const venueId: number = !!payload.venue_id ? payload.venue_id : localStorage.getItem("venue_id");

    return new Promise(async (resolve, reject) => {
      const resource: string = `api/v1/clientzone/venue/${venueId}`;

      try {
        const response = await ApiService.get(resource)

        if (payload.venue_id) {
          commit("SET_VENUE", response.data)
        }
        resolve();
      } catch (e) {
        reject()
      }
    })
  },
  UPDATE_VENUE({ commit }, payload: object): Promise<void> {
    const venueId = localStorage.getItem("venue_id");

    return new Promise(async (resolve, reject) => {
      const resource: string = `api/v1/clientzone/venue/${venueId}/playlist`;

      try {
        const response = await ApiService.put(resource, payload)

        commit("SET_VENUE", response.data);

        resolve();
      } catch (e) {
        reject()
      }
    })
  },

  FETCH_CUSTOM_PLAYLIST: ({ commit }, payload = {}): Promise<void> => {
    const venueId: number = !!payload.venue_id ? payload.venue_id : localStorage.getItem("venue_id");

    if (venueId) {
      return new Promise(async (resolve, reject) => {
        const resource: string = `api/v1/clientzone/venue/${venueId}/custom_playlist`;

        try {
          const response = await ApiService.get(resource, payload)

          commit("SET_CUSTOM_PLAYLIST", response.data)

          resolve();
        } catch (e) {
          reject()
        }
      })
    }
  },

  SET_CUSTOM_PLAYLIST({ commit }, payload: object): Promise<void> {
    const venueId = localStorage.getItem("venue_id");

    return new Promise(async (resolve, reject) => {
      const resource: string = `api/v1/clientzone/venue/${venueId}/custom_playlist`;

      try {
        const response = await ApiService.post(resource, payload)

        commit("SET_CUSTOM_PLAYLIST", response.data)

        resolve();
      } catch (e) {
        commit("SET_CUSTOM_PLAYLIST", e.response.data.error.code);
        reject()
      }
    })
  },
  DISABLE_CUSTOM_PLAYLIST(): Promise<void> {
    const venueId = localStorage.getItem("venue_id");

    return new Promise(async (resolve, reject) => {
      const resource: string = `api/v1/clientzone/venue/${venueId}/custom_playlist/disable`;

      try {
        const response = await ApiService.get(resource)

        resolve();
      } catch (e) {
        reject()
      }
    })
  },
}
