import ApiService from "@/services/api.service";
import router from "@/router";
import { VenueData, VenueRegisterData, SelectedWebstreamSelectVenueData } from "@/types";
import { ActionTree } from "vuex";
import { CASSOVIA_API_RESOURCE, ROOT_ACTION } from "@/utils/helpers";
import { errorMessage, successMessage } from "@/utils/messages-handlers";
import i18n from "@/i18n/i18n";
import { RootState, VenueState } from "@/types/store/clientzone";
import AxiosService from "@/services/axios.service";

export const actions: ActionTree<VenueState, RootState> = {
  ADD_NEW_VENUE({ commit, getters, dispatch }, payload): Promise<void> {
    const venueRegisterData: VenueRegisterData = getters["GET_VENUE_REGISTER_DATA"];
    const venueId: number = getters["GET_NEW_CREATED_VENUE_ID"];
    let routerId = null;

    return new Promise(async (resolve, reject) => {
      const resource: string = `/api/v1/clientzone/venue`;
      commit("SET_CREATE_VENUE_LOADER", true);
      try {
        if (!venueId) {
          const response = await ApiService.post(resource, venueRegisterData);
          commit("SET_NEW_VENUE_ID", response.data.id);
          commit("SET_NEW_CREATED_VENUE_ID", response.data.id);
          await dispatch("INIT_VENUE_OPENING_HOURS");
          await dispatch("venueSubscriptionModule/CREATE_VENUE_SUBSCRIPTION", { ...payload, venueId: response.data.id, isRenew: false }, ROOT_ACTION);
          await dispatch('emailsModule/SEND_VENUE_CREATED_EMAIL', { venueName: venueRegisterData.name, countryId: venueRegisterData.country_id, venueId: response.data.id }, ROOT_ACTION);
          if (payload.includedStreamingDevice == 1) {
            dispatch("emailsModule/SEND_STREAMER_DISPATCHED_EMAIL", { fromRegistration: false, venueName: venueRegisterData.name, countryId: venueRegisterData.country_id }, ROOT_ACTION);
          }
          routerId = response.data.id;
        } else {
          await dispatch("venueSubscriptionModule/CREATE_VENUE_SUBSCRIPTION", { ...payload, venueId: venueId, isRenew: false }, ROOT_ACTION);
          routerId = venueId;
        }
        router.push({
          path: '/venue-detail/' + routerId,
          params: { id: routerId }
        });
        successMessage("New venue created.", 3000);
        commit("SET_CREATE_VENUE_LOADER", false);
        commit("SET_NEW_CREATED_VENUE_ID", null);
        resolve();

      } catch (e) {
        reject()
      }
    })
  },
  CREATE_SUBSCRIPTION_AFTER_UNSUCCESSFUL_ATTEMPT({ commit, getters, dispatch }, payload): Promise<void> {

    return new Promise(async (resolve, reject) => {
      commit("SET_CREATE_VENUE_LOADER", true);
      try {
        const newVenueId = getters['GET_NEW_VENUE_ID'];
        await dispatch("venueSubscriptionModule/CREATE_VENUE_SUBSCRIPTION", { ...payload, venueId: newVenueId }, ROOT_ACTION);
        router.push({
          path: '/venue-detail/' + newVenueId,
          params: { id: newVenueId }
        });
        successMessage("New venue created.", 3000);
        commit("SET_CREATE_VENUE_LOADER", false);
        resolve();

      } catch (e) {
        commit("SET_CREATE_VENUE_LOADER", false);
        reject()
      }
    })
  },
  DELETE_VENUE: ({ commit, getters, dispatch }, venueId: string): Promise<void> => {
    commit("SET_VENUE_LOADER", true);
    const selectedVenue: VenueData = getters["GET_SELECTED_VENUE"];

    return new Promise(async (resolve, reject) => {
      const resource: string = `/api/v1/clientzone/venue/${venueId}`;

      try {
        await ApiService.delete(resource)

        commit("SET_VENUE_LOADER", false);
        await dispatch('emailsModule/SEND_VENUE_DELETED_EMAIL', { venueName: selectedVenue.name, countryId: selectedVenue.country_id }, ROOT_ACTION);
        successMessage("Venue deleted.", 3000);

        resolve();
      } catch (e) {
        commit("SET_VENUE_LOADER", false);
        reject()
      }
    })
  },
  FETCH_VENUES: ({ commit, rootGetters, dispatch }, payload): Promise<void> => {
    const companyId: number = rootGetters["companyModule/GET_COMPANY_ID"];

    return new Promise(async (resolve, reject) => {
      const resource: string = `api/v1/clientzone/venue?limit=10000&name=${payload.name}&venue_owner_id=${companyId}`;
      try {
        if (companyId) {
          const response = await ApiService.get(resource)
          if (payload.fetchRatings) {
            response.data.map(venue => {
              dispatch('FETCH_VENUES_RATINGS', venue.id)
            })
          }
          commit("SET_VENUES", response.data)
        }

        resolve();
      } catch (e) {
        reject()
      }
    })
  },
  FETCH_VENUES_COUNT: ({ commit, rootGetters }, name = null): Promise<void> => {
    const companyId: number = rootGetters["companyModule/GET_COMPANY_ID"];
    let resource: string = ''
    if (name) {
      resource = `${CASSOVIA_API_RESOURCE}/api/get-venues-count/${companyId}?name=${name}`;
    } else {
      resource = `${CASSOVIA_API_RESOURCE}/api/get-venues-count/${companyId}`;
    }

    return new Promise(async (resolve, reject) => {
      try {
        const response = await AxiosService.getAxios().get(resource);

        commit("SET_VENUES_COUNT", response.data)

        resolve();
      } catch (e) {
        reject();
      }
    })
  },
  RESET_USER_AUTHORISATIONS_VENUES: async ({ commit }, payload): Promise<void> => await commit("RESET_USER_AUTHORISATIONS_VENUES", payload),
  FETCH_VENUE: ({ commit }, payload = {}): Promise<void> => {
    const venueId: number = !!payload.venue_id ? payload.venue_id : router.currentRoute.params.id;
    if (!payload.venue_id) localStorage.setItem("venue_id", venueId.toString());


    return new Promise(async (resolve, reject) => {
      const resource: string = `api/v1/clientzone/venue/${venueId}`;

      try {
        const response = await ApiService.get(resource)

        if (!!payload.venue_id) {
          commit("SET_USER_AUTHORISATIONS_VENUES", {
            id: response.data.id,
            name: response.data.name,
            address1: response.data.address1,
            city: response.data.city
          });
          commit("SET_USER_ROLES_TO_AUTHORISATIONS_VENUES", payload);
        } else {
          commit("SET_VENUE", response.data)
        }

        resolve();
      } catch (e) {
        reject()
      }
    })
  },
  FETCH_VENUE_OPENING_HOURS({ commit }): Promise<void> {
    return new Promise(async (resolve, reject) => {
      const resource: string = `/api/v1/clientzone/venue/${router.currentRoute.params.id}/openinghours`;

      try {
        const response = await ApiService.get(resource)

        commit("SET_VENUE_OPENING_HOURS", response.data)

        resolve();
      } catch (e) {
        reject()
      }
    })
  },
  INIT_VENUE_OPENING_HOURS: ({ getters }, payload: number): Promise<void> => {
    const newAddedVenueId: number = getters["GET_NEW_VENUE_ID"];

    return new Promise(async (resolve, reject) => {
      let resource: string;

      if (payload) {
        resource = `api/v1/clientzone/venue/${payload}/openinghours`;
      } else {
        resource = `api/v1/clientzone/venue/${newAddedVenueId}/openinghours`;
      }

      const initVenueOpeningHoursData: object = {
        monday: {
          venue_opening: "07:00:00",
          venue_closing: "23:00:00",
        },
        tuesday: {
          venue_opening: "07:00:00",
          venue_closing: "23:00:00",
        },
        wednesday: {
          venue_opening: "07:00:00",
          venue_closing: "23:00:00",
        },
        thursday: {
          venue_opening: "07:00:00",
          venue_closing: "23:00:00",
        },
        friday: {
          venue_opening: "07:00:00",
          venue_closing: "23:00:00",
        },
        saturday: {
          venue_opening: "07:00:00",
          venue_closing: "23:00:00",
        },
        sunday: {
          venue_opening: "07:00:00",
          venue_closing: "23:00:00",
        }
      }

      try {
        await ApiService.post(resource, initVenueOpeningHoursData);

        resolve();
      } catch (e) {
        reject()
      }
    })
  },
  UPDATE_VENUE_OPENING_HOURS({ commit, dispatch }, payload: object): Promise<void> {
    return new Promise(async (resolve, reject) => {
      const resource: string = `api/v1/clientzone/venue/${router.currentRoute.params.id}/openinghours`;

      try {
        const response = await ApiService.put(resource, payload);

        commit("SET_VENUE_OPENING_HOURS", response.data);
        successMessage("Venue profile updated.", 3000);
        await dispatch("FETCH_VENUE_OPENING_HOURS");

        resolve();
      } catch (e) {
        reject()
      }
    })
  },
  UPDATE_VENUE({ commit }, payload: object): Promise<void> {
    return new Promise(async (resolve, reject) => {
      const resource: string = `api/v1/clientzone/venue/${router.currentRoute.params.id}`;

      try {
        const response = await ApiService.put(resource, payload)

        commit("SET_VENUE", response.data)

        resolve();
      } catch (e) {
        reject()
      }
    })
  },
  SET_COUNTRY_CODES({ commit }): Promise<void> {
    return new Promise(async (resolve, reject) => {
      const resource: string = "/api/v1/codelist/country?limit=200&offset=0";

      try {
        const response = await ApiService.get(resource, {
          headers: {
            'sgr-lang': i18n.locale
          }
        });
        commit("SET_COUNTRY_CODES", response.data.sort((a, b) => a.name.localeCompare(b.name, i18n.locale, { ignorePunctuation: true })));

        resolve();
      } catch (e) {
        reject()
      }
    })
  },
  SET_VENUE_IMAGE: ({ commit }, payload): void => commit("SET_VENUE_IMAGE", payload),
  SET_USER_ROLES_TO_AUTHORISATIONS_VENUES: ({ commit }, payload): void => commit("SET_USER_ROLES_TO_AUTHORISATIONS_VENUES", payload),
  ADD_USER_PERMISSION: ({ dispatch }, { payload, group = false }): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      const resource: string = "/api/v1/clientzone/user/venue_owner_role";

      if (!group) dispatch("SET_LOADER", payload[0].venue_id);
      else dispatch("SET_GROUP_ALL_LOADER", true);

      try {
        await ApiService.post(resource, payload);

        if (group) dispatch("SET_GROUP_ALL_LOADER", false);
        await dispatch("userModule/FETCH_USER_VENUES_PERMISSIONS", {}, ROOT_ACTION);

        resolve();
      } catch (e) {
        if (e.response.status == 403) {
          errorMessage('Access denied', 5000)
        } else {
          errorMessage('Operation failed', 5000)
        }
        if (group) dispatch("SET_GROUP_ALL_LOADER", false);
        if (!group) dispatch("UNSET_LOADER", payload[0].venue_id);


        reject()
      }
    })
  },
  REMOVE_USER_PERMISSION: ({ dispatch }, { payload, group = false }): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      const resource: string = group ? `/api/v1/clientzone/user/venue_owner_role/${payload}` : `/api/v1/clientzone/user/venue_owner_role/${payload.id}`;

      if (!group) dispatch("SET_LOADER", payload.venueId);

      try {
        await ApiService.delete(resource);

        if (!group) await dispatch("userModule/FETCH_USER_VENUES_PERMISSIONS", {}, ROOT_ACTION);

        resolve();
      } catch (e) {
        if (e.response.status == 403) {
          errorMessage('Access denied', 5000)
        } else {
          errorMessage('Operation failed', 5000)
        }
        if (group) dispatch("SET_GROUP_ALL_LOADER", false);
        if (!group) dispatch("UNSET_LOADER", payload.venueId);
        reject()
      }
    })
  },
  REMOVE_USER_PERMISSION_BY_GROUP: async ({ dispatch }, { payload }): Promise<void> => {
    dispatch("SET_GROUP_ALL_LOADER", true);

    for await (const p of payload) {
      await dispatch("REMOVE_USER_PERMISSION", { payload: p, group: true })
    }

    await dispatch("userModule/FETCH_USER_VENUES_PERMISSIONS", { group: true }, ROOT_ACTION);
  },
  SET_LOADER: ({ commit }, payload: number): void => commit("SET_LOADER", payload),
  UNSET_LOADER: ({ commit }, payload: number): void => commit("UNSET_LOADER", payload),
  SET_GROUP_ALL_LOADER: ({ commit }, payload: boolean): void => commit("SET_GROUP_ALL_LOADER", payload),
  RESET_VENUE_IMAGE: ({ commit }): void => commit("RESET_VENUE_IMAGE", ""),


  FETCH_VENUES_DETAIL_RATINGS: ({ commit }, payload): Promise<void> => {
    const venueId = localStorage.getItem("venue_id");
    const rating = payload?.rating;
    const campaignId = payload?.campaing;
    return new Promise(async (resolve, reject) => {
      let resource: string = `/api/v1/clientzone/venue/${venueId}/questionary/ratings`;

      if (!rating && !campaignId) {
        resource = `/api/v1/clientzone/venue/${venueId}/questionary/ratings`;
      }
      if (rating && campaignId) {
        resource = `/api/v1/clientzone/venue/${venueId}/questionary/ratings?questionary_id=${campaignId}&rating_filter=${rating}`
      }
      if (rating && !campaignId) {
        resource = `/api/v1/clientzone/venue/${venueId}/questionary/ratings?rating_filter=${rating}`
      }
      if (!rating && campaignId) {
        resource = `/api/v1/clientzone/venue/${venueId}/questionary/ratings?questionary_id=${campaignId}`
      }


      try {
        const response = await ApiService.get(resource)
        for (const venueRating of response.data.ratings) {
          if (venueRating.questionary_id === 1) {
            venueRating.questionary_title = 'Default survey'
          } else {
            const resource: string = `/api/v1/clientzone/venue_questionary/${venueRating.questionary_id}`
            const questionaryResponse = await ApiService.get(resource)
            venueRating.questionary_title = questionaryResponse.data.name
          }
        }
        commit("SET_VENUES_DETAIL_RATINGS", response.data)

        resolve();
      } catch (e) {
        reject()
      }
    })
  },
  FETCH_VENUES_RATINGS: ({ commit }, payload): Promise<void> => {

    return new Promise(async (resolve, reject) => {
      const resource: string = `/api/v1/clientzone/venue/${payload}/questionary/ratings`;

      try {
        const response = await ApiService.get(resource)
        commit("SET_VENUES_RATINGS", { data: response.data, id: payload })

        resolve();
      } catch (e) {
        reject()
      }
    })
  },

  FETCH_VENUES_DETAIL_ANSWERS: ({ commit }, payload): Promise<void> => {
    const venueId = localStorage.getItem("venue_id");
    return new Promise(async (resolve, reject) => {
      const userId = await payload.user_id;
      const questionaryIdQuery = payload.question_id ? `&questionary_id=${payload.question_id}` : '';
      const resource: string = `/api/v1/clientzone/venue/${venueId}/user/${userId}/questionary/answers?lang=${i18n.locale}${questionaryIdQuery}`;

      try {
        const response = await ApiService.get(resource)
        commit("SET_VENUES_DETAIL_ANSWERS", response.data)

        resolve();
      } catch (e) {
        reject()
      }
    })
  },
  FETCH_AVAILABLE_VENUES_FOR_ROLE_MANAGEMENT: ({ commit, rootGetters }): Promise<void> => {
    const venues: Array<VenueData> = rootGetters['venueModule/GET_VENUES'];
    const availableVenues = [];
    return new Promise(async (resolve, reject) => {
      const resource: string = `/api/v1/clientzone/user/role_permission/available_venues?limit=1000&offset=0`

      try {
        const response = await ApiService.get(resource);
        venues.forEach((v: VenueData) => {
          if (response.data.filter(function (e) { return e.id === v.id })) {
            availableVenues.push(v)
          }
        })
        commit("SET_AVAILABLE_VENUES_FOR_ROLE_MANAGEMENT", availableVenues)
        resolve();
      } catch (e) {
        reject()
      }
    })
  },
  SET_VENUE_REGISTER_DATA: async ({ commit, dispatch, rootGetters }, { payload, countryCode }): Promise<void> => {
    commit("SET_VENUE_REGISTER_DATA", payload);
    if (!rootGetters["companyModule/TAX_IS_NOT_VALID"]) await dispatch("venueSubscriptionModule/FETCH_TAX_RATE", countryCode, ROOT_ACTION);
    router.push("/add-new-venue-step-2");
  },

  FEEDBACK_MESSAGE_REPLY: ({ dispatch, commit }, payload): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      const resource: string = `/api/v1/clientzone/message/feedback_message_reply`;

      try {
        const response = await ApiService.post(resource, payload);
        await dispatch("FETCH_VENUES_DETAIL_RATINGS");
        await dispatch("FETCH_VENUES_DETAIL_ANSWERS", { user_id: response.data.recipient_user_id, venue_id: response.data.venue_id });

        resolve();
      } catch (e) {
        reject()
      }
    })
  },
  SET_RATING_USER_IMAGE: ({ commit }, payload): void => commit("SET_RATING_USER_IMAGE", payload),
  SET_ERROR_ON_INVALID_CREDIT_CARD: ({ commit }, payload): void => {
    commit("SET_ERROR_ON_INVALID_CREDIT_CARD", payload)
  },
  SET_CREATE_VENUE_LOADER: ({ commit }, payload): void => {
    commit("SET_CREATE_VENUE_LOADER", payload)
  },
  SET_SELECTED_WEBSTREAM_VENUE: ({ commit }, payload: SelectedWebstreamSelectVenueData | null): void => {
    commit("SET_SELECTED_WEBSTREAM_VENUE", payload);
  }
}
