import AxiosService from "@/services/axios.service";

export const actions = {
    FETCH_ACTIVITY_LOGS: ({ commit, dispatch }, payload): Promise<void> => {
      return new Promise(async (resolve, reject) => {
        let resource: string = ''
        if(payload) {
          resource = `/api/v1/clientzone/activity_log?search=${payload}`;
        }
        if(payload && !payload.name) {
          resource = `/api/v1/clientzone/activity_log?position=${payload.position}&jobApplicantStatus=${payload.jobApplicantStatus}&createdAt=${payload.createdAt}`;
        }
        else {
          resource = `/api/v1/clientzone/activity_log`;
        }
        try {
          const response = await AxiosService.getAxios().get(resource)
          commit("SET_ACTIVITY_LOGS", response.data);
          resolve();
        } catch (e) {
          reject()
        }
      })
    },

    EXPORT_LOGS: ({ commit, dispatch }, payload): Promise<void> => {
      return new Promise(async (resolve, reject) => {
        const resource: string = `/api/v1/clientzone/activity_log/export`;
        try {
          await AxiosService.getAxios().get(resource)
          resolve();
        } catch (e) {
          reject()
        }
      })
    }
}
