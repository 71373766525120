import Vue from "vue";
import { BankAccountData, PaymentCardData, PaymentMethodAssignmentData } from "@/types";
import { MutationTree } from "vuex";
import { PaymentState } from "@/types/store/clientzone";

export const mutations: MutationTree<PaymentState> = {
  SET_PAYMENT_CARDS: (state, payload: Array<PaymentCardData>): Array<PaymentCardData> => Vue.set(state, "paymentCards", payload),
  SET_BANK_ACCOUNTS: (state, payload: Array<BankAccountData>): Array<BankAccountData> => Vue.set(state, "bankAccounts", payload),
  SET_PAYMENT_METHOD_ASSIGNMENTS: (state, payload: Array<PaymentMethodAssignmentData>): Array<PaymentMethodAssignmentData> => Vue.set(state, "paymentMethodAssignments", payload),
  SET_STRIPE_OBJECT: (state, payload) => Vue.set(state, "stripeObject", payload),
  SET_STRIPE_CARD_OBJECT: (state, payload: object): object => Vue.set(state, "stripeCardObject", payload),
  SET_STRIPE_CARD: (state, payload): void => {
    Vue.set(state.stripeCard, "paymentMethodToken", payload.id);
    Vue.set(state.stripeCard, "expirationMonth", payload.card.exp_month.toString());
    Vue.set(state.stripeCard, "expirationYear", payload.card.exp_year.toString());
    Vue.set(state.stripeCard, "cardLast4", payload.card.last4);
  },
  SET_REGISTER_PAYMENT_METHOD: (state, payload: number): number => Vue.set(state, "registerPaymentMethod", payload),
  SET_FILTERING: (state, payload: boolean): boolean => Vue.set(state, "isFiltering", payload),
  SET_IS_PAYMENT_CARD_VALID: (state, payload: boolean): boolean => Vue.set(state, "isPaymentCardValid", payload),
  SET_PAYMENT_CARD_LOADER: (state, payload: boolean): boolean => Vue.set(state, "loader", payload),
  SET_IBAN_ERROR: (state, payload: string): string => Vue.set(state, 'ibanError', payload),
  SET_SETUP_INTENT: (state, payload: string): string => Vue.set(state, 'setupIntentObject', payload),
  SET_STRIPE_CARD_PAYMENT_METHOD_ID: (state, payload: string): string => Vue.set(state, 'cardPaymentMethodId', payload),
  SET_CARD_ADDED_STATE: (state, payload: boolean): boolean => Vue.set(state, 'cardAddedState', payload)
}
