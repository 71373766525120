import { CASSOVIA_API_RESOURCE, ROOT_ACTION } from "@/utils/helpers";
import router from '@/router';
import {ActionTree} from "vuex";
import { BillingAdminzoneState } from "@/types/store/adminzone/billing";
import { RootState } from "@/types/store/adminzone";
import i18n from "@/i18n/i18n";
import { successMessage } from "@/utils/messages-handlers";
import AxiosService from "@/services/axios.service";

export const actions: ActionTree<BillingAdminzoneState, RootState> = {
  FETCH_INVOICES: ({commit, rootGetters}): Promise<void> => {
    const companyId: number = rootGetters["companyModule/GET_COMPANY_ID"];

    return new Promise(async (resolve, reject) => {
      const resource: string = `${CASSOVIA_API_RESOURCE}/api/invoices?venueOwnerId=${companyId}`;

      try {
        const response = await AxiosService.getAxios().get(resource);

        commit("SET_INVOICES", response.data);

        resolve();
      } catch (e) {
        reject()
      }
    })
  },
  FETCH_ALL_INVOICES: ({commit, rootGetters},{currentPage: currentPage, payload: payload = null}): Promise<void> => {

    //paymentType%5B%5D=1&paymentType%5B%5D=2
    //status%5B%5D=paid&status%5B%5D=due
    //invoiceType%5B%5D=
    //?property[<after|before|strictly_after|strictly_before>]=value
    return new Promise(async (resolve, reject) => {
      let resource: string = ''
      let selectedPaymentMethodsQuery = '';
      let selectedStatusQuery = '';
      let selectedInvoiceTypesQuery = '';
      let isPairedQuery = ``;
      let invoiceDurationQuery = '';

      if(payload){
        if(payload.selectedPaymentMethods.length){
          const selectedPaymentMethodsInArray = payload.selectedPaymentMethods.split(",")
          selectedPaymentMethodsQuery = '&'
          for(const selectedPaymentMethod of selectedPaymentMethodsInArray){
            selectedPaymentMethodsQuery += `paymentType%5B%5D=${selectedPaymentMethod}`
          }
        }

        if(payload.selectedInvoiceTypes){
          const selectedInvoiceTypesInArray = payload.selectedInvoiceTypes.split(',')
          for(const selectedInvoiceType of selectedInvoiceTypesInArray){
            selectedInvoiceTypesQuery += `&invoiceType%5B%5D=${selectedInvoiceType}`
          }
        }

        if(payload.selectedStatuses){
          const selectedStatusesInArray = payload.selectedStatuses.split(",")
          for(const selectedStatus of selectedStatusesInArray){
            selectedStatusQuery += `&status%5B%5D=${selectedStatus}`
          }
        }

        if(payload.isPaired && payload.isPaired != 'null'){
            isPairedQuery = `&isPaired=${payload.isPaired}`
        } else  {
          isPairedQuery = ``
        }

        if(payload.invoiceDuration){
          const invoiceDurationInArray = payload.invoiceDuration.split(',')
          const from = invoiceDurationInArray[0]
          const to = invoiceDurationInArray[1]
          invoiceDurationQuery = `&startPeriodDate<after>=${from}&endPeriodDate<before>=${to}`
        }

      }

      if(payload){
        resource = `${CASSOVIA_API_RESOURCE}/api/invoices?invoiceNumber=${payload.invoiceNumber}&invoiceSubscriptionInstances.venueName=${payload.venueName}&venueOwnerName=${payload.venueOwnerName}${isPairedQuery}${selectedPaymentMethodsQuery}${selectedInvoiceTypesQuery}${selectedStatusQuery}${invoiceDurationQuery}`;
      } else {
        resource = `${CASSOVIA_API_RESOURCE}/api/invoices?page=${currentPage}`
      }

      try {
        const response = await AxiosService.getAxios().get(resource);

        commit("SET_ALL_INVOICES", response.data);
        commit("SET_ALL_INVOICES_COUNT", response.headers['total-count'])

        resolve();
      } catch (e) {
        reject()
      }
    })
  },
  FETCH_VENUES_ATTACHED_TO_INVOICE:({commit}, invoiceId): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      const resource: string = `${CASSOVIA_API_RESOURCE}/api/venues-attached-to-invoice/${invoiceId}`

      try{
        const response = await AxiosService.getAxios().get(resource);

        commit("SET_CORPORATE_INVOICE_VENUES", response.data);
        resolve();
      } catch(e){
        reject()
      }
    })
  },

  SEND_INVOICE_TO_BILLING_EMAIL: ({commit}, payload): Promise<void> => {
    return new Promise(async(resolve, reject) => {
      commit("SET_LOADER", { invoiceId: payload.invoiceId, value: true });
      const resource: string = `${CASSOVIA_API_RESOURCE}/api/email-invoice`

      try{
        const response = await AxiosService.getAxios().post(resource, payload);
        commit("azInvoiceSentConfirmationModule/SET_INVOICE_SENT_CONFIRMATION_DATA", response.data, ROOT_ACTION)
        commit("SET_LOADER", { invoiceId: payload.invoiceId, value: false });

        resolve();
      } catch (e) {
        reject()
      }
    })
  },
  DOWNLOAD_INVOICE: ({commit, getters}, invoiceId: number): Promise<void> => {
    commit("SET_LOADER_2", { invoiceId: invoiceId, value: true });
    // const invoiceNumber: Function = getters["GET_INVOICE_NUMBER"];

    return new Promise(async (resolve, reject) => {
      const resource: string = `${CASSOVIA_API_RESOURCE}/api/download-invoice/${invoiceId}/${i18n.locale}`;

      try {
        const response = await AxiosService.getAxios().get(resource, {
          responseType: 'blob'
        });

        commit("SET_LOADER_2", { invoiceId: invoiceId, value: false });

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `invoice-${(invoiceId)}.pdf`);
        document.body.appendChild(link);
        link.click();

        resolve();
      } catch (e) {
        commit("SET_LOADER_2", { invoiceId: invoiceId, value: false });
        reject()
      }
    })
  },

  PAIR_CORPORATE_INVOICES: ({commit}, file): Promise<void> => {
    return new Promise(async(resolve, reject) => {
      const formData = new FormData();
      formData.append('file', file)
      const resource: string = `${CASSOVIA_API_RESOURCE}/api/pair-corporate-invoices`;
      try{
        const response = await AxiosService.getAxios().post(resource, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
        })
        resolve()
      } catch(e){
        reject()
      }
    })
  },

  CREATE_INVOICE: ({commit}, {invoicePayload: invoicePayload, invoiceItemsPayload: invoiceItemsPayload, isSubjectToReverseCharge: isSubjectToReverseCharge}): Promise<void> => {
    return new Promise(async(resolve, reject) => {
      const resource: string = `${CASSOVIA_API_RESOURCE}/api/invoices`
      try{
        const response = await AxiosService.getAxios().post(resource, invoicePayload)
        const invoiceId = response.data.id
        for (const invoiceItem of invoiceItemsPayload.invoiceItems){
            const resource: string = `${CASSOVIA_API_RESOURCE}/api/create-subscription-instance-and-attach-to-invoice/${invoiceId}`
            const response = await AxiosService.getAxios().post(resource, {
              taxPercentage: String(invoicePayload.taxPercentage),
              venueId: invoiceItem.venue.id,
              venueName: invoiceItem.venue.name,
              venueAddress1: invoiceItem.venue.address1,
              venueAddress2: invoiceItem.venue.address2,
              venueCity: invoiceItem.venue.city,
              isVenueOwnerSubjectToReverseCharge: isSubjectToReverseCharge,
              startOfBillingPeriod: Number (invoicePayload.startSubscriptionPeriodDate),
              endOfBillingPeriod: Number (invoicePayload.endSubscriptionPeriodDate)
            })
          const invoiceSubscriptionInstanceId = response.data.id
          for (const product of invoiceItem.products){
            const resource: string = `${CASSOVIA_API_RESOURCE}/api/attach-invoice-subscription-item-to-invoice/${invoiceSubscriptionInstanceId}`
            const response = await AxiosService.getAxios().post(resource, {
              amount: invoicePayload.selectedInvoiceType == 4 ? -(product.price*100) : product.price*100,
              description: i18n.locale == 'en' ? product.productName : product.productNameSk,
              periodStart: Number (invoicePayload.startSubscriptionPeriodDate),
              periodEnd: Number (invoicePayload.endSubscriptionPeriodDate),
              songorooServiceType: product.songorooServiceType,
              taxPercentage: String(invoicePayload.taxPercentage),
              isPaid: false
            })
          }
        }

        successMessage('Invoice created', 5000);
        await router.push('/invoices')
        resolve()
      } catch (e){
        reject()
      }
    })
  }

}
