import {
  BankAccountData,
  PaymentCardData,
  PaymentMethodAssignmentData,
  StripeCardData,
  VenueData, VenueSubscriptionData
} from "@/types";
import {GetterTree} from "vuex";
import {buildSegCompareObj} from "@fullcalendar/vue";
import { PaymentState, RootState } from "@/types/store/clientzone";

export const getters: GetterTree<PaymentState, RootState> = {
  GET_PAYMENT_CARDS: (state): Array<PaymentCardData> => state.paymentCards,
  GET_BANK_ACCOUNTS: (state): Array<BankAccountData> => state.bankAccounts,
  GET_PAYMENT_METHODS: (state): Array<PaymentCardData | BankAccountData> => {
    const paymentMethodsArray: Array<PaymentCardData | BankAccountData> = [] as Array<PaymentCardData | BankAccountData>;

    state.paymentCards.forEach((card) => {
      paymentMethodsArray.push(card);
    })

    state.bankAccounts.forEach((account) => {
      paymentMethodsArray.push(account);
    })

    return paymentMethodsArray;
  },
  GET_PAYMENT_METHODS_ASSIGNMENTS: (state, getters, rootState, rootGetters): Array<any> => {
    const venues: Array<VenueData> = rootGetters["venueModule/GET_VENUES"];
    const paymentMethodAssignments: Array<PaymentMethodAssignmentData> = state.paymentMethodAssignments;
    const paymentCards: Array<PaymentCardData> = state.paymentCards;
    const bankAccounts: Array<BankAccountData> = state.bankAccounts;
    const paymentMethodAssignmentsArray: Array<any> = [] as Array<any>;
    const isFiltering: boolean = state.isFiltering;
    let filteredVenues: Array<VenueData> = [] as Array<VenueData>;

    if (isFiltering) {
      venues.find((venueData) => {
        return paymentMethodAssignments.find((paymentData) => {
          if (venueData.id === paymentData.venueId) {
            filteredVenues.push(venueData);
          }
        })
      })
    } else {
      filteredVenues = venues;
    }

    filteredVenues.forEach((v) => {
      const paymentType = paymentMethodAssignments.find(p => p.venueId == v.id);
      const paymentTypeId = paymentType ? paymentType.id : null;

      const paymentCard = paymentType ? paymentCards.find(c => c.id === paymentType.cardId) : null;
      const bankAccount = paymentType ? bankAccounts.find(a => a.id === paymentType.bankAccountId) : null;

      const paymentMethodAssignmentsData: object = {
        id: paymentTypeId,
        venueId: v.id,
        name: v.name,
        address1: v.address1,
        city: v.city,
        paymentType: !!paymentType ? paymentType.paymentType : null,
        cardId: !!paymentType ? paymentType.cardId : 0,
        bankAccountId: !!paymentType ? paymentType.bankAccountId : 0,
        paymentCard: !!paymentCard ? paymentCard.lastFourNumbers : null,
        bankAccount: !!bankAccount ? bankAccount.lastFourDigits : null,
        firstFourDigits: !!bankAccount ? bankAccount.firstFourDigits : null
      }

      paymentMethodAssignmentsArray.push(paymentMethodAssignmentsData)
    })

    return paymentMethodAssignmentsArray;
  },
  GET_STRIPE_PUBLISHABLE_KEY: (): string => process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
  GET_STRIPE_OBJECT: (state) => state.stripeObject,
  GET_STRIPE_CARD: (state): StripeCardData => state.stripeCard,
  GET_REGISTER_PAYMENT_METHOD: (state): number => state.registerPaymentMethod,
  GET_IS_PAYMENT_CARD_VALID: (state): boolean => state.isPaymentCardValid,
  GET_PAYMENT_CARD_LOADER: (state): boolean => state.loader,
  GET_IBAN_ERROR:(state): string => state.ibanError,
  GET_SETUP_INTENT_OBJECT: (state): object => state.setupIntentObject,
  GET_STRIPE_CARD_PAYMENT_METHOD_ID: (state): string => state.cardPaymentMethodId,
  GET_CARD_ADDED_STATE: (state): boolean => state.cardAddedState
}
