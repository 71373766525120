import { CASSOVIA_API_RESOURCE } from "@/utils/helpers";
import { ActionTree } from "vuex";
import { CZUserNotificationData } from "@/types";
import { notificationMessage } from "@/utils/cz-notifications-handlers";
import router from "@/router";
import i18n from "@/i18n/i18n";
import { NotificationsState, RootState } from "@/types/store/clientzone";
import AxiosService from "@/services/axios.service";

export const actions: ActionTree<NotificationsState, RootState> = {
  FETCH_CZ_USER_NOTIFICATIONS: ({ commit, dispatch, rootGetters }): Promise<void> => {
    if (router.currentRoute.path === "/select-company") return;

    commit("RESET_NOTIFICATIONS_STATE", []);
    const userId: number = rootGetters["userModule/GET_USER_ID"];
    const venueOwnerId = rootGetters["companyModule/GET_COMPANY_ID"]
    const notificationsLength: Array<string> = [] as Array<string>;

    return new Promise(async (resolve, reject) => {
      const resource: string = `${CASSOVIA_API_RESOURCE}/api/venue-owner-client-zone-notification-instances?&clientZoneUserId=${userId}&venueOwnerId=${venueOwnerId}&wasInspected=false`;

      try {
        const response = await AxiosService.getAxios().get(resource);

        commit("SET_CZ_USER_NOTIFICATIONS", response.data);

        if (response.data.length) {
          response.data.forEach((d: CZUserNotificationData) => {
            const notificationInstanceId: number = d.id;
            const userNotificationsKey: string = `user_${userId}_notifications`;
            const lang: string = i18n.locale;
            if (!sessionStorage.getItem(userNotificationsKey) && !d.wasInspected) {
              sessionStorage.setItem(userNotificationsKey, notificationInstanceId.toString());

              notificationMessage(
                lang == 'sk' ? d.clientZoneNotification.clientZoneNotificationNameSk : d.clientZoneNotification.clientZoneNotificationNameEn,
                d.clientZoneNotification.clientZoneNotificationType,
                d.clientZoneNotification.clientZoneNotificationImportance,
                d.targetEntityId,
                d.venueId,
                d.wasInspected,
                d.id,
              );
            } else if (sessionStorage.getItem(userNotificationsKey) && !d.wasInspected) {
              if (!sessionStorage.getItem(userNotificationsKey).includes(notificationInstanceId.toString())) {
                sessionStorage.setItem(userNotificationsKey, sessionStorage.getItem(userNotificationsKey) + "," + notificationInstanceId.toString());

                notificationMessage(
                  lang == 'sk' ? d.clientZoneNotification.clientZoneNotificationNameSk : d.clientZoneNotification.clientZoneNotificationNameEn,
                  d.clientZoneNotification.clientZoneNotificationType,
                  d.clientZoneNotification.clientZoneNotificationImportance,
                  d.targetEntityId,
                  d.venueId,
                  d.wasInspected,
                  d.id,
                );
              }
            }
            notificationsLength.push(d.id.toString());
          })

          const userId: number = rootGetters["userModule/GET_USER_ID"];
          const userNotificationsKey: string = `user_${userId}_notifications`;
          const storedNotifications: Array<string> = localStorage.getItem(userNotificationsKey).split(',')

          if (storedNotifications.length > notificationsLength.length) {
            let uniqueNotificationsKey: string = "";

            notificationsLength.forEach((n) => {
              uniqueNotificationsKey += n + ",";
            })

            localStorage.setItem(userNotificationsKey, uniqueNotificationsKey.slice(0, -1));
          }
        }

        resolve();
      } catch (e) {
        reject()
      }
    })
  },
  FETCH_UNCHECKED_NOTIFICATIONS_LENGTH: ({ commit, rootGetters }): Promise<void> => {
    const userId = rootGetters["userModule/GET_USER_ID"]
    const venueOwnerId = rootGetters["companyModule/GET_COMPANY_ID"]
    return new Promise(async (resolve, reject) => {
      const resource: string = `${CASSOVIA_API_RESOURCE}/api/unchecked-notifications-length/${userId}/${venueOwnerId}`
      try {
        const response = await AxiosService.getAxios().get(resource)
        commit("SET_UNCHECKED_NOTIFICATIONS_LENGTH", response.data)
        resolve()
      } catch (e) {
        reject()
      }

    })
  },
  SET_NOTIFICATION_AS_INSPECTED: ({ commit, dispatch }, id: number): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      const resource: string = `${CASSOVIA_API_RESOURCE}/api/venue-owner-client-zone-notification-instances/${id}`;

      try {
        const response = await AxiosService.getAxios().patch(resource, { wasInspected: true }, {
          headers: {
            'Content-Type': 'application/merge-patch+json'
          }
        });

        commit("SET_NOTIFICATION_AS_INSPECTED", id);
        dispatch('FETCH_UNCHECKED_NOTIFICATIONS_LENGTH')

        resolve();
      } catch (e) {
        reject()
      }
    })
  }
}
