import Vue from "vue";
import { MutationTree } from "vuex";
import { GenresData, VenueTypesData } from "@/types";
import { PlaylistState } from "@/types/store/clientzone";

export const mutations: MutationTree<PlaylistState> = {
  SET_VENUE_TYPES: (state, payload: Array<VenueTypesData>): Array<VenueTypesData> => Vue.set(state, "venueTypes", payload),
  SET_GENRES: (state, payload: Array<GenresData>): Array<GenresData> => Vue.set(state, "genres", payload),
  SET_VENUE: (state, payload) => Vue.set(state, "selectedVenue", payload),
  SET_CUSTOM_PLAYLIST: (state, payload) => Vue.set(state, "customPlaylist", payload),
  SET_PLAYLIST_IMAGE: (state, payload): void => {
    if (!!payload.id) {
      const playlistIndex = state.venueTypes.findIndex(x => x.venue_type_id === payload.id && x.venue_type_alias_id === null);
      const playlistIndexAlias = state.venueTypes.findIndex(x => x.venue_type_id === payload.id && x.venue_type_alias_id);
      Vue.set(state.venueTypes[playlistIndex], "image", payload.encodeImage);
      if (playlistIndexAlias !== -1) {
        Vue.set(state.venueTypes[playlistIndexAlias], "image", payload.encodeImage);
      }
    } /*else {
      Vue.set(state.selectedVenue, "image", payload);
    }*/
  },
  SET_GENRE_IMAGE: (state, payload): void => {
    if (!!payload.id) {
      const genreIndex = state.genres.findIndex(x => x.id === payload.id);
      Vue.set(state.genres[genreIndex], "image", payload.encodeImage);
    } /*else {
      Vue.set(state.selectedVenue, "image", payload);
    }*/
  }
}
