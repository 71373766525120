import { ActionTree } from "vuex";
import { successMessage } from "@/utils/messages-handlers";
import { CASSOVIA_API_RESOURCE } from "@/utils/helpers";
import { NotificationsAdminzoneState } from "@/types/store/adminzone/notification";
import { RootState } from "@/types/store/adminzone";
import i18n from "@/i18n/i18n";
import AxiosService from "@/services/axios.service";

export const actions: ActionTree<NotificationsAdminzoneState, RootState> = {
  FETCH_NOTIFICATIONS: ({commit, dispatch}, clientZoneName: string = ""): Promise<void> => {
    const languageOption = i18n.locale == 'en' ? '?clientZoneNotificationNameEn=' : '?clientZoneNotificationNameSk=';
    const notificationNameParam: string =  clientZoneName ? `${languageOption}${clientZoneName}` : "";
    return new Promise(async (resolve, reject) => {
      const resource: string = `${CASSOVIA_API_RESOURCE}/api/client-zone-notifications${notificationNameParam}`;

      try {
        const response = await AxiosService.getAxios().get(resource)


        commit("SET_NOTIFICATIONS", response.data);


        resolve();
      } catch (e) {
        reject()
      }
    })
  },
  // SET_COMPANY: ({commit}, companyId: number): Promise<void> => {
  //   localStorage.setItem("company_id", companyId.toString());

  //   return new Promise(async (resolve, reject) => {
  //     const resource: string = `/api/v1/clientzone/venue_owner/${companyId}`;

  //     try {
  //       const response = await ApiService.get(resource)

  //       commit("SET_COMPANY", response.data);

  //       resolve();
  //     } catch (e) {
  //       reject()
  //     }
  //   })
  // },
  UPDATE_NOTIFICATIONS: (context, payload): Promise<void> => {

    return new Promise(async (resolve, reject) => {
      const resource: string = `${CASSOVIA_API_RESOURCE}/api/client-zone-notifications/${payload.id}`;
      try {
        const response = await AxiosService.getAxios().patch(resource, payload.data, {
          headers: {
            'Content-Type': 'application/merge-patch+json'
          }
        });

        // commit("SET_COMPANY", response.data);
        successMessage("Notification updated successfully", 3000);

        resolve();
      } catch (e) {
        reject()
      }
    })
  },
  // SET_COMPANY_IMAGE: ({commit}, payload): void => commit("SET_COMPANY_IMAGE", payload),
  // FETCH_ROLE_PERMISSIONS: ({commit}): Promise<void> => {
  //   return new Promise(async (resolve, reject) => {
  //     // const resource: string = "/api/v1/clientzone/user/role/permission";
  //     const resource: string = "/api/v1/clientzone/codelist/permission";

  //     try {
  //       const response = await ApiService.get(resource);

  //       commit("SET_ROLE_PERMISSIONS", response.data);

  //       resolve();
  //     } catch (e) {
  //       reject()
  //     }
  //   })
  // },
  // FETCH_ROLE_PERMISSIONS_ASSOCIATIONS: ({commit, getters, rootGetters}): Promise<void> => {
  //   const userId: UserData = rootGetters["userModule/GET_LOGGED_USER"].id;
  //   const companyId: CompanyData = getters["GET_COMPANY_ID"];

  //   return new Promise(async (resolve, reject) => {
  //     const resource: string = `/api/v1/clientzone/user/${userId}/venue_owner/${companyId}/role_permission`;

  //     try {
  //       const response = await ApiService.get(resource);

  //       commit("SET_ROLE_PERMISSIONS_ASSOCIATIONS", response.data);

  //       resolve();
  //     } catch (e) {
  //       reject()
  //     }
  //   })
  // },
  // SET_LOADER: ({commit}, payload: number): void => commit("SET_LOADER", payload)
}
