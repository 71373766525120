export default {
    "User access rights": "Přístupová práva uživatele",
    "Add New User": "Přidat nového uživatele",
    "Show only active user": "Zobrazit pouze aktivního uživatele",
    "Job position": "Pracovní pozice",
    "Songoroo Role": "Songoroo role",
    "Phone number": "Telefonní číslo",
    "Venue authorization": "Oprávnění k provozovně",
    "Admin": "Admin",
    "Venue Manager": "Manažer provozovny",
    "Marketer": "Marketér",
    "Accountant": "Účetní",
    "Unassigned": "Bez role",
    "Music web player": "Hudební webový přehrávač",
    "Venue setup": "Nastavení provozovny",
    "Marketing": "Marketing",
    "Pending invitation": "Nevyřízená pozvánka",
    "Inactive": "Neaktivní",
    "Never logged in": "Nikdy nepřihlášen",
    "Deactivated": "Deaktivováno",
    "Add user": "Přidat uživatele",
    "Send invitation to": "Poslat pozvánku",
    "Edit user permissions": "Upravit uživatelská oprávnění",
    "Back to Role management": "Zpět na management oprávnění",
    "Venue": "Provozovna",
    "ALL VENUES": "VŠECHNY PROVOZOVNY",
    "User's authorisations to venues": "Oprávnění uživatele pro provozovny",
    "Turn on/off music streaming": "Zapnout/Vypnout streamování hudby",
    "Edit company data": "Úprava údajů o společnosti",
    "Edit venue details": "Úprava detailů provozovny",
    "Create/Delete venue": "Vytvořit/Odstranit provozovnu",
    "Change music playlist": "Změnit hudební playlist",
    "Set Songoroo app features": "Nastavení funkcí Songoroo aplikace",
    "Change subscription plan": "Změnit balíček služeb",
    "View and export invoices": "Zobrazit a exportovat faktury",
    "Change payment method": "Změnit způsob platby",
    "View list of users": "Zobrazit seznam uživatelů",
    "View audio campaigns": "Zobrazit audio kampaně",
    "Add/Manage audio spots": "Přidat/Spravovat audio spoty",
    "Create/Manage audio ad campaigns": "Vytvářet/Spravovat audio reklamní kampaně",
    "Respond to customer feedback received via Songoroo app": "Odpovědět na zpětnou vazbu od zákazníků přijatou prostřednictvím aplikace Songoroo",
    "Create tailored questions for customer questionnaire, and edit questionaries": "Vytvoření a editace na míru šitých dotazníků v mobilní aplikaci Songoroo",
    "Send messages to customers": "Posílat zprávy zákazníkům",
    "View history of messages sent to customer": "Zobrazit historii zpráv odeslaných zákazníkem",
    "Create, edit and delete users": "Vytvářet, upravovat a mazat uživatele",
    "Active User": "Aktivní",
}
