import { mapActions, mapGetters } from "vuex";
import moment from 'moment'
const globalMixin = {
  data: () => {
    return {
      moment:moment,
      notification_titles: [
        { val: 1, name: "MISSING COMPANY LOGO" },
        { val: 2, name: "MISSING COMPANY DATA" },
        { val: 3, name: "CREDIT CARD EXPIRED" },
        { val: 4, name: "UNPROCESSED PAYMENT WARNING" },
        { val: 5, name: "UNPROCESSED PAYMENT WARNING" },
        {
          val: 6,
          name: "DEACTIVATED VENUE SUBSCRIPTION DUE TO UNPROCESSED PAYMENT",
        },
        { val: 7, name: "MISSING CRITICAL VENUE DETAILS" },
        { val: 8, name: "MISSING VENUE DETAILS " },
        { val: 9, name: "END OF AUDIO CAMPAIGN " },
        { val: 10, name: "END OF CUSTOM SURVEY " },
        { val: 11, name: "MESSAGES TO CUSTOMERS " },
        { val: 12, name: "CUSTOMIZED VENUE SURVEY " },
      ],
    };
  },
  computed: {
    ...mapGetters("azNotificationModule", {
      notifications: "GET_NOTIFICATIONS",
    }),
    ...mapGetters("discountsModule", {
      discounts: "GET_DISCOUNTS",
    }),
    ...mapGetters("azBillingModule", {
      all_invoices: "GET_ALL_INVOICES",
    }),
    ...mapGetters("azDataFeedModule", {
      datafeed: "GET_DATAFEED",
      datafeedpayin: "GET_DATAPAYIN",
      venueowners:"GET_VENUEOWNERS",
      selectedDataFeed: "GET_SELECTED_DATAPAYIN"
    }),
    ...mapGetters("azEmailsModule", {
      emails: "GET_EMAILS",
    }),
    notification_importance(){
      return [
        { name: this.$i18n.t('Critical'), val: 1 },
        { name: this.$i18n.t('Warning'), val: 2 },
        { name: this.$i18n.t('Suggestion'), val: 3 },
      ]
    },
    notification_repitions(){
      return  [
        { name: `0 ${this.$i18n.t('days')}`, val: 0 },
        { name: `3 ${this.$i18n.t('days')}`, val: 3 },
        { name: `7 ${this.$i18n.t('days')}`, val: 7 },
        { name: `15 ${this.$i18n.t('days')}`, val: 15 },
        { name: `30 ${this.$i18n.t('days')}`, val: 30 },
      ]
    }
  },
  methods: {
    ...mapActions("azNotificationModule", {
      fetchNotifications: "FETCH_NOTIFICATIONS",
    }),

    ...mapActions("discountsModule", {
      fetchDiscounts: "FETCH_DISCOUNTS",

    }),
    ...mapActions("azEmailsModule", {
      fetchEmails: "FETCH_EMAILS",
      updateEmail:'UPDATE_EMAIL'

    }),
    ...mapActions("azBillingModule", {
      fetchAllInvoices: "FETCH_ALL_INVOICES",
    }),
    ...mapActions("azDataFeedModule",{
      fetchDatafeed:'FETCH_DATAFEED',
      createBank:'CREATE_BANK',
      updateDatafeed:'UPDATE_DATAFEED',
    }),
    getDate(date) {
      const formattedDate = new Date(date).toISOString()
      return formattedDate.split('T')[0]
    },

  },
};

export default globalMixin;
