import store from "@/state/store";
import { ROOT_ACTION } from "@/utils/helpers";
import { RouteConfig } from "vue-router";

const CZ_TITLE = 'Clientzone - Songoroo';

const registerRoutes: RouteConfig[] = [
  {
    path: "/register/step-1",
    name: "register-step-1",
    component: () => import("@/router/views/account/redesign-step-1.vue"),
    meta: {
      public: true,
      accessToClientzone: false,
      title: CZ_TITLE,
    },
  },
  {
    path: "/register/step-2",
    name: "register-step-2",
    component: () => import("@/router/views/account/redesign-step-2.vue"),
    meta: {
      public: true,
      accessToClientzone: false,
      title: CZ_TITLE,
    },
    beforeEnter: (_, __, next) => {
      if (!store.getters['registerModule/GET_INIT_REGISTER']) {
        next("/register")
      }

      next()
    }
  },
  {
    path: "/register/step-3",
    name: "register-step-3",
    component: () => import("@/router/views/account/redesign-step-3.vue"),
    meta: {
      public: true,
      accessToClientzone: false,
      title: CZ_TITLE,
    },
    beforeEnter: (_, __, next) => {
      if (!store.getters['registerModule/GET_INIT_REGISTER']) {
        next("/register")
      }

      next()
    }
  },
  {
    path: "/register/step-4",
    name: "register-step-4",
    component: () => import("@/router/views/account/redesign-step-4.vue"),
    meta: {
      public: true,
      accessToClientzone: false,
      title: CZ_TITLE,
    },
    beforeEnter: (_, __, next) => {
      if (!store.getters['registerModule/GET_INIT_REGISTER'] && !store.getters["registerModule/GET_REGISTER_FILLED_DATA_ID"]) {
        next("/register")
      }

      next()
    }
  },
  {
    path: "/register/step-5",
    name: "register-step-5",
    component: () => import("@/router/views/account/redesign-step-5.vue"),
    meta: {
      public: true,
      accessToClientzone: false,
      title: CZ_TITLE,
    },
    beforeEnter: async (to, _, next): Promise<void> => {
      if (to.query.user) {
        try {
          await store.dispatch("registerModule/FETCH_REGISTER_FILLED_DATA", to.query.user, ROOT_ACTION);
          next();
        } catch (e) {
          next("/register/step-1");
        }

        return;
      }
      // else {
      //   if (sessionStorage.getItem('isLoggedFromRegistration')) {
      //     sessionStorage.removeItem('isLoggedFromRegistration')
      //   }
      //   if (!store.getters['registerModule/GET_INIT_REGISTER'] && localStorage.getItem('registrationData') === null) {
      //     next("/register")
      //   }
      // }

      if (!localStorage.getItem('registrationData')) {
        next("/register")
      } else {
        store.dispatch['registerModule/SET_INIT_REGISTER'];
      }

      next()
    }
  },
  {
    path: "/register/step-6",
    name: "register-step-6",
    component: () => import("@/router/views/account/redesign-step-6.vue"),
    meta: {
      public: true,
      title: CZ_TITLE,
    },
    beforeEnter: (_, __, next) => {
      if (localStorage.getItem('registrationData')) {
        //localStorage.removeItem('registrationData')
        store.dispatch['registerModule/SET_INIT_REGISTER'];
      }
      // if (!store.getters['registerModule/GET_INIT_REGISTER'] && !store.getters["registerModule/GET_REGISTER_FILLED_DATA_ID"]) {
      if (!store.getters['registerModule/GET_INIT_REGISTER'] && !store.getters["registerModule/GET_REGISTER_FILLED_DATA_ID"]) {
        next("/register")
      }
      next()
    }
  },
  {
    path: "/after-registration",
    name: "after-registration",
    component: () => import("@/router/views/account/after-registration.vue"),
    meta: {
      public: true,
      title: CZ_TITLE,
    },
  }
]

export default registerRoutes
