export default {
  "Help & Support": "Pomoc a podpora",
  "Tech support": "Technická podpora",
  "Sales support": "Podpora predaja",
  "Submit a request": "Odoslať žiadosť",
  "How can we help?": "Ako môžeme pomôcť?",
  "Popular topics": "Populárne témy",
  "Connect with Sonos": "Spojiť sa so Sonos",
  "How to schedule music": "Ako naplánovať hudbu",
  "Play music legally": "Prehrávajte hudbu legálne",
  "Can't find what you're looking for?": "Nemôžete nájsť, čo hľadáte?",
  "Send us your question": "Pošlite nám svoju otázku",
  "Getting started": "Začíname",
  "Hardware & IT matters": "Hardvér a IT",
  "Client Account Management": "Správa klientskych účtov",
  "Music Playlists": "Hudobné playlisty",
  "Music Licensing": "Licencovanie hudby",
  "Songoroo Apps & App Features": "Aplikácie a funkcie aplikácií Songoroo",
  "Subscriptions & Payments": "Balík služieb & Platby",
  "Client Dashboard and Reports": "Panel a správy klientov",
  "Troubleshooting": "Riešenie problémov",
  "Please choose from the topics below": "Zvoľte si z nasledujúcich tém nižšie",
  "Subject": "Predmet",
  'Active Questionary': "Aktívny",
  "Inactive Questionary": "Neaktívny",
  "Description word": "Obsah žiadosti",
  "Please enter the details of your request. A member of our support staff will respond as soon as possible.": "Zadajte prosím podrobnosti o vašej žiadosti. Naši členovia podpory vám odpovedia čo najskôr.",
  "Attachments": "Prílohy",
  "Add file": "Pridať súbor",
  "or drop files here": "alebo presunúť sem",
  "Please choose from the topics.": "Vyberte si z tém.",
  "Phone number is required.": "Telefónne číslo je povinné.",
  "Only numbers are allowed.": "Iba čísla sú povolené.",
  "Business name is required.": "Obchodné meno je povinné.",
  "Subject is required.": "Predmet je povinný.",
  "Description is required.": "Obsah žiadosti je povinný",
  "General": "Iné",
  "Hardware & IT": "Na hardvéri a IT záleží",
  "Client account": "Správa klientskych účtov",
  "Music playlist": "Zoznamy hudby",
  "Music licensing": "Licencovanie hudby",
  "Mobile apps": "Aplikácie a funkcie aplikácií Songoroo",
  "Billing": "Predplatné a platby",
  "Reports": "Panel a správy klientov",
  "Survey name": "Názov prieskumu",
  "Survey Name": "Názov prieskumu",
  "Show only active surveys": "Zobraziť iba aktívne prieskumy",
  "Edit Audio Advertisement Campaign": "Upraviť audio reklamnú kampaň"
}
