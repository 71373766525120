export default {
    "Log in": "Přihlásit se",
    "Forgot your password?": "Zapomněli jste heslo?",
    "Don't have an account?": "Nemáte účet?",
    "Sign up.": "Zaregistrujte se.",
    "Let’s find your venue": "Pojďme najít vaši provozovnu",
    "Let’s find your business": "Pojďme najít vaši firmu",
    "venue name and/or address": "Název a/nebo adresa provozovny",
    "Skip and enter details manually": "Přeskočení a ruční zadání dat",
    "Enter venue details manually": "Ruční zadání údajů o provozovňe",
    "Let’s create your first business location": "Vytvořme vaše první místo podnikání",
    "If your company has multiple business locations, you can add the remaining business locations within the Songoroo client interface after completing this registration.": "Pokud má vaše společnost více provozoven, můžete po dokončení této registrace přidat zbývající místa již v klientském rozhraní Songoroo.",
    "Next step": "Další krok",
    "Back": "Zpět",
    "Let’s create your user account": "Vytvořme váš uživatelský účet",
    "Name": "Meno",
    "Surname": "Příjmení",
    "First": "První",
    "days period is for free. You can cancel Songoroo subscription anytime during the trial period and pay nothing": "dny jsou zdarma. Balíček služeb Songoroo můžete kdykoli během zkušebního období zrušit a nic neplatit",
    "Secure Checkout": "Bezpečná platba",
    "Pay with stripe": "Plaťte přes stripe",
    "Add Payment Card": "Přidat platební kartu",
    "Confirm": "Potvrdit",
    "Congratulations.": "Gratulujeme.",
    "Card was successfully added.": "Karta byla úspěšně přidána.",
    "By saving your credit/debit card information you agree to the terms & conditions of Stripe. You can read it by clicking on the following link.": "Uložením informací o vaší kreditní/debetní kartě souhlasíte s podmínkami společnosti Stripe. Můžete si je přečíst kliknutím na odkaz níže.",
    "Processing payment": "Zpracování platby",
    "Processing": "Zpracovávám",
    "Pay": "Zaplatit",
    "Company Billing Data": "Fakturační údaje společnosti",
    "Submit registration": "Potvrďte registraci",
    "Business identification number is required.": "IČO je povinné.",
    "VAT is required.": "DPH je povinné.",
    "Contact person is required": "Kontaktní osoba je povinná",
    "Contact person email is required.": "Email kontaktní osoby je povinný.",
    "Contact person phone is required.": "Telefón kontaktní osoby je povinný.",
    "Congratulations, all is set.": "Gratulujeme, vše je připraveno.",
    "Enjoy streaming music at your business.": "Užijte si hudbu Songoroo ve svém podniku.",
    "Continue to your account": "Pokračujte na svůj účet",
    "Reset password": "Obnovit heslo",
    "Create new password": "Zadat nové heslo",
    "Confirm new password": "Potvrdit nové heslo",
    "address placeholder": "např. firma, hlavní ulice, Praha",
    "Company with VAT": "Společnost s IČ DPH",
    "Company with name": "Společnost s názvem",
    "Company with business identification number": "Společnost s IČO",
    "is already registered.": "je už zaregistrovaná.",
    "For more details, contact us support": "Pro více informací kontaktujte naši podporu",
    "Continue to login": "Pokračovat na přihlašení",
    "is already registered. Please, log in or change email to continue the registration.": "je již zaregistrován. Chcete-li pokračovat v registraci, přihlaste se nebo změňte svůj e-mail.",
    "User with email": "Uživatel s e-mailem",
    "Login and continue": "Přihlásit se",
    "Please check your email and confirm your registration. Then you can log in.": "Zkontrolujte svůj e-mail a potvrďte svou registraci. Poté se můžete přihlásit.",
    "Email was successfully verified.": "E-mail byl úspěšně ověřen",
    "This account has been already activated. Please login.": "Váš účet již byl v minulosti aktivován. Můžete přejít ke svému účtu.",
    "There was problem to verify your email. Please try again later.": "V procesu ověřování se vyskytl problém.",
    "Allow geolocation": "Povolit geolokaci",
    "Credit card authorised. Your registration can be submitted.": "Kreditní karta byla autorizována.",
    "Authorization failed. Enter your card details again.": "Autorizace karty se nezdařila. Vyberte preferovaný balíček služeb a použijte jiný způsob platby.",
    "Geolocation is not supported by this browser.": "Geolokace není tímto prohlížečem podporována.",
    'Geolocation has been disabled for this site. Enable geolocation in your browser settings.': 'Geolokace pro tento web byla zakázána. Povolte sdílení vaší konkrétní polohy v nastavení prohlížeče.',
    "Find your business": "Najít provozovnu",
    "User account": "Uživatelský účet",
    "Email verification": "Potvrzení e-mailu",
    "Billing data": "Fakturační údaje",
    "Send again": "Poslat zpátky",
    "If you have not received the verification e-mail, repeat the action with the 'Send again' button.": "Pokud jste ověřovací e-mail neobdrželi, opakujte akci s tlačítkem 'Odeslat znovu'.",
    "We have sent a verification link to the email address": "Ověřovací odkaz byl odeslán na následující e-mailovou adresu",
    "If you have any questions, please contact us by email at": "Máte-li jakékoli dotazy, kontaktujte nás e-mailem na adrese",
    "Finish registration": "Dokončete registraci",
    "Your email has been successfully verified.": "Váš e-mail byl úspěšně ověřen",
    "Street and descriptive number": "Ulice a číslo popisné",
    "Please do close this screen and continue in the screen where you started the registration process": "Chcete-li registraci dokončit, zavřete toto okno a pokračujte v okně, ve kterém jste zahájili proces registrace",
    "Check your email inbox and confirm your registration. If you have not received the email, please click on": "Zkontrolujte svou e-mailovou schránku a potvrďte svou registraci. Pokud jste e-mail neobdrželi, klikněte prosím na ",
    "We have sent a verification link to your email address": "Ověřovací odkaz byl odeslán na vaši e-mailovou adresu",
}
