import { ActionTree } from "vuex";
import { TaxPricePeriodTranslationAdminzoneState } from "@/types/store/adminzone/tax_price_period_translation";
import { RootState } from "@/types/store/adminzone";
import { CASSOVIA_API_RESOURCE } from "@/utils/helpers";
import AxiosService from "@/services/axios.service";

export const actions: ActionTree<TaxPricePeriodTranslationAdminzoneState, RootState> = {
  FETCH_TAX_PRICE_PERIOD_TRANSLATION: ({commit, dispatch}): Promise<void> => {
    return new Promise(async(resolve, reject) => {
      const resource: string = `${CASSOVIA_API_RESOURCE}/api/price-period-tax-translations`;
      try{
        const response = await AxiosService.getAxios().get(resource)
        commit("SET_TAX_PRICE_PERIOD_TRANSLATION", response.data)
        resolve()
      }catch (e){
        reject()
      }
    })
  },
  UPDATE_TAX_PRICE_PERIOD_TRANSLATION: ({commit, dispatch}, payload): Promise<void> => {
    return new Promise(async(resolve, reject) => {
      const resource: string = `${CASSOVIA_API_RESOURCE}/api/price-period-tax-translations/1`
      try{
        await AxiosService.getAxios().patch(resource, payload, {
          headers: {
            'Content-Type': 'application/merge-patch+json'
          }
        })
        resolve()
      } catch (e){
        reject()
      }
    })
  }
}
