import { ActionTree } from "vuex";
import { TrialPeriodAdminzoneState } from "@/types/store/adminzone/trialPeriod";
import { RootState } from "@/types/store/adminzone";
import { CASSOVIA_API_RESOURCE } from "@/utils/helpers";
import { successMessage } from "@/utils/messages-handlers";
import AxiosService from "@/services/axios.service";

export const actions: ActionTree<TrialPeriodAdminzoneState, RootState> = {
  FETCH_TRIAL_PERIOD: ({commit, dispatch}): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      const resource: string = `${CASSOVIA_API_RESOURCE}/api/free-trial-periods?page=1`;
      try {
        const response = await AxiosService.getAxios().get(resource)
        commit("SET_TRIAL_PERIOD", response.data)
        resolve();
      } catch (e){
        reject()
      }
    })
  },

  UPDATE_TRIAL_PERIOD: ({commit, dispatch}, payload): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      const resource: string = `${CASSOVIA_API_RESOURCE}/api/free-trial-periods/1`;
      try{
        await AxiosService.getAxios().patch(resource, payload, {
          headers: {
            'Content-Type': 'application/merge-patch+json'
          }
        })
        successMessage("Trial period updates successfully", 3000);
        resolve();
      } catch (e) {
        reject()
      }
    })
  }
}
