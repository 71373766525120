export default {
  "Songoroo Solutions": "Songoroo komponenty",
  "Portfolio": "Portfólio klientov",
  "How to": "Podpora",
  "Company": "O spoločnosti",
  "Connect": "Sociálne siete",
  "Interactive in-store radio": "Interaktívne in-store rádio",
  "Songoroo Algorithm": "Songoroo algoritmus",
  "Mobile Apps": "Mobilná aplikácia",
  "Reporting & App-User-Statistics": "Reporting a Business Intelligence",
  "Pricing": "Cenník",
  "Food & Dining": "Reštaurácie",
  "Café & Pubs": "Kaviarne a bary",
  "Retail": "Maloobchod",
  "Hotels & Offices": "Hotely a kancelárie",
  "Club & Lounge": "Kluby a salóny",
  "Workout & Fitness": "Športoviská a fitness",
  "Spa, Wellness & Relax": "Kúpele, wellness a relax",
  "Public places": "Verejné priestranstvá",
  "Events & Special occasions": "Udalosti a špeciálne príležitosti",
  "... many more": "... oveľa viac",
  "App Features": "Funkcie aplikácie",
  "Music Streamer Setup": "Nastavenie streamovacieho zariadenia",
  "Support": "Centrum pomoci Songoroo",
  "About Us": "O nás",
  "Testimonials": "Referencie",
  "Partnership": "Partnerstvo",
  "Contact": "Kontaktujte nás",
  "Careers": "Kariéra",
}
