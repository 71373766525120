import Vue from "vue";
import i18n from "@/i18n/i18n";

export const mutations = {
    SET_BUSINESS_TYPES: (state, payload) => Vue.set(state, "businessTypes", payload),
    SET_BUSINESS_TYPE: (state, payload) => Vue.set(state, "businessType", payload),
    // ADD_BUSINESS_TYPE: (state, payload) => state.businessTypes.push(payload),
    ADD_BUSINESS_TYPE: (state, payload) => {
        const name = payload.name,
            nameArr = name.split("\n");
            
        const obj = {
            name_sk: nameArr[1].replace("sk|", ""),
            name_en: nameArr[0].replace("en|", ""),
            venue_type_id: payload.venue_type_id,
            id: payload.id,
            default_business_type: payload.default_business_type,
        }

        state.businessTypes.push(obj)
    },
    UPDATE_BUSINESS_TYPE: (state, payload) => {
        console.log(state.businessTypes);
        state.businessTypes = state.businessTypes.map(business => {
            if (business.id === payload.id) {
                let nameSk = "";
                let nameEn = "";
                const { name } = payload;

                if (name) {
                    const localeNames = name.split('\n');
       
                    localeNames.map(localeName => {
                        if (localeName.startsWith("en|")) {
                            nameEn = localeName.substring(3);
                        }
    
                        if (localeName.startsWith("sk|")) {
                            nameSk = localeName.substring(3);
                        }
                    });
                }

                return Object.assign({}, business, {...payload, name_en: nameEn, name_sk: nameSk})
            }

            if (payload.default_business_type === true) {
                return {...business, default_business_type: false};
            }
            
            return business;
        })
    },
    DELETE_BUSINESS_TYPE: (state, payload) => {
        state.businessTypes = state.businessTypes.filter(business => business.id !== payload)
    },
    SET_DEFAULT_BUSINESS_TYPE: (state, payload) => {
        const updatedBusinessTypes = [];

        state.businessTypes.map(business => {
            if (business.id === payload.id) {
                updatedBusinessTypes.push({...business, default_business_type: payload.isDefault});
            } else {
                if (payload.isDefault === true) {
                    updatedBusinessTypes.push({...business, default_business_type: false});
                } else {
                    updatedBusinessTypes.push(business);
                }
            }
        });

        state.businessTypes = updatedBusinessTypes;
    },
}
