import ApiService from "@/services/api.service";
import { ActionTree } from "vuex";
import { errorMessage, successMessage } from "@/utils/messages-handlers";
import { AudioCommercialsState, RootState } from "@/types/store/clientzone";
import { AdvertCampaignData } from "@/types";

export const actions: ActionTree<AudioCommercialsState, RootState> = {
  FETCH_AUDIO_SPOTS: ({ commit, rootGetters }, name: string = ""): Promise<void> => {
    const companyId: number = rootGetters["companyModule/GET_COMPANY_ID"];

    return new Promise(async (resolve, reject) => {
      const resource: string = `/api/v1/clientzone/audio_spot?venue_owner_id=${companyId}&limit=1000&name=${name}`;

      try {
        const response = await ApiService.get(resource);

        commit("SET_AUDIO_SPOTS", response.data);

        resolve();
      } catch (e) {
        reject()
      }
    })
  },
  FETCH_PERMISSION_AVAILABLE_CAMPAIGNS: ({ commit, rootGetters }, payload): Promise<void> => {
    const companyId: number = rootGetters['companyModule/GET_COMPANY_ID'];
    const venueAdvertCampaigns: Array<any> = []
    return new Promise(async (resolve, reject) => {
      try {
        for (const venueAdvertCampaign of payload) {
          const allowedVenues: Array<any> = []
          for (const venue of venueAdvertCampaign.venues) {
            const resource: string = `/api/v1/clientzone/user/role_permission/check?permission_id=13&venue_owner_id=${companyId}&venue_id=${venue.id}`
            const response = await ApiService.get(resource);
            if (response.data.found) {
              allowedVenues.push(venue)
            }
          }
          if (allowedVenues.length === venueAdvertCampaign.venues.length) {
            venueAdvertCampaigns.push({ ...venueAdvertCampaign })
          } else {
            const venueAdvertCampaignCopy: AdvertCampaignData = { ...venueAdvertCampaign }
            venueAdvertCampaignCopy.venues = venueAdvertCampaignCopy.venues.filter(venue => allowedVenues.includes(venue))
            venueAdvertCampaigns.push(venueAdvertCampaignCopy)
          }
        }
        commit("SET_PERMISSION_AVAILABLE_ADVERT_CAMPAIGNS", venueAdvertCampaigns)
        resolve();
      } catch (e) {
        reject()
      }
    })
  },
  FETCH_AVAILABLE_VENUE_QUESTIONARY: ({ commit, rootGetters }, payload): Promise<void> => {
    const companyId: number = rootGetters["companyModule/GET_COMPANY_ID"];

    return new Promise(async (resolve, reject) => {
      const resource: string = `/api/v1/clientzone/audio_spot_campaign/available_venue?start_at=${payload.dateFrom}&end_at=${payload.dateTo}&start_time=${payload.startTime}&end_time=${payload.endTime}&venue_owner_id=${companyId}&offset=0&include_deleted=False`;

      try {
        const response = await ApiService.get(resource);

        commit("SET_ADVERT_SPOT_AVAILABLE_VENUES", response.data);

        resolve();
      } catch (e) {
        reject()
      }
    })
  },
  ADD_AUDIO_SPOT: ({ commit, dispatch }, payload: object): Promise<void> => {
    commit("SET_AUDIO_SPOT_LOADER", true);

    return new Promise(async (resolve, reject) => {
      const resource: string = "/api/v1/clientzone/audio_spot";

      try {
        await ApiService.post(resource, payload);

        await dispatch("FETCH_AUDIO_SPOTS", "");
        successMessage("Audio spot added.", 3000);
        commit("SET_AUDIO_SPOT_LOADER", false);

        resolve();
      } catch (e) {
        commit("SET_AUDIO_SPOT_LOADER", false);
        if (e.response.data.error.code === "AudioSpotDurationTooLongError") {
          errorMessage("Audio spot is too long. Audio spot cannot exceed 30 seconds", 5000);
        }
        reject()
      }
    })
  },
  EDIT_AUDIO_SPOT: ({ commit, dispatch }, { payload, id }): Promise<void> => {
    commit("SET_AUDIO_SPOT_LOADER", true);

    return new Promise(async (resolve, reject) => {
      const resource: string = `/api/v1/clientzone/audio_spot/${id}`;

      try {
        await ApiService.put(resource, payload);

        await dispatch("FETCH_AUDIO_SPOTS", "");
        successMessage("Audio spot edited.", 3000);
        commit("SET_AUDIO_SPOT_LOADER", false);

        resolve();
      } catch (e) {
        commit("SET_AUDIO_SPOT_LOADER", false);
        if (e.response.data.error.code === "AudioSpotDurationTooLongError") {
          errorMessage("Audio spot is too long. Audio spot cannot exceed 30 seconds", 5000);
        }
        reject()
      }
    })
  },
  DELETE_AUDIO_SPOT: ({ dispatch }, id: number): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      const resource: string = `/api/v1/clientzone/audio_spot/${id}`;

      try {
        await ApiService.delete(resource);

        await dispatch("FETCH_AUDIO_SPOTS", "");
        successMessage("Audio spot removed.", 3000);

        resolve();
      } catch (e) {
        reject()
      }
    })
  },
  FETCH_ADVERT_CAMPAIGNS: ({ commit, rootGetters }, { payload = {}, name = "" }): Promise<void> => {
    const companyId: number = rootGetters["companyModule/GET_COMPANY_ID"];
    let parameters: string = "";

    if (!payload.venue_ids) {
      parameters = "";
    } else {
      if (!payload.start_at || !payload.end_at) {
        parameters = `&${payload.venue_ids}`
      } else {
        parameters = `&${payload.venue_ids}&start_at=${payload.start_at}&end_at=${payload.end_at}`;
      }
    }

    return new Promise(async (resolve, reject) => {
      const resource: string = `/api/v1/clientzone/audio_spot_campaign?venue_owner_id=${companyId}&limit=10000&name=${name}${parameters}`;

      try {
        const response = await ApiService.get(resource);

        commit("SET_ADVERT_CAMPAIGNS", response.data);

        resolve();
      } catch (e) {
        reject()
      }
    })
  },
  CREATE_ADVERT_CAMPAIGNS: ({ commit, dispatch }, payload: object): Promise<void> => {
    commit("SET_AUDIO_SPOT_LOADER", true);

    return new Promise(async (resolve, reject) => {
      const resource: string = "/api/v1/clientzone/audio_spot_campaign";

      try {
        await ApiService.post(resource, payload);

        successMessage("Advert campaign created.", 3000);
        await dispatch("FETCH_ADVERT_CAMPAIGNS", "");
        commit("SET_AUDIO_SPOT_LOADER", false);

        resolve();
      } catch (e) {
        commit("SET_AUDIO_SPOT_LOADER", false);
        reject()
      }
    })
  },
  EDIT_ADVERT_CAMPAIGNS: ({ commit, dispatch }, { payload, id }): Promise<void> => {
    commit("SET_AUDIO_SPOT_LOADER", true);

    return new Promise(async (resolve, reject) => {
      const resource: string = `/api/v1/clientzone/audio_spot_campaign/${id}`;

      try {
        await ApiService.put(resource, payload);

        successMessage("Advert campaign edited.", 3000);
        await dispatch("FETCH_ADVERT_CAMPAIGNS", "");
        commit("SET_AUDIO_SPOT_LOADER", false);

        resolve();
      } catch (e) {
        commit("SET_AUDIO_SPOT_LOADER", false);
        if (e.response.status === 400) {
          errorMessage("Advert spot campaign start and end time overlap existing campaigns", 5000);
        }
        reject()
      }
    })
  },
  DELETE_ADVERT_CAMPAIGNS: ({ dispatch }, id: number): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      const resource: string = `/api/v1/clientzone/audio_spot_campaign/${id}`;

      try {
        await ApiService.delete(resource);

        successMessage("Advert campaign deleted.", 3000);
        await dispatch("FETCH_ADVERT_CAMPAIGNS", "");

        resolve();
      } catch (e) {
        reject()
      }
    })
  },
  FILTER_ACTIVE_CAMPAIGNS: ({ commit }, payload: boolean): void => commit("FILTER_ACTIVE_CAMPAIGNS", payload),
  SET_AUDIO_SPOT_FILE: ({ commit, dispatch }, payload): void => {
    commit("SET_AUDIO_SPOT_FILE", payload);
    dispatch("SET_CAN_PLAY_STATUS", { ...payload, state: true });
  },
  SET_CAN_PLAY_STATUS: ({ commit }, payload): void => commit("SET_CAN_PLAY_STATUS", payload),
  SET_AUDIO_SPOT_USER_IMAGE: ({ commit }, payload): void => commit("SET_AUDIO_SPOT_USER_IMAGE", payload),
}
