import TokenService from "@/services/token.service";

const checkLogin = {
  methods: {
    checkLogin() {
      // If user is already logged in notify and redirect to dashboard
      if (!!TokenService.getToken()) {

       /*this.$vs.notify({
          title: 'Login Attempt',
          text: 'You are already logged in!',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        });*/
        this.$router.push('/dashboard')
      }
      return true;
    },
  }
};

export default checkLogin;
