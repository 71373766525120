import {ActionTree} from "vuex";
import {successMessage} from "@/utils/messages-handlers";
import { DiscountState, RootState } from "@/types/store/adminzone";
import { CASSOVIA_API_RESOURCE } from "@/utils/helpers";
import AxiosService from "@/services/axios.service";

export const actions: ActionTree<DiscountState, RootState> = {
  FETCH_DISCOUNTS: ({commit, dispatch}, currentPage: number): Promise<void> => {
    return new Promise(async (resolve, reject) => {

      const resource: string = `${CASSOVIA_API_RESOURCE}/api/discount-codes?page=${currentPage}`;

      try {
        const response = await AxiosService.getAxios().get(resource)
        commit("SET_DISCOUNTS", response.data);
        commit("SET_DISCOUNTS_TOTAL_COUNT", response.headers['total-count'])
        resolve();
      } catch (e) {
        reject()
      }
    })
  },
  CREATE_DISCOUNT: ({commit, dispatch}, {payload: payload, currentPage: currentPage}): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      const resource: string = `${CASSOVIA_API_RESOURCE}/api/discount-codes`;
      try {
        await AxiosService.getAxios().post(resource, payload)
        await dispatch("FETCH_DISCOUNTS", currentPage);
        successMessage("Discount created successfully", 3000);

        resolve();
      } catch (e) {
        reject()
      }
    })
  },
  // UPDATE_NOTIFICATIONS: (context, payload): Promise<void> => {

  //   return new Promise(async (resolve, reject) => {
  //     const resource: string = `/api/client-zone-notifications/${payload.id}`;
  //     try {
  //       const response = await ApiService.patch(resource, payload.data)
  //       console.log(response)

  //       // commit("SET_COMPANY", response.data);
  //       successMessage("Notification updated successfully", 3000);

  //       resolve();
  //     } catch (e) {
  //       reject()
  //     }
  //   })
  // },
}
