import { SubscriptionProductsData, TaxRateData, VenueSubscriptionData, DiscountCode } from "@/types/venue_subscription";
import { VenueSubscriptionState } from "@/types/store/clientzone";

export const state: VenueSubscriptionState = {
  subscriptionProducts: [] as Array<SubscriptionProductsData>,
  selectedRecurringInterval: "month",
  taxRate: {} as TaxRateData,
  venuesSubscription: [] as Array<VenueSubscriptionData>,
  selectedVenueSubscription: {} as VenueSubscriptionData,
  loader: false,
  discountCode: {} as DiscountCode,
  invalidDiscountCode: false,
  errorDetail: '',
  stripeSubscriptionResponse: {},
}
