import { SupportTicketCategory, PopularTopics, PopularSections, SearchTopics } from "@/types";
import { HelpAndSupportState } from "@/types/store/clientzone/help_and_support";

export const state: HelpAndSupportState = {
  SupportTicketCategory: [] as Array<SupportTicketCategory>,
  PopularTopicsEn: [] as Array<PopularTopics>,
  PopularTopicsSk: [] as Array<PopularTopics>,
  PopularSectionsSk: [] as Array<PopularSections>,
  PopularSectionsEn: [] as Array<PopularSections>,
  SearchTopics: [] as Array<SearchTopics>,
  loader: false
}
