import { state } from "./state";
import { mutations } from "./mutations";
import { getters } from "./getters";
import { actions } from "./actions";


const subscriptionDescriptionsModule = {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}

export default subscriptionDescriptionsModule
