import store from "@/state/store";
import { RouteConfig } from "vue-router";

const SONGOROO_TITLE = 'Songoroo'

const globalRoutes: RouteConfig[] = [
  {
    path: "/login",
    name: "login",
    component: () => import("@/router/views/account/login.vue"),
    meta: {
      public: true,
      title: SONGOROO_TITLE
    }
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: () => import("@/router/views/account/forgot-password.vue"),
    meta: {
      public: true,
      title: SONGOROO_TITLE
    },
    beforeEnter: (to, _, next) => {
      if (!store.getters['registerModule/GET_INIT_REGISTER'] && to.name !== 'forgot-password') {
        next("/register")
      }

      next()
    }
  },
  {
    path: "/reset-password",
    name: "reset-password",
    component: () => import("@/router/views/account/reset-password.vue"),
    meta: {
      public: true,
      title: SONGOROO_TITLE
    },
  },
  {
    path: "/new-user-login",
    name: "new-user-login",
    component: () => import("@/router/views/account/new-user-login.vue"),
    meta: {
      public: true,
      title: SONGOROO_TITLE
    },
  },
]

export default globalRoutes
