import {GetterTree} from "vuex";
import { HelpAndSupportState, RootState } from "@/types/store/clientzone";

export const getters: GetterTree<HelpAndSupportState, RootState> = {
  GET_SUPPORT_TICKET_CATEGORY: (state) => state.SupportTicketCategory,
  GET_POPULAR_TOPICS_EN: (state) => JSON.parse(sessionStorage.getItem('POPULAR_TOPICS_EN')) ? JSON.parse(sessionStorage.getItem('POPULAR_TOPICS_EN')) : state.PopularTopicsEn,
  GET_POPULAR_TOPICS_SK: (state) => JSON.parse(sessionStorage.getItem('POPULAR_TOPICS_SK')) ? JSON.parse(sessionStorage.getItem('POPULAR_TOPICS_SK')) : state.PopularTopicsSk,
  GET_POPULAR_SECTIONS_EN: (state) => JSON.parse(sessionStorage.getItem('POPULAR_SECTIONS_EN')) ? JSON.parse(sessionStorage.getItem('POPULAR_SECTIONS_EN')) : state.PopularSectionsEn,
  GET_POPULAR_SECTIONS_SK: (state) => JSON.parse(sessionStorage.getItem('POPULAR_SECTIONS_SK')) ? JSON.parse(sessionStorage.getItem('POPULAR_SECTIONS_SK')) : state.PopularSectionsSk,
  GET_SEARCH_TOPICS: (state) => state.SearchTopics,
  GET_LOADER: (state): boolean => state.loader
}
