import { state } from "@/state/modules/adminzone/az_data_feed/state";
import { mutations } from "@/state/modules/adminzone/az_data_feed/mutations";
import { getters } from "@/state/modules/adminzone/az_data_feed/getters";
import { actions } from "@/state/modules/adminzone/az_data_feed/actions";

const azDataFeedModule = {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}

export default azDataFeedModule
