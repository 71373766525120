export const actions = {
  changeLayoutType({ commit }, { layoutType }) {
    commit('CHANGE_LAYOUT', layoutType);
  },
  changeLayoutWidth({ commit }, { layoutWidth }) {
    commit('CHANGE_LAYOUT_WIDTH', layoutWidth)
  },
  changeLeftSidebarType({ commit }, { leftSidebarType }) {
    commit('CHANGE_LEFT_SIDEBAR_TYPE', leftSidebarType)
  },
  changeTopbar({ commit }, { topbar }) {
    commit('CHANGE_TOPBAR', topbar)
  },
  changeLoaderValue({ commit }, { loader }) {
    commit('LOADER', loader)
  },
  SET_TOGGLE_MENU_STATE: ({commit}, payload: boolean): boolean => commit("SET_TOGGLE_MENU_STATE", payload),
  SET_BROWSER_STREAMER_STATE: ({commit}, payload: boolean): boolean => commit("SET_BROWSER_STREAMER_STATE", payload),
  SET_GLOBAL_OVERLAY_SPINNER_STATE: ({commit}, payload: boolean): boolean => commit("SET_GLOBAL_OVERLAY_SPINNER_STATE", payload)

}
