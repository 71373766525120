export default {
    "Songoroo app features": "Funkce aplikace Songoroo",
    "Description": "Naše mobilní aplikace a mnoho jejích funkcí bylo vyvinuto se záměrem nabídnout uživatelům interaktivitu a umožnit jim ovlivňovat hudební seznamy skladeb mnoha způsoby. A když se tak stane, věříme, že vám pomohou vytvořit a rozvinout vaši značku v něco jedinečného. Je jen na vás, zda chcete svým zákazníkům dopřát naprostou svobodu přispět k vytvoření hudební atmosféry pro váš podnik, nebo zda a jak moc chcete tyto možnosti omezit.",
    "Disable explicit content": "Zakázání explicitního obsahu",
    "Disable explicit content description": "Interpreti mají různé způsoby vyjadřování. Někdy se to však může zdát pro vaše podnikání příliš a my to chápeme. Proto vám dáváme možnost zakázat skladby, které považujete za nevhodné pro vaši firmu.",
    "Voting": "Hlasovaní",
    "Voting description": "Tato vlastnost je zcela jasná. Jak asi tušíte, klepnutím na šipku nahoru přesunete skladbu na vyšší místo v seznamu skladeb. Čím více uživatelům se stejná skladba líbí, tím výše je v seznamu. Šipka \"dolů\" funguje podobně, ale s opačným efektem.",
    "Add song": "Přidat skladbu",
    "Add song description": "Všechny předdefinované a ručně vybrané seznamy skladeb se skládají ze skladeb, o kterých si vy jako vlastník a Songoroo myslíte, že se nejlépe hodí pro podnikání, které provozujete. Vaši zákazníci však mohou mít jiný názor. Pokud mají náladu poslechnout si konkrétní skladbu, mohou prohledat naši databázi a přidat skladby, které se jim líbí. Naši předplatitelé Premium účtu mají dokonce možnost nastavit limity pro tuto funkcionalitu, tj. kolik skladeb si zákazníci mohou přidat. Dalším způsobem, jak určit, co se přidá do seznamu stop, je použít funkci Limit Playlist Adjustment.",
    "Boost": "Boost",
    "Boost description": "Boost umožňuje vašim zákazníkům vystřelit vybranou skladbu na první místo v seznamu skladeb. Tato funkce se často používá v kombinaci s funkcí Přidat skladbu. Vaši zákazníci tak mají možnost vybrat si hudbu z naší databáze, přidat ji do playlistu a vylepšit ji, takže doba, po kterou uslyší svou skladbu hrát, je omezena na minimum. Ve výchozím nastavení má každý uživatel povolen pouze jeden aktivní boost. Předplatitelé Premium si mohou toto omezení přizpůsobit.",
    "Song for you": "Skladba pro tebe",
    "Song for you description": "Tuto komunikační funkci jsme vyvinuli, abychom zákazníkům umožnili věnovat skladby s připojenými zprávami. Tato interaktivní možnost zaměstná lidi, zaměstná je a stráví více času ve vaší firmě. Ve výchozím nastavení má každý uživatel povolen pouze jeden aktivní boost. Předplatitelé Premium si mohou toto omezení přizpůsobit.",
    "Playlist modification via Songoroo app": "Úprava seznamu skladeb pomocí mobilní aplikace Songoroo",
    "Playlist modification via Songoroo app description": "Aktivaci této funkce omezíte možnost přidávat skladby do playlistu provozovny pouze na ty, které odpovídají kritériím vybraného playlistu. Jinými slovy, vaši zákazníci nebudou moci přidat do playlistu (pomocí aplikace Songoroo) skladbu, která není zahrnuta v playlistu, který jste pro danu provozovnu vybrali.",
    "Censor song": "Zakázat skladbu",
    "Censor artist": "Zakázat umělce",
    "Search results": "Výsledky hledání",
    "Add back to playlist": "Přidat zpět",
    "Set as banned": "Zakázat",
    "Censored songs": "Zakázané skladby",
    "Censored artists": "Zakázaní umělci",
    "No censored songs": "Žádné zakázané skladby",
    "No censored artists": "Žádní zakázaní umělci",
    "Back to venue detail": "Zpět na detail provozovny"
}
