export default {
    "Votes on Songs": "Počet hlasů ve skladbách",
    "Active users": "Aktivní uživatelé",
    "Boosts used": "Použitý boost",
    "Songs added": "Přidané skladby",
    "Songs sent to others": "Skladby zaslané ostatním",
    "Songs played": "Přehrávané skladby",
    "Voted songs": "Hlasované skladby",
    "Artists": "Umělci",
    "add song": "Přidané skladby",
    "Song boost": "Skladby s boostem",
    "Most Frequent Genres": "Nejběžnější hrané žánry",
    "Song title": "Název skladby",
    "Artist name": "Jméno umělce",
    "Count of votes": "Počet hlasů",
}
