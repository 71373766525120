import {
  UserAuthorisationsData, UserAuthorisationsVenuesData,
  UserData,
  UserRolesSummaryData,
  UserVenuesPermissionsData, VenueData
} from "@/types";
import { GetterTree } from "vuex";
import { RootState, UserState } from "@/types/store/clientzone";

export const getters: GetterTree<UserState, RootState> = {
  GET_USER_DATA: (state): UserState => state,
  GET_LOGGED_USER: (state): UserData => state.loggedUser,
  GET_IS_LOGGED_USER_WITHOUT_ANY_PERMISSIONS: (state): boolean => state.loggedUser.isWithoutPermissions,
  GET_SELECTED_USER: (state): UserData => state.selectedUser,
  GET_ALL_USERS_TO_VENUE_OWNER: (state): Array<UserData> => state.users,
  GET_USERS: (state, getters, rootState, rootGetters): Array<UserData> => {
    const userRoles: Array<UserRolesSummaryData> = getters["GET_USER_ROLES_SUMMARY"];
    const venues: Array<VenueData> = rootGetters["venueModule/GET_VENUES"];
    const users: Array<UserData> = getters["GET_ALL_USERS_TO_VENUE_OWNER"];
    const usersVenuesAuthorisations: Array<UserAuthorisationsData> = state.usersAuthorisations;
    const usersArray: Array<UserData> = [] as Array<UserData>;

    users.forEach((u, i) => {
      const summaryRoles: UserRolesSummaryData = userRoles.find(r => r.id === u.id);
      usersArray.push({ ...u, roles: summaryRoles.roles });

      const venuesArray: Array<VenueData> = [] as Array<VenueData>;

      usersVenuesAuthorisations.filter(n => n.czone_user_id === u.id).forEach((m) => {
        if (m.venue_id === null && m.role_id === 1) {
          venues.forEach((u) => {
            venuesArray.push({ roleId: 1, ...u })
          })
        }
      });

      const filteredUsers: Array<UserAuthorisationsData> = usersVenuesAuthorisations.filter(v => v.czone_user_id === u.id && v.venue_id !== null);

      filteredUsers.forEach((a) => {
        const venue: VenueData = venues.find(x => x.id === a.venue_id);
        venuesArray.push({ roleId: a.role_id, ...venue })
      })
      const unique = venuesArray.filter((v, i, a) => a.findIndex(t => (t.id === v.id && t.roleId === v.roleId)) === i)
      usersArray[i].venueAuthorizedToUser = unique;
    })

    return state.filterByActiveStatus ? usersArray.filter(u => u.active === state.filterByActiveStatus) : usersArray;
  },
  IS_USER_IMAGE: (state): boolean => !!state.loggedUser.image_last_updated,
  IS_SELECTED_USER_IMAGE: (state): boolean => !!state.selectedUser.image_last_updated,
  GET_USER_IMAGE: (state): string => state.image,
  GET_SELECTED_USER_IMAGE: (state): string => state.selectedUser.image,
  GET_USER_ID: (state): number => state.loggedUser.id,
  GET_VENUE_ID: (state): number | string => state.venueId ? state.venueId : localStorage.getItem('venue_id'),
  GET_USER_ROLES_SUMMARY: (state): Array<UserRolesSummaryData> => state.userRolesSummary,
  GET_USER_VENUES_PERMISSIONS: (state, getters, rootState, rootGetters) => {
    const venues: Array<VenueData> = rootGetters["venueModule/GET_VENUES"];
    const userVenuesPermissions: Array<UserVenuesPermissionsData> = state.userVenuesPermissions;
    const userVenuesPermissionsArray: Array<any> = [] as Array<any>;

    venues.forEach((v) => {
      const role = userVenuesPermissions.filter(p => p.venue_id === v.id);

      if (!!role.length) {
        const rolesArray: Array<object> = [] as Array<object>;

        role.forEach((v) => {
          rolesArray.push({ id: v.id, role_id: v.role_id })
        })

        userVenuesPermissionsArray.push({ ...v, roles: rolesArray })
      } else {
        userVenuesPermissionsArray.push({ ...v })
      }
    })

    return userVenuesPermissionsArray;
  },
  GET_LOGGED_USER_VENUES_PERMISSIONS: (state, getters, rootState, rootGetters): Array<UserAuthorisationsVenuesData | VenueData> => {
    const venues: Array<VenueData> = rootGetters["venueModule/GET_VENUES"];
    const userVenuesAuthorisations: Array<UserAuthorisationsData> = state.userAuthorisations;
    const loggedUserVenuesPermissionsArray: Array<UserAuthorisationsVenuesData | VenueData> = [] as Array<UserAuthorisationsVenuesData | VenueData>;

    // TODO: ???
    if (userVenuesAuthorisations.find(v => v.venue_id === null && v.role_id)) {
      loggedUserVenuesPermissionsArray.push(...venues);
    }

    userVenuesAuthorisations.forEach((a) => {
      if (!!a.venue_id && a.role_id !== 5 && a.role_id !== 1) {
        const venue: VenueData = venues.find(v => v.id === a.venue_id);

        loggedUserVenuesPermissionsArray.push({ ...a, ...venue });
      }
    })

    return loggedUserVenuesPermissionsArray;
  },
  GET_USER_AUTHORISATIONS: (state): Array<UserAuthorisationsData> => state.userVenuesPermissions,
  GET_ADD_USER_LOADER: (state): boolean => state.addUserLoader,
  GET_USER_HAS_ACCESS: (state): boolean => state.userHasAccess,
  GET_LOADER: (state): boolean => state.loader,
  GET_USER_VENUES_AUTHORISATIONS: (state): Array<UserAuthorisationsData> => state.usersAuthorisations,
  HAS_USER_ROLE_PERMISSION: (state): boolean => state.hasUserRolePermission,
  GET_ZONE_TYPE: (state): string => {
    if (!!sessionStorage.getItem("access_token")) {
      if (state.loggedUser.is_superadmin) return "admin_zone";
      return "client_zone";
    }

    return "all";
  },
  IS_USER_LOGGED: (state): boolean => state.isUserLogged,
  IS_USER_SUPER_ADMIN: (state): boolean => state.loggedUser.is_superadmin,
  IS_BROWSER_STREAMER_VISIBLE: (state, getters): boolean => {
    if (getters["IS_USER_LOGGED"]) {
      return !state.loggedUser.is_superadmin;
    }

    return false;
  },
  GET_WEB_STREAMING_PERMISSION: (state): boolean => state.hasWebStreamingPermission,
  GET_APP_FEATURES_PERMISSION: (state): boolean => state.hasAppFeaturesPermission,
  GET_EDIT_SUBSCRIPTION_PERMISSION: (state): boolean => state.hasEditSubscriptionPermission,
  GET_CHANGE_PLAYLIST_PERMISSION: (state): boolean => state.hasChangePlaylistPermission,
  GET_LIST_OF_USERS_PERMISSION: (state): boolean => state.hasListOfUsersPermission,
  GET_MANAGE_USER_PERMISSION: (state): boolean => state.hasManageUserPermission,
  GET_ROLE_MANAGEMENT_PERMISSION: (state): boolean => state.hasRoleManagementPermission,
  GET_EDIT_COMPANY_PERMISSION: (state): boolean => state.hasEditCompanyPermission,
  GET_CREATE_DELETE_VENUE_PERMISSION: (state): boolean => state.hasCreateDeleteVenuePermission,
  GET_EDIT_VENUE_PERMISSION: (state): boolean => state.hasEditVenuePermission,
  GET_INVOICES_PERMISSION: (state): boolean => state.hasInvoicesPermission,
  GET_PAYMENT_METHOD_PERMISSION: (state): boolean => state.hasPaymentMethodPermission,
  GET_VIEW_AUDIO_CAMPAIGNS_PERMISSION: (state): boolean => state.hasViewAudioCampaignsPermission,
  GET_MANAGE_AUDIO_CAMPAIGNS_PERMISSION: (state): boolean => state.hasManageAudioCampaignsPermission,
  GET_MANAGE_AUDIO_SPOTS_PERMISSION: (state): boolean => state.hasManageAudioSpotsPermission,
  GET_VENUE_SURVEY_PERMISSION: (state): boolean => state.hasVenueSurveyPermission,
  GET_SEND_MESSAGES_PERMISSION: (state): boolean => state.hasSendMessagesPermission,
  GET_HISTORY_OF_MESSAGES_PERMISSION: (state): boolean => state.hasHistoryOfMessagesPermission
}
