import { SUMMERY_TYPE_COLOR, SUMMARY_TYPES, USER_ROLES } from "@/types/constants";

export function getSummaryColor(summaryType: number | string) {
  const type = SUMMARY_TYPES[summaryType]
  return SUMMERY_TYPE_COLOR[type]
}

export function getUserRole(id: number): string {
  let userRole: string;

  switch (id) {
    case USER_ROLES.ADMIN:
      userRole = "Admin";
      break;

    case USER_ROLES.OPERATOR:
      userRole = "Venue Manager";
      break;

    case USER_ROLES.MARKETER:
      userRole = "Marketer";
      break;

    case USER_ROLES.ACCOUNTANT:
      userRole = "Accountant";
      break;
  }

  return userRole;
}

export function formatUnitPrice(value: number): string {
  let formattedValue = value
  if (value % 1 != 0) {
    //@ts-ignore
    formattedValue = value.toFixed(2)
    formattedValue = formattedValue / 100
    //@ts-ignore
    formattedValue = formattedValue.toFixed(2)
    formattedValue = formattedValue * 100
    formattedValue = Math.floor(formattedValue)
  }

  const price: string = formattedValue.toString();
  return price.substring(0, price.length - 2) + "," + price.substring(price.length - 2);
}

export function fillWithMockData(value: Array<any>, definedLength: number, maxLength: number, mockData: any): Array<any> {
  const mixedContentArray: Array<any> = [] as Array<any>;

  if (value) {
    if (value.length > definedLength) {
      return value;
    } else {
      const mockDataLength: number = maxLength - value.length;

      value.forEach((v) => {
        mixedContentArray.push(v);
      })

      for (let i = 0; i < mockDataLength; i++) {
        mixedContentArray.push(mockData)
      }

      return mixedContentArray;
    }
  } else {
    for (let i = 0; i < maxLength; i++) {
      mixedContentArray.push(mockData)
    }

    return mixedContentArray;
  }
}

export function checkIfArrayIsUnique(myArray: Array<any>): boolean {
  return myArray.length === new Set(myArray).size;
}

export function getEmailLocale(countryCode: number): string {
  if (countryCode == 1) {
    return 'sk'
  } else {
    return 'en'
  }
}

export function isPeriodicityButtonDisabled(company: Object, billingPeriodicity: number) {
  if (!this.company || this.registerLayout) return false;
  if (this.company.billing_interval === billingPeriodicity) {
    return false;
  } else return this.company.corporate;
}

export const ROOT_ACTION = {
  root: true
}

export const WINDOW_WIDTH: number = window.innerWidth;

export const CASSOVIA_API_RESOURCE: string = process.env.VUE_APP_CASSOVIA_API;
export const SONGOROO_API_RESOURCE: string = process.env.VUE_APP_SONGOROO_API;

export const SGR_CLIENTZONE_API_KEY: string = process.env.VUE_APP_SGR_CLIENTZONE_API_KEY;

export const SONGOROO_WEB: string = process.env.VUE_APP_SONGOROO_WEB;
