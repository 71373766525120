export default {
    "Email is required.": "Email je povinen.",
    "Please enter a valid email address.": "Prosím zadejte platnou emailovou adresu.",
    "Please enter a valid VAT ID.": "Prosím zadejte platné IČ DPH.",
    "Please enter a valid swift code.": "Prosím zadejte platný swift kód.",
    "Please enter a valid IBAN.": "Prosím zadejte platný IBAN.",
    "First name is required.": "Křestní jméno je povinné.",
    "Last name is required.": "Příjmení je povinné.",
    "Job position is required.": "Pracovní pozice je povinná.",
    "Password is required.": "Heslo je povinné.",
    "Password must have at least": "Heslo musí mít minimálně",
    "Passwords must be identical.": "Hesla se musí shodovat.",
    "Repeat password": "Zopakujte heslo",
    "letters.": "znaků.",
    "letter.": "znaky.",
    "Access denied": "Přístup zamítnut",
    "Operation failed": "Operace selhala",
    "We are sorry but we were unable to find your venue based on selected search criteria. Please try using alternative search criteria or register manually.": "Je nám líto, ale na základě vybraných vyhledávacích kritérií se nám nepodařilo najít vaši provozovnu. Zkuste použít alternativní kritéria vyhledávání nebo se zaregistrujte ručně.",
    "Thank you.": "Děkujeme vám.",
    "Bank name is required.": "Jméno banky je povinné.",
    "IBAN is required.": "IBAN je povinen.",
    "This is not a valid International Bank Account Number (IBAN).": "Prosím zadejte platný IBAN.",
    "Swift code is required.": "Swift kód je povinen.",
    "Name is required.": "Obchodní jméno je povinen udej.",
    "Legal entity name is required.": "Název společnosti je povinen údaj.",
    "Business type is required.": "Typ podnikání je povinen.",
    "Address is required.": "Adresa je povinen údaj.",
    "Postal code is required.": "PSČ je povinné.",
    "Please enter valid postal code": "Zadejte platné PSČ.",
    "GPS latitude is required.": "GPS zeměpisná šířka je povinná.",
    "GPS latitude must be a number.": "GPS zeměpisná šířka musí být číslo.",
    "GPS longitude is required.": "GPS zeměpisná délka je povinná.",
    "GPS longitude must be a number.": "GPS zeměpisná délka musí být číslo.",
    "City is required.": "Město je povinné.",
    "Country is required.": "Země je povinná.",
    "This email is already registered.": "Tento e-mail je již registrován.",
    "Entity name is required.": "Jméno právnické osoby je povinné",
    "Zip code is required.": "PSČ je povinné.",
    "Please enter valid zip code": "Zadejte platné PSČ",
    "Playlist is required.": "Playlist je povinen.",
    "Venue name must be less than 30 characters.": "Název provozovny musí mít méně než 30 znaků",
    "Name must be less than 30 characters.": "Jméno musí mít méně než 30 znaků",
    "Venue's address is required.": "Adresa provozovny je povinná.",
    "Contact person is required.": "Kontaktní osoba je povinná.",
    "Contact phone is required.": "Telefon kontaktní osoby je povinen.",
    "All fields are required.": "Všechna pole jsou povinná.",
    "Please select venues.": "Prosím zvolte provozovnu pro danou kampaň.",
    "No venues available for this campaing.": "Žádné provozovny nejsou dostupné pro danou kampaň.",
    "No venues available for this survey.": "Žádné provozovny nejsou dostupné pro danou kampaň.",
    "Please select audio spot.": "Vyberte audio spot",
    "Please select at least one question.": "Vyberte alespoň jednu otázku.",
    "First name must have at least": "Křestní jméno musí mít alespoň",
    "First name must have a maximum of": "Křestní jméno musí mít nejvíce",
    "Last name must have at least": "Příjmení musí mít alespoň",
    "Last name must have a maximum of": "Příjmení musí mít nejvíce",
    "Value must be between -90 and 90": "Hodnota musí být mezi -90 a 90",
    "Value must be between -180 and 180": "Hodnota musí být mezi -180 a 180",
    "Remove duplicate audio spots.": "Odstraňte duplicitní zvukové spoty",
    "Sorry, survey can have up to 5 questions.": "Průzkum může mít nejvýše 5 otázek.",
    "Please take some of selected questions out.": "Odeberte některé z vybraných otázek",
    "Discount rate is required.": "Sleva v procentech je povinné.",
    "Discount rate must be between 1 and 100.": "Sleva musí být v mezi 1 a 100.",
    "Value cannot be less than 1.": "Hodnota musí být vyšší než 0.",
    "Campaign name is required.": "Název kampaně je povinen.",
    "Select at least one.": "Vyberte alespoň jednu možnost.",
    "Date not specified": "Vyberte datum",
    "Venue is required.": "Název provozny je povinen.",
    "Product name is required.": "Název produktu je povinen.",
    "Product price is required.": "Cena produktu je povinná.",
    "Company data are required.": "Prosím vyberte společnost.",
    "Billing period is required.": "Fakturační období je povinen údaj.",
    "Date of delivery is required.": "Datum dodání je povinen údaj.",
    "Invoice number is required.": "Číslo faktury je povinen údaj.",
    "Variable symbol is required.": "Variabilní symbol je povinen údaj.",
    "Campaign needs to start before its end.": "Kampaň musí začít před jejím koncem.",
    "Advert spot campaign start and end time overlap existing campaigns": "Zvolený čas kampaně se překrývá se stávající kampaní",
    "Discount code must be valid: apply valid discount code or delete your discount code input.": "Pro pokračování, vymažte neplatný nebo zadejte platný slevový kód.",
}
