import ApiService from "@/services/api.service";
import { CompanyData } from "@/types";
import { ActionTree } from "vuex";
import router from "@/router";
import { successMessage } from "@/utils/messages-handlers";
import { CompanyState, RootState } from "@/types/store/clientzone";

export const actions: ActionTree<CompanyState, RootState> = {
  FETCH_COMPANIES: ({ commit, dispatch }, login: boolean = false): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      const resource: string = `/api/v1/clientzone/venue_owner?limit=1000`;

      try {
        const response = await ApiService.get(resource)

        commit("SET_COMPANIES", response.data);

        if (response.data.length === 1) {
          await dispatch("SET_COMPANY", response.data[0].id);
          localStorage.setItem("company_id", response.data[0].id);
        }

        if (localStorage.getItem("company_id")) {
          await dispatch("SET_COMPANY", localStorage.getItem("company_id"));
          document.body.classList.add("home");
        }

        // if (response.data.length === 1) await dispatch("SET_COMPANY", response.data[0].id);

        //this condition checkes whether is users between steps 3 and 5 in registration. In case he is, he is not redirected to dashpoard or select-company page
        if (!sessionStorage.getItem('isLoggedFromRegistration')) {
          if (login) {
            if (response.data.length > 1 && router.currentRoute.name !== "select-company" && !localStorage.getItem("company_id")) {
              await router.push("/select-company");
            } else {
              await router.push("/dashboard");
              document.body.classList.add("home");
            }
          }
        }

        resolve();
      } catch (e) {
        reject()
      }
    })
  },
  SET_COMPANY: ({ commit }, companyId: number): Promise<void> => {
    localStorage.setItem("company_id", companyId.toString());

    return new Promise(async (resolve, reject) => {
      const resource: string = `/api/v1/clientzone/venue_owner/${companyId}`;

      try {
        const response = await ApiService.get(resource)

        commit("SET_COMPANY", response.data);

        resolve();
      } catch (e) {
        reject()
      }
    })
  },
  UPDATE_COMPANY: ({ commit, getters }, payload: object): Promise<void> => {
    const companyId: CompanyData = getters["GET_COMPANY_ID"];

    return new Promise(async (resolve, reject) => {
      const resource: string = `api/v1/clientzone/venue_owner/${companyId}`;

      try {
        const response = await ApiService.put(resource, payload)

        commit("SET_COMPANY", response.data);
        successMessage("Company profile updated.", 3000);

        resolve();
      } catch (e) {
        reject()
      }
    })
  },
  SET_COMPANY_IMAGE: ({ commit }, payload): void => commit("SET_COMPANY_IMAGE", payload),
  FETCH_ROLE_PERMISSIONS: ({ commit }): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      // const resource: string = "/api/v1/clientzone/user/role/permission";
      const resource: string = "/api/v1/clientzone/codelist/permission";

      try {
        const response = await ApiService.get(resource);

        commit("SET_ROLE_PERMISSIONS", response.data);

        resolve();
      } catch (e) {
        reject()
      }
    })
  },
  FETCH_ROLE_PERMISSIONS_ASSOCIATIONS: ({ commit, getters }): Promise<void> => {
    const companyId: CompanyData = getters["GET_COMPANY_ID"];

    return new Promise(async (resolve, reject) => {
      const resource: string = `/api/v1/clientzone/user/role_permission/venue_owner/${companyId}`;

      try {
        const response = await ApiService.get(resource);

        commit("SET_ROLE_PERMISSIONS_ASSOCIATIONS", response.data);

        resolve();
      } catch (e) {
        reject()
      }
    })
  },
  SET_LOADER: ({ commit }, payload: number): void => commit("SET_LOADER", payload),
  CLEAR_COMPANY: ({ commit }): void => {
    // #3733 odstranenie predchadzajucich dat o spolocnosti
    commit("RESET_STATE");
  },
}
