import { ActionTree } from "vuex";
import { ProductAdminzoneState, RootState } from "@/types/store/adminzone";
import { CASSOVIA_API_RESOURCE } from "@/utils/helpers";
import { successMessage } from "@/utils/messages-handlers";
import AxiosService from "@/services/axios.service";

export const actions: ActionTree<ProductAdminzoneState, RootState> = {
  FETCH_PRODUCTS: ({commit, dispatch}): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      const resource: string =`${CASSOVIA_API_RESOURCE}/api/stripe/stripe-product-with-prices?page=1`;
      try{
        const response = await AxiosService.getAxios().get(resource)
        commit("SET_PRODUCTS", response.data)

        resolve()
      }catch (e){
        reject()
      }
    })
  },
  CREATE_NEW_PRODUCT: ({commit, dispatch}, payload): Promise<void> => {
    return new Promise(async(resolve, reject) => {
      const resource: string =`${CASSOVIA_API_RESOURCE}/api/stripe/stripe-product-with-prices`;
      try{
        const response = await AxiosService.getAxios().post(resource, payload)
        successMessage("New additional service created successfully", 3000);
        resolve();
      }catch(e){
        reject()
      }
    })
  },
  UPDATE_PRODUCT_PRICE: ({commit, dispatch}, {productId: productId, payload: payload, songorooPackageType: songorooPackageType}): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      let songorooPackage = null;
      if( songorooPackageType != 3){
         songorooPackage = songorooPackageType == 1 ? 'Basic' : 'Premium';
      }
      const resource: string = `${CASSOVIA_API_RESOURCE}/api/stripe/update-price/${productId}`;
      try{
        await AxiosService.getAxios().patch(resource, payload, {
          headers: {
            'Content-Type': 'application/merge-patch+json'
          }
        })
        if(songorooPackage) {
          successMessage(`${songorooPackage} price was updated successfully`, 3000);
        }

        resolve();
      }catch (e){
        reject()
      }
    })
  },
  UPDATE_ADDITIONAL_SERVICE_NAME_AND_PRICE: ({commit, dispatch}, {productId: productId, unitAmount: unitAmount,  payload: payload}): Promise<void>=> {
    return new Promise(async (resolve, reject)=> {
      const resource: string = `${CASSOVIA_API_RESOURCE}/api/stripe/stripe-product-with-prices/${productId}`
      try{
        await AxiosService.getAxios().patch(resource, payload, {
          headers: {
            'Content-Type': 'application/merge-patch+json'
          }
        })
        const updatePricePayload = {
          'unitAmount': Number (unitAmount)
        };
        const songorooPackageType = 3;
        await dispatch("UPDATE_PRODUCT_PRICE", {productId: productId, payload: updatePricePayload, songorooPackageType: songorooPackageType});
        resolve()
      }catch(e){
        reject()
      }
    })
  },
  UPDATE_MAIN_PACKAGE_NAME: ({commit, dispatch}, payload): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      const resource: string = `${CASSOVIA_API_RESOURCE}/api/stripe/update-product-name`
      try{
        await AxiosService.getAxios().post(resource, payload);
        resolve()
      }catch (e){
        reject()
      }
    })
  },
  UPDATE_ADITIONAL_SERVICE_NAME: ({commit, dispatch}, {productId: productId, payload: payload}): Promise<void> => {
    return new Promise(async(resolve, reject) => {
      const resource: string = `${CASSOVIA_API_RESOURCE}/api/stripe/stripe-product-with-prices/${productId}`
      try{
        await AxiosService.getAxios().patch(resource, payload, {
          headers: {
            'Content-Type': 'application/merge-patch+json'
          }
        })
        resolve();
      }catch(e){
        reject();
      }
    })
  }

}
